import React from "react";
import { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {generateKeyBetween, generateNKeysBetween} from "fractional-indexing"

import { motion, AnimatePresence } from "framer-motion";

import { fallbackRender, add_sub, remove_subs } from "../utils.jsx";
import { COMP_REG } from "../comp_registry.js";
import g_fn from "../g_fn";

const AS = g_fn.AS;

// @req: keep reference to subs here and remove them on unmount or reload
const SUB_ID = "bnode_s5dvx";
const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};

// local code - e.g sub components

const comp_bnode_s5dvx = (props) => {
    const INFO = {
        model_id: "yvjbe",
        model_name: "test",
        op: "create_one",
        comp_id: "comp_bnode_s5dvx",
    };

    // Routes

    // FOR IS_MANY
    let idx = props.idx; // also for protection from error when we might think is many but it's not
    let V = props.V || {}; // for protection from error

    // STATES
    const [rerender, set_rerender] = useState({});
    const [M, set_M] = useState(props.M || {});
    const [PM, set_PM] = useState(props.PM || []);
    const [selected_M, set_selected_M] = useState(null);

    // REFS

    // EFFECTS

    useEffect(() => {
        console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
    }, [rerender]);

    useEffect(() => {
        const deinit = init();

        return () => {
            if (deinit && typeof deinit === "function") deinit();
        };
    }, []);

    useEffect(() => {
        // console.log("OP CREATE ONE PROPS CHANGED : ", INFO.model_id, INFO.comp_id, props, relation);
        if (!relation || !INFO.model_id || !INFO.comp_id) return;
        if (props.PM && Array.isArray(props.PM) && props.PM.length) {
            const parent_M = props.PM[props.PM.length - 1];
            const parent_M_copy = JSON.parse(JSON.stringify(parent_M));
            delete parent_M_copy[":db/id"];
            const model_data = parent_M_copy[relation.prop_name];
            if (!model_data) return;
            delete model_data[":db/id"];
            if (Object.keys(model_data).length === 0)
                return console.warn("NO RELATION DATA FOUND FROM PARENT MODEL");
            if (Object.keys(model_data).length === 1 && model_data.id) {
                (async () => {
                    const r = await AS.GSTORE.get_one_entity(
                        INFO.model_id,
                        model_data.id
                    );
                    if (r) {
                        // if we are getting from GSTORE, make sure to make a copy as the changes in form can alter the object because of reference.
                        const r_copy = JSON.parse(JSON.stringify(r));
                        set_M(r_copy);
                    }
                })();
            }
            if (Object.keys(model_data).length > 1) {
                set_M(model_data);
            }
        }
    }, [props]);

    useEffect(() => {
        if (!M) return;
        if (!INFO.model_id || !INFO.comp_id) return;
        const model_id = INFO.model_id;
        const comp_id = INFO.comp_id;

        const idx = props.idx || props.IDX;

        const subscribe_id = idx || 0;

        const model = g_fn.get_model(model_id);
        if (!model) return;
        const id_prop = model.props.find((p) => p.name === "id");
        if (
            model.name.toLowerCase() === "user" &&
            id_prop &&
            id_prop.type === "user_id"
        ) {
            if (M.email) {
                (async () => {
                    const user_id = await g_fn.generate_user_id_by_email(M);
                    // loop breaker for user_id in the following line, to be fixed properly
                    if (user_id === M.id) return;
                    if (user_id) {
                        // not storing id in draft, if the same component is mapped, id will also be same for different entities
                        set_M((PM) => {
                            PM.id = user_id;

                            console.log("SETTING REACT STATE : ", PM);
                            AS.GSTORE.update_draft(model_id, comp_id, subscribe_id, PM);
                            return PM;
                        });
                        return;
                    }
                })();
                return;
            }
        }
        AS.GSTORE.update_draft(model_id, comp_id, subscribe_id, M);
    }, [M]);

    useEffect(() => {
        if (EN_BROKEN_COMP_LOGS.MODEL_EFFECT) {
            const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
            console.log(
                "MODEL CHANGED : " + MI + "   => ",
                "model",
                M,
                " props",
                props
            );
        }
    }, [M]);

    useEffect(() => {
        if (!INFO.model_id) return;

        if (query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
        const id = g_fn.get_selected_entity_id(props, INFO);
        if (!id) {
            return console.warn("NO ID FOUND TO UPDATE ENTITY");
        }

        (async () => {
            const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
            console.log("GSTORE GET ONE ENTITY : ", r);
            if (r) set_selected_M(r);
        })();

        const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
            const id = g_fn.get_selected_entity_id(props, INFO);
            if (!id)
                return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
            (async () => {
                const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
                console.log(
                    "GSTORE GET ONE ENTITY IN SUBSCRIPTION : ",
                    r,
                    INFO.model_id,
                    INFO.comp_id
                );
                if (r) set_selected_M(r);
            })();
        });

        // On set in GSTORE set state
        const sub2 = AS.GSTORE.subscribe((e) => {
            const { type, model_id, eid, data } = e;
            if (!data || !data.id) return;
            if (type !== 'set') return;
            if (model_id !== INFO.model_id) return;
            set_M(M => {
                if (eid !== M.id) return M;
                return data;
            })
        });

        return () => {
            sub.unsubscribe();
            sub2.unsubscribe();
        }
    }, []);

    // FUNCTIONS

    const init = () => {
        if (!INFO.model_id || !INFO.comp_id) return;

        const model_id = INFO.model_id;
        const comp_id = INFO.comp_id;

        const subs = [];

        const id = g_fn.get_ulid();

        const idx = props.idx || props.IDX;

        const subscribe_id = idx || 0;

        subs.push(
            AS.GSTORE.subscribe_draft(model_id, comp_id, subscribe_id, (e) => {
                console.log(
                    "SUBSCRIBE DRAFT : ",
                    e,
                    comp_id,
                    props.idx,
                    props.IDX,
                    subscribe_id
                );

                if (e.data === null) {
                    set_M((M) => {
                        for (let [k, v] of Object.entries(M)) {
                            if (k === "id") continue;
                            delete M[k];
                        }
                    });
                    return M;
                }

                if (e.data) {
                    console.log("DRAFT DATA : ", e.data);
                    const D = e.data;
                    set_rerender({}); // to re-render
                    set_M((M) => {
                        console.log("DRAFT IS SAME AS M : ", D === M, D, M);
                        if (D === M) {
                            return D; // same object
                        }
                        if (D?.id !== M.id) {
                            console.log("NOT SETTING DATA FROM A DIFFERENT FORM : ", D, M);
                            return M; // ids are not equal which means data from a different form
                        }
                        return D;
                    });
                }
            })
        );

        subs.push(
            AS.GSTORE.subscribe_message(model_id, (e) => {
                if (e.eid && e.message !== "CREATED") return;

                set_M((M) => {
                    return { id: g_fn.get_ulid() };
                });

                if (!relation) return;

                // if relation exists, update the parent model draft

                const rel_eid = props.id || null;
                console.log("RELATION ENTITY ID : ", rel_eid);

                console.log("RELATION : ", relation);
                const rel_mid = relation.model_id;
                const rel_cid = relation.comp_id || props.INFO?.comp_id;
                const updates = { [relation.prop_name]: e.data };
                AS.GSTORE.update_draft(rel_mid, rel_cid, subscribe_id, updates);
            })
        );

        // one time setup
        set_M((M) => {
            const model = g_fn.get_model(model_id);
            let draft = AS.GSTORE.get_draft(model_id, comp_id, subscribe_id);
            if (!draft) {
                draft = {};
            }

            // drafts id can be same if the same component is mapped, so initialising new id every time
            if (M) return { ...M, ...draft, id };

            return draft;
        });

        return () => {
            subs.forEach((s) => s.unsubscribe());
        };
    };

    // const [inp_prompt, set_inp_prompt] = useState("")
    const [ques_string, set_ques_string] = useState("");
    const [show_loading, set_show_loading] = useState(false);
    const generate_test_ai = (e) => {
        (async () => {
            console.log("e is: ", e);
            set_show_loading(true);
            console.log("M in generate test ai fn is:", M);

            let doc_content = M.doc?.text || "";

            let system_msg = "";

            if (doc_content) {
                system_msg =
                    "You are a quiz generator you will be given a text document , context  and an extra prompt use that to generate test";
            } else {
                system_msg =
                    "You are a quiz generator you will be given context and an extra prompt use that to generate test with points betweeen 0 and 5 and exactly 2 tags for each question in json format";
            }

            const ctx = {
                grade: M.grade,
                subject: M.subject,
                curriculum: M.curriculum,
                doc: doc_content,
            };

            const prompt = M.ai_input_prompt;

            const data = {
                system_msg: system_msg,
                ctx: ctx,
                topic: prompt,
            };

            // const URL = "http://127.0.0.1:8787";
            const URL = "https://api-manager.brokenatom.workers.dev"
            const resp = await fetch(URL + "/input/openai/prompts", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }).catch((e) => {
                console.log("error is : ", e);
                set_show_loading(false);
                return undefined;
            });

            if (!resp) {
                console.warn("no response");
                set_show_loading(false);
                return;
            }

            const res = await resp.json();
            console.log("res in json is : ", res);
            set_show_loading(false);
            const test_obj = res.data[0].chatModelResult;
            console.log("test object is :", test_obj);
            const questions = test_obj.questions;

            if (!questions || !questions.length) {
                console.warn("no questions generated");
                g_fn.feedback(
                    "No questions generated from AI. please give proper input prompt"
                );
                return;
            }

            const app = g_fn.GET_GC().APP_JSON;
            if (!app) return console.warn("app json not found");

            const ques_id_arr = [];

            const questions_data = []; // matching the format of question model
            for (let q of questions) {
                let correct_answer = [];
                if (q.options.a.correct) {
                    correct_answer.push({ id: "a0", v: q.options.a.option });
                }
                if (q.options.b.correct) {
                    correct_answer.push({ id: "a1", v: q.options.b.option });
                }
                if (q.options.c.correct) {
                    correct_answer.push({ id: "a2", v: q.options.c.option });
                }
                if (q.options.d.correct) {
                    correct_answer.push({ id: "a3", v: q.options.d.option });
                }
                const tag_data = [];
                const tags = q?.tags;
                for (let i = 0; i < tags?.length; i++) {
                    tag_data.push({ id: `a${i}`, v: tags[i] });
                }
                const data = {
                    question: q.question,
                    answers: [
                        { id: "a0", v: q.options.a.option },
                        { id: "a1", v: q.options.b.option },
                        { id: "a2", v: q.options.c.option },
                        { id: "a3", v: q.options.d.option },
                    ],
                    correct_answers: correct_answer,
                    answer_explanation: q.answer_explanation,
                    tags: tag_data,
                    points: q.points,
                    type: "MCQs",
                };
                questions_data.push(data);
            }

            // create findex
            const fidxs = generateNKeysBetween(null, null, questions_data.length);
            questions_data.forEach((data, idx)=>{
                data.findex = fidxs[idx];
            });

            
            for(let data of questions_data){
                const question_model = app.models.find((m) => m.name === "question");
                if (!question_model) return console.warn("question model not found");

                console.log("data is: ", data);
                const ques_create = await g_fn.AS.GSTORE.create_one(
                    question_model.id,
                    data
                );
                if (!ques_create) {
                    console.warn("errror in question create  ");
                    return;
                }
                console.log("ques create one is: ", ques_create);
                const ques_id = ques_create.data?.id;
                console.log("ques id : ", ques_id);
                ques_id_arr.push(ques_id);
            }

            console.log("ques id array : ", ques_id_arr);

            M["questions"] = ques_id_arr;
            M["last_modified"] = Date.now();
            M["status"] = "Draft";

            const test_model = app.models.find((m) => m.name === "test");
            if (!test_model) return console.warn("test model not found");

            const test_create = await g_fn.AS.GSTORE.create_one(test_model.id, M);
            if (!test_create || !test_create.data) {
                console.warn("errror in test create  ");
                return;
            }
            console.log("test created is: ", test_create);
            console.log(" current el is: ", e.currentTarget);
            g_fn.bro_select_one(e, test_create.data, INFO, props);
            AS.navigate("bnode_jj973");
        })();
    };

    // STATEMENTS

    INFO.set_M = set_M;
    INFO.on_created = props.on_created || props.INFO?.on_created;
    INFO.on_selected = props.on_selected || props.INFO?.on_selected;
    const relation = null;
    const query = null;

    // CONDITIONALS ATTRS
    const COND_ATTRS = {};

    COND_ATTRS["bnode_1mveb"] = COND_ATTRS["bnode_1mveb"] || {};

    COND_ATTRS["bnode_38ffk"] = COND_ATTRS["bnode_38ffk"] || {};

    COND_ATTRS["bnode_h9cqq"] = COND_ATTRS["bnode_h9cqq"] || {};

    COND_ATTRS["bnode_bn6mf"] = COND_ATTRS["bnode_bn6mf"] || {};

    COND_ATTRS["bnode_k335p"] = COND_ATTRS["bnode_k335p"] || {};

    COND_ATTRS["bnode_dl43s"] = COND_ATTRS["bnode_dl43s"] || {};

    COND_ATTRS["bnode_9kgjy"] = COND_ATTRS["bnode_9kgjy"] || {};

    COND_ATTRS["bnode_0un5f"] = COND_ATTRS["bnode_0un5f"] || {};

    COND_ATTRS["bnode_6gmt7"] = COND_ATTRS["bnode_6gmt7"] || {};

    COND_ATTRS["bnode_o5m6c"] = COND_ATTRS["bnode_o5m6c"] || {};

    COND_ATTRS["bnode_c53dr"] = COND_ATTRS["bnode_c53dr"] || {};

    COND_ATTRS["bnode_seffj"] = COND_ATTRS["bnode_seffj"] || {};

    COND_ATTRS["bnode_8ks38"] = COND_ATTRS["bnode_8ks38"] || {};

    COND_ATTRS["bnode_60lx0"] = COND_ATTRS["bnode_60lx0"] || {};

    COND_ATTRS["bnode_tns9d"] = COND_ATTRS["bnode_tns9d"] || {};

    COND_ATTRS["bnode_tt6vj"] = COND_ATTRS["bnode_tt6vj"] || {};

    COND_ATTRS["bnode_fws9f"] = COND_ATTRS["bnode_fws9f"] || {};

    COND_ATTRS["bnode_5jcla"] = COND_ATTRS["bnode_5jcla"] || {};

    COND_ATTRS["bnode_oglo0"] = COND_ATTRS["bnode_oglo0"] || {};

    COND_ATTRS["bnode_rwigs"] = COND_ATTRS["bnode_rwigs"] || {};

    COND_ATTRS["bnode_uc8hl"] = COND_ATTRS["bnode_uc8hl"] || {};

    COND_ATTRS["bnode_teqc8"] = COND_ATTRS["bnode_teqc8"] || {};

    COND_ATTRS["bnode_d10jj"] = COND_ATTRS["bnode_d10jj"] || {};

    COND_ATTRS["bnode_actbm"] = COND_ATTRS["bnode_actbm"] || {};

    COND_ATTRS["bnode_0i0sf"] = COND_ATTRS["bnode_0i0sf"] || {};

    COND_ATTRS["bnode_1km1c"] = COND_ATTRS["bnode_1km1c"] || {};

    COND_ATTRS["bnode_fbf1h"] = COND_ATTRS["bnode_fbf1h"] || {};

    COND_ATTRS["bnode_xllkz"] = COND_ATTRS["bnode_xllkz"] || {};

    COND_ATTRS["bnode_3ozal"] = COND_ATTRS["bnode_3ozal"] || {};

    COND_ATTRS["bnode_8q3d9"] = COND_ATTRS["bnode_8q3d9"] || {};

    COND_ATTRS["bnode_mup9l"] = COND_ATTRS["bnode_mup9l"] || {};

    COND_ATTRS["bnode_vv8mx"] = COND_ATTRS["bnode_vv8mx"] || {};

    COND_ATTRS["bnode_xpo1n"] = COND_ATTRS["bnode_xpo1n"] || {};

    COND_ATTRS["bnode_54izh"] = COND_ATTRS["bnode_54izh"] || {};

    COND_ATTRS["bnode_sjp9l"] = COND_ATTRS["bnode_sjp9l"] || {};

    COND_ATTRS["bnode_3ljbt"] = COND_ATTRS["bnode_3ljbt"] || {};

    COND_ATTRS["bnode_35rvo"] = COND_ATTRS["bnode_35rvo"] || {};

    COND_ATTRS["bnode_0kx6n"] = COND_ATTRS["bnode_0kx6n"] || {};

    COND_ATTRS["bnode_7km1f"] = COND_ATTRS["bnode_7km1f"] || {};

    COND_ATTRS["bnode_1d7qv"] = COND_ATTRS["bnode_1d7qv"] || {};

    COND_ATTRS["bnode_gzllk"] = COND_ATTRS["bnode_gzllk"] || {};

    COND_ATTRS["bnode_6540y"] = COND_ATTRS["bnode_6540y"] || {};

    COND_ATTRS["bnode_121br"] = COND_ATTRS["bnode_121br"] || {};

    COND_ATTRS["bnode_ih8dq"] = COND_ATTRS["bnode_ih8dq"] || {};

    COND_ATTRS["bnode_3b2qw"] = COND_ATTRS["bnode_3b2qw"] || {};

    COND_ATTRS["bnode_kcxhv"] = COND_ATTRS["bnode_kcxhv"] || {};

    COND_ATTRS["bnode_vyt12"] = COND_ATTRS["bnode_vyt12"] || {};

    COND_ATTRS["bnode_ycqnh"] = COND_ATTRS["bnode_ycqnh"] || {};

    COND_ATTRS["bnode_wrfsy"] = COND_ATTRS["bnode_wrfsy"] || {};

    COND_ATTRS["bnode_n4puw"] = COND_ATTRS["bnode_n4puw"] || {};

    COND_ATTRS["bnode_7bchm"] = COND_ATTRS["bnode_7bchm"] || {};

    COND_ATTRS["bnode_9uqim"] = COND_ATTRS["bnode_9uqim"] || {};

    COND_ATTRS["bnode_3qisq"] = COND_ATTRS["bnode_3qisq"] || {};

    COND_ATTRS["bnode_7vudd"] = COND_ATTRS["bnode_7vudd"] || {};

    COND_ATTRS["bnode_88giy"] = COND_ATTRS["bnode_88giy"] || {};

    COND_ATTRS["bnode_ljky2"] = COND_ATTRS["bnode_ljky2"] || {};

    COND_ATTRS["bnode_s5dvx"] = COND_ATTRS["bnode_s5dvx"] || {};

    // DYNAMIC REACT STATES
    const REACT_STATES = {};
    REACT_STATES["M"] = { state: M, set_state: set_M };

    const AS_COMPONENTS = AS.components["comp_bnode_s5dvx"] || {};
    AS_COMPONENTS.REACT_STATES = REACT_STATES;
    AS.components["comp_bnode_s5dvx"] = AS_COMPONENTS;

    // MAPPED DATA
    const MAPPED_DATA = {};

    return (
        <ErrorBoundary
            fallbackRender={fallbackRender}
            onReset={(d) => {
                console.error(d);
            }}
        >
            <div
                data_id="bnode_s5dvx"
                name="test"
                op="create_one"
                className="w-full flex flex-col"
                id={"bnode_s5dvx" + (idx ? "__" + idx : "")}
                onClick={(e) => {
                    g_fn.dev.emit_m(e, M, INFO);
                }}
                extra-attr
            >
                <div
                    data_id="bnode_3b2qw"
                    name="test-data"
                    className="flex flex-col gap-4"
                    id={"bnode_3b2qw" + (idx ? "__" + idx : "")}
                    extra-attr
                >
                    <div
                        data_id="bnode_7km1f"
                        id={"bnode_7km1f" + (idx ? "__" + idx : "")}
                        className="flex items-center justify-center flex-row gap-2"
                        extra-attr
                    >
                        <div
                            data_id="bnode_o5m6c"
                            name="CONT-curriculum"
                            className="w-full"
                            id={"bnode_o5m6c" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <div
                                data_id="bnode_6gmt7"
                                id={"bnode_6gmt7" + (idx ? "__" + idx : "")}
                                className="gap-2"
                                extra-attr
                            >
                                <div
                                    data_id="bnode_1mveb"
                                    className="new-div w-full font-normal font-['Open_Sans'] flex justify-start items-center text-sm"
                                    id={"bnode_1mveb" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    Curriculum
                                </div>

                                <div
                                    data_id="bnode_0un5f"
                                    name="curriculum"
                                    className="flex flex-col  w-full gap-6 "
                                    id={"bnode_0un5f" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    <div
                                        data_id="bnode_9kgjy"
                                        className="flex flex-row items-center justify-center w-full gap-4"
                                        id={"bnode_9kgjy" + (idx ? "__" + idx : "")}
                                        extra-attr
                                    >
                                        <select
                                            data_id="bnode_dl43s"
                                            name="curriculum"
                                            placeholder="curriculum"
                                            action="oninput-update-m"
                                            className="bg-[#F3F4F6] p-2 w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] rounded-[12px] text-sm"
                                            id={"bnode_dl43s" + (idx ? "__" + idx : "")}
                                            model_id="yvjbe"
                                            onInput={(e) => {
                                                g_fn.bro_on_input(e, INFO);
                                            }}
                                            extra-attr
                                        >
                                            <option
                                                data_id="bnode_38ffk"
                                                selected="true"
                                                id={"bnode_38ffk" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            ></option>

                                            <option
                                                data_id="bnode_h9cqq"
                                                value="CBSE"
                                                selected={M.curriculum === "CBSE"}
                                                id={"bnode_h9cqq" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                CBSE
                                            </option>

                                            <option
                                                data_id="bnode_bn6mf"
                                                value="ICSE"
                                                selected={M.curriculum === "ICSE"}
                                                id={"bnode_bn6mf" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                ICSE
                                            </option>

                                            <option
                                                data_id="bnode_k335p"
                                                value="State Board"
                                                selected={M.curriculum === "State Board"}
                                                id={"bnode_k335p" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                State Board
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            data_id="bnode_teqc8"
                            name="CONT-grade"
                            className="w-full gap-2"
                            id={"bnode_teqc8" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <div
                                data_id="bnode_uc8hl"
                                id={"bnode_uc8hl" + (idx ? "__" + idx : "")}
                                extra-attr
                            >
                                <div
                                    data_id="bnode_c53dr"
                                    className="new-div w-full font-normal font-['Open_Sans'] flex justify-start items-center text-sm"
                                    id={"bnode_c53dr" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    Grade
                                </div>

                                <div
                                    data_id="bnode_rwigs"
                                    name="grade"
                                    className="flex flex-col  w-full gap-6 "
                                    id={"bnode_rwigs" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    <div
                                        data_id="bnode_oglo0"
                                        className="flex flex-row items-center justify-center w-full gap-4"
                                        id={"bnode_oglo0" + (idx ? "__" + idx : "")}
                                        extra-attr
                                    >
                                        <select
                                            data_id="bnode_5jcla"
                                            name="grade"
                                            placeholder="grade"
                                            action="oninput-update-m"
                                            className="bg-[#F3F4F6] p-2 rounded-[12px] w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] text-sm"
                                            id={"bnode_5jcla" + (idx ? "__" + idx : "")}
                                            model_id="yvjbe"
                                            onInput={(e) => {
                                                g_fn.bro_on_input(e, INFO);
                                            }}
                                            extra-attr
                                        >
                                            <option
                                                data_id="bnode_seffj"
                                                selected="true"
                                                id={"bnode_seffj" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            ></option>

                                            <option
                                                data_id="bnode_8ks38"
                                                value="8"
                                                selected={M.grade === "8"}
                                                id={"bnode_8ks38" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                8
                                            </option>

                                            <option
                                                data_id="bnode_60lx0"
                                                value="9"
                                                selected={M.grade === "9"}
                                                id={"bnode_60lx0" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                9
                                            </option>

                                            <option
                                                data_id="bnode_tns9d"
                                                value="10"
                                                selected={M.grade === "10"}
                                                id={"bnode_tns9d" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                10
                                            </option>

                                            <option
                                                data_id="bnode_tt6vj"
                                                value="11"
                                                selected={M.grade === "11"}
                                                id={"bnode_tt6vj" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                11
                                            </option>

                                            <option
                                                data_id="bnode_fws9f"
                                                value="12"
                                                selected={M.grade === "12"}
                                                id={"bnode_fws9f" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                12
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            data_id="bnode_0kx6n"
                            name="CONT-subject"
                            className="w-full gap-2"
                            id={"bnode_0kx6n" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <div
                                data_id="bnode_35rvo"
                                id={"bnode_35rvo" + (idx ? "__" + idx : "")}
                                extra-attr
                            >
                                <div
                                    data_id="bnode_d10jj"
                                    className="new-div w-full font-normal font-['Open_Sans'] flex justify-start items-center text-sm"
                                    id={"bnode_d10jj" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    Subject
                                </div>

                                <div
                                    data_id="bnode_3ljbt"
                                    name="subject"
                                    className="flex flex-col  w-full gap-6 "
                                    id={"bnode_3ljbt" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    <div
                                        data_id="bnode_sjp9l"
                                        className="flex flex-row items-center justify-center w-full gap-4"
                                        id={"bnode_sjp9l" + (idx ? "__" + idx : "")}
                                        extra-attr
                                    >
                                        <select
                                            data_id="bnode_54izh"
                                            name="subject"
                                            placeholder="subject"
                                            action="oninput-update-m"
                                            className="bg-[#F3F4F6] p-2 rounded-[12px] w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] text-sm"
                                            id={"bnode_54izh" + (idx ? "__" + idx : "")}
                                            model_id="yvjbe"
                                            onInput={(e) => {
                                                g_fn.bro_on_input(e, INFO);
                                            }}
                                            extra-attr
                                        >
                                            <option
                                                data_id="bnode_actbm"
                                                selected="true"
                                                id={"bnode_actbm" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            ></option>

                                            <option
                                                data_id="bnode_0i0sf"
                                                value="Mathematics"
                                                selected={M.subject === "Mathematics"}
                                                id={"bnode_0i0sf" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                Mathematics
                                            </option>

                                            <option
                                                data_id="bnode_1km1c"
                                                value="Science"
                                                selected={M.subject === "Science"}
                                                id={"bnode_1km1c" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                Science
                                            </option>

                                            <option
                                                data_id="bnode_fbf1h"
                                                value="Social Science"
                                                selected={M.subject === "Social Science"}
                                                id={"bnode_fbf1h" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                Social Science
                                            </option>

                                            <option
                                                data_id="bnode_xllkz"
                                                value="History"
                                                selected={M.subject === "History"}
                                                id={"bnode_xllkz" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                History
                                            </option>

                                            <option
                                                data_id="bnode_3ozal"
                                                value="Civics"
                                                selected={M.subject === "Civics"}
                                                id={"bnode_3ozal" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                Civics
                                            </option>

                                            <option
                                                data_id="bnode_8q3d9"
                                                value="Biology"
                                                selected={M.subject === "Biology"}
                                                id={"bnode_8q3d9" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                Biology
                                            </option>

                                            <option
                                                data_id="bnode_mup9l"
                                                value="Physics"
                                                selected={M.subject === "Physics"}
                                                id={"bnode_mup9l" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                Physics
                                            </option>

                                            <option
                                                data_id="bnode_vv8mx"
                                                value="Chemistry"
                                                selected={M.subject === "Chemistry"}
                                                id={"bnode_vv8mx" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                Chemistry
                                            </option>

                                            <option
                                                data_id="bnode_xpo1n"
                                                value="English"
                                                selected={M.subject === "English"}
                                                id={"bnode_xpo1n" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                English
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        data_id="bnode_ih8dq"
                        name="CONT-ai_input_prompt"
                        className="w-full"
                        id={"bnode_ih8dq" + (idx ? "__" + idx : "")}
                        extra-attr
                    >
                        <div
                            data_id="bnode_121br"
                            id={"bnode_121br" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <div
                                data_id="bnode_6540y"
                                name="ai_input_prompt"
                                className="flex flex-col  w-full gap-6 "
                                id={"bnode_6540y" + (idx ? "__" + idx : "")}
                                extra-attr
                            >
                                <div
                                    data_id="bnode_gzllk"
                                    name="cont"
                                    className="flex flex-row items-center justify-center w-full gap-4"
                                    id={"bnode_gzllk" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    <textarea
                                        data_id="bnode_1d7qv"
                                        name="ai_input_prompt"
                                        placeholder="5 questions on topic"
                                        value={M.ai_input_prompt}
                                        action="oninput-update-m"
                                        className="w-full rounded px-4 py-4 outline outline-[#FF5994] min-h-[150px] placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] text-sm"
                                        id={"bnode_1d7qv" + (idx ? "__" + idx : "")}
                                        style={{
                                            background: "rgb(255, 255, 255)",
                                            backgroundAttachment: "initial",
                                            backgroundClip: "initial",
                                            backgroundColor: "rgb(255, 255, 255)",
                                            backgroundImage: "initial",
                                            backgroundOrigin: "initial",
                                            backgroundPosition: "initial",
                                            backgroundPositionX: "initial",
                                            backgroundPositionY: "initial",
                                            backgroundRepeat: "initial",
                                            backgroundSize: "initial",
                                            borderBottomLeftRadius: "12px",
                                            borderBottomRightRadius: "12px",
                                            borderRadius: "12px",
                                            borderTopLeftRadius: "12px",
                                            borderTopRightRadius: "12px",
                                            boxShadow:
                                                "rgb(52, 136, 253) 0px 3px 7.8px 0px, rgb(255, 89, 148) 0px -2px 6.7px 0px",
                                            webkitBackgroundClip: "initial",
                                            webkitBackgroundOrigin: "initial",
                                            webkitBackgroundSize: "initial",
                                            webkitBorderBottomLeftRadius: "12px",
                                            webkitBorderBottomRightRadius: "12px",
                                            webkitBorderRadius: "12px",
                                            webkitBorderTopLeftRadius: "12px",
                                            webkitBorderTopRightRadius: "12px",
                                            webkitBoxShadow:
                                                "rgb(52, 136, 253) 0px 3px 7.8px 0px, rgb(255, 89, 148) 0px -2px 6.7px 0px",
                                        }}
                                        model_id="yvjbe"
                                        onInput={(e) => {
                                            g_fn.bro_on_input(e, INFO);
                                        }}
                                        extra-attr
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data_id="bnode_7bchm"
                    className="new-div w-full pt-5 gap-4 flex justify-start items-center"
                    id={"bnode_7bchm" + (idx ? "__" + idx : "")}
                    name="upload"
                    extra-attr
                >
                    <div
                        data_id="bnode_ycqnh"
                        className="new-div w-fit flex items-center justify-center gap-3 p-4 bg-[#F3F4F6] hover:text-white hover:bg-[#6B7280] rounded-[12px] cursor-pointer"
                        id={"bnode_ycqnh" + (idx ? "__" + idx : "")}
                        name="button"
                        extra-attr
                    >
                        <svg
                            data_id="bnode_kcxhv"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            data-lucide="upload"
                            className="w-[24px] h-[24px]"
                            id={"bnode_kcxhv" + (idx ? "__" + idx : "")}
                            b_type="icon"
                            extra-attr
                        >
                            <path
                                data_id=""
                                d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"
                                extra-attr
                            ></path>

                            <polyline data_id="" points="17 8 12 3 7 8" extra-attr></polyline>

                            <line data_id="" x1="12" x2="12" y1="3" y2="15" extra-attr></line>
                        </svg>

                        <label
                            data_id="bnode_vyt12"
                            className="new-div w-fit flex items-center justify-center font-['Open_Sans'] whitespace-nowrap text-sm font-normal"
                            id={"bnode_vyt12" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <input
                                type="file"
                                name=""
                                onInput={(e) => {
                                    const files = e.target.files;

                                    const reader = new FileReader();
                                    reader.onload = async (e) => {
                                        M.doc = {
                                            text: e.target.result,
                                            url: URL.createObjectURL(files[0]),
                                        };
                                        console.log(M.doc.text);
                                    };
                                    reader.readAsText(files[0]);

                                    console.log("readring: ", reader);
                                }}
                            />
                        </label>
                    </div>

                    <div
                        data_id="bnode_n4puw"
                        className="new-div w-fit flex justify-start items-center"
                        id={"bnode_n4puw" + (idx ? "__" + idx : "")}
                        extra-attr
                    >
                        <div
                            data_id="bnode_wrfsy"
                            className="new-div w-fit font-normal font-['Open_Sans'] italic flex justify-start items-start text-xs"
                            id={"bnode_wrfsy" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            Upload any document and generate quiz with your personalized
                            prompt.
                        </div>
                    </div>
                </div>

                <button
                    data_id="bnode_88giy"
                    className="group relative px-6 py-4 w-1/3 flex items-center justify-center flex-col bg-gradient-to-r from-[#FF5994] to-[#3488FD] hover:from-[#1E62C2] hover:to-[#E62368] overflow-hidden cursor-pointer transition-all ease-in-out duration-500 rounded-[12px] font-bold font-['Open_Sans'] text-2xl text-white mt-5 mx-auto"
                    id={"bnode_88giy" + (idx ? "__" + idx : "")}
                    model_id="yvjbe"
                    onClick={generate_test_ai}
                    extra-attr
                >
                    {!show_loading && (
                        <span
                            data_id="bnode_9uqim"
                            className="flex justify-end items-end font-['Open_Sans'] text-2xl font-semibold"
                            id={"bnode_9uqim" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            ✨Generate✨
                        </span>
                    )}
                    {show_loading && (
                        <div
                            data_id="bnode_7vudd"
                            id={"bnode_7vudd" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <svg
                                data_id="bnode_3qisq"
                                aria-hidden="true"
                                className="w-8 h-8 mr-2 text-white animate-spin fill-[#ff5994]"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                id={"bnode_3qisq" + (idx ? "__" + idx : "")}
                                b_type="icon"
                                extra-attr
                            >
                                <path
                                    data_id=""
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                    extra-attr
                                ></path>

                                <path
                                    data_id=""
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                    extra-attr
                                ></path>
                            </svg>
                        </div>
                    )}
                </button>

                <fn
                    data_id="bnode_ljky2"
                    name="fn:generateTestAI"
                    id={"bnode_ljky2" + (idx ? "__" + idx : "")}
                    extra-attr
                ></fn>
            </div>
        </ErrorBoundary>
    );
};

export default comp_bnode_s5dvx;
