import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_3mcwk";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_3mcwk = (props)=>{
			const INFO  = {"model_id":"yvjbe","model_name":"test","op":"get_one","comp_id":"comp_bnode_3mcwk"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					g_fn.bro_get_one(INFO, set_M, props);
				}, [props])
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = ()=>{

				if(!INFO.model_id) return;


				INFO.query = query; // @todo : make it possible to change in UI editor
				const id = g_fn.get_one_find_id(props, INFO, relation);
				if(!id) {
					// errors => [{code:string, msg:string}]
					// warning => []
					console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
					// return;
				}

				// subscription
				const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e)=>{
					console.log("USER RE-RENDERED");
					const data = e.data;
					set_M(data);
				});


				// first time
				AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);



				return ()=>{
					sub.unsubscribe();
				}

			}
		
const [show_delete_test_modal, set_show_delete_test_modal] = useState(false);
const delete_modal = (e) => {
  e.stopPropagation();
  set_show_delete_test_modal(true);
}
const close_test_delete_modal = (e) => {
  e.stopPropagation();
  set_show_delete_test_modal(false)
}


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_y63ii"] = COND_ATTRS["bnode_y63ii"] || {};
			

				COND_ATTRS["bnode_visd6"] = COND_ATTRS["bnode_visd6"] || {};
			

				COND_ATTRS["bnode_q1h7z"] = COND_ATTRS["bnode_q1h7z"] || {};
			

				COND_ATTRS["bnode_bfkwx"] = COND_ATTRS["bnode_bfkwx"] || {};
			

				COND_ATTRS["bnode_jhl8e"] = COND_ATTRS["bnode_jhl8e"] || {};
			

				COND_ATTRS["bnode_klp2f"] = COND_ATTRS["bnode_klp2f"] || {};
			

				COND_ATTRS["bnode_u3bfo"] = COND_ATTRS["bnode_u3bfo"] || {};
			

				COND_ATTRS["bnode_c4yst"] = COND_ATTRS["bnode_c4yst"] || {};
			

				COND_ATTRS["bnode_7qvsi"] = COND_ATTRS["bnode_7qvsi"] || {};
			

				COND_ATTRS["bnode_lmwe3"] = COND_ATTRS["bnode_lmwe3"] || {};
			

				COND_ATTRS["bnode_ddoky"] = COND_ATTRS["bnode_ddoky"] || {};
			

				COND_ATTRS["bnode_kad07"] = COND_ATTRS["bnode_kad07"] || {};
			

				COND_ATTRS["bnode_n123b"] = COND_ATTRS["bnode_n123b"] || {};
			

				COND_ATTRS["bnode_vgzhf"] = COND_ATTRS["bnode_vgzhf"] || {};
			

				COND_ATTRS["bnode_4yrbn"] = COND_ATTRS["bnode_4yrbn"] || {};
			

				COND_ATTRS["bnode_91xli"] = COND_ATTRS["bnode_91xli"] || {};
			

				COND_ATTRS["bnode_u0jw4"] = COND_ATTRS["bnode_u0jw4"] || {};
			

				COND_ATTRS["bnode_damc4"] = COND_ATTRS["bnode_damc4"] || {};
			

				COND_ATTRS["bnode_essfi"] = COND_ATTRS["bnode_essfi"] || {};
			

				COND_ATTRS["bnode_m3dtq"] = COND_ATTRS["bnode_m3dtq"] || {};
			

				COND_ATTRS["bnode_4k6xe"] = COND_ATTRS["bnode_4k6xe"] || {};
			

				COND_ATTRS["bnode_tp78i"] = COND_ATTRS["bnode_tp78i"] || {};
			

				COND_ATTRS["bnode_1rllc"] = COND_ATTRS["bnode_1rllc"] || {};
			

				COND_ATTRS["bnode_0yk88"] = COND_ATTRS["bnode_0yk88"] || {};
			

				COND_ATTRS["bnode_3lhqi"] = COND_ATTRS["bnode_3lhqi"] || {};
			

				COND_ATTRS["bnode_zjryc"] = COND_ATTRS["bnode_zjryc"] || {};
			

				COND_ATTRS["bnode_iqrqc"] = COND_ATTRS["bnode_iqrqc"] || {};
			

				COND_ATTRS["bnode_8jrdf"] = COND_ATTRS["bnode_8jrdf"] || {};
			

				COND_ATTRS["bnode_ekpt7"] = COND_ATTRS["bnode_ekpt7"] || {};
			

				COND_ATTRS["bnode_sndku"] = COND_ATTRS["bnode_sndku"] || {};
			

				COND_ATTRS["bnode_el50k"] = COND_ATTRS["bnode_el50k"] || {};
			

				COND_ATTRS["bnode_elth9"] = COND_ATTRS["bnode_elth9"] || {};
			

				COND_ATTRS["bnode_29x5l"] = COND_ATTRS["bnode_29x5l"] || {};
			

				COND_ATTRS["bnode_h2gai"] = COND_ATTRS["bnode_h2gai"] || {};
			

				COND_ATTRS["bnode_hx9cy"] = COND_ATTRS["bnode_hx9cy"] || {};
			

				COND_ATTRS["bnode_f6cvo"] = COND_ATTRS["bnode_f6cvo"] || {};
			

				COND_ATTRS["bnode_y6y21"] = COND_ATTRS["bnode_y6y21"] || {};
			

				COND_ATTRS["bnode_jyk39"] = COND_ATTRS["bnode_jyk39"] || {};
			

				COND_ATTRS["bnode_17qu1"] = COND_ATTRS["bnode_17qu1"] || {};
			

				COND_ATTRS["bnode_ni1gi"] = COND_ATTRS["bnode_ni1gi"] || {};
			

				COND_ATTRS["bnode_ewfbb"] = COND_ATTRS["bnode_ewfbb"] || {};
			

				COND_ATTRS["bnode_fzxpu"] = COND_ATTRS["bnode_fzxpu"] || {};
			

				COND_ATTRS["bnode_belmw"] = COND_ATTRS["bnode_belmw"] || {};
			

				COND_ATTRS["bnode_lxe8d"] = COND_ATTRS["bnode_lxe8d"] || {};
			

				COND_ATTRS["bnode_q7lbb"] = COND_ATTRS["bnode_q7lbb"] || {};
			

				COND_ATTRS["bnode_hz7z2"] = COND_ATTRS["bnode_hz7z2"] || {};
			

				COND_ATTRS["bnode_cmzm3"] = COND_ATTRS["bnode_cmzm3"] || {};
			

				COND_ATTRS["bnode_b7822"] = COND_ATTRS["bnode_b7822"] || {};
			

				COND_ATTRS["bnode_j9xp8"] = COND_ATTRS["bnode_j9xp8"] || {};
			

				COND_ATTRS["bnode_v2ju9"] = COND_ATTRS["bnode_v2ju9"] || {};
			

				COND_ATTRS["bnode_d8r38"] = COND_ATTRS["bnode_d8r38"] || {};
			

				COND_ATTRS["bnode_xcn96"] = COND_ATTRS["bnode_xcn96"] || {};
			

				COND_ATTRS["bnode_xy2xi"] = COND_ATTRS["bnode_xy2xi"] || {};
			

				COND_ATTRS["bnode_owz89"] = COND_ATTRS["bnode_owz89"] || {};
			

				COND_ATTRS["bnode_d4obp"] = COND_ATTRS["bnode_d4obp"] || {};
			

				COND_ATTRS["bnode_uwnug"] = COND_ATTRS["bnode_uwnug"] || {};
			

				COND_ATTRS["bnode_k0hzl"] = COND_ATTRS["bnode_k0hzl"] || {};
			

				COND_ATTRS["bnode_l7gn7"] = COND_ATTRS["bnode_l7gn7"] || {};
			

				COND_ATTRS["bnode_b5eck"] = COND_ATTRS["bnode_b5eck"] || {};
			

				COND_ATTRS["bnode_3mcwk"] = COND_ATTRS["bnode_3mcwk"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_3mcwk"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_3mcwk"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_3mcwk" name="test" op="get_one" className="w-full flex flex-col px-2 p-2 border rounded-[12px] border-zinc-200  bg-[#eeeeee] cursor-pointer hover:drop-shadow-md" id={"bnode_3mcwk" + (idx?"__"+idx:"")} page_id="bnode_jj973" action="['select-one','go-to-page']" model_id="yvjbe" onClick={async (e)=>{
            
             g_fn.dev.emit_m(e, M, INFO)
            
            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation();

            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                 g_fn.bro_select_one(e, M, INFO, props);  g_fn.bro_go_to_page(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <div data_id="bnode_ddoky" className="new-div w-full flex justify-between items-center" id={"bnode_ddoky" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_q1h7z" className="new-div w-fit flex justify-start items-center" id={"bnode_q1h7z" + (idx?"__"+idx:"")} extra-attr >
                { !M.name && (<div data_id="bnode_y63ii" className="new-div w-fit flex justify-start items-center font-['Open_Sans'] text-[#475467] text-base font-semibold" id={"bnode_y63ii" + (idx?"__"+idx:"")} textContentVb="{M.subject || ' ' + ' Quiz'}" extra-attr >
                {M.subject || ' ' + ' Quiz'}
            </div>) }
{ M?.name && (<div data_id="bnode_visd6" className="new-div w-fit flex justify-start items-center font-['Open_Sans'] text-[#475467] text-base font-semibold" id={"bnode_visd6" + (idx?"__"+idx:"")} textContentVb="{M.name}" extra-attr >
                {M.name}
            </div>) }
            </div>
        

            <div data_id="bnode_lmwe3" id={"bnode_lmwe3" + (idx?"__"+idx:"")} className="flex items-center justify-center gap-2" extra-attr >
                { M?.status === "Published" && (<div data_id="bnode_jhl8e" className="new-div w-fit flex items-center justify-center px-1 py-0.5 rounded-[6px] border-[1px] border-[#10B981] text-[#10b981]" id={"bnode_jhl8e" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_bfkwx" className="new-div w-fit flex items-center justify-center font-['Open_Sans'] text-xs font-normal" id={"bnode_bfkwx" + (idx?"__"+idx:"")} extra-attr >
                Published
            </div>
        
            </div>) }
{ M?.status !== "Published" && (<div data_id="bnode_u3bfo" className="new-div w-fit flex items-center justify-center px-1 py-0.5 rounded-[6px] border-[1px] border-[#9CA3AF] text-sm font-semibold font-['Open_Sans']" id={"bnode_u3bfo" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_klp2f" className="new-div w-fit flex items-center justify-center text-[#9CA3AF] font-normal text-xs font-['Open_Sans']" id={"bnode_klp2f" + (idx?"__"+idx:"")} extra-attr >
                Draft
            </div>
        
            </div>) }

            <button data_id="bnode_7qvsi" op="delete_one" className="p-2 rounded-[8px] bg-[#f3f4f6] hover:bg-[#fb7185] text-[#fb7185] hover:text-white flex justify-center items-center" id={"bnode_7qvsi" + (idx?"__"+idx:"")} onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_delete_one(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} model_id="yvjbe" extra-attr >
                
            <svg data_id="bnode_c4yst" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="trash-2" className="w-20px h-20px" id={"bnode_c4yst" + (idx?"__"+idx:"")} b_type="icon" style={{height:"20px", width:"20px"}} extra-attr >
                
            <path data_id="" d="M3 6h18" extra-attr >
                
            </path>
        

            <path data_id="" d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" extra-attr >
                
            </path>
        

            <path data_id="" d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" extra-attr >
                
            </path>
        

            <line data_id="" x1="10" x2="10" y1="11" y2="17" extra-attr >
                
            </line>
        

            <line data_id="" x1="14" x2="14" y1="11" y2="17" extra-attr >
                
            </line>
        
            </svg>
        
            </button>
        
            </div>
        
            </div>
        
{ false && (<div data_id="bnode_tp78i" className="fixed bg-black/30 backdrop-blur-[2.5px] left-0 top-0 w-full h-screen flex items-center justify-center" id={"bnode_tp78i" + (idx?"__"+idx:"")} name="DeleteMODAL" extra-attr >
                
            <div data_id="bnode_essfi" className="bg-white w-[50%] h-[30%] flex-col flex justify-center items-center  gap-4 rounded-xl relative" id={"bnode_essfi" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_kad07" className="new-div w-full flex items-center justify-center" id={"bnode_kad07" + (idx?"__"+idx:"")} extra-attr >
                Delete
            </div>
        

            <div data_id="bnode_n123b" className="new-div w-full flex items-center justify-center" id={"bnode_n123b" + (idx?"__"+idx:"")} extra-attr >
                Are you sure you want to delete this?
            </div>
        

            <div data_id="bnode_91xli" className="new-div w-full flex items-center justify-around" id={"bnode_91xli" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_vgzhf" className="new-div w-fit flex items-center justify-center bg-gray-300 hover:bg-gray-600 hover:text-white p-2 rounded-lg" id={"bnode_vgzhf" + (idx?"__"+idx:"")} onClick={(e) => close_test_delete_modal(e)} extra-attr >
                Cancel
            </div>
        

            <button data_id="bnode_4yrbn" className="new-div w-fit flex items-center justify-center bg-rose-300 hover:bg-rose-600 hover:text-white p-2 rounded-lg" op="delete_one" id={"bnode_4yrbn" + (idx?"__"+idx:"")} onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_delete_one(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} model_id="yvjbe" extra-attr >
                Delete
            </button>
        
            </div>
        

            <div data_id="bnode_damc4" className="absolute top-2 right-4 hover:bg-rose-600 rounded-full  hover:text-white p-0.5" id={"bnode_damc4" + (idx?"__"+idx:"")} onClick={(e) => close_test_delete_modal(e)} extra-attr >
                
            <svg data_id="bnode_u0jw4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="x" className="w-6 h-6" id={"bnode_u0jw4" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M18 6 6 18" extra-attr >
                
            </path>
        

            <path data_id="" d="m6 6 12 12" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        
            </div>
        

            <fn data_id="bnode_m3dtq" name="fn:deleteModal" id={"bnode_m3dtq" + (idx?"__"+idx:"")} extra-attr >
                
            </fn>
        

            <fn data_id="bnode_4k6xe" name="fn:close_test_delete_modal" id={"bnode_4k6xe" + (idx?"__"+idx:"")} extra-attr >
                
            </fn>
        
            </div>) }

            <div data_id="bnode_b5eck" name="test-data" className="flex flex-col" id={"bnode_b5eck" + (idx?"__"+idx:"")} page_id="bnode_jj973" style={{padding:"0px", paddingBottom:"0px", paddingLeft:"0px", paddingRight:"0px", paddingTop:"0px"}} extra-attr >
                
            <div data_id="bnode_8jrdf" name="CONT-grade" className="w-full  " id={"bnode_8jrdf" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_iqrqc" id={"bnode_iqrqc" + (idx?"__"+idx:"")} className="flex flex-row gap-2" extra-attr >
                
            <div data_id="bnode_1rllc" className="new-div w-fit flex items-center justify-center font-normal font-['Open_Sans'] text-xs" id={"bnode_1rllc" + (idx?"__"+idx:"")} extra-attr >
                🎓
            </div>
        

            <div data_id="bnode_zjryc" name="grade" className="flex w-fit gap-1  flex-row" id={"bnode_zjryc" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_0yk88" id={"bnode_0yk88" + (idx?"__"+idx:"")} className=" text-xs font-normal font-['Open_Sans']" textContentVb="{M.grade + 'th'}" extra-attr >
                {M.grade?(M.grade  + 'th'):"NA"}
            </span>
        

            <div data_id="bnode_3lhqi" className="new-div w-full flex justify-center text-xs font-normal font-['Open_Sans']" id={"bnode_3lhqi" + (idx?"__"+idx:"")} extra-attr >
                Grade
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_29x5l" name="CONT-subject" className="w-full  " id={"bnode_29x5l" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_elth9" id={"bnode_elth9" + (idx?"__"+idx:"")} className="flex flex-row gap-2" extra-attr >
                
            <div data_id="bnode_ekpt7" className="new-div w-fit flex items-center justify-center text-xs font-normal font-['Open_Sans']" id={"bnode_ekpt7" + (idx?"__"+idx:"")} extra-attr >
                📚
            </div>
        

            <div data_id="bnode_el50k" name="subject" className="flex flex-col  w-full gap-6 " id={"bnode_el50k" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_sndku" id={"bnode_sndku" + (idx?"__"+idx:"")} className=" text-xs font-normal font-['Open_Sans']" textContentVb="{M.subject}" extra-attr >
                {M.subject || "NA"}
            </span>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_f6cvo" className="new-div w-full flex items-center justify-center flex-row gap-2" id={"bnode_f6cvo" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_h2gai" className="new-div w-fit flex items-center justify-center text-xs font-normal font-['Open_Sans']" id={"bnode_h2gai" + (idx?"__"+idx:"")} extra-attr >
                📜
            </div>
        

            <div data_id="bnode_hx9cy" className="new-div w-full flex justify-start items-start text-xs font-normal font-['Open_Sans']" id={"bnode_hx9cy" + (idx?"__"+idx:"")} textContentVb="{M.questions?.length + ' Questions'}" extra-attr >
                {M.questions?.length + ' Questions'}
            </div>
        
            </div>
        

            <div data_id="bnode_l7gn7" id={"bnode_l7gn7" + (idx?"__"+idx:"")} className="flex items-center justify-center" extra-attr >
                
            <div data_id="bnode_xcn96" name="CONT-last_modified" className="w-full  " id={"bnode_xcn96" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_d8r38" id={"bnode_d8r38" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_v2ju9" name="last_modified" className="flex flex-col w-full gap-6 text-[#6B7280] font-normal font-['Open_Sans'] text-xs" id={"bnode_v2ju9" + (idx?"__"+idx:"")} extra-attr >
                { parseInt((Date?.now()-M?.last_modified)/1000) !==0 && parseInt((Date?.now()-M?.last_modified)/1000) < 60 && (<div data_id="bnode_y6y21" className="new-div w-full flex justify-start items-start" id={"bnode_y6y21" + (idx?"__"+idx:"")} textContentVb="{ 'Few seconds ago' }" extra-attr >
                { 'Few seconds ago' }
            </div>) }
{ parseInt((Date?.now()-M?.last_modified)/3600000) !== 0 && parseInt((Date?.now()-M?.last_modified)/3600000) < 24 && parseInt((Date?.now()-M?.last_modified)/3600000) !== 1 && (<span data_id="bnode_jyk39" id={"bnode_jyk39" + (idx?"__"+idx:"")} className="text-xs font-normal font-['Open_Sans'] text-[#6B7280]" name="HOURS" style={{width:"119px"}} textContentVb="{parseInt((Date.now()-M.last_modified)/3600000) + ' hours ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/3600000) + ' hours ago'}
            </span>) }
{ parseInt((Date?.now()-M?.last_modified)/3600000) === 1 && parseInt((Date?.now()-M?.last_modified)/1000) > 60 && (<div data_id="bnode_17qu1" className="new-div w-full flex items-center justify-center" id={"bnode_17qu1" + (idx?"__"+idx:"")} textContentVb="{parseInt((Date.now()-M.last_modified)/3600000) + ' hour ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/3600000) + ' hour ago'}
            </div>) }
{ parseInt((Date?.now()-M?.last_modified)/60000) <60 && parseInt((Date?.now()-M?.last_modified)/60000) !==0 && parseInt((Date?.now()-M?.last_modified)/60000) !== 1 && parseInt((Date?.now()-M?.last_modified)/1000) > 60 && (<div data_id="bnode_ni1gi" className="new-div w-full flex justify-start items-start text-xs font-['Open_Sans'] font-normal" id={"bnode_ni1gi" + (idx?"__"+idx:"")} name="Minutes" textContentVb="{parseInt((Date.now()-M.last_modified)/60000) + ' minutes ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/60000) + ' minutes ago'}
            </div>) }
{ parseInt((Date?.now()-M?.last_modified)/60000) === 1 && parseInt((Date?.now()-M?.last_modified)/1000) > 60 && (<div data_id="bnode_ewfbb" className="new-div w-full flex justify-start items-start" name="Minutes" id={"bnode_ewfbb" + (idx?"__"+idx:"")} textContentVb="{parseInt((Date.now()-M.last_modified)/60000) + ' minute ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/60000) + ' minute ago'}
            </div>) }
{ parseInt((Date?.now()-M?.last_modified)/86400000) !== 0 && parseInt((Date?.now()-M?.last_modified)/86400000) <= 7 && parseInt((Date?.now()-M?.last_modified)/86400000) !== 1 && parseInt((Date?.now()-M?.last_modified)/1000) > 60 && (<div data_id="bnode_fzxpu" className="new-div w-full flex justify-start items-start" id={"bnode_fzxpu" + (idx?"__"+idx:"")} textContentVb="{parseInt((Date.now()-M.last_modified)/86400000) + ' days ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/86400000) + ' days ago'}
            </div>) }
{ parseInt((Date?.now()-M?.last_modified)/86400000) === 1 && parseInt((Date?.now()-M?.last_modified)/1000) > 60 && (<div data_id="bnode_belmw" className="new-div w-full flex justify-start items-start" id={"bnode_belmw" + (idx?"__"+idx:"")} textContentVb="{parseInt((Date.now()-M.last_modified)/86400000) + ' day ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/86400000) + ' day ago'}
            </div>) }
{ parseInt((Date?.now()-M?.last_modified)/604800000) !== 0 && parseInt((Date?.now()-M?.last_modified)/604800000) < 4 && parseInt((Date?.now()-M?.last_modified)/604800000) !==1 && parseInt((Date?.now()-M?.last_modified)/1000) > 60 && (<div data_id="bnode_lxe8d" className="new-div w-full flex justify-start items-start" id={"bnode_lxe8d" + (idx?"__"+idx:"")} textContentVb="{parseInt((Date.now()-M.last_modified)/604800000) + ' weeks ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/604800000) + ' weeks ago'}
            </div>) }
{ parseInt((Date?.now()-M?.last_modified)/604800000) !== 0 && parseInt((Date?.now()-M?.last_modified)/604800000)  === 1 && parseInt((Date?.now()-M?.last_modified)/1000) > 60 && (<div data_id="bnode_q7lbb" className="new-div w-full flex justify-start items-start" id={"bnode_q7lbb" + (idx?"__"+idx:"")} textContentVb="{parseInt((Date.now()-M.last_modified)/604800000) + ' week ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/604800000) + ' week ago'}
            </div>) }
{ parseInt((Date?.now()-M?.last_modified)/2419200000) !== 0 && parseInt((Date?.now()-M?.last_modified)/2419200000) <12  && parseInt((Date?.now()-M?.last_modified)/2419200000) !== 1 && parseInt((Date?.now()-M?.last_modified)/1000) > 60 && (<div data_id="bnode_hz7z2" className="new-div w-full flex justify-start items-start" id={"bnode_hz7z2" + (idx?"__"+idx:"")} textContentVb="{parseInt((Date.now()-M.last_modified)/2419200000) + ' months ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/2419200000) + ' months ago'}
            </div>) }
{ parseInt((Date?.now()-M?.last_modified)/2419200000) === 1 && parseInt((Date?.now()-M?.last_modified)/1000) > 60 && (<div data_id="bnode_cmzm3" className="new-div w-full flex justify-start items-start" id={"bnode_cmzm3" + (idx?"__"+idx:"")} textContentVb="{parseInt((Date.now()-M.last_modified)/2419200000) + ' month ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/2419200000) + ' month ago'}
            </div>) }
{ parseInt((Date?.now()-M?.last_modified)/29030400000) !== 0 && parseInt((Date?.now()-M?.last_modified)/29030400000) !== 1 && parseInt((Date?.now()-M?.last_modified)/1000) > 60 && (<div data_id="bnode_b7822" className="new-div w-full flex justify-start items-start" id={"bnode_b7822" + (idx?"__"+idx:"")} textContentVb="{parseInt((Date.now()-M.last_modified)/29030400000) + ' years ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/29030400000) + ' years ago'}
            </div>) }
{ parseInt((Date?.now()-M?.last_modified)/29030400000) === 1 && parseInt((Date?.now()-M?.last_modified)/1000) > 60 && (<div data_id="bnode_j9xp8" className="new-div w-full flex justify-start items-start" id={"bnode_j9xp8" + (idx?"__"+idx:"")} textContentVb="{parseInt((Date.now()-M.last_modified)/29030400000) + ' year ago'}" extra-attr >
                {parseInt((Date.now()-M.last_modified)/29030400000) + ' year ago'}
            </div>) }
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_k0hzl" id={"bnode_k0hzl" + (idx?"__"+idx:"")} className="flex items-center justify-center flex-row" extra-attr >
                
            <div data_id="bnode_uwnug" name="csv-for-create" className="flex flex-row" id={"bnode_uwnug" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_d4obp" className="font-['Open_Sans'] flex-row px-2 py-1 bg-[#4B5563] text-white gap-1 flex justify-center items-center rounded-[8px]" id={"bnode_d4obp" + (idx?"__"+idx:"")} action="['go-to-page']" page_id="bnode_uqf0i" model_id="yvjbe" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_go_to_page(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <svg data_id="bnode_xy2xi" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="download" className="w-[14px] h-[14px]" id={"bnode_xy2xi" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" extra-attr >
                
            </path>
        

            <polyline data_id="" points="7 10 12 15 17 10" extra-attr >
                
            </polyline>
        

            <line data_id="" x1="12" x2="12" y1="15" y2="3" extra-attr >
                
            </line>
        
            </svg>
        

            <div data_id="bnode_owz89" id={"bnode_owz89" + (idx?"__"+idx:"")} className="flex items-center font-normal font-['Open_Sans'] text-sm" extra-attr >
                Export
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_3mcwk;