import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_kuni6";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_kuni6 = (props)=>{
			const INFO  = {"model_id":"","model_name":"","op":"get_one","comp_id":"comp_bnode_kuni6"};

			// Routes
			
			const navigate = useNavigate();
			AS.navigate = navigate;
		



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					g_fn.bro_get_one(INFO, set_M, props);
				}, [props])
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
			
		},[])
	

			// FUNCTIONS
			
			const init = ()=>{

				if(!INFO.model_id) return;


				INFO.query = query; // @todo : make it possible to change in UI editor
				const id = g_fn.get_one_find_id(props, INFO, relation);
				if(!id) {
					// errors => [{code:string, msg:string}]
					// warning => []
					console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
					// return;
				}

				// subscription
				const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e)=>{
					console.log("USER RE-RENDERED");
					const data = e.data;
					set_M(data);
				});


				// first time
				AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);



				return ()=>{
					sub.unsubscribe();
				}

			}
		


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_fheoq"] = COND_ATTRS["bnode_fheoq"] || {};
			

				COND_ATTRS["bnode_6029l"] = COND_ATTRS["bnode_6029l"] || {};
			

				COND_ATTRS["bnode_5ggae"] = COND_ATTRS["bnode_5ggae"] || {};
			

				COND_ATTRS["bnode_upyh5"] = COND_ATTRS["bnode_upyh5"] || {};
			

				COND_ATTRS["bnode_63lue"] = COND_ATTRS["bnode_63lue"] || {};
			

				COND_ATTRS["bnode_vq2ff"] = COND_ATTRS["bnode_vq2ff"] || {};
			

				COND_ATTRS["bnode_1i5bm"] = COND_ATTRS["bnode_1i5bm"] || {};
			

				COND_ATTRS["bnode_kuni6"] = COND_ATTRS["bnode_kuni6"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_kuni6"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_kuni6"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_kuni6" id={"bnode_kuni6" + (idx?"__"+idx:"")} login="true" className="bg-[#eeeeee]" onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_vq2ff" name="menu" className="w-full h-fit overflow-visible flex gap-4 items-center justify-center bg-[#eeeeee]" id={"bnode_vq2ff" + (idx?"__"+idx:"")} style={{padding:"16px", paddingBottom:"16px", paddingLeft:"16px", paddingRight:"16px", paddingTop:"16px"}} extra-attr >
                
            <div data_id="bnode_6029l" className="new-div h-fit w-full flex justify-start items-center" id={"bnode_6029l" + (idx?"__"+idx:"")} name="left" extra-attr >
                
            <svg data_id="bnode_fheoq" width="121" height="33" viewBox="0 0 121 33" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_fheoq" + (idx?"__"+idx:"")} b_type="icon" action="['go-to-page']" page_id="bnode_00000" className="cursor-pointer" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_go_to_page(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <path data_id="" d="M117.838 18.1653C117.838 19.7696 117.592 21.2058 117.1 22.4737C116.609 23.7158 115.91 24.7638 115.004 25.6177C114.125 26.4716 113.064 27.1186 111.822 27.5585C110.58 27.9984 109.195 28.2183 107.668 28.2183C106.142 28.2183 104.757 27.9854 103.515 27.5196C102.273 27.0539 101.199 26.394 100.294 25.5401C99.4139 24.6603 98.7282 23.5994 98.2365 22.3573C97.7449 21.1152 97.499 19.7179 97.499 18.1653C97.499 16.6386 97.7449 15.2542 98.2365 14.0121C98.7282 12.77 99.4139 11.722 100.294 10.8681C101.199 9.98832 102.273 9.31553 103.515 8.84976C104.757 8.38398 106.142 8.15109 107.668 8.15109C109.195 8.15109 110.58 8.39692 111.822 8.88857C113.064 9.35435 114.125 10.0271 115.004 10.9069C115.91 11.7609 116.609 12.8089 117.1 14.0509C117.592 15.293 117.838 16.6645 117.838 18.1653ZM104.253 18.1653C104.253 19.7438 104.55 20.96 105.146 21.8139C105.767 22.6419 106.62 23.056 107.707 23.056C108.794 23.056 109.622 22.629 110.191 21.7751C110.787 20.9211 111.084 19.7179 111.084 18.1653C111.084 16.6127 110.787 15.4224 110.191 14.5943C109.596 13.7404 108.755 13.3135 107.668 13.3135C106.582 13.3135 105.741 13.7404 105.146 14.5943C104.55 15.4224 104.253 16.6127 104.253 18.1653Z" fill="#82B6FF" extra-attr >
                
            </path>
        

            <path data_id="" d="M80.6658 27.5973C80.2517 27.235 79.9154 26.808 79.6566 26.3164C79.4237 25.8247 79.3073 25.2684 79.3073 24.6474C79.3073 24.1298 79.4237 23.6252 79.6566 23.1336C79.9154 22.616 80.2259 22.1373 80.5881 21.6974L87.2643 13.8569H80.1224C79.9671 13.5722 79.8118 13.2099 79.6566 12.77C79.5272 12.3301 79.4625 11.8514 79.4625 11.3339C79.4625 10.4282 79.6566 9.78131 80.0447 9.39316C80.4587 8.97914 81.0022 8.77213 81.6749 8.77213H93.9016C94.3156 9.1344 94.6391 9.56136 94.872 10.053C95.1307 10.5447 95.2601 11.101 95.2601 11.722C95.2601 12.2396 95.1307 12.7571 94.872 13.2746C94.6391 13.7663 94.3415 14.2321 93.9792 14.672L87.4583 22.5125H95.066C95.2213 22.7972 95.3636 23.1595 95.493 23.5994C95.6482 24.0393 95.7259 24.518 95.7259 25.0355C95.7259 25.9412 95.5189 26.601 95.1048 27.0151C94.7167 27.4032 94.1862 27.5973 93.5134 27.5973H80.6658Z" fill="#FF5994" extra-attr >
                
            </path>
        

            <path data_id="" d="M68.8961 3.49332C68.8961 2.51002 69.2196 1.68197 69.8665 1.00918C70.5393 0.336395 71.4191 0 72.5059 0C73.5927 0 74.4596 0.336395 75.1065 1.00918C75.7793 1.68197 76.1157 2.51002 76.1157 3.49332C76.1157 4.47663 75.7793 5.30468 75.1065 5.97747C74.4596 6.65026 73.5927 6.98665 72.5059 6.98665C71.4191 6.98665 70.5393 6.65026 69.8665 5.97747C69.2196 5.30468 68.8961 4.47663 68.8961 3.49332ZM75.8051 27.5585C75.5205 27.6102 75.0935 27.6749 74.5242 27.7525C73.9808 27.856 73.4245 27.9078 72.8552 27.9078C72.2859 27.9078 71.7684 27.869 71.3026 27.7913C70.8627 27.7137 70.4875 27.5585 70.177 27.3256C69.8665 27.0927 69.6207 26.7822 69.4395 26.394C69.2843 25.98 69.2066 25.4495 69.2066 24.8026V9.00502C69.4913 8.95327 69.9053 8.88857 70.4487 8.81094C71.018 8.70744 71.5873 8.65569 72.1566 8.65569C72.7258 8.65569 73.2304 8.6945 73.6703 8.77213C74.1361 8.84976 74.5242 9.00502 74.8348 9.23791C75.1453 9.4708 75.3782 9.79425 75.5334 10.2083C75.7146 10.5964 75.8051 11.114 75.8051 11.7609V27.5585Z" fill="#FF9668" extra-attr >
                
            </path>
        

            <path data_id="" d="M113.97 27.0092C113.323 27.682 113 28.51 113 29.4933C113 30.4766 113.323 31.3047 113.97 31.9775C114.643 32.6503 115.523 32.9867 116.61 32.9867C117.697 32.9867 118.563 32.6503 119.21 31.9775C119.883 31.3047 120.22 30.4766 120.22 29.4933C120.22 28.51 119.883 27.682 119.21 27.0092C118.563 26.3364 117.697 26 116.61 26C115.523 26 114.643 26.3364 113.97 27.0092Z" fill="#82B6FF" extra-attr >
                
            </path>
        

            <path data_id="" d="M60.5152 27.0927C60.1529 27.3773 59.6224 27.6102 58.9238 27.7913C58.2251 27.9725 57.4359 28.063 56.5561 28.063C55.5469 28.063 54.6671 27.9337 53.9167 27.6749C53.1921 27.4161 52.6876 27.0021 52.4029 26.4328C52.17 25.9929 51.8854 25.3848 51.549 24.6085C51.2385 23.8064 50.9021 22.9266 50.5398 21.9691C50.1775 20.9858 49.8023 19.9378 49.4142 18.8251C49.026 17.7124 48.6508 16.6127 48.2885 15.5259C47.9522 14.4391 47.6416 13.3911 47.357 12.3819C47.0724 11.3727 46.8395 10.48 46.6583 9.70367C47.0206 9.3414 47.4993 9.03088 48.0945 8.77212C48.7155 8.48747 49.3883 8.34515 50.1128 8.34515C51.0185 8.34515 51.756 8.53923 52.3253 8.92738C52.9204 9.28965 53.3603 9.98831 53.645 11.0234L55.1588 16.962C55.4434 18.1265 55.7151 19.1874 55.9739 20.1448C56.2326 21.0764 56.4267 21.8009 56.5561 22.3185H56.7502C57.2418 20.4036 57.7593 18.2817 58.3027 15.9528C58.8462 13.624 59.3378 11.3339 59.7777 9.08263C60.2176 8.84975 60.7093 8.66861 61.2527 8.53923C61.8219 8.40985 62.3654 8.34515 62.8829 8.34515C63.7886 8.34515 64.5519 8.53923 65.173 8.92738C65.794 9.31552 66.1045 9.98831 66.1045 10.9457C66.1045 11.3598 66.001 11.9679 65.794 12.77C65.6129 13.5463 65.367 14.4391 65.0565 15.4482C64.7719 16.4316 64.4225 17.4796 64.0085 18.5922C63.6204 19.7049 63.2193 20.7917 62.8053 21.8527C62.3912 22.9136 61.9772 23.9099 61.5632 24.8414C61.175 25.7471 60.8257 26.4975 60.5152 27.0927Z" fill="#FF5994" extra-attr >
                
            </path>
        

            <path data_id="" d="M24.3423 18.1653C24.3423 16.4574 24.601 14.9825 25.1186 13.7404C25.6361 12.4724 26.3477 11.4244 27.2534 10.5964C28.1849 9.76834 29.2588 9.16024 30.475 8.7721C31.7171 8.35807 33.0497 8.15106 34.4729 8.15106C34.9905 8.15106 35.4951 8.20281 35.9867 8.30632C36.4784 8.38395 36.8794 8.48746 37.19 8.61684V2.32885C37.4487 2.25122 37.8628 2.17359 38.432 2.09596C39.0013 1.99246 39.5835 1.9407 40.1787 1.9407C40.748 1.9407 41.2526 1.97952 41.6925 2.05715C42.1582 2.13478 42.5464 2.29004 42.8569 2.52293C43.1674 2.75581 43.4003 3.07927 43.5556 3.49329C43.7108 3.88144 43.7885 4.39897 43.7885 5.04588V23.4441C43.7885 24.6603 43.2192 25.6306 42.0806 26.3552C41.3302 26.8468 40.3598 27.2738 39.1695 27.6361C37.9792 28.0242 36.6077 28.2183 35.0552 28.2183C33.3732 28.2183 31.8594 27.9854 30.5138 27.5196C29.1941 27.0538 28.0685 26.381 27.1369 25.5012C26.2313 24.6214 25.5326 23.5734 25.041 22.3573C24.5752 21.1152 24.3423 19.7179 24.3423 18.1653ZM35.3657 13.3134C33.9683 13.3134 32.8945 13.7145 32.144 14.5167C31.3936 15.3188 31.0184 16.535 31.0184 18.1653C31.0184 19.7696 31.3678 20.9858 32.0664 21.8138C32.7651 22.616 33.7743 23.0171 35.094 23.0171C35.5597 23.0171 35.9738 22.9524 36.336 22.823C36.7242 22.6678 37.0218 22.4996 37.2288 22.3184V13.7792C36.7113 13.4687 36.0902 13.3134 35.3657 13.3134Z" fill="#FF5994" extra-attr >
                
            </path>
        

            <path data_id="" d="M6.63732 4.77422C7.07722 4.51546 7.73707 4.26963 8.61687 4.03674C9.52255 3.80385 10.4282 3.68741 11.3339 3.68741C12.6277 3.68741 13.7663 3.88148 14.7496 4.26963C15.7588 4.6319 16.4057 5.21412 16.6903 6.01629C17.1561 7.36187 17.6607 8.9274 18.2041 10.7129C18.7475 12.4984 19.2909 14.3485 19.8343 16.2634C20.3777 18.1524 20.9082 20.0284 21.4257 21.8915C21.9433 23.7287 22.3961 25.359 22.7842 26.7822C22.4737 27.1444 22.008 27.4291 21.3869 27.6361C20.7659 27.869 20.0284 27.9854 19.1745 27.9854C18.5534 27.9854 18.023 27.9337 17.5831 27.8302C17.169 27.7267 16.8197 27.5714 16.5351 27.3644C16.2763 27.1574 16.0564 26.8857 15.8752 26.5493C15.72 26.2129 15.5776 25.8247 15.4483 25.3848L14.7496 22.7066H7.72413C7.51712 23.4829 7.31011 24.2851 7.1031 25.1131C6.89608 25.9412 6.68907 26.7434 6.48206 27.5197C6.11979 27.649 5.73164 27.7525 5.31762 27.8302C4.92947 27.9337 4.45076 27.9854 3.88148 27.9854C2.56178 27.9854 1.57847 27.7655 0.931554 27.3256C0.310519 26.8598 0 26.2258 0 25.4237C0 25.0614 0.0517531 24.6991 0.155259 24.3368C0.258765 23.9746 0.375211 23.5476 0.504593 23.056C0.685729 22.3573 0.931555 21.4904 1.24207 20.4554C1.57847 19.4203 1.94074 18.3076 2.32889 17.1173C2.71703 15.9011 3.13106 14.659 3.57096 13.3911C4.01086 12.1231 4.42488 10.9328 4.81302 9.82014C5.20117 8.68157 5.55051 7.67239 5.86103 6.79259C6.19742 5.91279 6.45618 5.24 6.63732 4.77422ZM11.2951 9.78132C10.9328 10.8681 10.5188 12.0843 10.053 13.4299C9.61312 14.7496 9.21203 16.0952 8.84976 17.4666H13.6628C13.3005 16.0693 12.9253 14.7108 12.5372 13.3911C12.1749 12.0455 11.8385 10.8423 11.528 9.78132H11.2951Z" fill="#FF5994" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        

            <div data_id="bnode_63lue" className="w-full h-fit gap-2 flex justify-end items-center" id={"bnode_63lue" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <div data_id="bnode_5ggae" className="w-10 h-10  flex items-center justify-center" id={"bnode_5ggae" + (idx?"__"+idx:"")} extra-attr >
                < COMP_REG.comp_bnode_fnv81 {...{PM:[...PM, M], on_created: props.on_created || INFO.on_created, on_selected: props.on_selected || INFO.on_selected, INFO:INFO  }} extra-attr />
            </div>
        

            <button data_id="bnode_upyh5" type="button" className="text-[#FF5994] hover:bg-[#FF5994] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg px-5 py-2.5 mr-2 mb-2 font-['Open_Sans'] font-semibold flex justify-end items-center border-[#ff5994] border-[1px] text-base" id={"bnode_upyh5" + (idx?"__"+idx:"")} action="['logout']" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_logout()
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                Logout
            </button>
        
            </div>
        
            </div>
        
<Routes> <Route path="bnode_00000" element={<COMP_REG.comp_bnode_00000 />} />
<Route path="/" element={<COMP_REG.comp_bnode_00000 />} />
<Route path="bnode_a8dfp" element={<COMP_REG.comp_bnode_a8dfp />} />
<Route path="bnode_e2ttk" element={<COMP_REG.comp_bnode_e2ttk />} />
<Route path="bnode_jj973" element={<COMP_REG.comp_bnode_jj973 />} />
<Route path="bnode_4ft0a" element={<COMP_REG.comp_bnode_4ft0a />} />
<Route path="bnode_yezo2" element={<COMP_REG.comp_bnode_yezo2 />} />
<Route path="bnode_k2lnn" element={<COMP_REG.comp_bnode_k2lnn />} />
<Route path="bnode_v6ckv" element={<COMP_REG.comp_bnode_v6ckv />} />
<Route path="bnode_uqf0i" element={<COMP_REG.comp_bnode_uqf0i />} />
<Route path="bnode_6c7ez" element={<COMP_REG.comp_bnode_6c7ez />} />
<Route path="bnode_n381t" element={<COMP_REG.comp_bnode_n381t />} />
<Route path="bnode_pw9l9" element={<COMP_REG.comp_bnode_pw9l9 />} /> </Routes>
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_kuni6;