import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_m6ry2";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_m6ry2 = (props)=>{
			const INFO  = {"model_id":"fhmae","model_name":"question","op":"get_one","comp_id":"comp_bnode_m6ry2"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					g_fn.bro_get_one(INFO, set_M, props);
				}, [props])
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = ()=>{

				if(!INFO.model_id) return;


				INFO.query = query; // @todo : make it possible to change in UI editor
				const id = g_fn.get_one_find_id(props, INFO, relation);
				if(!id) {
					// errors => [{code:string, msg:string}]
					// warning => []
					console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
					// return;
				}

				// subscription
				const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e)=>{
					console.log("USER RE-RENDERED");
					const data = e.data;
					set_M(data);
				});


				// first time
				AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);



				return ()=>{
					sub.unsubscribe();
				}

			}
		


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= {"model_id":"yvjbe","prop_name":"questions","comp_id":"comp_bnode_5nuxp"};
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_glly6"] = COND_ATTRS["bnode_glly6"] || {};
			

				COND_ATTRS["bnode_np2a7"] = COND_ATTRS["bnode_np2a7"] || {};
			

				COND_ATTRS["bnode_xeulp"] = COND_ATTRS["bnode_xeulp"] || {};
			

				COND_ATTRS["bnode_k3zne"] = COND_ATTRS["bnode_k3zne"] || {};
			

				COND_ATTRS["bnode_gnopn"] = COND_ATTRS["bnode_gnopn"] || {};
			

				COND_ATTRS["bnode_8i1i1"] = COND_ATTRS["bnode_8i1i1"] || {};
			

				COND_ATTRS["bnode_hgqt4"] = COND_ATTRS["bnode_hgqt4"] || {};
			

				COND_ATTRS["bnode_q5gwy"] = COND_ATTRS["bnode_q5gwy"] || {};
			

				COND_ATTRS["bnode_9w0b3"] = COND_ATTRS["bnode_9w0b3"] || {};
			

				COND_ATTRS["bnode_uk0rc"] = COND_ATTRS["bnode_uk0rc"] || {};
			

				COND_ATTRS["bnode_63qv2"] = COND_ATTRS["bnode_63qv2"] || {};
			

				COND_ATTRS["bnode_uauw7"] = COND_ATTRS["bnode_uauw7"] || {};
			

				COND_ATTRS["bnode_rmu9o"] = COND_ATTRS["bnode_rmu9o"] || {};
			

				COND_ATTRS["bnode_pjsbx"] = COND_ATTRS["bnode_pjsbx"] || {};
			

				COND_ATTRS["bnode_o6mmg"] = COND_ATTRS["bnode_o6mmg"] || {};
			

				COND_ATTRS["bnode_u2tdy"] = COND_ATTRS["bnode_u2tdy"] || {};
			

				COND_ATTRS["bnode_3el2f"] = COND_ATTRS["bnode_3el2f"] || {};
			

				COND_ATTRS["bnode_44wk8"] = COND_ATTRS["bnode_44wk8"] || {};
			

				COND_ATTRS["bnode_n7nso"] = COND_ATTRS["bnode_n7nso"] || {};
			

				COND_ATTRS["bnode_3rbam"] = COND_ATTRS["bnode_3rbam"] || {};
			

				COND_ATTRS["bnode_kz1qn"] = COND_ATTRS["bnode_kz1qn"] || {};
			

				COND_ATTRS["bnode_ksunt"] = COND_ATTRS["bnode_ksunt"] || {};
			

				COND_ATTRS["bnode_fuvxh"] = COND_ATTRS["bnode_fuvxh"] || {};
			

				COND_ATTRS["bnode_gk5jm"] = COND_ATTRS["bnode_gk5jm"] || {};
			

				COND_ATTRS["bnode_9gjdm"] = COND_ATTRS["bnode_9gjdm"] || {};
			

				COND_ATTRS["bnode_pgbop"] = COND_ATTRS["bnode_pgbop"] || {};
			

				COND_ATTRS["bnode_fjavx"] = COND_ATTRS["bnode_fjavx"] || {};
			

				COND_ATTRS["bnode_vs7lq"] = COND_ATTRS["bnode_vs7lq"] || {};
			

				COND_ATTRS["bnode_6qugp"] = COND_ATTRS["bnode_6qugp"] || {};
			

				COND_ATTRS["bnode_67qv9"] = COND_ATTRS["bnode_67qv9"] || {};
			

				COND_ATTRS["bnode_bxlpe"] = COND_ATTRS["bnode_bxlpe"] || {};
			

				COND_ATTRS["bnode_thfgk"] = COND_ATTRS["bnode_thfgk"] || {};
			

				COND_ATTRS["bnode_pnxap"] = COND_ATTRS["bnode_pnxap"] || {};
			

				COND_ATTRS["bnode_ezpmb"] = COND_ATTRS["bnode_ezpmb"] || {};
			

				COND_ATTRS["bnode_lhafn"] = COND_ATTRS["bnode_lhafn"] || {};
			

				COND_ATTRS["bnode_cx95e"] = COND_ATTRS["bnode_cx95e"] || {};
			

				COND_ATTRS["bnode_c9euy"] = COND_ATTRS["bnode_c9euy"] || {};
			

				COND_ATTRS["bnode_pr5ly"] = COND_ATTRS["bnode_pr5ly"] || {};
			

				COND_ATTRS["bnode_nd3s5"] = COND_ATTRS["bnode_nd3s5"] || {};
			

				COND_ATTRS["bnode_qj9nh"] = COND_ATTRS["bnode_qj9nh"] || {};
			

				COND_ATTRS["bnode_x60hc"] = COND_ATTRS["bnode_x60hc"] || {};
			

				COND_ATTRS["bnode_g0m2g"] = COND_ATTRS["bnode_g0m2g"] || {};
			

				COND_ATTRS["bnode_qew1s"] = COND_ATTRS["bnode_qew1s"] || {};
			

				COND_ATTRS["bnode_0wpa3"] = COND_ATTRS["bnode_0wpa3"] || {};
			

				COND_ATTRS["bnode_m6ry2"] = COND_ATTRS["bnode_m6ry2"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_m6ry2"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_m6ry2"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_m6ry2" name="question" op="get_one" className="w-full flex flex-col" id={"bnode_m6ry2" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_0wpa3" name="question-data" action="select-one" className="flex flex-col" id={"bnode_0wpa3" + (idx?"__"+idx:"")} model_id="fhmae" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_select_one(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <div data_id="bnode_9w0b3" className="new-div w-full flex items-center justify-center pt-4" id={"bnode_9w0b3" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_k3zne" name="CONT-type" className="w-full  " id={"bnode_k3zne" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_xeulp" id={"bnode_xeulp" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_np2a7" name="type" className="flex flex-col  w-full gap-6 " id={"bnode_np2a7" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_glly6" id={"bnode_glly6" + (idx?"__"+idx:"")} className=" text-base font-normal font-['Open_Sans']" textContentVb="{M.type}" extra-attr >
                {M.type}
            </span>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_q5gwy" name="CONT-points" className="w-full flex justify-end items-center" id={"bnode_q5gwy" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_hgqt4" id={"bnode_hgqt4" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_8i1i1" name="points" className="flex flex-col w-full gap-6 " id={"bnode_8i1i1" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_gnopn" id={"bnode_gnopn" + (idx?"__"+idx:"")} className=" text-base font-normal font-['Open_Sans']" textContentVb="{M.points || ''} Points" extra-attr >
                {M.points || ''} Points
            </span>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_rmu9o" name="CONT-images" className="w-full flex flex-col gap-2" id={"bnode_rmu9o" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_uk0rc" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_uk0rc" + (idx?"__"+idx:"")} extra-attr >
                images
            </div>
        

                    {M && g_fn.safe_array(M.images).map(
                        (V, idx)=> (
                            
            <div data_id="bnode_uauw7" name="images" className="flex flex-col items-center justify-center w-full gap-6 " id={"bnode_uauw7" + (idx?"__"+idx:"")} key={g_fn.get_key_from_meta(M, "images", idx)} extra-attr >
                
            <div data_id="bnode_63qv2" name="image" style={{backgroundImage:`url("${V?.v?.url}")`}} className="w-32 h-32 bg-gray-200 bg-center bg-cover rounded-md" id={"bnode_63qv2" + (idx?"__"+idx:"")} extra-attr >
                
            </div>
        
            </div>
        
                        )
                    )}
                
            </div>
        

            <div data_id="bnode_3el2f" name="CONT-question" className="w-full  " id={"bnode_3el2f" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_u2tdy" id={"bnode_u2tdy" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_o6mmg" name="question" className="flex flex-col w-full gap-6 " id={"bnode_o6mmg" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_pjsbx" id={"bnode_pjsbx" + (idx?"__"+idx:"")} className="font-['Open_Sans'] font-semibold py-2 text-lg" textContentVb="{M.question || ''}" extra-attr >
                {M.question || ''}
            </span>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_kz1qn" className="new-div w-full flex-col flex justify-start items-start" id={"bnode_kz1qn" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_3rbam" name="CONT-answers" className="w-full flex flex-col gap-2 grid grid-cols-2" id={"bnode_3rbam" + (idx?"__"+idx:"")} extra-attr >
                
                    {M && g_fn.safe_array(M.answers).map(
                        (V, idx)=> (
                            
            <div data_id="bnode_n7nso" name="answers" className="w-full gap-6 flex justify-center items-center" id={"bnode_n7nso" + (idx?"__"+idx:"")} key={g_fn.get_key_from_meta(M, "answers", idx)} extra-attr >
                
            <span data_id="bnode_44wk8" id={"bnode_44wk8" + (idx?"__"+idx:"")} className=" text-base font-normal" textContentVb="{V?.v || ''}" extra-attr >
                {V?.v || ''}
            </span>
        
            </div>
        
                        )
                    )}
                
            </div>
        
            </div>
        

            <div data_id="bnode_gk5jm" name="CONT-correct_answers" className="w-full flex flex-col gap-2" id={"bnode_gk5jm" + (idx?"__"+idx:"")} extra-attr >
                
                    {M && g_fn.safe_array(M.correct_answers).map(
                        (V, idx)=> (
                            
            <div data_id="bnode_fuvxh" name="correct_answers" className="flex flex-col w-full gap-6 " id={"bnode_fuvxh" + (idx?"__"+idx:"")} key={g_fn.get_key_from_meta(M, "correct_answers", idx)} extra-attr >
                
            <span data_id="bnode_ksunt" id={"bnode_ksunt" + (idx?"__"+idx:"")} textContentVb="{V?.v || ''}" extra-attr >
                {V?.v || ''}
            </span>
        
            </div>
        
                        )
                    )}
                
            </div>
        

            <div data_id="bnode_6qugp" name="CONT-time" className="w-full  " id={"bnode_6qugp" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_vs7lq" id={"bnode_vs7lq" + (idx?"__"+idx:"")} extra-attr >
                { false && (<div data_id="bnode_9gjdm" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_9gjdm" + (idx?"__"+idx:"")} extra-attr >
                time
            </div>) }

            <div data_id="bnode_fjavx" name="time" className="flex flex-col w-full gap-6 " id={"bnode_fjavx" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_pgbop" id={"bnode_pgbop" + (idx?"__"+idx:"")} textContentVb="{M.time || ''}" extra-attr >
                {M.time || ''}
            </span>
        
            </div>
        
            </div>
        
            </div>
        
{ false && (<div data_id="bnode_pnxap" name="CONT-tags" className="w-full flex flex-col gap-2" id={"bnode_pnxap" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_67qv9" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_67qv9" + (idx?"__"+idx:"")} extra-attr >
                tags
            </div>
        

                    {M && g_fn.safe_array(M.tags).map(
                        (V, idx)=> (
                            
            <div data_id="bnode_thfgk" name="tags" className="flex flex-col w-full gap-6 " id={"bnode_thfgk" + (idx?"__"+idx:"")} key={g_fn.get_key_from_meta(M, "tags", idx)} extra-attr >
                
            <span data_id="bnode_bxlpe" id={"bnode_bxlpe" + (idx?"__"+idx:"")} textContentVb="{V?.v || ''}" extra-attr >
                {V?.v || ''}
            </span>
        
            </div>
        
                        )
                    )}
                
            </div>) }
{ false && (<div data_id="bnode_pr5ly" name="CONT-answer_explanation" className="w-full  " id={"bnode_pr5ly" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_c9euy" id={"bnode_c9euy" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_ezpmb" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_ezpmb" + (idx?"__"+idx:"")} extra-attr >
                answer_explanation
            </div>
        

            <div data_id="bnode_cx95e" name="answer_explanation" className="flex flex-col w-full gap-6 " id={"bnode_cx95e" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_lhafn" id={"bnode_lhafn" + (idx?"__"+idx:"")} textContentVb="{M.answer_explanation}" extra-attr >
                {M.answer_explanation}
            </span>
        
            </div>
        
            </div>
        
            </div>) }
{ false && (<div data_id="bnode_qew1s" name="CONT-answer_explanation_image" className="w-full  " id={"bnode_qew1s" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_g0m2g" id={"bnode_g0m2g" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_nd3s5" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_nd3s5" + (idx?"__"+idx:"")} extra-attr >
                answer_explanation_image
            </div>
        

            <div data_id="bnode_x60hc" name="answer_explanation_image" className="flex flex-col items-center justify-center w-full gap-6 " id={"bnode_x60hc" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_qj9nh" name="image" style={{backgroundImage:`url("${M.answer_explanation_image?.url}")`}} className="w-32 h-32 bg-gray-200 bg-center bg-cover rounded-md" id={"bnode_qj9nh" + (idx?"__"+idx:"")} extra-attr >
                
            </div>
        
            </div>
        
            </div>
        
            </div>) }
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_m6ry2;