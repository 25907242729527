import React from "react";
import { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Routes, Route, Link, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { useRaisedShadow } from "./use-raised-shadow";

import { fallbackRender, add_sub, remove_subs } from "../utils.jsx";
import { COMP_REG } from "../comp_registry.js";
import g_fn from "../g_fn";

const AS = g_fn.AS;

// @req: keep reference to subs here and remove them on unmount or reload
const SUB_ID = "bnode_wzto2";
const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};

// local code - e.g sub components


export function ReorderIcon({ dragControls }) {
    return (
        <svg
        data_id="bnode_4fdh6"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        b_type="icon"
        className="cursor-grab"
        style={{pointerEvents: "auto"}}
        // onPointerDown={(event) => {
        //     dragControls.start(event);
        //     event.stopPropagation();
        // }}
        // onMouseUp={(e)=>{e.preventDefault(); e.stopPropagation()}}
        // onMouseDown={(e)=>{e.preventDefault(); e.stopPropagation()}}
        // onClick={(e)=>{e.preventDefault(); e.stopPropagation()}}
        >
        <rect
            data_id=""
            width="44"
            height="44"
            rx="8"
            fill="#F3F4F6"
            extra-attr
        ></rect>

        <path
            data_id=""
            d="M14 20H30"
            stroke="#6B7280"
            stroke-width="2"
            stroke-linecap="round"
            extra-attr
        ></path>

        <path
            data_id=""
            d="M14 24H30"
            stroke="#6B7280"
            stroke-width="2"
            stroke-linecap="round"
            extra-attr
        ></path>

        <path
            data_id=""
            d="M18 16L22 12L26 16H18Z"
            fill="#6B7280"
            stroke="#6B7280"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            extra-attr
        ></path>

        <path
            data_id=""
            d="M18 28L22 32L26 28H18Z"
            fill="#6B7280"
            stroke="#6B7280"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            extra-attr
        ></path>
        </svg>
    );
};

const comp_bnode_wzto2 = (props) => {
  const INFO = {
    model_id: "fhmae",
    model_name: "question",
    op: "get_one",
    comp_id: "comp_bnode_wzto2",
  };

  // Routes

  // FOR IS_MANY
  let idx = props.idx; // also for protection from error when we might think is many but it's not
  let V = props.V || {}; // for protection from error

  // STATES
  const [rerender, set_rerender] = useState({});
  const [M, set_M] = useState(props.M || {});
  const [PM, set_PM] = useState(props.PM || []);
  const [selected_M, set_selected_M] = useState(null);

  // REFS

  // EFFECTS

  useEffect(() => {
    console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
  }, [rerender]);

  useEffect(() => {
    const deinit = init();

    return () => {
      if (deinit && typeof deinit === "function") deinit();
    };
  }, []);

  useEffect(() => {
    g_fn.bro_get_one(INFO, set_M, props);
  }, [props]);

  useEffect(() => {
    const sub = AS.GSTORE.subscribe((e) => {
      // console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);
      // if model_id and eid are same
      // once data changes we will update M
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (EN_BROKEN_COMP_LOGS.MODEL_EFFECT) {
      const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
      console.log(
        "MODEL CHANGED : " + MI + "   => ",
        "model",
        M,
        " props",
        props
      );
    }
  }, [M]);

  useEffect(() => {
    if (!INFO.model_id) return;

    if (query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
    const id = g_fn.get_selected_entity_id(props, INFO);
    if (!id) {
      return console.warn("NO ID FOUND TO UPDATE ENTITY");
    }

    (async () => {
      const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
      console.log("GSTORE GET ONE ENTITY : ", r);
      if (r) set_selected_M(r);
    })();

    const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
      const id = g_fn.get_selected_entity_id(props, INFO);
      if (!id)
        return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
      (async () => {
        const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
        console.log(
          "GSTORE GET ONE ENTITY IN SUBSCRIPTION : ",
          r,
          INFO.model_id,
          INFO.comp_id
        );
        if (r) set_selected_M(r);
      })();
    });

    // On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
  }, []);

  // FUNCTIONS

  const init = () => {
    if (!INFO.model_id) return;

    INFO.query = query; // @todo : make it possible to change in UI editor
    const id = g_fn.get_one_find_id(props, INFO, relation);
    if (!id) {
      // errors => [{code:string, msg:string}]
      // warning => []
      console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
      // return;
    }

    // subscription
    const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e) => {
      console.log("USER RE-RENDERED");
      const data = e.data;
      set_M(data);
    });

    // first time
    AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);

    return () => {
      sub.unsubscribe();
    };
  };

  const [show_dropdown, set_show_dropdown] = useState(false);
  const show_dropdown_fn = () => {
    set_show_dropdown(true);
  };
  const hide_dropdown_fn = () => {
    set_show_dropdown(false);
  };
  const enhance_question = async (e, inp) => {
    e.stopPropagation();
    console.log("M is: ", M);
    const system_msg =
      "You are a question enhancer you will be given question and an extra prompt use that to enhance given question in json format";
    // const question_data = {
    //   question:M.question,
    //   // answers:M.answers,
    //   // correct_answer:M.correct_answer,
    //   // answer_explanation:M.answer_explanation,
    //   // tags:M.tags
    // }
    const ctx = {
      question_data: M.question,
    };

    const prompt = inp;

    const data = {
      system_msg: system_msg,
      ctx: ctx,
      topic: prompt,
    };
    console.log("data is: ", data);
    const resp = await fetch(
      "https://api-manager.brokenatom.workers.dev/enhance/openai/prompts",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    ).catch((e) => {
      console.log("error is : ", e.message);
      // set_show_loading(false);
      return;
    });

    if (!resp) {
      console.warn("no response");
      // set_show_loading(false);
      return;
    }

    const res = await resp.json().catch((e) => {
      console.warn("error in parsing res : ", e.message);
      return;
    });
    console.log("res in json is : ", res);
    // set_show_loading(false);
    const test_obj = res.data[0].chatModelResult;
    console.log("test object is :", test_obj);
    if (!test_obj) return;

    const questions = test_obj.questions;
    if (!questions) {
      console.warn("no questions generated");
      g_fn.feedback(
        "No questions generated from AI. please give proper input prompt"
      );
      return;
    }

    console.log("question is :", questions);
  };
  const close_question_update_modal = () => {
    set_show_question_update_modal(false);
  };
  const [show_question_update_modal, set_show_question_update_modal] =
    useState(false);
  const question_update_modal = (e) => {
    console.log("e is :", e, show_question_update_modal);
    e.stopPropagation();
    g_fn.bro_select_one(e, M, INFO, props);
    AS.navigate("bnode_k2lnn");
    // set_show_question_update_modal(true);
  };

  // STATEMENTS

  INFO.set_M = set_M;
  INFO.on_created = props.on_created || props.INFO?.on_created;
  INFO.on_selected = props.on_selected || props.INFO?.on_selected;
  const relation = {
    model_id: "yvjbe",
    prop_name: "questions",
    comp_id: "comp_bnode_36i4u",
  };
  const query = null;

  // CONDITIONALS ATTRS
  const COND_ATTRS = {};

  

  // DYNAMIC REACT STATES
  const REACT_STATES = {};
  REACT_STATES["M"] = { state: M, set_state: set_M };

  const AS_COMPONENTS = AS.components["comp_bnode_wzto2"] || {};
  AS_COMPONENTS.REACT_STATES = REACT_STATES;
  AS.components["comp_bnode_wzto2"] = AS_COMPONENTS;

  // MAPPED DATA
  const MAPPED_DATA = {};


  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={(d) => {
        console.error(d);
      }}
    >
    <Reorder.Item
        value={M}
        id={M.id}
        style={{ boxShadow, y }}
        dragListener={false}
        dragControls={dragControls}
        className="w-full"
        >
            <div
                data_id="bnode_wzto2"
                name="question"
                op="get_one"
                className="new-div w-full bg-white rounded-[20px] flex-col p-4 gap-4 flex justify-start items-start hover:drop-shadow-md"
                id={"bnode_wzto2" + (idx ? "__" + idx : "")}
                style={{
                columnGap: "16px",
                gap: "16px",
                rowGap: "16px",
                webkitColumnGap: "16px",
                }}
                page_id="bnode_4ft0a"
                onClick={(e) => {
                g_fn.dev.emit_m(e, M, INFO);
                }}
                extra-attr
            >

                <div
                data_id="bnode_7qgn5"

                onClick={question_update_modal}
                model_id="fhmae"
                name="question-data"
                className="w-full flex flex-col gap-4"
                id={"bnode_7qgn5" + (idx ? "__" + idx : "")}
                page_id="bnode_k2lnn"
                extra-attr
                >
                <div
                    data_id="bnode_tmlrq"
                    className="new-div w-full flex items-center justify-center relative"
                    id={"bnode_tmlrq" + (idx ? "__" + idx : "")}
                    extra-attr
                >
                    <div
                    data_id="bnode_lbffi"
                    className="new-div w-fit flex items-center justify-center px-2"
                    id={"bnode_lbffi" + (idx ? "__" + idx : "")}
                    textContentVb="{props.IDX + 1}"
                    extra-attr
                    >
                    {props.IDX + 1}
                    </div>

                    <div
                    data_id="bnode_mwt52"
                    name="CONT-type"
                    className="w-full  "
                    id={"bnode_mwt52" + (idx ? "__" + idx : "")}
                    extra-attr
                    >
                    <div
                        data_id="bnode_34ja9"
                        id={"bnode_34ja9" + (idx ? "__" + idx : "")}
                        className="flex-row flex justify-center items-center gap-2"
                        extra-attr
                    >
                        

                        <ReorderIcon dragControls={dragControls} />

                        <div
                        data_id="bnode_s5bww"
                        name="type"
                        className="flex flex-col  w-full gap-6 "
                        id={"bnode_s5bww" + (idx ? "__" + idx : "")}
                        extra-attr
                        >
                        {M?.type !== "MCQs" && (
                            <span
                            data_id="bnode_cokba"
                            id={"bnode_cokba" + (idx ? "__" + idx : "")}
                            className=" text-xl font-semibold font-['Open_Sans']"
                            textContentVb="{M.type}"
                            extra-attr
                            >
                            {M.type}
                            </span>
                        )}
                        {M?.type === "MCQs" && (
                            <div
                            data_id="bnode_csfq0"
                            className="font-['Open_Sans'] font-normal text-sm"
                            id={"bnode_csfq0" + (idx ? "__" + idx : "")}
                            extra-attr
                            >
                            Multiple Choice
                            </div>
                        )}
                        </div>
                    </div>
                    </div>

                    <div
                    data_id="bnode_d9f63"
                    className="new-div w-full gap-3 flex justify-end items-start"
                    id={"bnode_d9f63" + (idx ? "__" + idx : "")}
                    name="right"
                    extra-attr
                    >
                    <div
                        data_id="bnode_ph4ts"
                        id={"bnode_ph4ts" + (idx ? "__" + idx : "")}
                        className="items-center justify-center gap-3 flex flex-col"
                        onMouseOver={show_dropdown_fn}
                        onMouseOut={hide_dropdown_fn}
                        extra-attr
                    >
                        <button
                        data_id="bnode_msv5j"
                        type="button"
                        className="p-0.5 rounded-full text-xl text-black hover:text-white group flex font-medium bg-gradient-to-r from-[#FF5994] to-[#3488FD] hover:bg-gradient-to-r hover:from-[#E62368] hover:to-[#1E62C2]"
                        id={"bnode_msv5j" + (idx ? "__" + idx : "")}
                        //   onMouseEnter={show_dropdown_fn}
                        extra-attr
                        >
                        <div
                            data_id="bnode_rr6s7"
                            className="inline-flex items-center justify-center gap-1 p-2 bg-white group-hover:bg-gradient-to-r from-[#E62368] to-[#1E62C2] rounded-full flex"
                            id={"bnode_rr6s7" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <div
                            data_id="bnode_iowbf"
                            className="new-div w-fit flex items-center justify-center text-base font-semibold font-['Open_Sans']"
                            id={"bnode_iowbf" + (idx ? "__" + idx : "")}
                            extra-attr
                            >
                            ✨
                            </div>

                            <div
                            data_id="bnode_dobeu"
                            className="bg-clip-text text-transparent group-hover:text-white bg-gradient-to-r hover:from-[#E62368] hover:to-[#1E62C2] whitespace-nowrap text-base font-semibold font-['Open_Sans']"
                            id={"bnode_dobeu" + (idx ? "__" + idx : "")}
                            extra-attr
                            >
                            Enhance With AI
                            </div>

                            {!show_dropdown && (
                            <svg
                                data_id="bnode_n8k5f"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                data-lucide="chevron-down"
                                className="w-[24px] h-[24px]"
                                id={"bnode_n8k5f" + (idx ? "__" + idx : "")}
                                b_type="icon"
                                extra-attr
                            >
                                <path data_id="" d="m6 9 6 6 6-6" extra-attr></path>
                            </svg>
                            )}
                            {show_dropdown && (
                            <svg
                                data_id="bnode_pf2pn"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                data-lucide="chevron-up"
                                className="w-[24px] h-[24px]"
                                id={"bnode_pf2pn" + (idx ? "__" + idx : "")}
                                b_type="icon"
                                extra-attr
                            >
                                <path data_id="" d="m18 15-6-6-6 6" extra-attr></path>
                            </svg>
                            )}
                        </div>
                        </button>

                        <fn
                        data_id="bnode_umnvv"
                        name="fn:show_dropdown_fn"
                        id={"bnode_umnvv" + (idx ? "__" + idx : "")}
                        extra-attr
                        ></fn>

                        {show_dropdown && (
                        <div
                            data_id="bnode_u92pk"
                            className="w-fit flex items-center justify-center flex-col p-3 gap-[10px] absolute bg-white z-50 shadow-md rounded-lg border border-[#F3F4F6] top-[56px] right-[79px]"
                            id={"bnode_u92pk" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <div
                            data_id="bnode_tkz8p"
                            className="new-div hover:text-[#ff5994] w-fit flex items-center justify-start border-b border-[#E5E7EB] pb-[10px] text-lg font-normal font-['Open_Sans'] whitespace-nowrap"
                            onClick={(e) =>
                                enhance_question(e, "regenerate the question")
                            }
                            id={"bnode_tkz8p" + (idx ? "__" + idx : "")}
                            extra-attr
                            >
                            Regenerate the question
                            </div>

                            <div
                            data_id="bnode_zo826"
                            className="new-div hover:text-[#ff5994] w-fit flex items-center justify-start border-b border-[#E5E7EB] pb-[10px] text-lg font-normal font-['Open_Sans'] whitespace-nowrap"
                            id={"bnode_zo826" + (idx ? "__" + idx : "")}
                            onClick={(e) =>
                                enhance_question(e, "Convert to Real-World scenario")
                            }
                            extra-attr
                            >
                            Convert to Real-World scenario
                            </div>

                            <div
                            data_id="bnode_g51sq"
                            className="new-div hover:text-[#ff5994] w-fit flex items-center justify-start border-b border-[#E5E7EB] pb-[10px] text-lg font-normal font-['Open_Sans'] whitespace-nowrap"
                            id={"bnode_g51sq" + (idx ? "__" + idx : "")}
                            onClick={(e) =>
                                enhance_question(e, "Make it more Captivating")
                            }
                            extra-attr
                            >
                            Make it more Captivating
                            </div>

                            <div
                            data_id="bnode_w758g"
                            className="new-div hover:text-[#ff5994] w-fit flex items-center justify-start text-lg font-normal font-['Open_Sans'] whitespace-nowrap"
                            id={"bnode_w758g" + (idx ? "__" + idx : "")}
                            onClick={(e) =>
                                enhance_question(e, "Double check answer ")
                            }
                            extra-attr
                            >
                            Double check answer
                            </div>

                            <fn
                            data_id="bnode_mfm2q"
                            name="fn:enhance_question"
                            id={"bnode_mfm2q" + (idx ? "__" + idx : "")}
                            extra-attr
                            ></fn>
                        </div>
                        )}
                    </div>

                    <button
                        data_id="bnode_nraoe"
                        type="button"
                        className="text-[#6B7280] bg-[#F3F4F6] hover:text-white hover:bg-[#6B7280] focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center"
                        id={"bnode_nraoe" + (idx ? "__" + idx : "")}
                        name="copy"
                        onPointerDown={(e)=>e.stopPropagation()}
                        onClick={(e)=>{
                          e.stopPropagation();
                          // This is setup in 36i4u component
                          const duplicate_question = window.duplicate_question;
                          if(duplicate_question && typeof duplicate_question === "function" && props.IDX !== undefined){
                              duplicate_question(M.id);
                          }
                        }}
                        extra-attr
                    >
                        <svg
                        data_id="bnode_p5vhn"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-lucide="files"
                        className="lucide lucide-files w-20px h-20px"
                        id={"bnode_p5vhn" + (idx ? "__" + idx : "")}
                        b_type="icon"
                        extra-attr
                        >
                        <path
                            data_id=""
                            d="M15.5 2H8.6c-.4 0-.8.2-1.1.5-.3.3-.5.7-.5 1.1v12.8c0 .4.2.8.5 1.1.3.3.7.5 1.1.5h9.8c.4 0 .8-.2 1.1-.5.3-.3.5-.7.5-1.1V6.5L15.5 2z"
                            extra-attr
                        ></path>

                        <path
                            data_id=""
                            d="M3 7.6v12.8c0 .4.2.8.5 1.1.3.3.7.5 1.1.5h9.8"
                            extra-attr
                        ></path>

                        <path data_id="" d="M15 2v5h5" extra-attr></path>
                        </svg>
                    </button>

                    <button
                        data_id="bnode_6ciu6"
                        type="button"
                        className="text-[#FB7185] bg-[#F3F4F6] hover:text-white hover:bg-[#FB7185] focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center"
                        id={"bnode_6ciu6" + (idx ? "__" + idx : "")}
                        name="questions"
                        action="['delete-prop-item']"
                        b_idx={props.IDX}
                        model_id="fhmae"
                        onClick={(e) => {
                            e.stopPropagation();
                            // This is setup in 36i4u component
                            // 
                            const delete_question = window.delete_question;
                            if(delete_question && typeof delete_question === "function" && props.IDX !== undefined){
                                delete_question(M.id);
                            }
                        }}

                        // This is because we are going to update the question in clicking the entire component
                        onPointerDown={(e)=>e.stopPropagation()}
                        extra-attr
                    >
                        <svg
                        data_id="bnode_bhovd"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-lucide="trash-2"
                        className="w-20px h-20px"
                        id={"bnode_bhovd" + (idx ? "__" + idx : "")}
                        b_type="icon"
                        extra-attr
                        >
                        <path data_id="" d="M3 6h18" extra-attr></path>

                        <path
                            data_id=""
                            d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"
                            extra-attr
                        ></path>

                        <path
                            data_id=""
                            d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"
                            extra-attr
                        ></path>

                        <line
                            data_id=""
                            x1="10"
                            x2="10"
                            y1="11"
                            y2="17"
                            extra-attr
                        ></line>

                        <line
                            data_id=""
                            x1="14"
                            x2="14"
                            y1="11"
                            y2="17"
                            extra-attr
                        ></line>
                        </svg>
                    </button>
                    </div>
                </div>

                <div
                    data_id="bnode_tzvf3"
                    name="CONT-question"
                    className="w-full  "
                    id={"bnode_tzvf3" + (idx ? "__" + idx : "")}
                    extra-attr
                >
                    <div
                    data_id="bnode_474wu"
                    id={"bnode_474wu" + (idx ? "__" + idx : "")}
                    extra-attr
                    >
                    <div
                        data_id="bnode_2ga6c"
                        name="question"
                        className="flex flex-col w-full gap-6 "
                        id={"bnode_2ga6c" + (idx ? "__" + idx : "")}
                        extra-attr
                    >
                        <span
                        data_id="bnode_rejmh"
                        id={"bnode_rejmh" + (idx ? "__" + idx : "")}
                        className=" font-semibold font-['Open_Sans'] text-base"
                        textContentVb="{M.question || ''}"
                        extra-attr
                        >
                        {M.question || ""}
                        </span>
                    </div>
                    </div>
                </div>

                {/* Images If any */}
                <div
                    data_id="bnode_uvwkb"
                    name="CONT-images"
                    className="w-full flex gap-2"
                    id={"bnode_uvwkb" + (idx ? "__" + idx : "")}
                    extra-attr
                >
                    

                    {M && g_fn.safe_array(M.images).map((V, idx) => (
                        <div
                        data_id="bnode_qhsua"
                        name="images"
                        className="flex flex-col items-center justify-center w-full gap-6 "
                        id={"bnode_qhsua" + (idx ? "__" + idx : "")}
                        key={g_fn.get_key_from_meta(M, "images", idx)}
                        extra-attr
                        >
                        <div
                            data_id="bnode_abt5g"
                            name="image"
                            style={{ backgroundImage: `url("${V?.v?.url}")` }}
                            className="w-32 h-32 flex flex-shrink-0 bg-no-repeat bg-gray-200 bg-center bg-cover rounded-md"
                            id={"bnode_abt5g" + (idx ? "__" + idx : "")}
                            extra-attr
                        ></div>
                        </div>
                    ))}
                </div>


                <div
                    data_id="bnode_104il"
                    name="CONT-answers"
                    className="w-full grid grid-cols-2 gap-4"
                    id={"bnode_104il" + (idx ? "__" + idx : "")}
                    style={{
                    margin: "0px",
                    marginBottom: "0px",
                    marginLeft: "0px",
                    marginRight: "0px",
                    marginTop: "0px",
                    }}
                    extra-attr
                >
                    {M &&
                    g_fn.safe_array(M.answers).map((V, idx) => (
                        <div
                        data_id="bnode_12y4n"
                        name="answers"
                        className="w-full flex items-center p-5 bg-[#F3F4F6] rounded-[12px]"
                        id={"bnode_12y4n" + (idx ? "__" + idx : "")}
                        key={g_fn.get_key_from_meta(M, "answers", idx)}
                        extra-attr
                        >
                        <div
                            data_id="bnode_byu68"
                            className="gap-2 flex justify-start items-center font-['Open_Sans']"
                            id={"bnode_byu68" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            {!M.correct_answers.find((a) => a.id === V.id) && (
                            <div
                                data_id="bnode_iwbte"
                                className="w-fit text-xl"
                                id={"bnode_iwbte" + (idx ? "__" + idx : "")}
                                extra-attr
                            >
                                ❌
                            </div>
                            )}
                            {M?.correct_answers?.find((a) => a?.id === V?.id) && (
                            <div
                                data_id="bnode_93645"
                                className="w-fit text-xl"
                                id={"bnode_93645" + (idx ? "__" + idx : "")}
                                extra-attr
                            >
                                ✅
                            </div>
                            )}

                            <span
                            data_id="bnode_uyut1"
                            id={"bnode_uyut1" + (idx ? "__" + idx : "")}
                            className="font-['Open_Sans'] flex justify-start items-center text-base font-semibold"
                            textContentVb="{V?.v || ''}"
                            extra-attr
                            >
                            {V?.v || ""}
                            </span>
                        </div>
                        </div>
                    ))}
                </div>

                <div
                    data_id="bnode_wwhjj"
                    className="new-div w-full flex items-center justify-center"
                    id={"bnode_wwhjj" + (idx ? "__" + idx : "")}
                    extra-attr
                >
                    <div
                    data_id="bnode_54sdp"
                    name="CONT-tags"
                    className="w-full gap-2 flex justify-end items-end flex-row"
                    id={"bnode_54sdp" + (idx ? "__" + idx : "")}
                    extra-attr
                    >
                    {M &&
                        g_fn.safe_array(M.tags).map((V, idx) => (
                        <div
                            data_id="bnode_bc8ob"
                            name="tags"
                            className="flex flex-col w-fit gap-6 rounded-[8px] bg-[#3D3D3D] text-white text-base font-semibold font-['Open_Sans'] p-2"
                            id={"bnode_bc8ob" + (idx ? "__" + idx : "")}
                            key={g_fn.get_key_from_meta(M, "tags", idx)}
                            extra-attr
                        >
                            <span
                            data_id="bnode_fbdji"
                            id={"bnode_fbdji" + (idx ? "__" + idx : "")}
                            className=" text-sm font-normal font-['Open_Sans']"
                            textContentVb="{V?.v || ''}"
                            extra-attr
                            >
                            {V?.v || ""}
                            </span>
                        </div>
                        ))}
                    </div>
                </div>

                <div
                    data_id="bnode_nmj19"
                    name="CONT-correct_answers"
                    className="w-full flex flex-col gap-2"
                    id={"bnode_nmj19" + (idx ? "__" + idx : "")}
                    extra-attr
                >
                    {M &&
                    g_fn
                        .safe_array(M.correct_answers)
                        .map((V, idx) => (
                        <div
                            data_id="bnode_rez5i"
                            name="correct_answers"
                            className="flex flex-col w-full gap-6 "
                            id={"bnode_rez5i" + (idx ? "__" + idx : "")}
                            key={g_fn.get_key_from_meta(M, "correct_answers", idx)}
                            extra-attr
                        ></div>
                        ))}
                </div>
                </div>

                {show_question_update_modal ||
                (false && (
                    <div
                    data_id="bnode_xfggv"
                    className="w-full fixed left-0 top-0 overflow-auto bg-black/40 h-full flex  rounded-xl"
                    id={"bnode_xfggv" + (idx ? "__" + idx : "")}
                    name="UPDATEPOPUP"
                    extra-attr
                    >
                    <div
                        data_id="bnode_njrgs"
                        className="bg-white w-[70%]   flex-col flex justify-center  items-center relative gap-4 shadow-md rounded-xl mx-auto  mt-10 h-full"
                        id={"bnode_njrgs" + (idx ? "__" + idx : "")}
                        extra-attr
                    >
                        <div
                        data_id="bnode_w7pgw"
                        className="new-div w-full flex items-center justify-center h-full flex-col"
                        id={"bnode_w7pgw" + (idx ? "__" + idx : "")}
                        extra-attr
                        >
                        <COMP_REG.comp_bnode_qp1d5
                            {...{
                            M: V,
                            PM: [...PM, M],
                            on_created: props.on_created || INFO.on_created,
                            on_selected: props.on_selected || INFO.on_selected,
                            INFO: INFO,
                            id: M?.id,
                            prop_name: "questions",
                            IDX: idx,
                            }}
                            extra-attr
                        />
                        </div>

                        <div
                        data_id="bnode_g596l"
                        className="absolute top-2 right-4 hover:bg-rose-600 rounded-full  hover:text-white p-0.5"
                        id={"bnode_g596l" + (idx ? "__" + idx : "")}
                        onClick={close_question_update_modal}
                        extra-attr
                        >
                        <svg
                            data_id="bnode_obpfb"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            data-lucide="x"
                            className="w-6 h-6"
                            b_type="icon"
                            id={"bnode_obpfb" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <path data_id="" d="M18 6 6 18" extra-attr></path>

                            <path data_id="" d="m6 6 12 12" extra-attr></path>
                        </svg>
                        </div>

                        <fn
                        data_id="bnode_usxwq"
                        name="fn:close_question_update_modal"
                        id={"bnode_usxwq" + (idx ? "__" + idx : "")}
                        extra-attr
                        ></fn>
                    </div>
                    </div>
                ))}
            </div>
    </Reorder.Item>

      
    </ErrorBoundary>
  );
};

export default comp_bnode_wzto2;
