import g_fn from "./g_fn";

// online setup
let id = setInterval(() => {
    const B = window.broken || false;
    if (B) {
        clearInterval(id);
        console.log('INIT: online module');
        if(B.online) B.current = B.online;
        return;
    }
});

function init() {
    console.log('INIT: prod module');
    g_fn.AS.is_dev = false;
}

export default {
    init,
};
