import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_4ft0a";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_4ft0a = (props)=>{
			const INFO  = {"model_id":"","model_name":"","op":"get_one","comp_id":"comp_bnode_4ft0a"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					g_fn.bro_get_one(INFO, set_M, props);
				}, [props])
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = ()=>{

				if(!INFO.model_id) return;


				INFO.query = query; // @todo : make it possible to change in UI editor
				const id = g_fn.get_one_find_id(props, INFO, relation);
				if(!id) {
					// errors => [{code:string, msg:string}]
					// warning => []
					console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
					// return;
				}

				// subscription
				const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e)=>{
					console.log("USER RE-RENDERED");
					const data = e.data;
					set_M(data);
				});


				// first time
				AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);



				return ()=>{
					sub.unsubscribe();
				}

			}
		


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_onagw"] = COND_ATTRS["bnode_onagw"] || {};
			

				COND_ATTRS["bnode_yvim8"] = COND_ATTRS["bnode_yvim8"] || {};
			

				COND_ATTRS["bnode_s87ct"] = COND_ATTRS["bnode_s87ct"] || {};
			

				COND_ATTRS["bnode_2xzo4"] = COND_ATTRS["bnode_2xzo4"] || {};
			

				COND_ATTRS["bnode_jxahy"] = COND_ATTRS["bnode_jxahy"] || {};
			

				COND_ATTRS["bnode_akk07"] = COND_ATTRS["bnode_akk07"] || {};
			

				COND_ATTRS["bnode_47sxy"] = COND_ATTRS["bnode_47sxy"] || {};
			

				COND_ATTRS["bnode_59kzu"] = COND_ATTRS["bnode_59kzu"] || {};
			

				COND_ATTRS["bnode_0qn0c"] = COND_ATTRS["bnode_0qn0c"] || {};
			

				COND_ATTRS["bnode_prufl"] = COND_ATTRS["bnode_prufl"] || {};
			

				COND_ATTRS["bnode_bfenx"] = COND_ATTRS["bnode_bfenx"] || {};
			

				COND_ATTRS["bnode_hl7r2"] = COND_ATTRS["bnode_hl7r2"] || {};
			

				COND_ATTRS["bnode_7lvho"] = COND_ATTRS["bnode_7lvho"] || {};
			

				COND_ATTRS["bnode_4zpyf"] = COND_ATTRS["bnode_4zpyf"] || {};
			

				COND_ATTRS["bnode_wg8rw"] = COND_ATTRS["bnode_wg8rw"] || {};
			

				COND_ATTRS["bnode_eikwt"] = COND_ATTRS["bnode_eikwt"] || {};
			

				COND_ATTRS["bnode_83co7"] = COND_ATTRS["bnode_83co7"] || {};
			

				COND_ATTRS["bnode_78xtt"] = COND_ATTRS["bnode_78xtt"] || {};
			

				COND_ATTRS["bnode_klthz"] = COND_ATTRS["bnode_klthz"] || {};
			

				COND_ATTRS["bnode_mq8ds"] = COND_ATTRS["bnode_mq8ds"] || {};
			

				COND_ATTRS["bnode_awdu7"] = COND_ATTRS["bnode_awdu7"] || {};
			

				COND_ATTRS["bnode_lcey6"] = COND_ATTRS["bnode_lcey6"] || {};
			

				COND_ATTRS["bnode_c7m2g"] = COND_ATTRS["bnode_c7m2g"] || {};
			

				COND_ATTRS["bnode_ih0a2"] = COND_ATTRS["bnode_ih0a2"] || {};
			

				COND_ATTRS["bnode_rs0yj"] = COND_ATTRS["bnode_rs0yj"] || {};
			

				COND_ATTRS["bnode_657yp"] = COND_ATTRS["bnode_657yp"] || {};
			

				COND_ATTRS["bnode_5ypvw"] = COND_ATTRS["bnode_5ypvw"] || {};
			

				COND_ATTRS["bnode_e8o4r"] = COND_ATTRS["bnode_e8o4r"] || {};
			

				COND_ATTRS["bnode_x8a7q"] = COND_ATTRS["bnode_x8a7q"] || {};
			

				COND_ATTRS["bnode_sj1c0"] = COND_ATTRS["bnode_sj1c0"] || {};
			

				COND_ATTRS["bnode_5mgq9"] = COND_ATTRS["bnode_5mgq9"] || {};
			

				COND_ATTRS["bnode_n2jeh"] = COND_ATTRS["bnode_n2jeh"] || {};
			

				COND_ATTRS["bnode_eidfg"] = COND_ATTRS["bnode_eidfg"] || {};
			

				COND_ATTRS["bnode_4ft0a"] = COND_ATTRS["bnode_4ft0a"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_4ft0a"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_4ft0a"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_4ft0a" className="min-h-[64px] w-full h-fit overflow-auto flex gap-4 p-4 items-center justify-center flex-col bg-[#eeeeee]" name="page:quizsettings" findex="a4" id={"bnode_4ft0a" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_eidfg" className="new-div w-5/6 flex items-center justify-center flex-col bg-white p-8 rounded-[16px]" id={"bnode_eidfg" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_yvim8" className="new-div w-full flex justify-end items-center" id={"bnode_yvim8" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_onagw" width="57" height="58" viewBox="0 0 57 58" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_onagw" + (idx?"__"+idx:"")} b_type="icon" className="cursor-pointer" extra-attr >
                
            <rect data_id="" x="0.431641" y="0.5" width="56.5685" height="56.5685" rx="28.2842" fill="#FFE4E6" extra-attr >
                
            </rect>
        

            <path data_id="" fill-rule="evenodd" clip-rule="evenodd" d="M36.8107 20.6896C37.3695 21.2484 37.3695 22.1544 36.8107 22.7133L22.6449 36.8791C22.086 37.4379 21.18 37.4379 20.6212 36.8791C20.0624 36.3202 20.0624 35.4142 20.6212 34.8554L34.787 20.6896C35.3458 20.1308 36.2518 20.1308 36.8107 20.6896Z" fill="#F43F5E" extra-attr >
                
            </path>
        

            <path data_id="" fill-rule="evenodd" clip-rule="evenodd" d="M20.621 20.6896C21.1798 20.1308 22.0858 20.1308 22.6447 20.6896L36.8105 34.8554C37.3693 35.4142 37.3693 36.3202 36.8105 36.8791C36.2516 37.4379 35.3456 37.4379 34.7868 36.8791L20.621 22.7133C20.0621 22.1544 20.0621 21.2484 20.621 20.6896Z" fill="#F43F5E" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        

            <div data_id="bnode_s87ct" className="new-div w-full text-xl font-bold font-['Open_Sans'] flex justify-start items-center pb-5" id={"bnode_s87ct" + (idx?"__"+idx:"")} extra-attr >
                Quiz Settings
            </div>
        

            <div data_id="bnode_x8a7q" className="new-div w-full flex items-center justify-center gap-5 flex-col" id={"bnode_x8a7q" + (idx?"__"+idx:"")} name="inputbox" extra-attr >
                
            <div data_id="bnode_akk07" className="new-div w-full flex items-center justify-center flex-col gap-2" id={"bnode_akk07" + (idx?"__"+idx:"")} name="name" extra-attr >
                
            <div data_id="bnode_2xzo4" className="new-div w-full text-xl font-normal font-['Open_Sans'] flex justify-start items-center" id={"bnode_2xzo4" + (idx?"__"+idx:"")} extra-attr >
                Name
            </div>
        
<input data_id="bnode_jxahy" type="text" placeholder="Enter Name here" className="w-full rounded p-3 outline outline-1 outline-[#9CA3AF] rounded-[12px] text-xl font-normal font-['Open_Sans']" id={"bnode_jxahy" + (idx?"__"+idx:"")} extra-attr />
            </div>
        

            <div data_id="bnode_47sxy" className="new-div w-full flex justify-start items-center text-xl font-normal font-['Open_Sans']" id={"bnode_47sxy" + (idx?"__"+idx:"")} extra-attr >
                Subject
            </div>
        

            <div data_id="bnode_bfenx" className="new-div w-full flex items-center justify-center p-5 bg-[#F3F4F6] rounded-[12px] cursor-pointer" id={"bnode_bfenx" + (idx?"__"+idx:"")} name="dropdown" extra-attr >
                
            <div data_id="bnode_59kzu" className="new-div w-full flex justify-start items-center text-xl font-normal font-['Open_Sans']" id={"bnode_59kzu" + (idx?"__"+idx:"")} extra-attr >
                📚 Mathematics
            </div>
        

            <div data_id="bnode_prufl" className="new-div w-fit flex justify-end items-center" id={"bnode_prufl" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <svg data_id="bnode_0qn0c" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="chevron-down" className="w-[24px] h-[24px]" id={"bnode_0qn0c" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="m6 9 6 6 6-6" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        
            </div>
        

            <div data_id="bnode_hl7r2" className="new-div w-full flex justify-start items-center text-xl font-normal font-['Open_Sans']" id={"bnode_hl7r2" + (idx?"__"+idx:"")} extra-attr >
                Syllabus
            </div>
        

            <div data_id="bnode_eikwt" className="new-div w-full flex items-center justify-center p-5 bg-[#F3F4F6] rounded-[12px] cursor-pointer" id={"bnode_eikwt" + (idx?"__"+idx:"")} name="dropdown" extra-attr >
                
            <div data_id="bnode_7lvho" className="new-div w-full flex justify-start items-center text-xl font-normal font-['Open_Sans']" id={"bnode_7lvho" + (idx?"__"+idx:"")} extra-attr >
                🏫 ICSE (Indian School Certificate Examination)
            </div>
        

            <div data_id="bnode_wg8rw" className="new-div w-fit flex justify-end items-center" id={"bnode_wg8rw" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <svg data_id="bnode_4zpyf" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="chevron-down" className="w-[24px] h-[24px]" id={"bnode_4zpyf" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="m6 9 6 6 6-6" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        
            </div>
        

            <div data_id="bnode_83co7" className="new-div w-full flex justify-start items-center text-xl font-normal font-['Open_Sans']" id={"bnode_83co7" + (idx?"__"+idx:"")} extra-attr >
                Grade
            </div>
        

            <div data_id="bnode_awdu7" className="new-div w-full flex items-center justify-center p-5 bg-[#F3F4F6] rounded-[12px] cursor-pointer" id={"bnode_awdu7" + (idx?"__"+idx:"")} name="dropdown" extra-attr >
                
            <div data_id="bnode_78xtt" className="new-div w-full flex justify-start items-center text-xl font-normal font-['Open_Sans']" id={"bnode_78xtt" + (idx?"__"+idx:"")} extra-attr >
                🎓 11th Grade
            </div>
        

            <div data_id="bnode_mq8ds" className="new-div w-fit flex justify-end items-center" id={"bnode_mq8ds" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <svg data_id="bnode_klthz" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="chevron-down" className="w-[24px] h-[24px]" id={"bnode_klthz" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="m6 9 6 6 6-6" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        
            </div>
        

            <div data_id="bnode_lcey6" className="new-div w-full flex justify-start items-center text-xl font-normal font-['Open_Sans']" id={"bnode_lcey6" + (idx?"__"+idx:"")} extra-attr >
                Quiz Duration
            </div>
        

            <div data_id="bnode_657yp" className="new-div w-full flex items-center justify-center p-5 bg-[#F3F4F6] rounded-[12px] cursor-pointer" id={"bnode_657yp" + (idx?"__"+idx:"")} name="dropdown" extra-attr >
                
            <div data_id="bnode_c7m2g" className="new-div w-full flex justify-start items-center text-xl font-normal font-['Open_Sans']" id={"bnode_c7m2g" + (idx?"__"+idx:"")} extra-attr >
                45 minutes
            </div>
        

            <div data_id="bnode_rs0yj" className="new-div w-fit flex justify-end items-center" id={"bnode_rs0yj" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <svg data_id="bnode_ih0a2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="chevron-down" className="w-[24px] h-[24px]" id={"bnode_ih0a2" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="m6 9 6 6 6-6" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        
            </div>
        

            <div data_id="bnode_5ypvw" className="new-div w-full flex justify-start items-center text-xl font-normal font-['Open_Sans']" id={"bnode_5ypvw" + (idx?"__"+idx:"")} extra-attr >
                Text Instruction
            </div>
        

            <textarea data_id="bnode_e8o4r" type="text" placeholder="Textarea..." className="w-full rounded p-2 px-4 outline outline-1 outline-[#9CA3AF] p-5" id={"bnode_e8o4r" + (idx?"__"+idx:"")} extra-attr >
                
            </textarea>
        
            </div>
        

            <div data_id="bnode_n2jeh" className="new-div w-full flex justify-start items-center gap-4 p-5" id={"bnode_n2jeh" + (idx?"__"+idx:"")} extra-attr >
                
            <button data_id="bnode_sj1c0" type="button" className="w-fit text-white bg-[#FF5994]  focus:ring-4 focus:ring-blue-300 rounded-[8px] p-4 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center" id={"bnode_sj1c0" + (idx?"__"+idx:"")} name="publish" extra-attr >
                Submit
            </button>
        

            <button data_id="bnode_5mgq9" type="button" className="text-black bg-[#F3F4F6]  focus:ring-4 focus:ring-blue-300 rounded-[8px] p-4 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center" id={"bnode_5mgq9" + (idx?"__"+idx:"")} name="publish" extra-attr >
                Cancel
            </button>
        
            </div>
        
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_4ft0a;