import React from "react";
import { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Routes, Route, Link, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";

import { fallbackRender, add_sub, remove_subs } from "../utils.jsx";
import { COMP_REG } from "../comp_registry.js";
import g_fn from "../g_fn";

const AS = g_fn.AS;

// @req: keep reference to subs here and remove them on unmount or reload
const SUB_ID = "bnode_986bz";
const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};

// local code - e.g sub components

const comp_bnode_986bz = (props) => {
    const INFO = {
        model_id: "fhmae",
        model_name: "question",
        op: "update_one",
        comp_id: "comp_bnode_986bz",
    };

    // Routes

    // FOR IS_MANY
    let idx = props.idx; // also for protection from error when we might think is many but it's not
    let V = props.V || {}; // for protection from error

    // STATES
    const [rerender, set_rerender] = useState({});
    const [M, set_M] = useState(props.M || {});
    const [PM, set_PM] = useState(props.PM || []);
    const [selected_M, set_selected_M] = useState(null);

    // REFS

    // EFFECTS

    useEffect(() => {
        console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
    }, [rerender]);

    useEffect(() => {
        const deinit = init();

        return () => {
            if (deinit && typeof deinit === "function") deinit();
        };
    }, []);

    useEffect(() => {
        console.log("PROPS : ", props, INFO, relation);
    }, [props]);

    useEffect(() => {
        const sub = AS.GSTORE.subscribe((e) => {
            // console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);
            // if model_id and eid are same
            // once data changes we will update M
        });

        return () => {
            sub.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (EN_BROKEN_COMP_LOGS.MODEL_EFFECT) {
            const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
            console.log(
                "MODEL CHANGED : " + MI + "   => ",
                "model",
                M,
                " props",
                props
            );
        }
    }, [M]);

    useEffect(() => {
        if (!INFO.model_id) return;

        if (query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
        const id = g_fn.get_selected_entity_id(props, INFO);
        if (!id) {
            return console.warn("NO ID FOUND TO UPDATE ENTITY");
        }

        (async () => {
            const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
            console.log("GSTORE GET ONE ENTITY : ", r);
            if (r) set_selected_M(r);
        })();

        const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
            const id = g_fn.get_selected_entity_id(props, INFO);
            if (!id)
                return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
            (async () => {
                const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
                console.log(
                    "GSTORE GET ONE ENTITY IN SUBSCRIPTION : ",
                    r,
                    INFO.model_id,
                    INFO.comp_id
                );
                if (r) set_selected_M(r);
            })();
        });


        // On set in GSTORE set state
        const sub2 = AS.GSTORE.subscribe((e)=>{
            const {type, model_id, eid, data} = e;
            if(!data || !data.id) return;
            if(type !== 'set') return;
            if(model_id !== INFO.model_id) return;
            set_M(M=>{
            if(eid !== M.id) return M;
            return data;
            })
        });

        return () => {
            sub.unsubscribe();
            sub2.unsubscribe();
        };
    }, []);

    // FUNCTIONS

    const init = () => {
        if (!INFO.model_id) return;

        if (query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
        let id = g_fn.get_selected_entity_id(props, INFO);
        if (!id && !relation) {
            return console.warn("NO ID FOUND TO UPDATE ENTITY");
        }

        if (!id && relation) {
            const parent_M =
                props.PM && Array.isArray(props.PM) && props.PM.length
                    ? props.PM[props.PM.length - 1]
                    : null;
            console.log("PM AND PARENT_M : ", props.PM, parent_M);
            if (parent_M) {
                const prop = parent_M[relation.prop_name];
                if (!prop)
                    return console.warn("NO PROP FOUND FROM PARENT TO UPDATE ENTITY");
                if (typeof prop === "string") {
                    id = prop;
                } else {
                    id = prop.id;
                }
            }
        }

        (async () => {
            const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
            console.log("GSTORE GET ONE ENTITY : ", r);
            if (r) {
                const r_copy = JSON.parse(JSON.stringify(r));
                set_M(r_copy);
            }
        })();

        const subs = [];

        subs.push(
            AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
                const id = g_fn.get_selected_entity_id(props, INFO);
                if (!id)
                    return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
                (async () => {
                    const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
                    console.log(
                        "GSTORE GET ONE ENTITY IN SUBSCRIPTION -> UPDATE_ONE : ",
                        INFO.model_id,
                        r
                    );
                    if (r) {
                        const r_copy = JSON.parse(JSON.stringify(r));
                        set_M(r_copy);
                    }
                })();
            })
        );

        return () => {
            subs.forEach((sub) => sub.unsubscribe());
        };
    };

    const toggle_correct_ans = (idx) => {
        const value = M.answers[idx || 0];
        if (!value) console.warn("value is not defined");

        let cans = M.correct_answers;
        if (!cans || !Array.isArray(cans)) cans = [];

        const pos = cans.findIndex((ca) => ca.id === value.id);
        if (pos < 0) cans.push(value);
        else cans.splice(pos, 1);

        set_M(m => ({ ...M, correct_answers: [...cans] }))

    };




    const [tag_val, set_tag_val] = useState("");
    const addtag = (e) => {
        const inp_val = e.target?.value;
        console.log("inp val i s : ", inp_val);
        set_tag_val(inp_val);
    };
    const new_tag_add_fn = (e, tag_val) => {
        g_fn.bro_add_prop_item(e, INFO);
        set_M((M) => {
            const L = M.tags.length;
            if (!L) return M;
            M.tags[L - 1].v = tag_val;
            return { ...M, tags: [...M.tags] };
        });
    };
    const update_question_fn = async () => {
        const entity_id = M.id;
        console.log("Entity id in the question update fn is: ", entity_id);
        const question_model = g_fn.get_model_by_name("question");
        const upd_ques = await g_fn.AS.GSTORE.update_one(
            question_model.id,
            entity_id,
            M
        );
        if (!upd_ques?.success) {
            console.warn("error in question update");
            return;
        }
        AS.navigate("bnode_jj973");
    };

    // STATEMENTS

    INFO.set_M = set_M;
    INFO.on_created = props.on_created || props.INFO?.on_created;
    INFO.on_selected = props.on_selected || props.INFO?.on_selected;
    const relation = null;
    const query = null;

    // CONDITIONALS ATTRS
    const COND_ATTRS = {};

    COND_ATTRS["bnode_lqbui"] = COND_ATTRS["bnode_lqbui"] || {};

    COND_ATTRS["bnode_sioco"] = COND_ATTRS["bnode_sioco"] || {};

    COND_ATTRS["bnode_x7km0"] = COND_ATTRS["bnode_x7km0"] || {};

    COND_ATTRS["bnode_oaqlj"] = COND_ATTRS["bnode_oaqlj"] || {};

    COND_ATTRS["bnode_bfxgr"] = COND_ATTRS["bnode_bfxgr"] || {};

    COND_ATTRS["bnode_fo80e"] = COND_ATTRS["bnode_fo80e"] || {};

    COND_ATTRS["bnode_8p5hq"] = COND_ATTRS["bnode_8p5hq"] || {};

    COND_ATTRS["bnode_p3fva"] = COND_ATTRS["bnode_p3fva"] || {};

    COND_ATTRS["bnode_vxtr9"] = COND_ATTRS["bnode_vxtr9"] || {};

    COND_ATTRS["bnode_lwa7c"] = COND_ATTRS["bnode_lwa7c"] || {};

    COND_ATTRS["bnode_l5icn"] = COND_ATTRS["bnode_l5icn"] || {};

    COND_ATTRS["bnode_k7h81"] = COND_ATTRS["bnode_k7h81"] || {};

    COND_ATTRS["bnode_ggn9t"] = COND_ATTRS["bnode_ggn9t"] || {};

    COND_ATTRS["bnode_eariz"] = COND_ATTRS["bnode_eariz"] || {};

    COND_ATTRS["bnode_8b2s8"] = COND_ATTRS["bnode_8b2s8"] || {};

    COND_ATTRS["bnode_v7om9"] = COND_ATTRS["bnode_v7om9"] || {};

    COND_ATTRS["bnode_fwy9v"] = COND_ATTRS["bnode_fwy9v"] || {};

    COND_ATTRS["bnode_vrf7p"] = COND_ATTRS["bnode_vrf7p"] || {};

    COND_ATTRS["bnode_2wsgs"] = COND_ATTRS["bnode_2wsgs"] || {};

    COND_ATTRS["bnode_tzrzt"] = COND_ATTRS["bnode_tzrzt"] || {};

    COND_ATTRS["bnode_oidbx"] = COND_ATTRS["bnode_oidbx"] || {};

    COND_ATTRS["bnode_6ib5e"] = COND_ATTRS["bnode_6ib5e"] || {};

    COND_ATTRS["bnode_1sulq"] = COND_ATTRS["bnode_1sulq"] || {};

    COND_ATTRS["bnode_zh8sq"] = COND_ATTRS["bnode_zh8sq"] || {};

    COND_ATTRS["bnode_2n8j3"] = COND_ATTRS["bnode_2n8j3"] || {};

    COND_ATTRS["bnode_2kyub"] = COND_ATTRS["bnode_2kyub"] || {};

    COND_ATTRS["bnode_ohuj5"] = COND_ATTRS["bnode_ohuj5"] || {};

    COND_ATTRS["bnode_dhy0b"] = COND_ATTRS["bnode_dhy0b"] || {};

    COND_ATTRS["bnode_93ock"] = COND_ATTRS["bnode_93ock"] || {};

    COND_ATTRS["bnode_mq0k4"] = COND_ATTRS["bnode_mq0k4"] || {};

    COND_ATTRS["bnode_evr4w"] = COND_ATTRS["bnode_evr4w"] || {};

    COND_ATTRS["bnode_a1tdj"] = COND_ATTRS["bnode_a1tdj"] || {};

    COND_ATTRS["bnode_8i5dj"] = COND_ATTRS["bnode_8i5dj"] || {};

    COND_ATTRS["bnode_bamjq"] = COND_ATTRS["bnode_bamjq"] || {};

    COND_ATTRS["bnode_yau56"] = COND_ATTRS["bnode_yau56"] || {};

    COND_ATTRS["bnode_y56ht"] = COND_ATTRS["bnode_y56ht"] || {};

    COND_ATTRS["bnode_8rg4i"] = COND_ATTRS["bnode_8rg4i"] || {};

    COND_ATTRS["bnode_wjg11"] = COND_ATTRS["bnode_wjg11"] || {};

    COND_ATTRS["bnode_8ctz7"] = COND_ATTRS["bnode_8ctz7"] || {};

    COND_ATTRS["bnode_wv7ng"] = COND_ATTRS["bnode_wv7ng"] || {};

    COND_ATTRS["bnode_eq111"] = COND_ATTRS["bnode_eq111"] || {};

    COND_ATTRS["bnode_tvuyh"] = COND_ATTRS["bnode_tvuyh"] || {};

    COND_ATTRS["bnode_5lh9v"] = COND_ATTRS["bnode_5lh9v"] || {};

    COND_ATTRS["bnode_urm7c"] = COND_ATTRS["bnode_urm7c"] || {};

    COND_ATTRS["bnode_yikwo"] = COND_ATTRS["bnode_yikwo"] || {};

    COND_ATTRS["bnode_ti4sr"] = COND_ATTRS["bnode_ti4sr"] || {};

    COND_ATTRS["bnode_omymf"] = COND_ATTRS["bnode_omymf"] || {};

    COND_ATTRS["bnode_9ha1s"] = COND_ATTRS["bnode_9ha1s"] || {};

    COND_ATTRS["bnode_wyr37"] = COND_ATTRS["bnode_wyr37"] || {};

    COND_ATTRS["bnode_y59k6"] = COND_ATTRS["bnode_y59k6"] || {};

    COND_ATTRS["bnode_jb2vu"] = COND_ATTRS["bnode_jb2vu"] || {};

    COND_ATTRS["bnode_2dfib"] = COND_ATTRS["bnode_2dfib"] || {};

    COND_ATTRS["bnode_iku12"] = COND_ATTRS["bnode_iku12"] || {};

    COND_ATTRS["bnode_7k97o"] = COND_ATTRS["bnode_7k97o"] || {};

    COND_ATTRS["bnode_608ra"] = COND_ATTRS["bnode_608ra"] || {};

    COND_ATTRS["bnode_go328"] = COND_ATTRS["bnode_go328"] || {};

    COND_ATTRS["bnode_jdd59"] = COND_ATTRS["bnode_jdd59"] || {};

    COND_ATTRS["bnode_szykx"] = COND_ATTRS["bnode_szykx"] || {};

    COND_ATTRS["bnode_55u0y"] = COND_ATTRS["bnode_55u0y"] || {};

    COND_ATTRS["bnode_980xr"] = COND_ATTRS["bnode_980xr"] || {};

    COND_ATTRS["bnode_d9qaq"] = COND_ATTRS["bnode_d9qaq"] || {};

    COND_ATTRS["bnode_8qo5n"] = COND_ATTRS["bnode_8qo5n"] || {};

    COND_ATTRS["bnode_6wld8"] = COND_ATTRS["bnode_6wld8"] || {};

    COND_ATTRS["bnode_2zpxs"] = COND_ATTRS["bnode_2zpxs"] || {};

    COND_ATTRS["bnode_t32ee"] = COND_ATTRS["bnode_t32ee"] || {};

    COND_ATTRS["bnode_sgo9m"] = COND_ATTRS["bnode_sgo9m"] || {};

    COND_ATTRS["bnode_alt3j"] = COND_ATTRS["bnode_alt3j"] || {};

    COND_ATTRS["bnode_5cqqv"] = COND_ATTRS["bnode_5cqqv"] || {};

    COND_ATTRS["bnode_ctfww"] = COND_ATTRS["bnode_ctfww"] || {};

    COND_ATTRS["bnode_16qz9"] = COND_ATTRS["bnode_16qz9"] || {};

    COND_ATTRS["bnode_pphkr"] = COND_ATTRS["bnode_pphkr"] || {};

    COND_ATTRS["bnode_r2sw2"] = COND_ATTRS["bnode_r2sw2"] || {};

    COND_ATTRS["bnode_pmw0p"] = COND_ATTRS["bnode_pmw0p"] || {};

    COND_ATTRS["bnode_fb5rd"] = COND_ATTRS["bnode_fb5rd"] || {};

    COND_ATTRS["bnode_eiwrt"] = COND_ATTRS["bnode_eiwrt"] || {};

    COND_ATTRS["bnode_qeaaa"] = COND_ATTRS["bnode_qeaaa"] || {};

    COND_ATTRS["bnode_hldh4"] = COND_ATTRS["bnode_hldh4"] || {};

    COND_ATTRS["bnode_huoqv"] = COND_ATTRS["bnode_huoqv"] || {};

    COND_ATTRS["bnode_pxucm"] = COND_ATTRS["bnode_pxucm"] || {};

    COND_ATTRS["bnode_m2eto"] = COND_ATTRS["bnode_m2eto"] || {};

    COND_ATTRS["bnode_vpbov"] = COND_ATTRS["bnode_vpbov"] || {};

    COND_ATTRS["bnode_ttkay"] = COND_ATTRS["bnode_ttkay"] || {};

    COND_ATTRS["bnode_cqny4"] = COND_ATTRS["bnode_cqny4"] || {};

    COND_ATTRS["bnode_lk6xf"] = COND_ATTRS["bnode_lk6xf"] || {};

    COND_ATTRS["bnode_6mvxb"] = COND_ATTRS["bnode_6mvxb"] || {};

    COND_ATTRS["bnode_cobx5"] = COND_ATTRS["bnode_cobx5"] || {};

    COND_ATTRS["bnode_96czt"] = COND_ATTRS["bnode_96czt"] || {};

    COND_ATTRS["bnode_z25ru"] = COND_ATTRS["bnode_z25ru"] || {};

    COND_ATTRS["bnode_vb6ae"] = COND_ATTRS["bnode_vb6ae"] || {};

    COND_ATTRS["bnode_pihn5"] = COND_ATTRS["bnode_pihn5"] || {};

    COND_ATTRS["bnode_0vu98"] = COND_ATTRS["bnode_0vu98"] || {};

    COND_ATTRS["bnode_2c3y6"] = COND_ATTRS["bnode_2c3y6"] || {};

    COND_ATTRS["bnode_cshvs"] = COND_ATTRS["bnode_cshvs"] || {};

    COND_ATTRS["bnode_4jzky"] = COND_ATTRS["bnode_4jzky"] || {};

    COND_ATTRS["bnode_76nu0"] = COND_ATTRS["bnode_76nu0"] || {};

    COND_ATTRS["bnode_mee7g"] = COND_ATTRS["bnode_mee7g"] || {};

    COND_ATTRS["bnode_y3vl7"] = COND_ATTRS["bnode_y3vl7"] || {};

    COND_ATTRS["bnode_g7k1q"] = COND_ATTRS["bnode_g7k1q"] || {};

    COND_ATTRS["bnode_ejjiy"] = COND_ATTRS["bnode_ejjiy"] || {};

    COND_ATTRS["bnode_cahzg"] = COND_ATTRS["bnode_cahzg"] || {};

    COND_ATTRS["bnode_ioyz8"] = COND_ATTRS["bnode_ioyz8"] || {};

    COND_ATTRS["bnode_986bz"] = COND_ATTRS["bnode_986bz"] || {};

    // DYNAMIC REACT STATES
    const REACT_STATES = {};
    REACT_STATES["M"] = { state: M, set_state: set_M };

    const AS_COMPONENTS = AS.components["comp_bnode_986bz"] || {};
    AS_COMPONENTS.REACT_STATES = REACT_STATES;
    AS.components["comp_bnode_986bz"] = AS_COMPONENTS;

    // MAPPED DATA
    const MAPPED_DATA = {};

    return (
        <ErrorBoundary
            fallbackRender={fallbackRender}
            onReset={(d) => {
                console.error(d);
            }}
        >
            <div
                data_id="bnode_986bz"
                name="question"
                op="update_one"
                className="w-3/4 flex flex-col p-16  rounded relative overflow-auto h-full bg-white rounded-[16px]"
                id={"bnode_986bz" + (idx ? "__" + idx : "")}
                onClick={(e) => {
                    g_fn.dev.emit_m(e, M, INFO);
                }}
                extra-attr
            >
                <div
                    data_id="bnode_g7k1q"
                    name="question-data"
                    className="flex flex-col gap-4"
                    id={"bnode_g7k1q" + (idx ? "__" + idx : "")}
                    extra-attr
                >
                    <div
                        data_id="bnode_k7h81"
                        className="new-div w-full flex items-center justify-center"
                        id={"bnode_k7h81" + (idx ? "__" + idx : "")}
                        extra-attr
                    >
                        <div
                            data_id="bnode_vxtr9"
                            name="CONT-type"
                            className="w-full"
                            id={"bnode_vxtr9" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <div
                                data_id="bnode_p3fva"
                                id={"bnode_p3fva" + (idx ? "__" + idx : "")}
                                extra-attr
                            >
                                <div
                                    data_id="bnode_8p5hq"
                                    name="type"
                                    className="flex flex-col  w-full gap-6 "
                                    id={"bnode_8p5hq" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    <div
                                        data_id="bnode_fo80e"
                                        className="flex flex-row items-center justify-center w-fit gap-4"
                                        id={"bnode_fo80e" + (idx ? "__" + idx : "")}
                                        extra-attr
                                    >
                                        <select
                                            data_id="bnode_bfxgr"
                                            name="type"
                                            placeholder="type"
                                            action="oninput-update-m"
                                            className="bg-white px-2 py-1 rounded-[8px] border border-[#8A8A8A] w-fit text-lg placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-semibold font-['Open_Sans']"
                                            id={"bnode_bfxgr" + (idx ? "__" + idx : "")}
                                            model_id="fhmae"
                                            onInput={(e) => {
                                                g_fn.bro_on_input(e, INFO);
                                            }}
                                            extra-attr
                                        >
                                            <option
                                                data_id="bnode_lqbui"
                                                selected="true"
                                                id={"bnode_lqbui" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            ></option>

                                            <option
                                                data_id="bnode_sioco"
                                                value="MCQs"
                                                selected={M.type === "MCQs"}
                                                id={"bnode_sioco" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                MCQs
                                            </option>

                                            <option
                                                data_id="bnode_x7km0"
                                                value="FITB"
                                                selected={M.type === "FITB"}
                                                id={"bnode_x7km0" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                FITB
                                            </option>

                                            <option
                                                data_id="bnode_oaqlj"
                                                value="reorder"
                                                selected={M.type === "reorder"}
                                                id={"bnode_oaqlj" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                reorder
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            data_id="bnode_l5icn"
                            className="hidden w-fit flex items-center justify-center border border-[#8A8A8A] hover:bg-[#8A8A8A] p-2 rounded-[8px] group hover:text-black"
                            id={"bnode_l5icn" + (idx ? "__" + idx : "")}
                            action="['go-to-page']"
                            page_id="bnode_v6ckv"
                            model_id="fhmae"
                            onClick={(e) => {
                                if (
                                    window.BROKEN_DEV_VARS &&
                                    window.BROKEN_DEV_VARS.IS_EDITING_MODE
                                )
                                    return;
                                e.preventDefault();
                                e.stopPropagation();

                                const el = e.currentTarget;
                                el.disabled = true;
                                el.classList?.add("disabled", "cursor-progress");
                                g_fn.bro_go_to_page(e, M, INFO, props);
                                el.disabled = false;
                                el.classList?.remove("disabled", "cursor-progress");
                            }}
                            extra-attr
                        >
                            <div
                                data_id="bnode_lwa7c"
                                className="new-div w-fit flex items-center justify-center whitespace-nowrap font-semibold font-['Open_Sans'] text-[#8A8A8A] text-sm"
                                id={"bnode_lwa7c" + (idx ? "__" + idx : "")}
                                extra-attr
                            >
                                💡 Add Answer Explaination
                            </div>
                        </div>
                    </div>

                    <div
                        data_id="bnode_vrf7p"
                        name="CONT-question"
                        className="w-full"
                        id={"bnode_vrf7p" + (idx ? "__" + idx : "")}
                        extra-attr
                    >
                        <div
                            data_id="bnode_fwy9v"
                            id={"bnode_fwy9v" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <div
                                data_id="bnode_ggn9t"
                                className="block mb-2 uppercase text-xl font-bold font-['Open_Sans'] text-black"
                                id={"bnode_ggn9t" + (idx ? "__" + idx : "")}
                                extra-attr
                            >
                                Question
                            </div>

                            <div
                                data_id="bnode_v7om9"
                                name="question"
                                className="flex flex-col  w-full gap-6 "
                                id={"bnode_v7om9" + (idx ? "__" + idx : "")}
                                extra-attr
                            >
                                <div
                                    data_id="bnode_8b2s8"
                                    name="cont"
                                    className="flex flex-row items-center justify-center w-full gap-4"
                                    id={"bnode_8b2s8" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    <input
                                        data_id="bnode_eariz"
                                        name="question"
                                        type="text"
                                        value={M.question}
                                        placeholder="question"
                                        action="oninput-update-m"
                                        className="border border-[#9CA3AF] bg-white p-5 rounded-[12px] w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 text-xl font-semibold font-['Open_Sans']"
                                        id={"bnode_eariz" + (idx ? "__" + idx : "")}
                                        model_id="fhmae"
                                        onInput={(e) => {
                                            g_fn.bro_on_input(e, INFO);
                                        }}
                                        extra-attr
                                    />
                                </div>
                            </div>
                        </div>


                        <div data_id="bnode_zzwgd" name="CONT-images" className="w-full flex flex-col gap-2 py-2" id={"bnode_zzwgd" + (idx ? "__" + idx : "")} extra-attr >

                            <div className="text-center">Add Images</div>


                            {M && g_fn.safe_array(M.images).map(
                                (V, idx) => (

                                    <div data_id="bnode_fq8w4" name="images" className="flex flex-col  w-full gap-6 rounded-lg border border-gray-200 p-1" id={"bnode_fq8w4" + (idx ? "__" + idx : "")} key={g_fn.get_key_from_meta(M, "images", idx)} extra-attr >

                                        <div data_id="bnode_m76vl" name="cont" className="relative w-full flex flex-col md:flex-row items-center justify-center gap-4" id={"bnode_m76vl" + (idx ? "__" + idx : "")} extra-attr >

                                            <div data_id="bnode_msmgu" name="image" style={{ backgroundImage: `url("${V?.v?.url}")` }} className="w-32 h-32  flex flex-shrink-0 bg-no-repeat bg-gray-200 bg-center bg-cover rounded-md" id={"bnode_msmgu" + (idx ? "__" + idx : "")} extra-attr >

                                            </div>


                                            <div data_id="bnode_tms2j" className="flex flex-col items-center" id={"bnode_tms2j" + (idx ? "__" + idx : "")} extra-attr >
                                                <input data_id="bnode_4226h" multiple="true" name="images" b_idx={idx} type="file" placeholder="images" action="oninput-update-m-file-idx" className="block w-full text-sm text-[var(--neutral-500)] file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-[var(--primary-100)] file:text-[var(--neutral-50)] hover:file:bg-[var(--primary-100)] p-1 rounded-full bg-[var(--neutral-100)] w-fit " id={"bnode_4226h" + (idx ? "__" + idx : "")} model_id="fhmae" onInput={(e) => { g_fn.bro_on_input_file_idx(e, INFO) }} extra-attr />

                                                <div data_id="bnode_leuoo" className="text-gray-400 w-fit px-2 text-center" id={"bnode_leuoo" + (idx ? "__" + idx : "")} extra-attr >
                                                    or
                                                </div>


                                                <div data_id="bnode_m1faj" className="relative w-full md:w-fit" id={"bnode_m1faj" + (idx ? "__" + idx : "")} extra-attr >

                                                    <div data_id="bnode_c44o2" className="absolute left-2 top-1/2 -translate-y-1/2" id={"bnode_c44o2" + (idx ? "__" + idx : "")} extra-attr >

                                                        <svg data_id="bnode_ep9qw" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" id={"bnode_ep9qw" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                                            <path data_id="" d="M10.0002 13.7031C10.4297 14.2772 10.9776 14.7523 11.6067 15.096C12.2359 15.4398 12.9317 15.6442 13.6468 15.6954C14.362 15.7466 15.0798 15.6435 15.7515 15.3929C16.4233 15.1423 17.0333 14.7502 17.5402 14.2431L20.5402 11.2431C21.451 10.3001 21.955 9.03707 21.9436 7.72608C21.9322 6.4151 21.4063 5.16104 20.4793 4.234C19.5523 3.30696 18.2982 2.78111 16.9872 2.76972C15.6762 2.75833 14.4132 3.2623 13.4702 4.17309L11.7502 5.88309M14.0002 11.7031C13.5707 11.129 13.0228 10.6539 12.3936 10.3102C11.7645 9.96639 11.0687 9.76197 10.3535 9.71076C9.63841 9.65954 8.92061 9.76272 8.24885 10.0133C7.5771 10.2639 6.96709 10.656 6.4602 11.1631L3.4602 14.1631C2.54941 15.1061 2.04544 16.3691 2.05683 17.6801C2.06822 18.9911 2.59407 20.2451 3.52111 21.1722C4.44815 22.0992 5.70221 22.6251 7.01319 22.6365C8.32418 22.6479 9.58719 22.1439 10.5302 21.2331L12.2402 19.5231" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" extra-attr >

                                                            </path>

                                                        </svg>

                                                    </div>

                                                    <input data_id="bnode_6shys" name="images" b_idx={idx} className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 pl-9 " b_type="image" type="text" placeholder="Paste image url" action="oninput-update-m-idx" id={"bnode_6shys" + (idx ? "__" + idx : "")} model_id="fhmae" onInput={(e) => { g_fn.bro_on_input_idx(e, INFO) }} extra-attr />
                                                </div>

                                            </div>


                                            <button data_id="bnode_i771z" name="images" b_idx={idx} action="delete-prop-item" className="bg-none text-rose-300 p-1.5 rounded-md hover:bg-rose-400 hover:text-white transition-all ease-in-out duration-150 absolute top-1.5 md:my-auto md:top-auto right-1.5  " id={"bnode_i771z" + (idx ? "__" + idx : "")} model_id="fhmae" onClick={(e) => {



                                                if (window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
                                                e.preventDefault(); e.stopPropagation();

                                                const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                                                g_fn.bro_delete_prop_item(e, INFO)
                                                el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
                                            }} extra-attr >

                                                <svg data_id="bnode_bfeqi" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id={"bnode_bfeqi" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                                    <path data_id="" d="M18 6 6 18" extra-attr >

                                                    </path>


                                                    <path data_id="" d="m6 6 12 12" extra-attr >

                                                    </path>

                                                </svg>

                                            </button>

                                        </div>

                                    </div>

                                )
                            )}


                            <button data_id="bnode_l4cdw" type="image" action="add-prop-item" name="images" className="w-10 h-10 flex justify-center items-center mx-auto border border-gray-100 bg-gray-200 hover:border-gray-200 text-gray-700 text-3xl rounded-lg " id={"bnode_l4cdw" + (idx ? "__" + idx : "")} model_id="fhmae" onClick={(e) => {



                                if (window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
                                e.preventDefault(); e.stopPropagation();

                                const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                                g_fn.bro_add_prop_item(e, INFO)
                                el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
                            }} extra-attr >

                                <svg data_id="bnode_lstaa" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id={"bnode_lstaa" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                    <path data_id="" d="M5 12h14" extra-attr >

                                    </path>


                                    <path data_id="" d="M12 5v14" extra-attr >

                                    </path>

                                </svg>

                            </button>

                        </div>
                    </div>

                    <div
                        data_id="bnode_ctfww"
                        name="CONT-answers"
                        className="w-full flex gap-2 flex-col"
                        id={"bnode_ctfww" + (idx ? "__" + idx : "")}
                        extra-attr
                    >
                        <div
                            data_id="bnode_t32ee"
                            className="new-div w-full flex items-center justify-center gap-4"
                            id={"bnode_t32ee" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            {M &&
                                g_fn.safe_array(M.answers).map((V, idx) => (
                                    <div
                                        data_id="bnode_2zpxs"
                                        name="answers"
                                        className="flex flex-col  w-full gap-6 "
                                        id={"bnode_2zpxs" + (idx ? "__" + idx : "")}
                                        key={g_fn.get_key_from_meta(M, "answers", idx)}
                                        extra-attr
                                    >
                                        <div
                                            data_id="bnode_dhy0b"
                                            name="1"
                                            className="flex items-center justify-center w-full gap-1 flex-col"
                                            id={"bnode_dhy0b" + (idx ? "__" + idx : "")}
                                            extra-attr
                                        >
                                            <div
                                                data_id="bnode_2n8j3"
                                                className="new-div w-full flex items-center justify-between flex-row px-2"
                                                id={"bnode_2n8j3" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            >
                                                {!M.correct_answers?.find((a) => a.id === V.id) && (
                                                    <div
                                                        data_id="bnode_tzrzt"
                                                        className="w-6 h-6 rounded-full bg-gray-500 p-1"
                                                        id={"bnode_tzrzt" + (idx ? "__" + idx : "")}
                                                        name="correct:false"
                                                        onClick={() => toggle_correct_ans(idx)}
                                                        extra-attr
                                                    >
                                                        <svg
                                                            data_id="bnode_2wsgs"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            data-lucide="check"
                                                            className="w-full h-full text-white"
                                                            id={"bnode_2wsgs" + (idx ? "__" + idx : "")}
                                                            b_type="icon"
                                                            extra-attr
                                                        >
                                                            <path
                                                                data_id=""
                                                                d="M20 6 9 17l-5-5"
                                                                extra-attr
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                )}
                                                {M?.correct_answers?.find((a) => a?.id === V?.id) && (
                                                    <div
                                                        data_id="bnode_6ib5e"
                                                        className="w-6 h-6 rounded-full bg-teal-500 p-1"
                                                        id={"bnode_6ib5e" + (idx ? "__" + idx : "")}
                                                        onClick={() => toggle_correct_ans(idx)}
                                                        name="correct:true"
                                                        extra-attr
                                                    >
                                                        <svg
                                                            data_id="bnode_oidbx"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            data-lucide="check"
                                                            className="w-full h-full text-white"
                                                            id={"bnode_oidbx" + (idx ? "__" + idx : "")}
                                                            b_type="icon"
                                                            extra-attr
                                                        >
                                                            <path
                                                                data_id=""
                                                                d="M20 6 9 17l-5-5"
                                                                extra-attr
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                )}

                                                <div
                                                    data_id="bnode_zh8sq"
                                                    className="new-div w-fit flex items-center justify-center bg-[#F3F4F6] text-[#4B5563] p-1 rounded-[8px]"
                                                    id={"bnode_zh8sq" + (idx ? "__" + idx : "")}
                                                    b_idx={idx}
                                                    action="['delete-prop-item']"
                                                    name="answers"
                                                    model_id="fhmae"
                                                    onClick={(e) => {
                                                        if (
                                                            window.BROKEN_DEV_VARS &&
                                                            window.BROKEN_DEV_VARS.IS_EDITING_MODE
                                                        )
                                                            return;
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        const el = e.currentTarget;
                                                        el.disabled = true;
                                                        el.classList?.add("disabled", "cursor-progress");
                                                        g_fn.bro_delete_prop_item(e, INFO);
                                                        el.disabled = false;
                                                        el.classList?.remove("disabled", "cursor-progress");
                                                    }}
                                                    extra-attr
                                                >
                                                    <svg
                                                        data_id="bnode_1sulq"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        data-lucide="trash-2"
                                                        className="w-6 h-6"
                                                        id={"bnode_1sulq" + (idx ? "__" + idx : "")}
                                                        b_type="icon"
                                                        extra-attr
                                                    >
                                                        <path data_id="" d="M3 6h18" extra-attr></path>

                                                        <path
                                                            data_id=""
                                                            d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"
                                                            extra-attr
                                                        ></path>

                                                        <path
                                                            data_id=""
                                                            d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"
                                                            extra-attr
                                                        ></path>

                                                        <line
                                                            data_id=""
                                                            x1="10"
                                                            x2="10"
                                                            y1="11"
                                                            y2="17"
                                                            extra-attr
                                                        ></line>

                                                        <line
                                                            data_id=""
                                                            x1="14"
                                                            x2="14"
                                                            y1="11"
                                                            y2="17"
                                                            extra-attr
                                                        ></line>
                                                    </svg>
                                                </div>
                                            </div>

                                            <input
                                                data_id="bnode_2kyub"
                                                name="answers"
                                                b_idx={idx}
                                                type="text"
                                                value={V.v}
                                                placeholder="answers"
                                                action="oninput-update-m-idx"
                                                className="border border-[#9CA3AF] bg-white p-4 min-h-24 w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] text-left flex justify-start items-start rounded-[12px] text-sm"
                                                id={"bnode_2kyub" + (idx ? "__" + idx : "")}
                                                model_id="fhmae"
                                                onInput={(e) => {
                                                    g_fn.bro_on_input_idx(e, INFO);
                                                }}
                                                extra-attr
                                            />

                                            <fn
                                                data_id="bnode_ohuj5"
                                                name="fn:toggle-correct"
                                                id={"bnode_ohuj5" + (idx ? "__" + idx : "")}
                                                extra-attr
                                            ></fn>
                                        </div>



                                        <fn
                                            data_id="bnode_8qo5n"
                                            name="overallUseEffect"
                                            id={"bnode_8qo5n" + (idx ? "__" + idx : "")}
                                            extra-attr
                                        ></fn>


                                    </div>
                                ))}
                        </div>

                        <button
                            data_id="bnode_5cqqv"
                            type="text"
                            action="add-prop-item"
                            name="answers"
                            className="w-fit flex justify-center items-center bg-[#F3F4F6] p-3 text-[#6B7280] text-3xl rounded-[12px]"
                            id={"bnode_5cqqv" + (idx ? "__" + idx : "")}
                            model_id="fhmae"
                            onClick={(e) => {
                                if (
                                    window.BROKEN_DEV_VARS &&
                                    window.BROKEN_DEV_VARS.IS_EDITING_MODE
                                )
                                    return;
                                e.preventDefault();
                                e.stopPropagation();

                                const el = e.currentTarget;
                                el.disabled = true;
                                el.classList?.add("disabled", "cursor-progress");
                                g_fn.bro_add_prop_item(e, INFO);
                                el.disabled = false;
                                el.classList?.remove("disabled", "cursor-progress");
                            }}
                            extra-attr
                        >
                            <svg
                                data_id="bnode_sgo9m"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                id={"bnode_sgo9m" + (idx ? "__" + idx : "")}
                                b_type="icon"
                                extra-attr
                            >
                                <path data_id="" d="M5 12h14" extra-attr></path>

                                <path data_id="" d="M12 5v14" extra-attr></path>
                            </svg>

                            <div
                                data_id="bnode_alt3j"
                                className="new-div w-full flex items-center justify-center text-xl font-normal font-['Open_Sans'] whitespace-nowrap"
                                id={"bnode_alt3j" + (idx ? "__" + idx : "")}
                                style={{
                                    padding: "0px",
                                    paddingBottom: "0px",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                    paddingTop: "0px",
                                }}
                                extra-attr
                            >
                                Add More Options
                            </div>
                        </button>
                    </div>

                    {false && (
                        <div
                            data_id="bnode_eiwrt"
                            name="CONT-time"
                            className="w-full"
                            id={"bnode_eiwrt" + (idx ? "__" + idx : "")}
                            extra-attr
                        >
                            <div
                                data_id="bnode_fb5rd"
                                id={"bnode_fb5rd" + (idx ? "__" + idx : "")}
                                extra-attr
                            >
                                <div
                                    data_id="bnode_16qz9"
                                    className="block text-gray-700 text-sm font-medium mb-2 capitalize"
                                    id={"bnode_16qz9" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    time
                                </div>

                                <div
                                    data_id="bnode_pmw0p"
                                    name="time"
                                    className="flex flex-col  w-full gap-6 "
                                    id={"bnode_pmw0p" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    <div
                                        data_id="bnode_r2sw2"
                                        name="cont"
                                        className="flex flex-row items-center justify-center w-full gap-4"
                                        id={"bnode_r2sw2" + (idx ? "__" + idx : "")}
                                        extra-attr
                                    >
                                        <input
                                            data_id="bnode_pphkr"
                                            name="time"
                                            type="number"
                                            value={M.time}
                                            placeholder="time"
                                            action="oninput-update-m"
                                            className="border border-[#222222]/5 hover:border-[#222222]/30 bg-[#222222]/5 px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-lg placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 "
                                            id={"bnode_pphkr" + (idx ? "__" + idx : "")}
                                            model_id="fhmae"
                                            onInput={(e) => {
                                                g_fn.bro_on_input(e, INFO);
                                            }}
                                            extra-attr
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div
                        data_id="bnode_pihn5"
                        name="CONT-tags"
                        className="w-full flex-col gap-2 flex justify-center items-end"
                        id={"bnode_pihn5" + (idx ? "__" + idx : "")}
                        extra-attr
                    >
                        <div
                            data_id="bnode_vpbov"
                            className="w-fit gap-2 flex justify-end items-center"
                            id={"bnode_vpbov" + (idx ? "__" + idx : "")}
                            name="created-tags"
                            extra-attr
                        >
                            {M &&
                                g_fn.safe_array(M.tags).map((V, idx) => (
                                    <div
                                        data_id="bnode_m2eto"
                                        name="tags"
                                        className="flex flex-col w-fit"
                                        id={"bnode_m2eto" + (idx ? "__" + idx : "")}
                                        key={g_fn.get_key_from_meta(M, "tags", idx)}
                                        extra-attr
                                    >
                                        <div
                                            data_id="bnode_pxucm"
                                            name="cont"
                                            className="flex flex-row items-center justify-center w-fit gap-2.5 bg-[#3D3D3D] p-2 rounded-[8px]"
                                            id={"bnode_pxucm" + (idx ? "__" + idx : "")}
                                            extra-attr
                                        >
                                            <div
                                                data_id="bnode_qeaaa"
                                                name="tags"
                                                b_idx={idx}
                                                className="text-[#D1D5DB] placeholder:text-[#222222]/30 text-base font-semibold font-['Open_Sans'] bg-[#3D3D3D] w-fit capitalize"
                                                id={"bnode_qeaaa" + (idx ? "__" + idx : "")}
                                                textContentVb="{V.v}"
                                                extra-attr
                                            >
                                                {V.v}
                                            </div>

                                            <button
                                                data_id="bnode_huoqv"
                                                name="tags"
                                                b_idx={idx}
                                                action="delete-prop-item"
                                                className="bg-none text-[#9CA3AF] hover:text-bg-rose-400 transition-all ease-in-out duration-150"
                                                id={"bnode_huoqv" + (idx ? "__" + idx : "")}
                                                model_id="fhmae"
                                                onClick={(e) => {
                                                    if (
                                                        window.BROKEN_DEV_VARS &&
                                                        window.BROKEN_DEV_VARS.IS_EDITING_MODE
                                                    )
                                                        return;
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    const el = e.currentTarget;
                                                    el.disabled = true;
                                                    el.classList?.add("disabled", "cursor-progress");
                                                    g_fn.bro_delete_prop_item(e, INFO);
                                                    el.disabled = false;
                                                    el.classList?.remove("disabled", "cursor-progress");
                                                }}
                                                extra-attr
                                            >
                                                <svg
                                                    data_id="bnode_hldh4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    id={"bnode_hldh4" + (idx ? "__" + idx : "")}
                                                    b_type="icon"
                                                    extra-attr
                                                >
                                                    <path data_id="" d="M18 6 6 18" extra-attr></path>

                                                    <path data_id="" d="m6 6 12 12" extra-attr></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                        </div>

                        <div
                            data_id="bnode_vb6ae"
                            className="new-div w-fit flex items-center justify-center gap-2"
                            id={"bnode_vb6ae" + (idx ? "__" + idx : "")}
                            name="adding tags"
                            extra-attr
                        >
                            <div
                                data_id="bnode_lk6xf"
                                className="new-div w-fit flex items-center justify-center"
                                id={"bnode_lk6xf" + (idx ? "__" + idx : "")}
                                name="add-tag"
                                extra-attr
                            >
                                <input
                                    data_id="bnode_ttkay"
                                    name="tags"
                                    value={tag_val}
                                    type="text"
                                    placeholder="Type Tag"
                                    onChange={(e) => addtag(e)}
                                    id={"bnode_ttkay" + (idx ? "__" + idx : "")}
                                    className="flex flex-row items-center justify-center w-fit gap-4 bg-white border border-[#9CA3AF] p-2 rounded-[4px] text-base font-normal font-['Open_Sans']"
                                    extra-attr
                                />

                                <fn
                                    data_id="bnode_cqny4"
                                    id={"bnode_cqny4" + (idx ? "__" + idx : "")}
                                    name="fn:addtag"
                                    extra-attr
                                ></fn>
                            </div>

                            <button
                                data_id="bnode_96czt"
                                type="text"
                                name="tags"
                                className="w-fit flex justify-center items-center bg-[#F3F4F6] text-[#6B7280] rounded-[4px] text-base font-normal font-['Open_Sans'] p-2"
                                id={"bnode_96czt" + (idx ? "__" + idx : "")}
                                onClick={(e) => new_tag_add_fn(e, tag_val)}
                                extra-attr
                            >
                                <svg
                                    data_id="bnode_6mvxb"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    id={"bnode_6mvxb" + (idx ? "__" + idx : "")}
                                    b_type="icon"
                                    extra-attr
                                >
                                    <path data_id="" d="M5 12h14" extra-attr></path>

                                    <path data_id="" d="M12 5v14" extra-attr></path>
                                </svg>

                                <div
                                    data_id="bnode_cobx5"
                                    className="new-div w-full flex items-center justify-center"
                                    id={"bnode_cobx5" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    Add Tag
                                </div>
                            </button>

                            <fn
                                data_id="bnode_z25ru"
                                name="fn:new_tag_add_fn"
                                id={"bnode_z25ru" + (idx ? "__" + idx : "")}
                                extra-attr
                            ></fn>
                        </div>
                    </div>

                    {/* Explainantions - Update */}
                    <div data_id="bnode_ywxho" name="CONT-answer explanation" className="w-full" id={"bnode_ywxho" + (idx ? "__" + idx : "")} extra-attr >

                        <div data_id="bnode_usjop" id={"bnode_usjop" + (idx ? "__" + idx : "")} extra-attr >

                            <div data_id="bnode_nw2jx" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_nw2jx" + (idx ? "__" + idx : "")} extra-attr >
                                answer explanation
                            </div>


                            <div data_id="bnode_955y1" name="answer_explanation" className="flex flex-col  w-full gap-6 " id={"bnode_955y1" + (idx ? "__" + idx : "")} extra-attr >

                                <div data_id="bnode_f79ra" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_f79ra" + (idx ? "__" + idx : "")} extra-attr >

                                    <textarea data_id="bnode_10ebz" name="answer_explanation" placeholder="Answer Explaination" value={M.answer_explanation} action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_10ebz" + (idx ? "__" + idx : "")} model_id="fhmae" onInput={(e) => { g_fn.bro_on_input(e, INFO) }} extra-attr >
                                    </textarea>

                                </div>

                            </div>

                        </div>

                    </div>

                    {/* Explainantions: Image - Update */}
                    <div data_id="bnode_kvt1w" name="CONT-answer explanation image" className="w-full" id={"bnode_kvt1w" + (idx ? "__" + idx : "")} extra-attr >

                        <div data_id="bnode_nv3pn" id={"bnode_nv3pn" + (idx ? "__" + idx : "")} extra-attr >

                            <div data_id="bnode_bx34v" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_bx34v" + (idx ? "__" + idx : "")} extra-attr >
                                answer explanation image
                            </div>


                            <div data_id="bnode_xa6jj" name="answer_explanation_image" className="flex flex-col  w-full gap-6 " id={"bnode_xa6jj" + (idx ? "__" + idx : "")} extra-attr >

                                <div data_id="bnode_2ewwn" name="cont" className="w-full flex flex-col md:flex-row items-center justify-center gap-4" id={"bnode_2ewwn" + (idx ? "__" + idx : "")} extra-attr >

                                    <div data_id="bnode_a5k1n" name="image" style={{ backgroundImage: `url("${M.answer_explanation_image?.url}")` }} className="w-32 h-32 flex flex-shrink-0 bg-no-repeat bg-gray-200 bg-center bg-cover rounded-md" id={"bnode_a5k1n" + (idx ? "__" + idx : "")} extra-attr >

                                    </div>


                                    <div data_id="bnode_1b307" className="flex flex-col items-center" id={"bnode_1b307" + (idx ? "__" + idx : "")} extra-attr >
                                        <input data_id="bnode_c03up" name="answer_explanation_image" type="file" placeholder="answer_explanation_image" action="oninput-update-m-file" className="block w-full text-sm text-[var(--neutral-500)] file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-[var(--primary-100)] file:text-[var(--neutral-50)] hover:file:bg-[var(--primary-100)] p-1 rounded-full bg-[var(--neutral-100)] " id={"bnode_c03up" + (idx ? "__" + idx : "")} model_id="fhmae" onInput={(e) => { g_fn.bro_on_input_file(e, INFO) }} extra-attr />

                                        <div data_id="bnode_zfqme" className="text-gray-400 w-fit px-2 text-center" id={"bnode_zfqme" + (idx ? "__" + idx : "")} extra-attr >
                                            or
                                        </div>


                                        <div data_id="bnode_w6ygo" className="relative w-full md:w-fit" id={"bnode_w6ygo" + (idx ? "__" + idx : "")} extra-attr >

                                            <div data_id="bnode_9rda6" className="absolute left-2 top-1/2 -translate-y-1/2" id={"bnode_9rda6" + (idx ? "__" + idx : "")} extra-attr >

                                                <svg data_id="bnode_ma4pd" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" id={"bnode_ma4pd" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                                    <path data_id="" d="M10.0002 13.7031C10.4297 14.2772 10.9776 14.7523 11.6067 15.096C12.2359 15.4398 12.9317 15.6442 13.6468 15.6954C14.362 15.7466 15.0798 15.6435 15.7515 15.3929C16.4233 15.1423 17.0333 14.7502 17.5402 14.2431L20.5402 11.2431C21.451 10.3001 21.955 9.03707 21.9436 7.72608C21.9322 6.4151 21.4063 5.16104 20.4793 4.234C19.5523 3.30696 18.2982 2.78111 16.9872 2.76972C15.6762 2.75833 14.4132 3.2623 13.4702 4.17309L11.7502 5.88309M14.0002 11.7031C13.5707 11.129 13.0228 10.6539 12.3936 10.3102C11.7645 9.96639 11.0687 9.76197 10.3535 9.71076C9.63841 9.65954 8.92061 9.76272 8.24885 10.0133C7.5771 10.2639 6.96709 10.656 6.4602 11.1631L3.4602 14.1631C2.54941 15.1061 2.04544 16.3691 2.05683 17.6801C2.06822 18.9911 2.59407 20.2451 3.52111 21.1722C4.44815 22.0992 5.70221 22.6251 7.01319 22.6365C8.32418 22.6479 9.58719 22.1439 10.5302 21.2331L12.2402 19.5231" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" extra-attr >

                                                    </path>

                                                </svg>

                                            </div>

                                            <input data_id="bnode_b7xdu" name="answer_explanation_image" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 pl-9 " b_type="image" type="text" placeholder="Paste image url" action="oninput-update-m" id={"bnode_b7xdu" + (idx ? "__" + idx : "")} model_id="fhmae" onInput={(e) => { g_fn.bro_on_input(e, INFO) }} extra-attr />
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>


                </div>



                <div
                    data_id="bnode_cahzg"
                    className="flex flex-col"
                    id={"bnode_cahzg" + (idx ? "__" + idx : "")}
                    extra-attr
                >
                    <button
                        data_id="bnode_ejjiy"
                        className="bg-[#FF5994] hover:bg- text-white p-4 px-8 focus:outline-none w-fit rounded-[8px] text-sm font-normal font-['Open_Sans']"
                        id={"bnode_ejjiy" + (idx ? "__" + idx : "")}
                        onClick={update_question_fn}
                        extra-attr
                    >
                        SAVE
                    </button>
                </div>

                <fn
                    data_id="bnode_ioyz8"
                    name="fn:update_question_fn"
                    id={"bnode_ioyz8" + (idx ? "__" + idx : "")}
                    extra-attr
                ></fn>
            </div>
        </ErrorBoundary>
    );
};

export default comp_bnode_986bz;
