// @broken-generated-code => this is important for us to know inside the webcontainer that the files have been written
import React, { useEffect, useState } from "react";
import './app.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page.jsx";
// @ts-ignore
import { COMP_REG } from "./comp_registry.js";
import g_fn from "./g_fn.js";
import Login from "./Login";
import { add_sub, remove_subs } from "./utils.jsx";
const AS = g_fn.AS;
// LOGS FOR BROKEN COMP - later move unto utility
const EN_BROKEN_COMP_LOGS = {
    MODEL_EFFECT: false,
    FILTERS_CHANGED: false,
    PAGE_CHANGED: true,
};
window.EN_BROKEN_COMP_LOGS = EN_BROKEN_COMP_LOGS;
// ONLY MEANINGFUL FOR DEV
const init_relay = () => {
    if (window.init_relay && typeof (window.init_relay) === "function")
        window.init_relay();
};
// hmr causes useEffect to be called many times during development
// so we will use a global variable to store the subscription
const SUB_ID = "app";
const router = createBrowserRouter([
    // for app.brokenatom.io
    {
        path: "/:app_id/:ui_id/login",
        element: <Login />,
    },
    {
        path: "/:app_id/:ui_id/*",
        element: <COMP_REG.main />,
        errorElement: <ErrorPage />,
    },
    // for custom domains
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/*",
        element: <COMP_REG.main />,
        errorElement: <ErrorPage />,
    },
]);
export default function App(props) {
    const [M, set_M] = useState({});
    const [user, set_user] = useState(AS.user);
    const [show_login, set_show_login] = useState(AS.rx_show_login.value);
    const [std_lib_loaded, set_std_lib_loaded] = useState(false);
    // ONLY MEANINGFUL FOR DEV
    useEffect(() => { init_relay(); }, []);
    useEffect(() => {
        remove_subs(SUB_ID);
        const on_key_up = (e) => {
            if (e.key === 'p' || e.key === 'P') {
                if (g_fn.is_event_in_editing_mode(e))
                    return;
                g_fn.runtime_select_next_page(e.key === 'P');
            }
        };
        // const on_pop_state = (e) => {
        //     g_fn.runtime_set_page_from_url();
        // };
        // window.addEventListener('popstate', on_pop_state);
        window.addEventListener('keyup', on_key_up);
        // keep a reference so we can clear it on hmr and unmount
        // add_sub(SUB_ID, { event: "popstate", fn: on_pop_state });
        add_sub(SUB_ID, { event: "keyup", fn: on_key_up });
        // APP SETUP
        g_fn.g_app_init({});
        add_sub(SUB_ID, AS.rx_boken_module.subscribe((B) => {
            if (B) {
                console.log("BROKEN MODULE LOADED");
                set_std_lib_loaded(true);
                // fix it so that we load App only once api is loaded
                g_fn.get_user_profile();
            }
        }));
        // USER
        add_sub(SUB_ID, AS.rx_user.subscribe(user => {
            set_user(user);
            if (!user)
                return;
            if (user.from_database)
                return;
            g_fn.get_user_profile();
        }));
        add_sub(SUB_ID, AS.rx_token.subscribe(token => {
            g_fn.get_user_profile();
        }));
        add_sub(SUB_ID, AS.rx_show_login.subscribe(show_login => {
            set_show_login(show_login);
        }));
        return () => remove_subs(SUB_ID);
    }, []);
    // Loading STD LIB
    if (!std_lib_loaded) {
        return (<div className="h-screen w-full h-max-[1000px] bg-[var(--primary-200)] text-[var(--secondary--600)] flex justify-center items-center">
                <div className="text-xl font-semibold animate-bounce">Loading Broken standard Library</div>
            </div>);
    }
    if (AS.enable_login && show_login && !user)
        return <Login />;
    return (<RouterProvider router={router}/>);
}
