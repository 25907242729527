import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_qp1d5";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_qp1d5 = (props)=>{
			const INFO  = {"model_id":"fhmae","model_name":"question","op":"update_one","comp_id":"comp_bnode_qp1d5"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					console.log("PROPS : ", props, INFO, relation);
				},[props]);
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = () => {
				if(!INFO.model_id) return;

				if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
				let id = g_fn.get_selected_entity_id(props, INFO);
				if(!id && !relation) {
					return console.warn("NO ID FOUND TO UPDATE ENTITY");
				}

				if(!id && relation) {

					const parent_M = (props.PM && Array.isArray(props.PM) && props.PM.length) ? props.PM[props.PM.length-1] : null;
					console.log("PM AND PARENT_M : ", props.PM, parent_M);
					if(parent_M) {
						const prop = parent_M[relation.prop_name];
						if(!prop) return console.warn("NO PROP FOUND FROM PARENT TO UPDATE ENTITY");
						if(typeof(prop) === "string") {
							id = prop;
						}
						else {
							id = prop.id;
						}
					}
				}

				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY : ", r);
					if(r) {
						const r_copy = JSON.parse(JSON.stringify(r));
						set_M(r_copy);
					}
				})();

				const subs = [];

				subs.push(AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
					const id = g_fn.get_selected_entity_id(props, INFO);
					if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
					(async () => {
						const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
						console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION -> UPDATE_ONE : ", INFO.model_id, r);
						if(r) {
							const r_copy = JSON.parse(JSON.stringify(r));
							set_M(r_copy);
						}
					})();
				}));

				return () => {
					subs.forEach(sub=>sub.unsubscribe());
				}
			}
		
const [first_ans_sta, set_first_ans_sta] = useState(false)
useEffect(() => {
  if(!M.correct_answers || !M.correct_answers.length ) {
    return;
  }
  const crt_ans =  M.correct_answers[0].v;
  console.log('correct answer is: ', crt_ans)
  if(V.v === crt_ans ) {
    set_first_ans_sta(true);
  }
}, [])
const [second_ans_sta, set_second_ans_sta] = useState(false)
useEffect(() => {
  if(!M.correct_answers || !M.correct_answers.length ) {
    return;
  }
  const crt_ans =  M.correct_answers[0].v;
  console.log('correct answer is: ', crt_ans)
  if(V.v === crt_ans ) {
    set_second_ans_sta(true);
  }
}, [])
const [tag_val, set_tag_val] = useState("");
const addtag = (e)=>{
  const inp_val = e.target?.value;
  console.log('inp val i s : ', inp_val);
  set_tag_val(inp_val);
}
const new_tag_add_fn = (e) => {
  g_fn.bro_add_prop_item(e, INFO);
  set_M(M=>{
    const L = M.tags.length;
    if(!L) return M;
    M.tags[L-1].v = e.target.value;
    return {...M, tags: [...M.tags]}
  })
}


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= {"model_id":"fhmae","prop_name":"questions","comp_id":"comp_bnode_wzto2"};
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_lqbui"] = COND_ATTRS["bnode_lqbui"] || {};
			

				COND_ATTRS["bnode_sioco"] = COND_ATTRS["bnode_sioco"] || {};
			

				COND_ATTRS["bnode_x7km0"] = COND_ATTRS["bnode_x7km0"] || {};
			

				COND_ATTRS["bnode_oaqlj"] = COND_ATTRS["bnode_oaqlj"] || {};
			

				COND_ATTRS["bnode_bfxgr"] = COND_ATTRS["bnode_bfxgr"] || {};
			

				COND_ATTRS["bnode_fo80e"] = COND_ATTRS["bnode_fo80e"] || {};
			

				COND_ATTRS["bnode_8p5hq"] = COND_ATTRS["bnode_8p5hq"] || {};
			

				COND_ATTRS["bnode_p3fva"] = COND_ATTRS["bnode_p3fva"] || {};
			

				COND_ATTRS["bnode_vxtr9"] = COND_ATTRS["bnode_vxtr9"] || {};
			

				COND_ATTRS["bnode_k7h81"] = COND_ATTRS["bnode_k7h81"] || {};
			

				COND_ATTRS["bnode_ggn9t"] = COND_ATTRS["bnode_ggn9t"] || {};
			

				COND_ATTRS["bnode_eariz"] = COND_ATTRS["bnode_eariz"] || {};
			

				COND_ATTRS["bnode_8b2s8"] = COND_ATTRS["bnode_8b2s8"] || {};
			

				COND_ATTRS["bnode_v7om9"] = COND_ATTRS["bnode_v7om9"] || {};
			

				COND_ATTRS["bnode_fwy9v"] = COND_ATTRS["bnode_fwy9v"] || {};
			

				COND_ATTRS["bnode_vrf7p"] = COND_ATTRS["bnode_vrf7p"] || {};
			

				COND_ATTRS["bnode_1k29t"] = COND_ATTRS["bnode_1k29t"] || {};
			

				COND_ATTRS["bnode_t1av6"] = COND_ATTRS["bnode_t1av6"] || {};
			

				COND_ATTRS["bnode_0lsm7"] = COND_ATTRS["bnode_0lsm7"] || {};
			

				COND_ATTRS["bnode_97as8"] = COND_ATTRS["bnode_97as8"] || {};
			

				COND_ATTRS["bnode_qxr8h"] = COND_ATTRS["bnode_qxr8h"] || {};
			

				COND_ATTRS["bnode_ned6u"] = COND_ATTRS["bnode_ned6u"] || {};
			

				COND_ATTRS["bnode_r1aj7"] = COND_ATTRS["bnode_r1aj7"] || {};
			

				COND_ATTRS["bnode_5dnde"] = COND_ATTRS["bnode_5dnde"] || {};
			

				COND_ATTRS["bnode_gb5ls"] = COND_ATTRS["bnode_gb5ls"] || {};
			

				COND_ATTRS["bnode_v65qz"] = COND_ATTRS["bnode_v65qz"] || {};
			

				COND_ATTRS["bnode_0uynz"] = COND_ATTRS["bnode_0uynz"] || {};
			

				COND_ATTRS["bnode_zbugx"] = COND_ATTRS["bnode_zbugx"] || {};
			

				COND_ATTRS["bnode_iwtor"] = COND_ATTRS["bnode_iwtor"] || {};
			

				COND_ATTRS["bnode_dhy0b"] = COND_ATTRS["bnode_dhy0b"] || {};
			

				COND_ATTRS["bnode_xpjkn"] = COND_ATTRS["bnode_xpjkn"] || {};
			

				COND_ATTRS["bnode_jshgy"] = COND_ATTRS["bnode_jshgy"] || {};
			

				COND_ATTRS["bnode_kq37n"] = COND_ATTRS["bnode_kq37n"] || {};
			

				COND_ATTRS["bnode_790pu"] = COND_ATTRS["bnode_790pu"] || {};
			

				COND_ATTRS["bnode_yif4d"] = COND_ATTRS["bnode_yif4d"] || {};
			

				COND_ATTRS["bnode_6ce8n"] = COND_ATTRS["bnode_6ce8n"] || {};
			

				COND_ATTRS["bnode_3rqsx"] = COND_ATTRS["bnode_3rqsx"] || {};
			

				COND_ATTRS["bnode_xwm6a"] = COND_ATTRS["bnode_xwm6a"] || {};
			

				COND_ATTRS["bnode_y2k1p"] = COND_ATTRS["bnode_y2k1p"] || {};
			

				COND_ATTRS["bnode_grszq"] = COND_ATTRS["bnode_grszq"] || {};
			

				COND_ATTRS["bnode_wg3xh"] = COND_ATTRS["bnode_wg3xh"] || {};
			

				COND_ATTRS["bnode_onivz"] = COND_ATTRS["bnode_onivz"] || {};
			

				COND_ATTRS["bnode_zg47p"] = COND_ATTRS["bnode_zg47p"] || {};
			

				COND_ATTRS["bnode_wx9z0"] = COND_ATTRS["bnode_wx9z0"] || {};
			

				COND_ATTRS["bnode_6gu96"] = COND_ATTRS["bnode_6gu96"] || {};
			

				COND_ATTRS["bnode_xbjh5"] = COND_ATTRS["bnode_xbjh5"] || {};
			

				COND_ATTRS["bnode_fj25r"] = COND_ATTRS["bnode_fj25r"] || {};
			

				COND_ATTRS["bnode_67mij"] = COND_ATTRS["bnode_67mij"] || {};
			

				COND_ATTRS["bnode_jkbvs"] = COND_ATTRS["bnode_jkbvs"] || {};
			

				COND_ATTRS["bnode_wbcyt"] = COND_ATTRS["bnode_wbcyt"] || {};
			

				COND_ATTRS["bnode_1p8ve"] = COND_ATTRS["bnode_1p8ve"] || {};
			

				COND_ATTRS["bnode_qgkio"] = COND_ATTRS["bnode_qgkio"] || {};
			

				COND_ATTRS["bnode_6grj4"] = COND_ATTRS["bnode_6grj4"] || {};
			

				COND_ATTRS["bnode_edpdy"] = COND_ATTRS["bnode_edpdy"] || {};
			

				COND_ATTRS["bnode_9g9lt"] = COND_ATTRS["bnode_9g9lt"] || {};
			

				COND_ATTRS["bnode_b6lxl"] = COND_ATTRS["bnode_b6lxl"] || {};
			

				COND_ATTRS["bnode_j6vyy"] = COND_ATTRS["bnode_j6vyy"] || {};
			

				COND_ATTRS["bnode_0knyn"] = COND_ATTRS["bnode_0knyn"] || {};
			

				COND_ATTRS["bnode_cavtj"] = COND_ATTRS["bnode_cavtj"] || {};
			

				COND_ATTRS["bnode_2zpxs"] = COND_ATTRS["bnode_2zpxs"] || {};
			

				COND_ATTRS["bnode_t32ee"] = COND_ATTRS["bnode_t32ee"] || {};
			

				COND_ATTRS["bnode_sgo9m"] = COND_ATTRS["bnode_sgo9m"] || {};
			

				COND_ATTRS["bnode_alt3j"] = COND_ATTRS["bnode_alt3j"] || {};
			

				COND_ATTRS["bnode_5cqqv"] = COND_ATTRS["bnode_5cqqv"] || {};
			

				COND_ATTRS["bnode_ctfww"] = COND_ATTRS["bnode_ctfww"] || {};
			

				COND_ATTRS["bnode_16qz9"] = COND_ATTRS["bnode_16qz9"] || {};
			

				COND_ATTRS["bnode_pphkr"] = COND_ATTRS["bnode_pphkr"] || {};
			

				COND_ATTRS["bnode_r2sw2"] = COND_ATTRS["bnode_r2sw2"] || {};
			

				COND_ATTRS["bnode_pmw0p"] = COND_ATTRS["bnode_pmw0p"] || {};
			

				COND_ATTRS["bnode_fb5rd"] = COND_ATTRS["bnode_fb5rd"] || {};
			

				COND_ATTRS["bnode_eiwrt"] = COND_ATTRS["bnode_eiwrt"] || {};
			

				COND_ATTRS["bnode_qeaaa"] = COND_ATTRS["bnode_qeaaa"] || {};
			

				COND_ATTRS["bnode_hldh4"] = COND_ATTRS["bnode_hldh4"] || {};
			

				COND_ATTRS["bnode_huoqv"] = COND_ATTRS["bnode_huoqv"] || {};
			

				COND_ATTRS["bnode_pxucm"] = COND_ATTRS["bnode_pxucm"] || {};
			

				COND_ATTRS["bnode_m2eto"] = COND_ATTRS["bnode_m2eto"] || {};
			

				COND_ATTRS["bnode_vpbov"] = COND_ATTRS["bnode_vpbov"] || {};
			

				COND_ATTRS["bnode_cqny4"] = COND_ATTRS["bnode_cqny4"] || {};
			

				COND_ATTRS["bnode_lk6xf"] = COND_ATTRS["bnode_lk6xf"] || {};
			

				COND_ATTRS["bnode_4000d"] = COND_ATTRS["bnode_4000d"] || {};
			

				COND_ATTRS["bnode_vb6ae"] = COND_ATTRS["bnode_vb6ae"] || {};
			

				COND_ATTRS["bnode_pihn5"] = COND_ATTRS["bnode_pihn5"] || {};
			

				COND_ATTRS["bnode_0vu98"] = COND_ATTRS["bnode_0vu98"] || {};
			

				COND_ATTRS["bnode_2c3y6"] = COND_ATTRS["bnode_2c3y6"] || {};
			

				COND_ATTRS["bnode_cshvs"] = COND_ATTRS["bnode_cshvs"] || {};
			

				COND_ATTRS["bnode_4jzky"] = COND_ATTRS["bnode_4jzky"] || {};
			

				COND_ATTRS["bnode_y3vl7"] = COND_ATTRS["bnode_y3vl7"] || {};
			

				COND_ATTRS["bnode_g7k1q"] = COND_ATTRS["bnode_g7k1q"] || {};
			

				COND_ATTRS["bnode_qp1d5"] = COND_ATTRS["bnode_qp1d5"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_qp1d5"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_qp1d5"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_qp1d5" name="question" op="update_one" className="w-full flex flex-col p-4 border rounded border-zinc-200 relative overflow-auto h-full" id={"bnode_qp1d5" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_g7k1q" name="question-data" className="flex flex-col gap-4" id={"bnode_g7k1q" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_k7h81" className="new-div w-full flex items-center justify-center" id={"bnode_k7h81" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_vxtr9" name="CONT-type" className="w-full" id={"bnode_vxtr9" + (idx?"__"+idx:"")} action="null" page_id="bnode_a8dfp" model_id="fhmae" extra-attr >
                
            <div data_id="bnode_p3fva" id={"bnode_p3fva" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_8p5hq" name="type" className="flex flex-col  w-full gap-6 " id={"bnode_8p5hq" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_fo80e" className="flex flex-row items-center justify-center w-fit gap-4" id={"bnode_fo80e" + (idx?"__"+idx:"")} extra-attr >
                
            <select data_id="bnode_bfxgr" name="type" placeholder="type" action="oninput-update-m" className="bg-white px-2 py-1 rounded-[8px] border border-[#8A8A8A] w-fit placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-semibold font-['Open_Sans'] text-sm" id={"bnode_bfxgr" + (idx?"__"+idx:"")} model_id="fhmae" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            <option data_id="bnode_lqbui" selected="true" id={"bnode_lqbui" + (idx?"__"+idx:"")} extra-attr >
                
            </option>
        

            <option data_id="bnode_sioco" value="MCQs" selected={M.type === 'MCQs'} id={"bnode_sioco" + (idx?"__"+idx:"")} extra-attr >
                MCQs
            </option>
        

            <option data_id="bnode_x7km0" value="FITB" selected={M.type === 'FITB'} id={"bnode_x7km0" + (idx?"__"+idx:"")} extra-attr >
                FITB
            </option>
        

            <option data_id="bnode_oaqlj" value="reorder" selected={M.type === 'reorder'} id={"bnode_oaqlj" + (idx?"__"+idx:"")} extra-attr >
                reorder
            </option>
        
            </select>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_vrf7p" name="CONT-question" className="w-full" id={"bnode_vrf7p" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_fwy9v" id={"bnode_fwy9v" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_ggn9t" className="block mb-2 uppercase font-['Open_Sans'] text-black text-base font-semibold" id={"bnode_ggn9t" + (idx?"__"+idx:"")} extra-attr >
                Question
            </div>
        

            <div data_id="bnode_v7om9" name="question" className="flex flex-col  w-full gap-6 " id={"bnode_v7om9" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_8b2s8" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_8b2s8" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_eariz" name="question" type="text" value={M.question} placeholder="question" action="oninput-update-m" className="border border-[#9CA3AF] bg-white p-5 w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-['Open_Sans'] text-sm font-normal rounded-[12px]" id={"bnode_eariz" + (idx?"__"+idx:"")} model_id="fhmae" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_ctfww" name="CONT-answers" className="w-full flex gap-2 flex-col" id={"bnode_ctfww" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_t32ee" className="w-full flex items-center justify-center" id={"bnode_t32ee" + (idx?"__"+idx:"")} extra-attr >
                
                    {M && g_fn.safe_array(M.answers).map(
                        (V, idx)=> (
                            
            <div data_id="bnode_2zpxs" name="answers" className="w-full flex flex-row gap-2" id={"bnode_2zpxs" + (idx?"__"+idx:"")} key={g_fn.get_key_from_meta(M, "answers", idx)} extra-attr >
                
            <div data_id="bnode_dhy0b" name="1" className="flex items-center justify-center w-full  flex-col" id={"bnode_dhy0b" + (idx?"__"+idx:"")} extra-attr >
                
            <button data_id="bnode_t1av6" name="answers" b_idx={idx} action="delete-prop-item" className="bg-[#F3F4F6] text-[#4B5563] p-2 rounded-[8px] hover:bg-[#4B5563] hover:text-white transition-all ease-in-out duration-150" id={"bnode_t1av6" + (idx?"__"+idx:"")} style={{padding:"8px", paddingBottom:"8px", paddingLeft:"8px", paddingRight:"8px", paddingTop:"8px"}} model_id="fhmae" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_delete_prop_item(e, INFO)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <svg data_id="bnode_1k29t" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="trash-2" className="w-[20px] h-[20px]" id={"bnode_1k29t" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M3 6h18" extra-attr >
                
            </path>
        

            <path data_id="" d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" extra-attr >
                
            </path>
        

            <path data_id="" d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" extra-attr >
                
            </path>
        

            <line data_id="" x1="10" x2="10" y1="11" y2="17" extra-attr >
                
            </line>
        

            <line data_id="" x1="14" x2="14" y1="11" y2="17" extra-attr >
                
            </line>
        
            </svg>
        
            </button>
        

            <div data_id="bnode_qxr8h" className="new-div w-full flex items-center justify-center" id={"bnode_qxr8h" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_97as8" className="new-div w-full flex items-center justify-center" id={"bnode_97as8" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_0lsm7" className="w-6 h-6 cursor-pointer " b_type="icon" id={"bnode_0lsm7" + (idx?"__"+idx:"")} extra-attr >
                
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_zbugx" className="new-div w-full flex items-center justify-between flex-row px-2" id={"bnode_zbugx" + (idx?"__"+idx:"")} extra-attr >
                { !first_ans_sta && (<div data_id="bnode_r1aj7" className="h-[20px] w-[20px] rounded-full bg-gray-700 p-0.5" b_type="icon" id={"bnode_r1aj7" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_ned6u" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="check" className="w-full h-full text-white" id={"bnode_ned6u" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M20 6 9 17l-5-5" extra-attr >
                
            </path>
        
            </svg>
        
            </div>) }
{ first_ans_sta && (<div data_id="bnode_gb5ls" className="h-[20px] w-[20px] rounded-full bg-teal-700 p-0.5" b_type="icon" id={"bnode_gb5ls" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_5dnde" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="check" className="w-full h-full text-white" b_type="icon" id={"bnode_5dnde" + (idx?"__"+idx:"")} extra-attr >
                
            <path data_id="" d="M20 6 9 17l-5-5" extra-attr >
                
            </path>
        
            </svg>
        
            </div>) }

            <div data_id="bnode_0uynz" className="w-6 h-6 cursor-pointer" id={"bnode_0uynz" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_v65qz" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="trash-2" className="w-6 h-6" id={"bnode_v65qz" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M3 6h18" extra-attr >
                
            </path>
        

            <path data_id="" d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" extra-attr >
                
            </path>
        

            <path data_id="" d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" extra-attr >
                
            </path>
        

            <line data_id="" x1="10" x2="10" y1="11" y2="17" extra-attr >
                
            </line>
        

            <line data_id="" x1="14" x2="14" y1="11" y2="17" extra-attr >
                
            </line>
        
            </svg>
        
            </div>
        
            </div>
        

            <fn data_id="bnode_iwtor" name="1USEFFECT" id={"bnode_iwtor" + (idx?"__"+idx:"")} className="flex flex-row" extra-attr >
                
            </fn>
        
            </div>
        
{ false && (<div data_id="bnode_grszq" name="2" className="flex items-center justify-center w-full gap-4 flex-col" id={"bnode_grszq" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_3rqsx" className="new-div w-full flex items-center justify-between flex-row px-2" id={"bnode_3rqsx" + (idx?"__"+idx:"")} extra-attr >
                { !second_ans_sta && (<div data_id="bnode_jshgy" className="h-[20px] w-[20px] rounded-full bg-gray-700 p-0.5" b_type="icon" id={"bnode_jshgy" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_xpjkn" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="check" className="w-full h-full text-white" id={"bnode_xpjkn" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M20 6 9 17l-5-5" extra-attr >
                
            </path>
        
            </svg>
        
            </div>) }
{ null && (<div data_id="bnode_790pu" className="h-[20px] w-[20px] rounded-full bg-teal-700 p-0.5" b_type="icon" id={"bnode_790pu" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_kq37n" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="check" className="w-full h-full text-white" b_type="icon" id={"bnode_kq37n" + (idx?"__"+idx:"")} extra-attr >
                
            <path data_id="" d="M20 6 9 17l-5-5" extra-attr >
                
            </path>
        
            </svg>
        
            </div>) }

            <div data_id="bnode_6ce8n" className="w-6 h-6 cursor-pointer" id={"bnode_6ce8n" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_yif4d" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="trash-2" className="w-6 h-6" id={"bnode_yif4d" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M3 6h18" extra-attr >
                
            </path>
        

            <path data_id="" d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" extra-attr >
                
            </path>
        

            <path data_id="" d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" extra-attr >
                
            </path>
        

            <line data_id="" x1="10" x2="10" y1="11" y2="17" extra-attr >
                
            </line>
        

            <line data_id="" x1="14" x2="14" y1="11" y2="17" extra-attr >
                
            </line>
        
            </svg>
        
            </div>
        
            </div>
        
<input data_id="bnode_xwm6a" name="answers" b_idx={idx} type="text" value={V.v} placeholder="answers" action="oninput-update-m-idx" className="border border-[#9CA3AF] bg-white p-4 min-h-24 w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] text-base text-left flex justify-start items-start rounded-[12px]" id={"bnode_xwm6a" + (idx?"__"+idx:"")} model_id="fhmae" onInput={(e)=>{g_fn.bro_on_input_idx(e, INFO)}} extra-attr />

            <fn data_id="bnode_y2k1p" name="2USEEFFECT" id={"bnode_y2k1p" + (idx?"__"+idx:"")} extra-attr >
                
            </fn>
        
            </div>) }
{ false && (<div data_id="bnode_jkbvs" name="3" className="flex items-center justify-center w-full gap-4 flex-col" id={"bnode_jkbvs" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_fj25r" className="new-div w-full flex items-center justify-between flex-row px-2" id={"bnode_fj25r" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_onivz" className="h-[20px] w-[20px] rounded-full bg-gray-700 p-0.5" b_type="icon" id={"bnode_onivz" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_wg3xh" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="check" className="w-full h-full text-white" id={"bnode_wg3xh" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M20 6 9 17l-5-5" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        

            <div data_id="bnode_wx9z0" className="h-[20px] w-[20px] rounded-full bg-teal-700 p-0.5" b_type="icon" id={"bnode_wx9z0" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_zg47p" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="check" className="w-full h-full text-white" b_type="icon" id={"bnode_zg47p" + (idx?"__"+idx:"")} extra-attr >
                
            <path data_id="" d="M20 6 9 17l-5-5" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        

            <div data_id="bnode_xbjh5" className="w-6 h-6 cursor-pointer" id={"bnode_xbjh5" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_6gu96" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="trash-2" className="w-6 h-6" id={"bnode_6gu96" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M3 6h18" extra-attr >
                
            </path>
        

            <path data_id="" d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" extra-attr >
                
            </path>
        

            <path data_id="" d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" extra-attr >
                
            </path>
        

            <line data_id="" x1="10" x2="10" y1="11" y2="17" extra-attr >
                
            </line>
        

            <line data_id="" x1="14" x2="14" y1="11" y2="17" extra-attr >
                
            </line>
        
            </svg>
        
            </div>
        
            </div>
        
<input data_id="bnode_67mij" name="answers" b_idx={idx} type="text" value={V.v} placeholder="answers" action="oninput-update-m-idx" className="border border-[#9CA3AF] bg-white p-4 min-h-24 w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] text-base text-left flex justify-start items-start rounded-[12px]" id={"bnode_67mij" + (idx?"__"+idx:"")} model_id="fhmae" onInput={(e)=>{g_fn.bro_on_input_idx(e, INFO)}} extra-attr />
            </div>) }
{ false && (<div data_id="bnode_0knyn" name="4" className="flex items-center justify-center w-full gap-4 flex-col" id={"bnode_0knyn" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_b6lxl" className="new-div w-full flex items-center justify-between flex-row px-2" id={"bnode_b6lxl" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_1p8ve" className="h-[20px] w-[20px] rounded-full bg-gray-700 p-0.5" b_type="icon" id={"bnode_1p8ve" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_wbcyt" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="check" className="w-full h-full text-white" id={"bnode_wbcyt" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M20 6 9 17l-5-5" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        

            <div data_id="bnode_6grj4" className="h-[20px] w-[20px] rounded-full bg-teal-700 p-0.5" b_type="icon" id={"bnode_6grj4" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_qgkio" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="check" className="w-full h-full text-white" b_type="icon" id={"bnode_qgkio" + (idx?"__"+idx:"")} extra-attr >
                
            <path data_id="" d="M20 6 9 17l-5-5" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        

            <div data_id="bnode_9g9lt" className="w-6 h-6 cursor-pointer" id={"bnode_9g9lt" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_edpdy" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="trash-2" className="w-6 h-6" id={"bnode_edpdy" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M3 6h18" extra-attr >
                
            </path>
        

            <path data_id="" d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" extra-attr >
                
            </path>
        

            <path data_id="" d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" extra-attr >
                
            </path>
        

            <line data_id="" x1="10" x2="10" y1="11" y2="17" extra-attr >
                
            </line>
        

            <line data_id="" x1="14" x2="14" y1="11" y2="17" extra-attr >
                
            </line>
        
            </svg>
        
            </div>
        
            </div>
        
<input data_id="bnode_j6vyy" name="answers" b_idx={idx} type="text" value={V.v} placeholder="answers" action="oninput-update-m-idx" className="border border-[#9CA3AF] bg-white p-4 min-h-24 w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] text-base text-left flex justify-start items-start rounded-[12px]" id={"bnode_j6vyy" + (idx?"__"+idx:"")} model_id="fhmae" onInput={(e)=>{g_fn.bro_on_input_idx(e, INFO)}} extra-attr />
            </div>) }

            <div data_id="bnode_cavtj" className="new-div w-full flex items-center justify-center" id={"bnode_cavtj" + (idx?"__"+idx:"")} extra-attr >
                
            </div>
        
            </div>
        
                        )
                    )}
                
            </div>
        

            <button data_id="bnode_5cqqv" type="text" action="add-prop-item" name="add-more-ans" className="w-fit flex justify-center items-center bg-[#F3F4F6] p-3 text-[#6B7280] text-3xl rounded-[12px]" id={"bnode_5cqqv" + (idx?"__"+idx:"")} model_id="fhmae" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_add_prop_item(e, INFO)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <svg data_id="bnode_sgo9m" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id={"bnode_sgo9m" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M5 12h14" extra-attr >
                
            </path>
        

            <path data_id="" d="M12 5v14" extra-attr >
                
            </path>
        
            </svg>
        

            <div data_id="bnode_alt3j" className="new-div w-full flex items-center justify-center font-normal font-['Open_Sans'] whitespace-nowrap text-sm" id={"bnode_alt3j" + (idx?"__"+idx:"")} style={{padding:"0px", paddingBottom:"0px", paddingLeft:"0px", paddingRight:"0px", paddingTop:"0px"}} extra-attr >
                Add More Options
            </div>
        
            </button>
        
            </div>
        
{ false && (<div data_id="bnode_eiwrt" name="CONT-time" className="w-full" id={"bnode_eiwrt" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_fb5rd" id={"bnode_fb5rd" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_16qz9" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_16qz9" + (idx?"__"+idx:"")} extra-attr >
                time
            </div>
        

            <div data_id="bnode_pmw0p" name="time" className="flex flex-col  w-full gap-6 " id={"bnode_pmw0p" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_r2sw2" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_r2sw2" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_pphkr" name="time" type="number" value={M.time} placeholder="time" action="oninput-update-m" className="border border-[#222222]/5 hover:border-[#222222]/30 bg-[#222222]/5 px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-lg placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_pphkr" + (idx?"__"+idx:"")} model_id="fhmae" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>) }

            <div data_id="bnode_pihn5" name="CONT-tags" className="w-full flex-col gap-2 flex justify-center items-end" id={"bnode_pihn5" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_vpbov" className="w-fit gap-2 flex justify-end items-center" id={"bnode_vpbov" + (idx?"__"+idx:"")} name="created-tags" extra-attr >
                
                    {M && g_fn.safe_array(M.tags).map(
                        (V, idx)=> (
                            
            <div data_id="bnode_m2eto" name="tags" className="flex flex-col w-fit" id={"bnode_m2eto" + (idx?"__"+idx:"")} key={g_fn.get_key_from_meta(M, "tags", idx)} extra-attr >
                
            <div data_id="bnode_pxucm" name="cont" className="flex flex-row items-center justify-center w-fit gap-2.5 bg-[#3D3D3D] p-2 rounded-[8px]" id={"bnode_pxucm" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_qeaaa" name="tags" b_idx={idx} className="text-[#D1D5DB] placeholder:text-[#222222]/30 font-['Open_Sans'] bg-[#3D3D3D] w-fit capitalize text-xs font-normal" id={"bnode_qeaaa" + (idx?"__"+idx:"")} textContentVb="{V.v}" extra-attr >
                {V.v}
            </div>
        

            <button data_id="bnode_huoqv" name="tags" b_idx={idx} action="delete-prop-item" className="bg-none text-[#9CA3AF] hover:text-bg-rose-400 transition-all ease-in-out duration-150" id={"bnode_huoqv" + (idx?"__"+idx:"")} model_id="fhmae" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_delete_prop_item(e, INFO)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <svg data_id="bnode_hldh4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id={"bnode_hldh4" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M18 6 6 18" extra-attr >
                
            </path>
        

            <path data_id="" d="m6 6 12 12" extra-attr >
                
            </path>
        
            </svg>
        
            </button>
        
            </div>
        
            </div>
        
                        )
                    )}
                
            </div>
        

            <div data_id="bnode_vb6ae" className="w-fit flex items-center justify-center gap-2" id={"bnode_vb6ae" + (idx?"__"+idx:"")} name="adding tags" extra-attr >
                
            <div data_id="bnode_lk6xf" className="new-div w-fit flex items-center justify-center" id={"bnode_lk6xf" + (idx?"__"+idx:"")} name="add-tag" extra-attr >
                
            <fn data_id="bnode_cqny4" id={"bnode_cqny4" + (idx?"__"+idx:"")} name="fn:addtag" extra-attr >
                
            </fn>
        
            </div>
        

            <fn data_id="bnode_4000d" name="fn:adding_tag_fn" id={"bnode_4000d" + (idx?"__"+idx:"")} extra-attr >
                
            </fn>
        
            </div>
        
            </div>
        

            <div data_id="bnode_4jzky" name="CONT-answer_explanation" className="w-full" id={"bnode_4jzky" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_cshvs" id={"bnode_cshvs" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_2c3y6" name="answer_explanation" className="flex flex-col  w-full gap-6 " id={"bnode_2c3y6" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_0vu98" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_0vu98" + (idx?"__"+idx:"")} extra-attr >
                
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_y3vl7" name="CONT-answer_explanation_image" className="w-full" id={"bnode_y3vl7" + (idx?"__"+idx:"")} extra-attr >
                
            </div>
        
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_qp1d5;