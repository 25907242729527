import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_5nuxp";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_5nuxp = (props)=>{
			const INFO  = {"model_id":"yvjbe","model_name":"test","op":"get_selected_one","comp_id":"comp_bnode_5nuxp"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = () => {
				if(!INFO.model_id) return;

				if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) {
					console.warn("NO ID FOUND TO GET SELECTED ENTITY");
				}

				if(id) {
					(async () => {
						const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
						console.log("GSTORE GET ONE ENTITY : ", r);
						if(r) set_M(r);
					})();
				}

				const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
					if(!e.eids) return;
					if(e.eids.length !== 1) return;

					const id = e.eids[0];
					if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
					(async () => {
						const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
						console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r);
						if(r) set_M(r);
					})();
				});

				return () => {
					sub.unsubscribe();
				}
			}
		


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_tl0d7"] = COND_ATTRS["bnode_tl0d7"] || {};
			

				COND_ATTRS["bnode_7lve8"] = COND_ATTRS["bnode_7lve8"] || {};
			

				COND_ATTRS["bnode_dz98y"] = COND_ATTRS["bnode_dz98y"] || {};
			

				COND_ATTRS["bnode_mj7zb"] = COND_ATTRS["bnode_mj7zb"] || {};
			

				COND_ATTRS["bnode_3q0m6"] = COND_ATTRS["bnode_3q0m6"] || {};
			

				COND_ATTRS["bnode_d63rh"] = COND_ATTRS["bnode_d63rh"] || {};
			

				COND_ATTRS["bnode_b9m6j"] = COND_ATTRS["bnode_b9m6j"] || {};
			

				COND_ATTRS["bnode_i91ae"] = COND_ATTRS["bnode_i91ae"] || {};
			

				COND_ATTRS["bnode_w7lnw"] = COND_ATTRS["bnode_w7lnw"] || {};
			

				COND_ATTRS["bnode_h6te3"] = COND_ATTRS["bnode_h6te3"] || {};
			

				COND_ATTRS["bnode_gsu67"] = COND_ATTRS["bnode_gsu67"] || {};
			

				COND_ATTRS["bnode_qt514"] = COND_ATTRS["bnode_qt514"] || {};
			

				COND_ATTRS["bnode_rrdh4"] = COND_ATTRS["bnode_rrdh4"] || {};
			

				COND_ATTRS["bnode_dhvad"] = COND_ATTRS["bnode_dhvad"] || {};
			

				COND_ATTRS["bnode_303pa"] = COND_ATTRS["bnode_303pa"] || {};
			

				COND_ATTRS["bnode_0gb76"] = COND_ATTRS["bnode_0gb76"] || {};
			

				COND_ATTRS["bnode_e8zoz"] = COND_ATTRS["bnode_e8zoz"] || {};
			

				COND_ATTRS["bnode_5gdet"] = COND_ATTRS["bnode_5gdet"] || {};
			

				COND_ATTRS["bnode_oguzt"] = COND_ATTRS["bnode_oguzt"] || {};
			

				COND_ATTRS["bnode_ppxx8"] = COND_ATTRS["bnode_ppxx8"] || {};
			

				COND_ATTRS["bnode_mew3m"] = COND_ATTRS["bnode_mew3m"] || {};
			

				COND_ATTRS["bnode_wfsk6"] = COND_ATTRS["bnode_wfsk6"] || {};
			

				COND_ATTRS["bnode_s4lzs"] = COND_ATTRS["bnode_s4lzs"] || {};
			

				COND_ATTRS["bnode_g6ncn"] = COND_ATTRS["bnode_g6ncn"] || {};
			

				COND_ATTRS["bnode_ib6v5"] = COND_ATTRS["bnode_ib6v5"] || {};
			

				COND_ATTRS["bnode_bywwb"] = COND_ATTRS["bnode_bywwb"] || {};
			

				COND_ATTRS["bnode_8d7b6"] = COND_ATTRS["bnode_8d7b6"] || {};
			

				COND_ATTRS["bnode_u6r6t"] = COND_ATTRS["bnode_u6r6t"] || {};
			

				COND_ATTRS["bnode_mawgl"] = COND_ATTRS["bnode_mawgl"] || {};
			

				COND_ATTRS["bnode_bg887"] = COND_ATTRS["bnode_bg887"] || {};
			

				COND_ATTRS["bnode_ubtsf"] = COND_ATTRS["bnode_ubtsf"] || {};
			

				COND_ATTRS["bnode_7r551"] = COND_ATTRS["bnode_7r551"] || {};
			

				COND_ATTRS["bnode_3ocil"] = COND_ATTRS["bnode_3ocil"] || {};
			

				COND_ATTRS["bnode_hdt2d"] = COND_ATTRS["bnode_hdt2d"] || {};
			

				COND_ATTRS["bnode_k8mjp"] = COND_ATTRS["bnode_k8mjp"] || {};
			

				COND_ATTRS["bnode_21evy"] = COND_ATTRS["bnode_21evy"] || {};
			

				COND_ATTRS["bnode_dv4nz"] = COND_ATTRS["bnode_dv4nz"] || {};
			

				COND_ATTRS["bnode_i15kh"] = COND_ATTRS["bnode_i15kh"] || {};
			

				COND_ATTRS["bnode_apfzb"] = COND_ATTRS["bnode_apfzb"] || {};
			

				COND_ATTRS["bnode_vxzx2"] = COND_ATTRS["bnode_vxzx2"] || {};
			

				COND_ATTRS["bnode_07bvp"] = COND_ATTRS["bnode_07bvp"] || {};
			

				COND_ATTRS["bnode_na2jn"] = COND_ATTRS["bnode_na2jn"] || {};
			

				COND_ATTRS["bnode_nuuvt"] = COND_ATTRS["bnode_nuuvt"] || {};
			

				COND_ATTRS["bnode_fluec"] = COND_ATTRS["bnode_fluec"] || {};
			

				COND_ATTRS["bnode_4lu0k"] = COND_ATTRS["bnode_4lu0k"] || {};
			

				COND_ATTRS["bnode_eqq8x"] = COND_ATTRS["bnode_eqq8x"] || {};
			

				COND_ATTRS["bnode_dpx9x"] = COND_ATTRS["bnode_dpx9x"] || {};
			

				COND_ATTRS["bnode_c8j2y"] = COND_ATTRS["bnode_c8j2y"] || {};
			

				COND_ATTRS["bnode_yc7h3"] = COND_ATTRS["bnode_yc7h3"] || {};
			

				COND_ATTRS["bnode_ha8w1"] = COND_ATTRS["bnode_ha8w1"] || {};
			

				COND_ATTRS["bnode_sjjm3"] = COND_ATTRS["bnode_sjjm3"] || {};
			

				COND_ATTRS["bnode_sucyk"] = COND_ATTRS["bnode_sucyk"] || {};
			

				COND_ATTRS["bnode_hoz9i"] = COND_ATTRS["bnode_hoz9i"] || {};
			

				COND_ATTRS["bnode_jumep"] = COND_ATTRS["bnode_jumep"] || {};
			

				COND_ATTRS["bnode_90ey9"] = COND_ATTRS["bnode_90ey9"] || {};
			

				COND_ATTRS["bnode_sjunb"] = COND_ATTRS["bnode_sjunb"] || {};
			

				COND_ATTRS["bnode_vqv0v"] = COND_ATTRS["bnode_vqv0v"] || {};
			

				COND_ATTRS["bnode_jibg3"] = COND_ATTRS["bnode_jibg3"] || {};
			

				COND_ATTRS["bnode_q9ftg"] = COND_ATTRS["bnode_q9ftg"] || {};
			

				COND_ATTRS["bnode_k1236"] = COND_ATTRS["bnode_k1236"] || {};
			

				COND_ATTRS["bnode_5ftpx"] = COND_ATTRS["bnode_5ftpx"] || {};
			

				COND_ATTRS["bnode_mf85a"] = COND_ATTRS["bnode_mf85a"] || {};
			

				COND_ATTRS["bnode_o329r"] = COND_ATTRS["bnode_o329r"] || {};
			

				COND_ATTRS["bnode_6mqde"] = COND_ATTRS["bnode_6mqde"] || {};
			

				COND_ATTRS["bnode_gvjyc"] = COND_ATTRS["bnode_gvjyc"] || {};
			

				COND_ATTRS["bnode_upqbq"] = COND_ATTRS["bnode_upqbq"] || {};
			

				COND_ATTRS["bnode_hvjdg"] = COND_ATTRS["bnode_hvjdg"] || {};
			

				COND_ATTRS["bnode_hf1jg"] = COND_ATTRS["bnode_hf1jg"] || {};
			

				COND_ATTRS["bnode_bdakk"] = COND_ATTRS["bnode_bdakk"] || {};
			

				COND_ATTRS["bnode_5nuxp"] = COND_ATTRS["bnode_5nuxp"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_5nuxp"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_5nuxp"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_5nuxp" name="test" op="get_selected_one" className="w-full flex flex-col p-4 border rounded border-zinc-200 relative" id={"bnode_5nuxp" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_bdakk" name="test-data" action="select-one" className="flex flex-col" id={"bnode_bdakk" + (idx?"__"+idx:"")} model_id="yvjbe" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_select_one(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <div data_id="bnode_rrdh4" className="new-div w-full flex items-center justify-center flex-col gap-2 pb-4" id={"bnode_rrdh4" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_mj7zb" name="CONT-school_logo" className="w-full  " id={"bnode_mj7zb" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_dz98y" id={"bnode_dz98y" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_7lve8" name="school_logo" className="flex flex-col items-center justify-center w-full gap-6 " id={"bnode_7lve8" + (idx?"__"+idx:"")} extra-attr >
                
            <div  data_id="bnode_tl0d7" name="image" style={{backgroundImage:`url("${M.school_logo?.url}")`}} className="w-32 h-32 bg-gray-200 bg-center bg-cover rounded-md" id={"bnode_tl0d7" + (idx?"__"+idx:"")} extra-attr >
                
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_i91ae" name="CONT-header" className="w-full  " id={"bnode_i91ae" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_b9m6j" id={"bnode_b9m6j" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_d63rh" name="header" className="flex flex-col w-full gap-6 " id={"bnode_d63rh" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_3q0m6" id={"bnode_3q0m6" + (idx?"__"+idx:"")} className="text-2xl font-['Open_Sans'] flex justify-center items-center font-bold" textContentVb="{M.header}" extra-attr >
                {M.header}
            </span>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_qt514" name="CONT-name" className="w-full  " id={"bnode_qt514" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_gsu67" id={"bnode_gsu67" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_h6te3" name="name" className="flex flex-col w-full gap-6 " id={"bnode_h6te3" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_w7lnw" id={"bnode_w7lnw" + (idx?"__"+idx:"")} className="flex justify-center items-center text-2xl font-bold font-['Open_Sans']" textContentVb="{M.name || ''}" extra-attr >
                {M.name || ''}
            </span>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_7r551" className="new-div w-full flex items-center justify-center" id={"bnode_7r551" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_wfsk6" className="new-div w-full flex items-center justify-center flex-col" id={"bnode_wfsk6" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_e8zoz" name="CONT-subject" className="w-full  " id={"bnode_e8zoz" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_0gb76" id={"bnode_0gb76" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_303pa" name="subject" className="flex flex-col  w-full gap-6 " id={"bnode_303pa" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_dhvad" id={"bnode_dhvad" + (idx?"__"+idx:"")} className=" text-base font-['Open_Sans'] font-semibold" textContentVb="{M.subject}" extra-attr >
                {M.subject}
            </span>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_mew3m" name="CONT-curriculum" className="w-full  " id={"bnode_mew3m" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_ppxx8" id={"bnode_ppxx8" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_oguzt" name="curriculum" className="flex flex-col  w-full gap-6 " id={"bnode_oguzt" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_5gdet" id={"bnode_5gdet" + (idx?"__"+idx:"")} className=" text-base font-['Open_Sans'] font-semibold" textContentVb="{M.curriculum}" extra-attr >
                {M.curriculum}
            </span>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_ubtsf" className="new-div w-full flex items-center justify-center flex-col" id={"bnode_ubtsf" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_bywwb" name="CONT-time" className="w-full  " id={"bnode_bywwb" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_ib6v5" id={"bnode_ib6v5" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_g6ncn" name="time" className="flex flex-col w-full gap-6 " id={"bnode_g6ncn" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_s4lzs" id={"bnode_s4lzs" + (idx?"__"+idx:"")} className="font-semibold text-base font-['Open_Sans'] flex justify-end items-center" textContentVb="{M.time || ''} Minutes" extra-attr >
                {M.time || ''} Minutes
            </span>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_bg887" name="CONT-grade" className="w-full  " id={"bnode_bg887" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_mawgl" id={"bnode_mawgl" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_u6r6t" name="grade" className="flex flex-col  w-full gap-6 " id={"bnode_u6r6t" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_8d7b6" id={"bnode_8d7b6" + (idx?"__"+idx:"")} className="text-base font-semibold font-['Open_Sans'] flex justify-end items-center" textContentVb="{M.grade}th Grade" extra-attr >
                {M.grade}th Grade
            </span>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_dv4nz" name="CONT-test_instructions" className="w-full  " id={"bnode_dv4nz" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_21evy" id={"bnode_21evy" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_3ocil" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_3ocil" + (idx?"__"+idx:"")} extra-attr >
                Test Instructions
            </div>
        

            <div data_id="bnode_k8mjp" name="test_instructions" className="flex flex-col w-full gap-6 " id={"bnode_k8mjp" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_hdt2d" id={"bnode_hdt2d" + (idx?"__"+idx:"")} className=" text-base font-['Open_Sans'] font-semibold" textContentVb="{M.test_instructions}" extra-attr >
                {M.test_instructions}
            </span>
        
            </div>
        
            </div>
        
            </div>
        
{ false && (<div data_id="bnode_na2jn" name="CONT-guidelines" className="w-full  " id={"bnode_na2jn" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_07bvp" id={"bnode_07bvp" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_i15kh" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_i15kh" + (idx?"__"+idx:"")} extra-attr >
                guidelines
            </div>
        

            <div data_id="bnode_vxzx2" name="guidelines" className="flex flex-col w-full gap-6 " id={"bnode_vxzx2" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_apfzb" id={"bnode_apfzb" + (idx?"__"+idx:"")} textContentVb="{M.guidelines || ''}" extra-attr >
                {M.guidelines || ''}
            </span>
        
            </div>
        
            </div>
        
            </div>) }

            <div data_id="bnode_fluec" name="CONT-questions" className="w-full flex flex-col gap-2" id={"bnode_fluec" + (idx?"__"+idx:"")} extra-attr >
                
                    {M && g_fn.safe_array(M.questions).map(
                        (V, idx)=> (
                            
            <div data_id="bnode_nuuvt" name="questions" id={"bnode_nuuvt" + (idx?"__"+idx:"")} key={g_fn.get_key_from_meta(M, "questions", idx)} extra-attr >
                < COMP_REG.comp_bnode_m6ry2 {...{M: V, PM:[...PM, M], on_created: props.on_created || INFO.on_created, on_selected: props.on_selected || INFO.on_selected, INFO:INFO, id : M?.id, prop_name:"questions", IDX:idx, }} extra-attr />
            </div>
        
                        )
                    )}
                
            </div>
        
{ false && (<div data_id="bnode_c8j2y" name="CONT-tag" className="w-full flex flex-col gap-2" id={"bnode_c8j2y" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_4lu0k" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_4lu0k" + (idx?"__"+idx:"")} extra-attr >
                tag
            </div>
        

                    {M && g_fn.safe_array(M.tag).map(
                        (V, idx)=> (
                            
            <div data_id="bnode_dpx9x" name="tag" className="flex flex-col w-full gap-6 " id={"bnode_dpx9x" + (idx?"__"+idx:"")} key={g_fn.get_key_from_meta(M, "tag", idx)} extra-attr >
                
            <span data_id="bnode_eqq8x" id={"bnode_eqq8x" + (idx?"__"+idx:"")} textContentVb="{V?.v || ''}" extra-attr >
                {V?.v || ''}
            </span>
        
            </div>
        
                        )
                    )}
                
            </div>) }
{ false && (<div data_id="bnode_hoz9i" name="CONT-ai_input_prompt" className="w-full  " id={"bnode_hoz9i" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_sucyk" id={"bnode_sucyk" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_yc7h3" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_yc7h3" + (idx?"__"+idx:"")} extra-attr >
                ai_input_prompt
            </div>
        

            <div data_id="bnode_sjjm3" name="ai_input_prompt" className="flex flex-col w-full gap-6 " id={"bnode_sjjm3" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_ha8w1" id={"bnode_ha8w1" + (idx?"__"+idx:"")} textContentVb="{M.ai_input_prompt}" extra-attr >
                {M.ai_input_prompt}
            </span>
        
            </div>
        
            </div>
        
            </div>) }
{ false && (<div data_id="bnode_jibg3" name="CONT-difficulty" className="w-full  " id={"bnode_jibg3" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_vqv0v" id={"bnode_vqv0v" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_jumep" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_jumep" + (idx?"__"+idx:"")} extra-attr >
                difficulty
            </div>
        

            <div data_id="bnode_sjunb" name="difficulty" className="flex flex-col  w-full gap-6 " id={"bnode_sjunb" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_90ey9" id={"bnode_90ey9" + (idx?"__"+idx:"")} textContentVb="{M.difficulty}" extra-attr >
                {M.difficulty}
            </span>
        
            </div>
        
            </div>
        
            </div>) }
{ false && (<div data_id="bnode_o329r" name="CONT-status" className="w-full  " id={"bnode_o329r" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_mf85a" id={"bnode_mf85a" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_q9ftg" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_q9ftg" + (idx?"__"+idx:"")} extra-attr >
                status
            </div>
        

            <div data_id="bnode_5ftpx" name="status" className="flex flex-col  w-full gap-6 " id={"bnode_5ftpx" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_k1236" id={"bnode_k1236" + (idx?"__"+idx:"")} textContentVb="{M.status}" extra-attr >
                {M.status}
            </span>
        
            </div>
        
            </div>
        
            </div>) }
{ false && (<div data_id="bnode_hf1jg" name="CONT-last_modified" className="w-full  " id={"bnode_hf1jg" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_hvjdg" id={"bnode_hvjdg" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_6mqde" className="block text-gray-700 text-sm font-medium capitalize" id={"bnode_6mqde" + (idx?"__"+idx:"")} extra-attr >
                last_modified
            </div>
        

            <div data_id="bnode_upqbq" name="last_modified" className="flex flex-col w-full gap-6 " id={"bnode_upqbq" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_gvjyc" id={"bnode_gvjyc" + (idx?"__"+idx:"")} textContentVb="{new Date(M.last_modified).toLocaleString()}" extra-attr >
                {new Date(M.last_modified).toLocaleString()}
            </span>
        
            </div>
        
            </div>
        
            </div>) }
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_5nuxp;