import React from 'react';
import { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
	Routes,
	Route,
	Link,
	useNavigate,
} from "react-router-dom";

import {
	motion,
	AnimatePresence
} from "framer-motion";

import {
	fallbackRender,
	add_sub,
	remove_subs
} from "../utils.jsx";
import { COMP_REG } from "../comp_registry.js";
import g_fn from '../g_fn';






const AS = g_fn.AS;


// @req: keep reference to subs here and remove them on unmount or reload
const SUB_ID = "bnode_muwns";
const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


// local code - e.g sub components



const comp_bnode_muwns = (props) => {
	const INFO = { "model_id": "yvjbe", "model_name": "test", "op": "get_many", "comp_id": "comp_bnode_muwns" };

	// Routes




	// FOR IS_MANY
	let idx = props.idx;   // also for protection from error when we might think is many but it's not
	let V = props.V || {}; // for protection from error


	// STATES
	const [rerender, set_rerender] = useState({});
	const [M, set_M] = useState(g_fn.array_or_null(props.M) || [])
	const [PM, set_PM] = useState(props.PM || [])
	const [errors, set_errors] = useState([])
	const [warnings, set_warnings] = useState([])
	const [filter_object, set_filter_object] = useState({})
	const [selected_M, set_selected_M] = useState(null)

	// REFS


	// EFFECTS

	useEffect(() => {
		console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
	}, [rerender])


	useEffect(() => {
		const deinit = init();

		return () => {
			deinit();
		}
	}, [])

	useEffect(() => {
		if (EN_BROKEN_COMP_LOGS.MODEL_EFFECT) {
			const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
			console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
		}
	}, [M])

	useEffect(() => {

		if (!INFO.model_id) return;

		if (query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
		const id = g_fn.get_selected_entity_id(props, INFO);
		if (!id) {
			return console.warn("NO ID FOUND TO UPDATE ENTITY");
		}

		(async () => {
			const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
			console.log("GSTORE GET ONE ENTITY : ", r);
			if (r) set_selected_M(r);
		})();

		const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
			const id = g_fn.get_selected_entity_id(props, INFO);
			if (!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
				if (r) set_selected_M(r);
			})();
		});

		// On set in GSTORE set state
		const sub2 = AS.GSTORE.subscribe((e)=>{
			const {type, model_id, eid, data} = e;
			 if(!data || !data.id) return;
			 if(type !== 'set') return;
			 if(model_id !== INFO.model_id) return;
			 set_M(M=>{
			   if(eid !== M.id) return M;
			   return data;
			 })
		 });

		return () => {
			sub.unsubscribe();
			sub2.unsubscribe();
		}
	}, [])


	// FUNCTIONS

	const init = () => {

		if (!INFO.model_id || !INFO.comp_id) return;

		const subs = [];

		const model_id = INFO.model_id;
		const comp_id = INFO.comp_id;


		// init FO in GSTORE
		const FO = {
			static_filters: { bnode_jzzuw: [], bnode_8674b: [{ id: 'yvjbe.rel.created_by', name: 'test.created_by', filter: [{ id: 'user', attr: 'created_by.id', op: 'eq', val: '{user.id}' }], enabled: true, static: true }] },
			text_filters: "",
			filters: { bnode_jzzuw: [{ id: 'test.status => clear all', name: 'test.status => clear all', filter: [], static: false }, { id: 'test.status => Published', name: 'test.status => Published', filter: [{ id: 'Published', attr: 'status', op: 'eq', val: 'Published' }], static: false }, { id: 'test.status => Draft', name: 'test.status => Draft', filter: [{ id: 'Draft', attr: 'status', op: 'eq', val: 'Draft' }], static: false }], bnode_8674b: [] },
			sorts: [],
			limit: 16
		};
		let fstore = AS.GSTORE.filters[model_id];
		if (!fstore) {
			fstore = {};
			AS.GSTORE.filters[model_id] = fstore;
		}
		fstore[comp_id] = FO;
		set_filter_object(FO);



		subs.push(AS.GSTORE.subscribe_many(model_id, comp_id, (e) => {
			console.warn("GET MANY SUBSCRIBE");
			const data = e.data;
			set_M(data);
		}));


		// on delete or update
		subs.push(AS.GSTORE.subscribe((e) => {
			if (e.model_id !== model_id) return;

			// if delete
			if (!["delete"].includes(e.type)) return; // including set here causes infinite loop
			if (!["set"].includes(e.type) && e.qid) return; // checking for qid to avoid infinite, by checking that the event is not 'many' event

			const fstore = AS.GSTORE.filters[model_id];

			if (!fstore) return console.warn("NO FSTORE FOR THE MODEL_ID : ", model_id);

			const FO = fstore[comp_id];
			if (!FO) return console.warn("NO FO");

			g_fn.bro_get_many(INFO, FO);
		}));


		// on filter change => subs
		subs.push(AS.GSTORE.subscribe((e) => {
			if (e.type !== "filter") return;
			if (e.model_id !== model_id) return;
			if (e.comp_id !== comp_id) return;
			console.log("FILTER3 : ", e);
			const FO = e.filters;

			if (!FO) return console.warn("NO FO FROM GSTORE");

			set_filter_object(FO);

			g_fn.bro_get_many(INFO, FO);
		}));

		g_fn.bro_get_many(INFO, FO);

		return () => {
			subs.forEach(s => s.unsubscribe());
		}
	}



	// STATEMENTS

	INFO.set_M = set_M;
	INFO.on_created = props.on_created || props.INFO?.on_created;
	INFO.on_selected = props.on_selected || props.INFO?.on_selected;
	const relation = null;
	const query = null;




	// CONDITIONALS ATTRS
	const COND_ATTRS = {};



	// DYNAMIC REACT STATES
	const REACT_STATES = {};
	REACT_STATES["M"] = { state: M, set_state: set_M };

	const AS_COMPONENTS = AS.components["comp_bnode_muwns"] || {};
	AS_COMPONENTS.REACT_STATES = REACT_STATES;
	AS.components["comp_bnode_muwns"] = AS_COMPONENTS;


	const [hiddens, set_hiddens] = useState([]); // ids
	const localsearch = (text) => {
		console.log("SEARCHING : ", text);
		const hidden = [];
		M.forEach((V, idx) => {
			const haystack = JSON.stringify(V).toLowerCase();
			const needle = text?.toLowerCase();
			if (haystack.includes(needle)) return;
			hidden.push(V.id);
		});
		set_hiddens(hidden);
	}


	// MAPPED DATA
	const MAPPED_DATA = {};

	//


	return (
		<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => { console.error(d) }}>

			<div data_id="bnode_muwns" name="test" op="get_many" className="w-full flex flex-col" id={"bnode_muwns" + (idx ? "__" + idx : "")} extra-attr >

				<div data_id="bnode_6kiub" className="new-div w-full flex items-center justify-center pb-2" id={"bnode_6kiub" + (idx ? "__" + idx : "")} name="top" extra-attr >

					<div data_id="bnode_oa8jr" className="new-div w-1/3 flex items-center justify-center" id={"bnode_oa8jr" + (idx ? "__" + idx : "")} name="left" extra-attr >

						<div data_id="bnode_8kaka" className="flex w-full flex-row relative" id={"bnode_8kaka" + (idx ? "__" + idx : "")} extra-attr >
							<input data_id="bnode_iun1n" type="text" placeholder="Search Quiz" className="w-full rounded-md p-2 px-4 outline outline-2 outline-zinc-200 font-['Open_Sans'] font-normal text-base" id={"bnode_iun1n" + (idx ? "__" + idx : "")} action="['oninput-set-text-filters']" model_id="yvjbe" onInput={(e) => { localsearch(e.target.value); g_fn.bro_on_input_set_text_filters(e, INFO) }} extra-attr />

							<div data_id="bnode_x6pdw" className="absolute right-4 top-2" id={"bnode_x6pdw" + (idx ? "__" + idx : "")} extra-attr >

								<svg data_id="bnode_c3d1h" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id={"bnode_c3d1h" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

									<circle data_id="" cx="11" cy="11" r="8" extra-attr >

									</circle>


									<path data_id="" d="m21 21-4.3-4.3" extra-attr >

									</path>

								</svg>

							</div>

						</div>

					</div>


					<div data_id="bnode_jzzuw" className="w-full gap-2 pb-4 rounded flex justify-end items-center" id={"bnode_jzzuw" + (idx ? "__" + idx : "")} filters-id="bnode_jzzuw" extra-attr >

						<button data_id="bnode_yd7p6" action="apply-filters" filter-action="remove-all" className="px-2 py-1 rounded-[8px] cursor-pointer active:bg-[#374151] active:text-white text-sm font-semibold font-['Open_Sans'] text-[#6B7280]" id={"bnode_yd7p6" + (idx ? "__" + idx : "")} filters-id="bnode_jzzuw" filter-id="test.status => clear all" model_id="yvjbe" onClick={(e) => {



							if (window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
							e.preventDefault(); e.stopPropagation();

							const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
							g_fn.bro_apply_filters(e, INFO)
							el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
						}} extra-attr >
							Clear
						</button>


						<button data_id="bnode_ji9kt" action="apply-filters" filter-action="set" className="px-2 py-1 rounded-[8px] cursor-pointer active:bg-[#374151] active:text-white text-sm font-semibold font-['Open_Sans'] text-[#6B7280]" id={"bnode_ji9kt" + (idx ? "__" + idx : "")} filters-id="bnode_jzzuw" filter-id="test.status => Published" model_id="yvjbe" onClick={(e) => {



							if (window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
							e.preventDefault(); e.stopPropagation();

							const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
							g_fn.bro_apply_filters(e, INFO)
							el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
						}} extra-attr >

							Published

						</button>


						<button data_id="bnode_3wbt8" action="apply-filters" filter-action="set" className="px-2 py-1 rounded-[8px] cursor-pointer active:bg-[#374151] active:text-white text-sm font-semibold font-['Open_Sans'] text-[#6B7280]" id={"bnode_3wbt8" + (idx ? "__" + idx : "")} filters-id="bnode_jzzuw" filter-id="test.status => Draft" model_id="yvjbe" onClick={(e) => {



							if (window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
							e.preventDefault(); e.stopPropagation();

							const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
							g_fn.bro_apply_filters(e, INFO)
							el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
						}} extra-attr >

							Draft

						</button>

					</div>

				</div>


				<div data_id="bnode_gae5s" name="test-data" className="grid grid-cols-3 grid-flow-row gap-4" id={"bnode_gae5s" + (idx ? "__" + idx : "")} page_id="bnode_jj973" extra-attr >
					{M && M.map((V, idx) => (
						<div style={{display: hiddens.includes(V.id) ? "none" : "block"}}>
							< COMP_REG.comp_bnode_3mcwk {...{ M: V, PM: [...PM, M], idx, on_created: props.on_created || INFO.on_created, on_selected: props.on_selected || INFO.on_selected, INFO: INFO, prop_name: "" }} key={V.id} extra-attr />
						</div>
					))}
				</div>

				{false && (<div data_id="bnode_u8i0p" name="pagination" className="flex gap-2 pt-2" id={"bnode_u8i0p" + (idx ? "__" + idx : "")} extra-attr >

					<button data_id="bnode_f1nox" action="get-prev" op="get_many" className="border rounded bg-zinc-100 px-2 py-0.5 hover:bg-zinc-200 text-lg" id={"bnode_f1nox" + (idx ? "__" + idx : "")} model_id="yvjbe" onClick={(e) => {



						if (window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
						e.preventDefault(); e.stopPropagation();

						const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
						g_fn.bro_get_many_for_pagination(set_M, INFO, { static_filters, filters, sorts, limit, prev: true })
						el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
					}} model_id="yvjbe" extra-attr >

						Prev

					</button>


					<div data_id="bnode_07zz2" name="page-indicator" id={"bnode_07zz2" + (idx ? "__" + idx : "")} extra-attr >

					</div>


					<button data_id="bnode_ysfod" action="get-next" op="get_many" className="border rounded bg-zinc-100 px-2 py-0.5 hover:bg-zinc-200 text-lg" id={"bnode_ysfod" + (idx ? "__" + idx : "")} model_id="yvjbe" onClick={(e) => {



						if (window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
						e.preventDefault(); e.stopPropagation();

						const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
						g_fn.bro_get_many_for_pagination(set_M, INFO, { static_filters, filters, sorts, limit, prev: false })
						el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
					}} model_id="yvjbe" extra-attr >

						Next

					</button>

				</div>)}

				<div data_id="bnode_8674b" className="flex  rounded" id={"bnode_8674b" + (idx ? "__" + idx : "")} filters-id="bnode_8674b" extra-attr >

				</div>

				<COMP_REG.comp_bnode_me6w0 {...{ ...props, M, INFO, prop_name: "" }} extra-attr />
			</div>

		</ErrorBoundary>
	)
}

export default comp_bnode_muwns;