import React, { useEffect, useRef, useState } from "react";
import g_fn from "./g_fn.js";
const Element = () => {
    return (<div>UTILITY ELEMENT</div>);
};
// replace this with whatever utility component you want
const UtilityElement = Element;
const AS = g_fn.AS;
export default function Utility(props) {
    const [M, set_M] = useState({});
    return (<div className="h-[800px]  w-full flex justify-center items-center">
            <UtilityElement />
        </div>);
}
