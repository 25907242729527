import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_l1z1x";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_l1z1x = (props)=>{
			const INFO  = {"model_id":"yvjbe","model_name":"test","op":"create_one","comp_id":"comp_bnode_l1z1x"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit = init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			},[])
		

			useEffect(()=>{
				// console.log("OP CREATE ONE PROPS CHANGED : ", INFO.model_id, INFO.comp_id, props, relation);
				if(!relation || !INFO.model_id || !INFO.comp_id) return;
				if(props.PM && Array.isArray(props.PM) && props.PM.length) {
					const parent_M = props.PM[props.PM.length - 1];
					const parent_M_copy = JSON.parse(JSON.stringify(parent_M));
					delete parent_M_copy[':db/id'];
					const model_data = parent_M_copy[relation.prop_name];
					if(!model_data) return;
					delete model_data[':db/id'];
					if(Object.keys(model_data).length === 0) return console.warn("NO RELATION DATA FOUND FROM PARENT MODEL");
					if(Object.keys(model_data).length === 1 && model_data.id) {
						(async () => {
							const r = await AS.GSTORE.get_one_entity(INFO.model_id, model_data.id);
							if(r) {
								// if we are getting from GSTORE, make sure to make a copy as the changes in form can alter the object because of reference.
								const r_copy = JSON.parse(JSON.stringify(r));
								set_M(r_copy);
							}
						})();
					}
					if(Object.keys(model_data).length > 1) {
						set_M(model_data);
					}
				}
			},[props])
		

			useEffect(()=>{
				if(!M) return;
				if(!INFO.model_id || !INFO.comp_id) return;
				const model_id = INFO.model_id;
				const comp_id = INFO.comp_id;

				const idx = props.idx || props.IDX;

				const subscribe_id = idx || 0;

				const model = g_fn.get_model(model_id);
				if(!model) return;
				const id_prop = model.props.find(p=>p.name === 'id');
				if(model.name.toLowerCase() === 'user' && id_prop && id_prop.type === 'user_id') {
					if(M.email) {
						(async () => {
							const user_id = await g_fn.generate_user_id_by_email(M);
							// loop breaker for user_id in the following line, to be fixed properly
							if(user_id === M.id) return;
							if(user_id) {
								// not storing id in draft, if the same component is mapped, id will also be same for different entities
								set_M(PM => {
									PM.id = user_id;

									console.log("SETTING REACT STATE : ", PM);
									AS.GSTORE.update_draft(model_id, comp_id, subscribe_id, PM);
									return PM;
								});
								return;
							}
						})();
						return;
					}
				}
				AS.GSTORE.update_draft(model_id, comp_id, subscribe_id, M);
			},[M])
		
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = () => {

				if(!INFO.model_id || !INFO.comp_id) return;

				const model_id = INFO.model_id;
				const comp_id = INFO.comp_id;

				const subs = [];

				const id = g_fn.get_ulid();

				const idx = props.idx || props.IDX;

				const subscribe_id = idx || 0;

				subs.push(AS.GSTORE.subscribe_draft(model_id, comp_id, subscribe_id, (e)=>{

					console.log("SUBSCRIBE DRAFT : ", e, comp_id, props.idx, props.IDX, subscribe_id);

					if(e.data === null) {
						set_M(M => {
							for(let [k,v] of Object.entries(M)){
								if(k === "id") continue;
								delete M[k];
							}
						});
						return M;
					}

					if(e.data) {
						console.log("DRAFT DATA : ", e.data);
						const D = e.data;
						set_rerender({}); // to re-render
						set_M(M => {
							console.log("DRAFT IS SAME AS M : ", D === M, D, M);
							if(D === M) {
								return D; // same object
							}
							if((D?.id) !== M.id) {
								console.log("NOT SETTING DATA FROM A DIFFERENT FORM : ", D, M);
								return M; // ids are not equal which means data from a different form
							}
							return D;
						});
					}
				}));


				subs.push(AS.GSTORE.subscribe_message(model_id, (e) => {

					if(e.eid && e.message !== "CREATED") return;

					set_M(M => {
						return {id : g_fn.get_ulid()};
					});

					if(!relation) return;

					// if relation exists, update the parent model draft

					const rel_eid = props.id || null;
					console.log("RELATION ENTITY ID : ", rel_eid);

					console.log("RELATION : ", relation);
					const rel_mid = relation.model_id;
					const rel_cid = relation.comp_id || props.INFO?.comp_id;
					const updates = {[relation.prop_name] : e.data};
					AS.GSTORE.update_draft(rel_mid, rel_cid, subscribe_id, updates);
				}));

				// one time setup
				set_M(M => {
					const model = g_fn.get_model(model_id);
					let draft = AS.GSTORE.get_draft(model_id, comp_id, subscribe_id);
					if(!draft) {
						draft = {};
					}

					// drafts id can be same if the same component is mapped, so initialising new id every time
					if(M) return {...M, ...draft, id};

					return draft;
				});

				return () => {
					subs.forEach(s=>s.unsubscribe());
				}
			}
		
useEffect(() => {
  console.log('M is: ', M);
  set_M(M => ({
    ...M,
      'questions':[{id:1}]
  }))
}, [])


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_2u4sl"] = COND_ATTRS["bnode_2u4sl"] || {};
			

				COND_ATTRS["bnode_h3unx"] = COND_ATTRS["bnode_h3unx"] || {};
			

				COND_ATTRS["bnode_l3f3s"] = COND_ATTRS["bnode_l3f3s"] || {};
			

				COND_ATTRS["bnode_i1133"] = COND_ATTRS["bnode_i1133"] || {};
			

				COND_ATTRS["bnode_gn201"] = COND_ATTRS["bnode_gn201"] || {};
			

				COND_ATTRS["bnode_z9lk6"] = COND_ATTRS["bnode_z9lk6"] || {};
			

				COND_ATTRS["bnode_jrv6z"] = COND_ATTRS["bnode_jrv6z"] || {};
			

				COND_ATTRS["bnode_rrjrx"] = COND_ATTRS["bnode_rrjrx"] || {};
			

				COND_ATTRS["bnode_dopgc"] = COND_ATTRS["bnode_dopgc"] || {};
			

				COND_ATTRS["bnode_brovm"] = COND_ATTRS["bnode_brovm"] || {};
			

				COND_ATTRS["bnode_b7yw2"] = COND_ATTRS["bnode_b7yw2"] || {};
			

				COND_ATTRS["bnode_abuxl"] = COND_ATTRS["bnode_abuxl"] || {};
			

				COND_ATTRS["bnode_wnj30"] = COND_ATTRS["bnode_wnj30"] || {};
			

				COND_ATTRS["bnode_jlr0w"] = COND_ATTRS["bnode_jlr0w"] || {};
			

				COND_ATTRS["bnode_hnhb5"] = COND_ATTRS["bnode_hnhb5"] || {};
			

				COND_ATTRS["bnode_dx4yf"] = COND_ATTRS["bnode_dx4yf"] || {};
			

				COND_ATTRS["bnode_ld6pl"] = COND_ATTRS["bnode_ld6pl"] || {};
			

				COND_ATTRS["bnode_y4ybv"] = COND_ATTRS["bnode_y4ybv"] || {};
			

				COND_ATTRS["bnode_1gd2b"] = COND_ATTRS["bnode_1gd2b"] || {};
			

				COND_ATTRS["bnode_ufu4b"] = COND_ATTRS["bnode_ufu4b"] || {};
			

				COND_ATTRS["bnode_3xh00"] = COND_ATTRS["bnode_3xh00"] || {};
			

				COND_ATTRS["bnode_pkryq"] = COND_ATTRS["bnode_pkryq"] || {};
			

				COND_ATTRS["bnode_twvz5"] = COND_ATTRS["bnode_twvz5"] || {};
			

				COND_ATTRS["bnode_0rvyp"] = COND_ATTRS["bnode_0rvyp"] || {};
			

				COND_ATTRS["bnode_iy56c"] = COND_ATTRS["bnode_iy56c"] || {};
			

				COND_ATTRS["bnode_hbyta"] = COND_ATTRS["bnode_hbyta"] || {};
			

				COND_ATTRS["bnode_gmmqz"] = COND_ATTRS["bnode_gmmqz"] || {};
			

				COND_ATTRS["bnode_3xedk"] = COND_ATTRS["bnode_3xedk"] || {};
			

				COND_ATTRS["bnode_3zxpo"] = COND_ATTRS["bnode_3zxpo"] || {};
			

				COND_ATTRS["bnode_ugukv"] = COND_ATTRS["bnode_ugukv"] || {};
			

				COND_ATTRS["bnode_r00nn"] = COND_ATTRS["bnode_r00nn"] || {};
			

				COND_ATTRS["bnode_qu2ov"] = COND_ATTRS["bnode_qu2ov"] || {};
			

				COND_ATTRS["bnode_ck1z0"] = COND_ATTRS["bnode_ck1z0"] || {};
			

				COND_ATTRS["bnode_o3n7p"] = COND_ATTRS["bnode_o3n7p"] || {};
			

				COND_ATTRS["bnode_2kg45"] = COND_ATTRS["bnode_2kg45"] || {};
			

				COND_ATTRS["bnode_lsws4"] = COND_ATTRS["bnode_lsws4"] || {};
			

				COND_ATTRS["bnode_ab77t"] = COND_ATTRS["bnode_ab77t"] || {};
			

				COND_ATTRS["bnode_af7yy"] = COND_ATTRS["bnode_af7yy"] || {};
			

				COND_ATTRS["bnode_e8rqq"] = COND_ATTRS["bnode_e8rqq"] || {};
			

				COND_ATTRS["bnode_74s53"] = COND_ATTRS["bnode_74s53"] || {};
			

				COND_ATTRS["bnode_kiovb"] = COND_ATTRS["bnode_kiovb"] || {};
			

				COND_ATTRS["bnode_jzjv3"] = COND_ATTRS["bnode_jzjv3"] || {};
			

				COND_ATTRS["bnode_9habm"] = COND_ATTRS["bnode_9habm"] || {};
			

				COND_ATTRS["bnode_hrbkh"] = COND_ATTRS["bnode_hrbkh"] || {};
			

				COND_ATTRS["bnode_aq3tt"] = COND_ATTRS["bnode_aq3tt"] || {};
			

				COND_ATTRS["bnode_fuq03"] = COND_ATTRS["bnode_fuq03"] || {};
			

				COND_ATTRS["bnode_guks0"] = COND_ATTRS["bnode_guks0"] || {};
			

				COND_ATTRS["bnode_c0w7p"] = COND_ATTRS["bnode_c0w7p"] || {};
			

				COND_ATTRS["bnode_qpslo"] = COND_ATTRS["bnode_qpslo"] || {};
			

				COND_ATTRS["bnode_6vqsd"] = COND_ATTRS["bnode_6vqsd"] || {};
			

				COND_ATTRS["bnode_sjyga"] = COND_ATTRS["bnode_sjyga"] || {};
			

				COND_ATTRS["bnode_d56rs"] = COND_ATTRS["bnode_d56rs"] || {};
			

				COND_ATTRS["bnode_de32q"] = COND_ATTRS["bnode_de32q"] || {};
			

				COND_ATTRS["bnode_echbr"] = COND_ATTRS["bnode_echbr"] || {};
			

				COND_ATTRS["bnode_hvwrw"] = COND_ATTRS["bnode_hvwrw"] || {};
			

				COND_ATTRS["bnode_kgc64"] = COND_ATTRS["bnode_kgc64"] || {};
			

				COND_ATTRS["bnode_tzrtt"] = COND_ATTRS["bnode_tzrtt"] || {};
			

				COND_ATTRS["bnode_8olm7"] = COND_ATTRS["bnode_8olm7"] || {};
			

				COND_ATTRS["bnode_k9zn6"] = COND_ATTRS["bnode_k9zn6"] || {};
			

				COND_ATTRS["bnode_9knwu"] = COND_ATTRS["bnode_9knwu"] || {};
			

				COND_ATTRS["bnode_v8ykg"] = COND_ATTRS["bnode_v8ykg"] || {};
			

				COND_ATTRS["bnode_irvma"] = COND_ATTRS["bnode_irvma"] || {};
			

				COND_ATTRS["bnode_k5yh4"] = COND_ATTRS["bnode_k5yh4"] || {};
			

				COND_ATTRS["bnode_cosd6"] = COND_ATTRS["bnode_cosd6"] || {};
			

				COND_ATTRS["bnode_ntvvd"] = COND_ATTRS["bnode_ntvvd"] || {};
			

				COND_ATTRS["bnode_xw6u7"] = COND_ATTRS["bnode_xw6u7"] || {};
			

				COND_ATTRS["bnode_0vwxk"] = COND_ATTRS["bnode_0vwxk"] || {};
			

				COND_ATTRS["bnode_oyej8"] = COND_ATTRS["bnode_oyej8"] || {};
			

				COND_ATTRS["bnode_ldz67"] = COND_ATTRS["bnode_ldz67"] || {};
			

				COND_ATTRS["bnode_oo4fd"] = COND_ATTRS["bnode_oo4fd"] || {};
			

				COND_ATTRS["bnode_b6mrn"] = COND_ATTRS["bnode_b6mrn"] || {};
			

				COND_ATTRS["bnode_rhee2"] = COND_ATTRS["bnode_rhee2"] || {};
			

				COND_ATTRS["bnode_96yny"] = COND_ATTRS["bnode_96yny"] || {};
			

				COND_ATTRS["bnode_1fz67"] = COND_ATTRS["bnode_1fz67"] || {};
			

				COND_ATTRS["bnode_4if7w"] = COND_ATTRS["bnode_4if7w"] || {};
			

				COND_ATTRS["bnode_znkia"] = COND_ATTRS["bnode_znkia"] || {};
			

				COND_ATTRS["bnode_z6kew"] = COND_ATTRS["bnode_z6kew"] || {};
			

				COND_ATTRS["bnode_py2rb"] = COND_ATTRS["bnode_py2rb"] || {};
			

				COND_ATTRS["bnode_tib5d"] = COND_ATTRS["bnode_tib5d"] || {};
			

				COND_ATTRS["bnode_iykcm"] = COND_ATTRS["bnode_iykcm"] || {};
			

				COND_ATTRS["bnode_2s760"] = COND_ATTRS["bnode_2s760"] || {};
			

				COND_ATTRS["bnode_gr10j"] = COND_ATTRS["bnode_gr10j"] || {};
			

				COND_ATTRS["bnode_gpser"] = COND_ATTRS["bnode_gpser"] || {};
			

				COND_ATTRS["bnode_edqmc"] = COND_ATTRS["bnode_edqmc"] || {};
			

				COND_ATTRS["bnode_53bo1"] = COND_ATTRS["bnode_53bo1"] || {};
			

				COND_ATTRS["bnode_or619"] = COND_ATTRS["bnode_or619"] || {};
			

				COND_ATTRS["bnode_r6chd"] = COND_ATTRS["bnode_r6chd"] || {};
			

				COND_ATTRS["bnode_m2hdr"] = COND_ATTRS["bnode_m2hdr"] || {};
			

				COND_ATTRS["bnode_3oaj1"] = COND_ATTRS["bnode_3oaj1"] || {};
			

				COND_ATTRS["bnode_ywpjk"] = COND_ATTRS["bnode_ywpjk"] || {};
			

				COND_ATTRS["bnode_56xxd"] = COND_ATTRS["bnode_56xxd"] || {};
			

				COND_ATTRS["bnode_yj325"] = COND_ATTRS["bnode_yj325"] || {};
			

				COND_ATTRS["bnode_cbzhv"] = COND_ATTRS["bnode_cbzhv"] || {};
			

				COND_ATTRS["bnode_13gl1"] = COND_ATTRS["bnode_13gl1"] || {};
			

				COND_ATTRS["bnode_0b27g"] = COND_ATTRS["bnode_0b27g"] || {};
			

				COND_ATTRS["bnode_as4ue"] = COND_ATTRS["bnode_as4ue"] || {};
			

				COND_ATTRS["bnode_swm3m"] = COND_ATTRS["bnode_swm3m"] || {};
			

				COND_ATTRS["bnode_3t4i6"] = COND_ATTRS["bnode_3t4i6"] || {};
			

				COND_ATTRS["bnode_tgb87"] = COND_ATTRS["bnode_tgb87"] || {};
			

				COND_ATTRS["bnode_3pfcl"] = COND_ATTRS["bnode_3pfcl"] || {};
			

				COND_ATTRS["bnode_pufy0"] = COND_ATTRS["bnode_pufy0"] || {};
			

				COND_ATTRS["bnode_4tgm7"] = COND_ATTRS["bnode_4tgm7"] || {};
			

				COND_ATTRS["bnode_a2rly"] = COND_ATTRS["bnode_a2rly"] || {};
			

				COND_ATTRS["bnode_79jgv"] = COND_ATTRS["bnode_79jgv"] || {};
			

				COND_ATTRS["bnode_c5nx1"] = COND_ATTRS["bnode_c5nx1"] || {};
			

				COND_ATTRS["bnode_x41xh"] = COND_ATTRS["bnode_x41xh"] || {};
			

				COND_ATTRS["bnode_ciq35"] = COND_ATTRS["bnode_ciq35"] || {};
			

				COND_ATTRS["bnode_tbect"] = COND_ATTRS["bnode_tbect"] || {};
			

				COND_ATTRS["bnode_t3kgo"] = COND_ATTRS["bnode_t3kgo"] || {};
			

				COND_ATTRS["bnode_eo50s"] = COND_ATTRS["bnode_eo50s"] || {};
			

				COND_ATTRS["bnode_pe37d"] = COND_ATTRS["bnode_pe37d"] || {};
			

				COND_ATTRS["bnode_w9llt"] = COND_ATTRS["bnode_w9llt"] || {};
			

				COND_ATTRS["bnode_phkoq"] = COND_ATTRS["bnode_phkoq"] || {};
			

				COND_ATTRS["bnode_56agr"] = COND_ATTRS["bnode_56agr"] || {};
			

				COND_ATTRS["bnode_jfcxs"] = COND_ATTRS["bnode_jfcxs"] || {};
			

				COND_ATTRS["bnode_rs7jq"] = COND_ATTRS["bnode_rs7jq"] || {};
			

				COND_ATTRS["bnode_kep3z"] = COND_ATTRS["bnode_kep3z"] || {};
			

				COND_ATTRS["bnode_ftjjl"] = COND_ATTRS["bnode_ftjjl"] || {};
			

				COND_ATTRS["bnode_xok0z"] = COND_ATTRS["bnode_xok0z"] || {};
			

				COND_ATTRS["bnode_apsl6"] = COND_ATTRS["bnode_apsl6"] || {};
			

				COND_ATTRS["bnode_m62co"] = COND_ATTRS["bnode_m62co"] || {};
			

				COND_ATTRS["bnode_01b6y"] = COND_ATTRS["bnode_01b6y"] || {};
			

				COND_ATTRS["bnode_qdd3i"] = COND_ATTRS["bnode_qdd3i"] || {};
			

				COND_ATTRS["bnode_vfbjh"] = COND_ATTRS["bnode_vfbjh"] || {};
			

				COND_ATTRS["bnode_gsk98"] = COND_ATTRS["bnode_gsk98"] || {};
			

				COND_ATTRS["bnode_ap77k"] = COND_ATTRS["bnode_ap77k"] || {};
			

				COND_ATTRS["bnode_e04vt"] = COND_ATTRS["bnode_e04vt"] || {};
			

				COND_ATTRS["bnode_x7zjk"] = COND_ATTRS["bnode_x7zjk"] || {};
			

				COND_ATTRS["bnode_kefds"] = COND_ATTRS["bnode_kefds"] || {};
			

				COND_ATTRS["bnode_w5cps"] = COND_ATTRS["bnode_w5cps"] || {};
			

				COND_ATTRS["bnode_bvp6c"] = COND_ATTRS["bnode_bvp6c"] || {};
			

				COND_ATTRS["bnode_qbpts"] = COND_ATTRS["bnode_qbpts"] || {};
			

				COND_ATTRS["bnode_l1z1x"] = COND_ATTRS["bnode_l1z1x"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_l1z1x"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_l1z1x"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_l1z1x" name="test" op="create_one" className="w-full flex flex-col p-4" id={"bnode_l1z1x" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_2u4sl" name="title" className=" text-2xl font-medium text-gray-600 text-left pb-6" id={"bnode_2u4sl" + (idx?"__"+idx:"")} extra-attr >
                
            CREATE TEST
        
            </div>
        

            <div data_id="bnode_w5cps" name="test-data" className="flex flex-col gap-4" id={"bnode_w5cps" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_brovm" name="CONT-tag" className="w-full flex flex-col gap-2" id={"bnode_brovm" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_h3unx" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_h3unx" + (idx?"__"+idx:"")} extra-attr >
                tag
            </div>
        

                    {M && g_fn.safe_array(M.tag).map(
                        (V, idx)=> (
                            
            <div data_id="bnode_jrv6z" name="tag" className="flex flex-col  w-full gap-6 " id={"bnode_jrv6z" + (idx?"__"+idx:"")} key={g_fn.get_key_from_meta(M, "tag", idx)} extra-attr >
                
            <div data_id="bnode_z9lk6" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_z9lk6" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_l3f3s" name="tag" b_idx={idx} type="text" value={V.v} placeholder="tag" action="oninput-update-m-idx" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_l3f3s" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input_idx(e, INFO)}} extra-attr />

            <button data_id="bnode_gn201" name="tag" b_idx={idx} action="delete-prop-item" className="bg-none text-rose-300 p-1.5 rounded-md hover:bg-rose-400 hover:text-white transition-all ease-in-out duration-150" id={"bnode_gn201" + (idx?"__"+idx:"")} model_id="yvjbe" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_delete_prop_item(e, INFO)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <svg data_id="bnode_i1133" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id={"bnode_i1133" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M18 6 6 18" extra-attr >
                
            </path>
        

            <path data_id="" d="m6 6 12 12" extra-attr >
                
            </path>
        
            </svg>
        
            </button>
        
            </div>
        
            </div>
        
                        )
                    )}
                

            <button data_id="bnode_dopgc" type="text" action="add-prop-item" name="tag" className="w-10 h-10 flex justify-center items-center mx-auto border border-gray-100 bg-gray-200 hover:border-gray-200 text-gray-700 text-3xl rounded-lg " id={"bnode_dopgc" + (idx?"__"+idx:"")} model_id="yvjbe" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_add_prop_item(e, INFO)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <svg data_id="bnode_rrjrx" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id={"bnode_rrjrx" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M5 12h14" extra-attr >
                
            </path>
        

            <path data_id="" d="M12 5v14" extra-attr >
                
            </path>
        
            </svg>
        
            </button>
        
            </div>
        

            <div data_id="bnode_dx4yf" name="CONT-guidelines" className="w-full" id={"bnode_dx4yf" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_hnhb5" id={"bnode_hnhb5" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_b7yw2" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_b7yw2" + (idx?"__"+idx:"")} extra-attr >
                guidelines
            </div>
        

            <div data_id="bnode_jlr0w" name="guidelines" className="flex flex-col  w-full gap-6 " id={"bnode_jlr0w" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_wnj30" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_wnj30" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_abuxl" name="guidelines" type="text" value={M.guidelines} placeholder="guidelines" action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_abuxl" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_gmmqz" name="CONT-questions" className="w-full flex flex-col gap-2" id={"bnode_gmmqz" + (idx?"__"+idx:"")} extra-attr >
                
            <fn data_id="bnode_ld6pl" name="first_question_show_effect" id={"bnode_ld6pl" + (idx?"__"+idx:"")} extra-attr >
                
            </fn>
        

            <div data_id="bnode_y4ybv" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_y4ybv" + (idx?"__"+idx:"")} extra-attr >
                questions
            </div>
        

                    {M && g_fn.safe_array(M.questions).map(
                        (V, idx)=> (
                            <COMP_REG.comp_bnode_0rvyp  M={M.questions}  set_parent_M={set_M} INFO={INFO} prop_name={"questions"}  idx={idx}  key={g_fn.get_key_from_meta(M, "questions", idx)} extra-attr />
                        )
                    )}
                

            <button data_id="bnode_hbyta" type="fhmae" action="add-prop-item" name="questions" is_rel="true" className="w-10 h-10 flex justify-center items-center mx-auto border border-gray-100 bg-gray-200 hover:border-gray-200 text-gray-700 text-3xl rounded-lg " id={"bnode_hbyta" + (idx?"__"+idx:"")} model_id="yvjbe" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_add_prop_item(e, INFO)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <svg data_id="bnode_iy56c" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id={"bnode_iy56c" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M5 12h14" extra-attr >
                
            </path>
        

            <path data_id="" d="M12 5v14" extra-attr >
                
            </path>
        
            </svg>
        
            </button>
        
            </div>
        

            <div data_id="bnode_e8rqq" name="CONT-difficulty" className="w-full" id={"bnode_e8rqq" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_af7yy" id={"bnode_af7yy" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_3xedk" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_3xedk" + (idx?"__"+idx:"")} extra-attr >
                difficulty
            </div>
        

            <div data_id="bnode_ab77t" name="difficulty" className="flex flex-col  w-full gap-6 " id={"bnode_ab77t" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_lsws4" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_lsws4" + (idx?"__"+idx:"")} extra-attr >
                
            <select data_id="bnode_2kg45" name="difficulty" placeholder="difficulty" action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_2kg45" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            <option data_id="bnode_3zxpo" selected="true" id={"bnode_3zxpo" + (idx?"__"+idx:"")} extra-attr >
                
            </option>
        

            <option data_id="bnode_ugukv" value="1" selected={M.difficulty === '1'} id={"bnode_ugukv" + (idx?"__"+idx:"")} extra-attr >
                1
            </option>
        

            <option data_id="bnode_r00nn" value="2" selected={M.difficulty === '2'} id={"bnode_r00nn" + (idx?"__"+idx:"")} extra-attr >
                2
            </option>
        

            <option data_id="bnode_qu2ov" value="3" selected={M.difficulty === '3'} id={"bnode_qu2ov" + (idx?"__"+idx:"")} extra-attr >
                3
            </option>
        

            <option data_id="bnode_ck1z0" value="4" selected={M.difficulty === '4'} id={"bnode_ck1z0" + (idx?"__"+idx:"")} extra-attr >
                4
            </option>
        

            <option data_id="bnode_o3n7p" value="5" selected={M.difficulty === '5'} id={"bnode_o3n7p" + (idx?"__"+idx:"")} extra-attr >
                5
            </option>
        
            </select>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_hvwrw" name="CONT-subject" className="w-full" id={"bnode_hvwrw" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_echbr" id={"bnode_echbr" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_74s53" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_74s53" + (idx?"__"+idx:"")} extra-attr >
                subject
            </div>
        

            <div data_id="bnode_de32q" name="subject" className="flex flex-col  w-full gap-6 " id={"bnode_de32q" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_d56rs" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_d56rs" + (idx?"__"+idx:"")} extra-attr >
                
            <select data_id="bnode_sjyga" name="subject" placeholder="subject" action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_sjyga" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            <option data_id="bnode_kiovb" selected="true" id={"bnode_kiovb" + (idx?"__"+idx:"")} extra-attr >
                
            </option>
        

            <option data_id="bnode_jzjv3" value="Mathematics" selected={M.subject === 'Mathematics'} id={"bnode_jzjv3" + (idx?"__"+idx:"")} extra-attr >
                Mathematics
            </option>
        

            <option data_id="bnode_9habm" value="Science" selected={M.subject === 'Science'} id={"bnode_9habm" + (idx?"__"+idx:"")} extra-attr >
                Science
            </option>
        

            <option data_id="bnode_hrbkh" value="Social Science" selected={M.subject === 'Social Science'} id={"bnode_hrbkh" + (idx?"__"+idx:"")} extra-attr >
                Social Science
            </option>
        

            <option data_id="bnode_aq3tt" value="History" selected={M.subject === 'History'} id={"bnode_aq3tt" + (idx?"__"+idx:"")} extra-attr >
                History
            </option>
        

            <option data_id="bnode_fuq03" value="Civics" selected={M.subject === 'Civics'} id={"bnode_fuq03" + (idx?"__"+idx:"")} extra-attr >
                Civics
            </option>
        

            <option data_id="bnode_guks0" value="Biology" selected={M.subject === 'Biology'} id={"bnode_guks0" + (idx?"__"+idx:"")} extra-attr >
                Biology
            </option>
        

            <option data_id="bnode_c0w7p" value="Physics" selected={M.subject === 'Physics'} id={"bnode_c0w7p" + (idx?"__"+idx:"")} extra-attr >
                Physics
            </option>
        

            <option data_id="bnode_qpslo" value="Chemistry" selected={M.subject === 'Chemistry'} id={"bnode_qpslo" + (idx?"__"+idx:"")} extra-attr >
                Chemistry
            </option>
        

            <option data_id="bnode_6vqsd" value="English" selected={M.subject === 'English'} id={"bnode_6vqsd" + (idx?"__"+idx:"")} extra-attr >
                English
            </option>
        
            </select>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_ntvvd" name="CONT-curriculum" className="w-full" id={"bnode_ntvvd" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_cosd6" id={"bnode_cosd6" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_kgc64" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_kgc64" + (idx?"__"+idx:"")} extra-attr >
                curriculum
            </div>
        

            <div data_id="bnode_k5yh4" name="curriculum" className="flex flex-col  w-full gap-6 " id={"bnode_k5yh4" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_irvma" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_irvma" + (idx?"__"+idx:"")} extra-attr >
                
            <select data_id="bnode_v8ykg" name="curriculum" placeholder="curriculum" action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_v8ykg" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            <option data_id="bnode_tzrtt" selected="true" id={"bnode_tzrtt" + (idx?"__"+idx:"")} extra-attr >
                
            </option>
        

            <option data_id="bnode_8olm7" value="CBSE" selected={M.curriculum === 'CBSE'} id={"bnode_8olm7" + (idx?"__"+idx:"")} extra-attr >
                CBSE
            </option>
        

            <option data_id="bnode_k9zn6" value="ICSE" selected={M.curriculum === 'ICSE'} id={"bnode_k9zn6" + (idx?"__"+idx:"")} extra-attr >
                ICSE
            </option>
        

            <option data_id="bnode_9knwu" value="State Board" selected={M.curriculum === 'State Board'} id={"bnode_9knwu" + (idx?"__"+idx:"")} extra-attr >
                State Board
            </option>
        
            </select>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_1fz67" name="CONT-status" className="w-full" id={"bnode_1fz67" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_96yny" id={"bnode_96yny" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_xw6u7" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_xw6u7" + (idx?"__"+idx:"")} extra-attr >
                status
            </div>
        

            <div data_id="bnode_rhee2" name="status" className="flex flex-col  w-full gap-6 " id={"bnode_rhee2" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_b6mrn" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_b6mrn" + (idx?"__"+idx:"")} extra-attr >
                
            <select data_id="bnode_oo4fd" name="status" placeholder="status" action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_oo4fd" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            <option data_id="bnode_0vwxk" selected="true" id={"bnode_0vwxk" + (idx?"__"+idx:"")} extra-attr >
                
            </option>
        

            <option data_id="bnode_oyej8" value="Published" selected={M.status === 'Published'} id={"bnode_oyej8" + (idx?"__"+idx:"")} extra-attr >
                Published
            </option>
        

            <option data_id="bnode_ldz67" value="Draft" selected={M.status === 'Draft'} id={"bnode_ldz67" + (idx?"__"+idx:"")} extra-attr >
                Draft
            </option>
        
            </select>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_iykcm" name="CONT-time" className="w-full" id={"bnode_iykcm" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_tib5d" id={"bnode_tib5d" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_4if7w" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_4if7w" + (idx?"__"+idx:"")} extra-attr >
                time
            </div>
        

            <div data_id="bnode_py2rb" name="time" className="flex flex-col  w-full gap-6 " id={"bnode_py2rb" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_z6kew" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_z6kew" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_znkia" name="time" type="number" value={M.time} placeholder="time" action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 noscroll" id={"bnode_znkia" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_or619" name="CONT-name" className="w-full" id={"bnode_or619" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_53bo1" id={"bnode_53bo1" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_2s760" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_2s760" + (idx?"__"+idx:"")} extra-attr >
                name
            </div>
        

            <div data_id="bnode_edqmc" name="name" className="flex flex-col  w-full gap-6 " id={"bnode_edqmc" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_gpser" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_gpser" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_gr10j" name="name" type="text" value={M.name} placeholder="name" action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_gr10j" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_3t4i6" name="CONT-grade" className="w-full" id={"bnode_3t4i6" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_swm3m" id={"bnode_swm3m" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_r6chd" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_r6chd" + (idx?"__"+idx:"")} extra-attr >
                grade
            </div>
        

            <div data_id="bnode_as4ue" name="grade" className="flex flex-col  w-full gap-6 " id={"bnode_as4ue" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_0b27g" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_0b27g" + (idx?"__"+idx:"")} extra-attr >
                
            <select data_id="bnode_13gl1" name="grade" placeholder="grade" action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_13gl1" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            <option data_id="bnode_m2hdr" selected="true" id={"bnode_m2hdr" + (idx?"__"+idx:"")} extra-attr >
                
            </option>
        

            <option data_id="bnode_3oaj1" value="8" selected={M.grade === '8'} id={"bnode_3oaj1" + (idx?"__"+idx:"")} extra-attr >
                8
            </option>
        

            <option data_id="bnode_ywpjk" value="9" selected={M.grade === '9'} id={"bnode_ywpjk" + (idx?"__"+idx:"")} extra-attr >
                9
            </option>
        

            <option data_id="bnode_56xxd" value="10" selected={M.grade === '10'} id={"bnode_56xxd" + (idx?"__"+idx:"")} extra-attr >
                10
            </option>
        

            <option data_id="bnode_yj325" value="11" selected={M.grade === '11'} id={"bnode_yj325" + (idx?"__"+idx:"")} extra-attr >
                11
            </option>
        

            <option data_id="bnode_cbzhv" value="12" selected={M.grade === '12'} id={"bnode_cbzhv" + (idx?"__"+idx:"")} extra-attr >
                12
            </option>
        
            </select>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_79jgv" name="CONT-last modified" className="w-full" id={"bnode_79jgv" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_a2rly" id={"bnode_a2rly" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_tgb87" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_tgb87" + (idx?"__"+idx:"")} extra-attr >
                last modified
            </div>
        

            <div data_id="bnode_4tgm7" name="last_modified" className="flex flex-col  w-full gap-6 " id={"bnode_4tgm7" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_pufy0" name="cont" className="flex flex-col items-center justify-center w-full gap-4" id={"bnode_pufy0" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_3pfcl" name="last_modified" type="datetime-local" value={g_fn.time_utils.get_valid_datetime(M.last_modified)} placeholder="last_modified" action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_3pfcl" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_eo50s" name="CONT-header" className="w-full" id={"bnode_eo50s" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_t3kgo" id={"bnode_t3kgo" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_c5nx1" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_c5nx1" + (idx?"__"+idx:"")} extra-attr >
                header
            </div>
        

            <div data_id="bnode_tbect" name="header" className="flex flex-col  w-full gap-6 " id={"bnode_tbect" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_ciq35" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_ciq35" + (idx?"__"+idx:"")} extra-attr >
                
            <textarea data_id="bnode_x41xh" name="header" placeholder="header" value={M.header} action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_x41xh" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            </textarea>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_rs7jq" name="CONT-test instructions" className="w-full" id={"bnode_rs7jq" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_jfcxs" id={"bnode_jfcxs" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_pe37d" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_pe37d" + (idx?"__"+idx:"")} extra-attr >
                test instructions
            </div>
        

            <div data_id="bnode_56agr" name="test_instructions" className="flex flex-col  w-full gap-6 " id={"bnode_56agr" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_phkoq" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_phkoq" + (idx?"__"+idx:"")} extra-attr >
                
            <textarea data_id="bnode_w9llt" name="test_instructions" placeholder="test_instructions" value={M.test_instructions} action="oninput-update-m" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 " id={"bnode_w9llt" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            </textarea>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_kefds" name="CONT-school logo" className="w-full" id={"bnode_kefds" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_x7zjk" id={"bnode_x7zjk" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_kep3z" className="block text-gray-700 text-sm font-medium mb-2 capitalize" id={"bnode_kep3z" + (idx?"__"+idx:"")} extra-attr >
                school logo
            </div>
        

            <div data_id="bnode_e04vt" name="school_logo" className="flex flex-col  w-full gap-6 " id={"bnode_e04vt" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_ap77k" name="cont" className="w-full flex flex-col md:flex-row items-center justify-center gap-4" id={"bnode_ap77k" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_ftjjl" name="image" style={{backgroundImage:`url("${M.school_logo?.url}")`}} className="w-32 h-32 flex flex-shrink-0 bg-no-repeat bg-gray-200 bg-center bg-cover rounded-md" id={"bnode_ftjjl" + (idx?"__"+idx:"")} extra-attr >
                
            </div>
        

            <div data_id="bnode_gsk98" className="flex flex-col items-center" id={"bnode_gsk98" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_xok0z" name="school_logo" type="file" placeholder="school_logo" action="oninput-update-m-file" className="block w-full text-sm text-[var(--neutral-500)] file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-[var(--primary-100)] file:text-[var(--neutral-50)] hover:file:bg-[var(--primary-100)] p-1 rounded-full bg-[var(--neutral-100)] " id={"bnode_xok0z" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input_file(e, INFO)}} extra-attr />

            <div data_id="bnode_apsl6" className="text-gray-400 w-fit px-2 text-center" id={"bnode_apsl6" + (idx?"__"+idx:"")} extra-attr >
                or
            </div>
        

            <div data_id="bnode_vfbjh" className="relative w-full md:w-fit" id={"bnode_vfbjh" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_01b6y" className="absolute left-2 top-1/2 -translate-y-1/2" id={"bnode_01b6y" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_m62co" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" id={"bnode_m62co" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M10.0002 13.7031C10.4297 14.2772 10.9776 14.7523 11.6067 15.096C12.2359 15.4398 12.9317 15.6442 13.6468 15.6954C14.362 15.7466 15.0798 15.6435 15.7515 15.3929C16.4233 15.1423 17.0333 14.7502 17.5402 14.2431L20.5402 11.2431C21.451 10.3001 21.955 9.03707 21.9436 7.72608C21.9322 6.4151 21.4063 5.16104 20.4793 4.234C19.5523 3.30696 18.2982 2.78111 16.9872 2.76972C15.6762 2.75833 14.4132 3.2623 13.4702 4.17309L11.7502 5.88309M14.0002 11.7031C13.5707 11.129 13.0228 10.6539 12.3936 10.3102C11.7645 9.96639 11.0687 9.76197 10.3535 9.71076C9.63841 9.65954 8.92061 9.76272 8.24885 10.0133C7.5771 10.2639 6.96709 10.656 6.4602 11.1631L3.4602 14.1631C2.54941 15.1061 2.04544 16.3691 2.05683 17.6801C2.06822 18.9911 2.59407 20.2451 3.52111 21.1722C4.44815 22.0992 5.70221 22.6251 7.01319 22.6365C8.32418 22.6479 9.58719 22.1439 10.5302 21.2331L12.2402 19.5231" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        
<input data_id="bnode_qdd3i" name="school_logo" className="border border-[--neutral-100] hover:border-[--neutral-50] bg-[--shades-800] px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-sm placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 pl-9 " b_type="image" type="text" placeholder="Paste image url" action="oninput-update-m" id={"bnode_qdd3i" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_qbpts" className="flex-row flex justify-end items-end pt-5 gap-4" id={"bnode_qbpts" + (idx?"__"+idx:"")} extra-attr >
                
            <button data_id="bnode_bvp6c" op="create_one" className="bg-[var(--primary-500)] hover:bg--[var(--primary-600)] text-white font-medium py-2 px-6 rounded-xl focus:outline-none w-fit text-sm " id={"bnode_bvp6c" + (idx?"__"+idx:"")} onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_create_one(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} model_id="yvjbe" extra-attr >
                
                    CREATE
                
            </button>
        
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_l1z1x;