import React from "react";
import { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Routes, Route, Link, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";

import { fallbackRender, add_sub, remove_subs } from "../utils.jsx";
import { COMP_REG } from "../comp_registry.js";
import g_fn from "../g_fn";

const AS = g_fn.AS;

// @req: keep reference to subs here and remove them on unmount or reload
const SUB_ID = "bnode_h4zoh";
const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};

// local code - e.g sub components

const comp_bnode_h4zoh = (props) => {
  const INFO = {
    model_id: "yvjbe",
    model_name: "test",
    op: "update_one",
    comp_id: "comp_bnode_h4zoh",
  };

  // Routes

  // FOR IS_MANY
  let idx = props.idx; // also for protection from error when we might think is many but it's not
  let V = props.V || {}; // for protection from error

  // STATES
  const [rerender, set_rerender] = useState({});
  const [M, set_M] = useState(props.M || {});
  const [PM, set_PM] = useState(props.PM || []);
  const [selected_M, set_selected_M] = useState(null);

  // REFS

  // EFFECTS

  useEffect(() => {
    console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
  }, [rerender]);

  useEffect(() => {
    const deinit = init();

    return () => {
      if (deinit && typeof deinit === "function") deinit();
    };
  }, []);

  useEffect(() => {
    console.log("PROPS : ", props, INFO, relation);
  }, [props]);

  useEffect(() => {
    const sub = AS.GSTORE.subscribe((e) => {
      // console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);
      // if model_id and eid are same
      // once data changes we will update M
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (EN_BROKEN_COMP_LOGS.MODEL_EFFECT) {
      const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
      console.log(
        "MODEL CHANGED : " + MI + "   => ",
        "model",
        M,
        " props",
        props
      );
    }
  }, [M]);

  useEffect(() => {
    if (!INFO.model_id) return;

    if (query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
    const id = g_fn.get_selected_entity_id(props, INFO);
    if (!id) {
      return console.warn("NO ID FOUND TO UPDATE ENTITY");
    }

    (async () => {
      const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
      console.log("GSTORE GET ONE ENTITY : ", r);
      if (r) set_selected_M(r);
    })();

    const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
      const id = g_fn.get_selected_entity_id(props, INFO);
      if (!id)
        return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
      (async () => {
        const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
        console.log(
          "GSTORE GET ONE ENTITY IN SUBSCRIPTION : ",
          r,
          INFO.model_id,
          INFO.comp_id
        );
        if (r) set_selected_M(r);
      })();
    });

    // On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
      
  }, []);

  // FUNCTIONS

  const init = () => {
    if (!INFO.model_id) return;

    if (query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
    let id = g_fn.get_selected_entity_id(props, INFO);
    if (!id && !relation) {
      return console.warn("NO ID FOUND TO UPDATE ENTITY");
    }

    if (!id && relation) {
      const parent_M =
        props.PM && Array.isArray(props.PM) && props.PM.length
          ? props.PM[props.PM.length - 1]
          : null;
      console.log("PM AND PARENT_M : ", props.PM, parent_M);
      if (parent_M) {
        const prop = parent_M[relation.prop_name];
        if (!prop)
          return console.warn("NO PROP FOUND FROM PARENT TO UPDATE ENTITY");
        if (typeof prop === "string") {
          id = prop;
        } else {
          id = prop.id;
        }
      }
    }

    (async () => {
      const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
      console.log("GSTORE GET ONE ENTITY : ", r);
      if (r) {
        const r_copy = JSON.parse(JSON.stringify(r));
        set_M(r_copy);
      }
    })();

    const subs = [];

    subs.push(
      AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
        const id = g_fn.get_selected_entity_id(props, INFO);
        if (!id)
          return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
        (async () => {
          const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
          console.log(
            "GSTORE GET ONE ENTITY IN SUBSCRIPTION -> UPDATE_ONE : ",
            INFO.model_id,
            r
          );
          if (r) {
            const r_copy = JSON.parse(JSON.stringify(r));
            set_M(r_copy);
          }
        })();
      })
    );

    return () => {
      subs.forEach((sub) => sub.unsubscribe());
    };
  };

  // STATEMENTS

  INFO.set_M = set_M;
  INFO.on_created = props.on_created || props.INFO?.on_created;
  INFO.on_selected = props.on_selected || props.INFO?.on_selected;
  const relation = null;
  const query = null;

  // CONDITIONALS ATTRS
  const COND_ATTRS = {};

  COND_ATTRS["bnode_uliti"] = COND_ATTRS["bnode_uliti"] || {};

  COND_ATTRS["bnode_a0w1h"] = COND_ATTRS["bnode_a0w1h"] || {};

  COND_ATTRS["bnode_5rma8"] = COND_ATTRS["bnode_5rma8"] || {};

  COND_ATTRS["bnode_5vj9l"] = COND_ATTRS["bnode_5vj9l"] || {};

  COND_ATTRS["bnode_rptv5"] = COND_ATTRS["bnode_rptv5"] || {};

  COND_ATTRS["bnode_rd8rh"] = COND_ATTRS["bnode_rd8rh"] || {};

  COND_ATTRS["bnode_e5v5f"] = COND_ATTRS["bnode_e5v5f"] || {};

  COND_ATTRS["bnode_tagv3"] = COND_ATTRS["bnode_tagv3"] || {};

  COND_ATTRS["bnode_96u2d"] = COND_ATTRS["bnode_96u2d"] || {};

  COND_ATTRS["bnode_febi1"] = COND_ATTRS["bnode_febi1"] || {};

  COND_ATTRS["bnode_byc8a"] = COND_ATTRS["bnode_byc8a"] || {};

  COND_ATTRS["bnode_xyhxr"] = COND_ATTRS["bnode_xyhxr"] || {};

  COND_ATTRS["bnode_yzjv9"] = COND_ATTRS["bnode_yzjv9"] || {};

  COND_ATTRS["bnode_oblpj"] = COND_ATTRS["bnode_oblpj"] || {};

  COND_ATTRS["bnode_xx3t4"] = COND_ATTRS["bnode_xx3t4"] || {};

  COND_ATTRS["bnode_z7us9"] = COND_ATTRS["bnode_z7us9"] || {};

  COND_ATTRS["bnode_rnhcx"] = COND_ATTRS["bnode_rnhcx"] || {};

  COND_ATTRS["bnode_bvzct"] = COND_ATTRS["bnode_bvzct"] || {};

  COND_ATTRS["bnode_aup4a"] = COND_ATTRS["bnode_aup4a"] || {};

  COND_ATTRS["bnode_8fxhu"] = COND_ATTRS["bnode_8fxhu"] || {};

  COND_ATTRS["bnode_ys22s"] = COND_ATTRS["bnode_ys22s"] || {};

  COND_ATTRS["bnode_0vula"] = COND_ATTRS["bnode_0vula"] || {};

  COND_ATTRS["bnode_scdml"] = COND_ATTRS["bnode_scdml"] || {};

  COND_ATTRS["bnode_h4zoh"] = COND_ATTRS["bnode_h4zoh"] || {};

  // DYNAMIC REACT STATES
  const REACT_STATES = {};
  REACT_STATES["M"] = { state: M, set_state: set_M };

  const AS_COMPONENTS = AS.components["comp_bnode_h4zoh"] || {};
  AS_COMPONENTS.REACT_STATES = REACT_STATES;
  AS.components["comp_bnode_h4zoh"] = AS_COMPONENTS;

  // MAPPED DATA
  const MAPPED_DATA = {};

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={(d) => {
        console.error(d);
      }}
    >
      <div
        data_id="bnode_h4zoh"
        name="test"
        op="update_one"
        className="w-full flex flex-col p-4 border rounded border-zinc-200 relative gap-4"
        id={"bnode_h4zoh" + (idx ? "__" + idx : "")}
        onClick={(e) => {
          g_fn.dev.emit_m(e, M, INFO);
        }}
        extra-attr
      >
        <div
          data_id="bnode_8fxhu"
          name="test-data"
          className="flex gap-4 flex-row gap-2"
          id={"bnode_8fxhu" + (idx ? "__" + idx : "")}
          extra-attr
        >
          <div
            data_id="bnode_yzjv9"
            name="CONT-school_logo"
            className="w-full"
            id={"bnode_yzjv9" + (idx ? "__" + idx : "")}
            extra-attr
          >
            <div
              data_id="bnode_xyhxr"
              id={"bnode_xyhxr" + (idx ? "__" + idx : "")}
              extra-attr
            >
              <div
                data_id="bnode_uliti"
                className="block text-gray-700 text-sm font-medium mb-2 capitalize"
                id={"bnode_uliti" + (idx ? "__" + idx : "")}
                extra-attr
              >
                Upload school logo
              </div>

              <div
                data_id="bnode_byc8a"
                name="school_logo"
                className="flex flex-col  w-full gap-6 "
                id={"bnode_byc8a" + (idx ? "__" + idx : "")}
                extra-attr
              >
                <div
                  data_id="bnode_febi1"
                  name="cont"
                  className="w-full flex flex-col md:flex-row items-center justify-center gap-4"
                  id={"bnode_febi1" + (idx ? "__" + idx : "")}
                  extra-attr
                >
                  <div
                    data_id="bnode_a0w1h"
                    name="image"
                    style={{ backgroundImage: `url("${M.school_logo?.url}")` }}
                    className="w-32 h-32 bg-gray-200 bg-center bg-cover rounded-md"
                    id={"bnode_a0w1h" + (idx ? "__" + idx : "")}
                    extra-attr
                  ></div>

                  <div
                    data_id="bnode_96u2d"
                    className="flex flex-col md:flex-col items-center"
                    id={"bnode_96u2d" + (idx ? "__" + idx : "")}
                    extra-attr
                  >
                    <input
                      data_id="bnode_5rma8"
                      name="school_logo"
                      type="file"
                      placeholder="school_logo"
                      action="oninput-update-m-file"
                      className="block w-full text-sm text-[var(--neutral-500)] file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-[var(--primary-100)] file:text-[var(--primary-700)] hover:file:bg-[var(--primary-100)] p-1 rounded-full bg-[var(--neutral-100)] "
                      id={"bnode_5rma8" + (idx ? "__" + idx : "")}
                      model_id="yvjbe"
                      onInput={(e) => {
                        g_fn.bro_on_input_file(e, INFO);
                      }}
                      extra-attr
                    />

                    <div
                      data_id="bnode_5vj9l"
                      className="text-gray-400 w-fit px-2 text-center"
                      id={"bnode_5vj9l" + (idx ? "__" + idx : "")}
                      extra-attr
                    >
                      or
                    </div>

                    <div
                      data_id="bnode_tagv3"
                      className="relative w-full md:w-fit"
                      id={"bnode_tagv3" + (idx ? "__" + idx : "")}
                      extra-attr
                    >
                      <div
                        data_id="bnode_rd8rh"
                        className="absolute left-2 top-2"
                        id={"bnode_rd8rh" + (idx ? "__" + idx : "")}
                        extra-attr
                      >
                        <svg
                          data_id="bnode_rptv5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          id={"bnode_rptv5" + (idx ? "__" + idx : "")}
                          b_type="icon"
                          extra-attr
                        >
                          <path
                            data_id=""
                            d="M10.0002 13.7031C10.4297 14.2772 10.9776 14.7523 11.6067 15.096C12.2359 15.4398 12.9317 15.6442 13.6468 15.6954C14.362 15.7466 15.0798 15.6435 15.7515 15.3929C16.4233 15.1423 17.0333 14.7502 17.5402 14.2431L20.5402 11.2431C21.451 10.3001 21.955 9.03707 21.9436 7.72608C21.9322 6.4151 21.4063 5.16104 20.4793 4.234C19.5523 3.30696 18.2982 2.78111 16.9872 2.76972C15.6762 2.75833 14.4132 3.2623 13.4702 4.17309L11.7502 5.88309M14.0002 11.7031C13.5707 11.129 13.0228 10.6539 12.3936 10.3102C11.7645 9.96639 11.0687 9.76197 10.3535 9.71076C9.63841 9.65954 8.92061 9.76272 8.24885 10.0133C7.5771 10.2639 6.96709 10.656 6.4602 11.1631L3.4602 14.1631C2.54941 15.1061 2.04544 16.3691 2.05683 17.6801C2.06822 18.9911 2.59407 20.2451 3.52111 21.1722C4.44815 22.0992 5.70221 22.6251 7.01319 22.6365C8.32418 22.6479 9.58719 22.1439 10.5302 21.2331L12.2402 19.5231"
                            stroke="#525252"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            extra-attr
                          ></path>
                        </svg>
                      </div>

                      <input
                        data_id="bnode_e5v5f"
                        name="school_logo"
                        className="w-full p-2 text-base border border-zinc-400 rounded-xl pl-9 h-11"
                        b_type="image"
                        type="text"
                        placeholder="Paste image url"
                        action="oninput-update-m"
                        id={"bnode_e5v5f" + (idx ? "__" + idx : "")}
                        model_id="yvjbe"
                        onInput={(e) => {
                          g_fn.bro_on_input(e, INFO);
                        }}
                        extra-attr
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            data_id="bnode_aup4a"
            name="CONT-header"
            className="w-full"
            id={"bnode_aup4a" + (idx ? "__" + idx : "")}
            extra-attr
          >
            <div
              data_id="bnode_bvzct"
              id={"bnode_bvzct" + (idx ? "__" + idx : "")}
              extra-attr
            >
              <div
                data_id="bnode_oblpj"
                className="block text-gray-700 text-sm font-medium mb-2 capitalize"
                id={"bnode_oblpj" + (idx ? "__" + idx : "")}
                extra-attr
              >
                header
              </div>

              <div
                data_id="bnode_rnhcx"
                name="header"
                className="flex flex-col  w-full gap-6 "
                id={"bnode_rnhcx" + (idx ? "__" + idx : "")}
                extra-attr
              >
                <div
                  data_id="bnode_z7us9"
                  name="cont"
                  className="flex flex-row items-center justify-center w-full gap-4"
                  id={"bnode_z7us9" + (idx ? "__" + idx : "")}
                  extra-attr
                >
                  <textarea
                    data_id="bnode_xx3t4"
                    name="header"
                    placeholder="header"
                    value={M.header}
                    action="oninput-update-m"
                    className="border border-[#222222]/5 hover:border-[#222222]/30 bg-[#222222]/5 px-4 py-1.5 rounded focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full text-lg placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 "
                    id={"bnode_xx3t4" + (idx ? "__" + idx : "")}
                    model_id="yvjbe"
                    onInput={(e) => {
                      g_fn.bro_on_input(e, INFO);
                    }}
                    extra-attr
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data_id="bnode_scdml"
          className="flex-row gap-10 flex justify-center items-center"
          id={"bnode_scdml" + (idx ? "__" + idx : "")}
          extra-attr
        >
          <button
            data_id="bnode_ys22s"
            op="update_one"
            className="w-fit text-white bg-[#FF5994] focus:ring-4 focus:ring-blue-300 p-3 px-10 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center rounded-[8px]"
            id={"bnode_ys22s" + (idx ? "__" + idx : "")}
            onClick={(e) => {
              if (
                window.BROKEN_DEV_VARS &&
                window.BROKEN_DEV_VARS.IS_EDITING_MODE
              )
                return;
              e.preventDefault();
              e.stopPropagation();

              const el = e.currentTarget;
              el.disabled = true;
              el.classList?.add("disabled", "cursor-progress");
              g_fn.bro_update_one(e, M, INFO, props);
              el.disabled = false;
              el.classList?.remove("disabled", "cursor-progress");
            }}
            model_id="yvjbe"
            extra-attr
          >
            ADD
          </button>

          <div
            data_id="bnode_0vula"
            className="text-black bg-[#F3F4F6]  focus:ring-4 focus:ring-blue-300 rounded-[8px]  p-3 px-10 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center text-[#4B5563] cursor-pointer"
            id={"bnode_0vula" + (idx ? "__" + idx : "")}
            action="['go-to-page']"
            page_id="bnode_jj973"
            model_id="yvjbe"
            onClick={(e) => {
              if (
                window.BROKEN_DEV_VARS &&
                window.BROKEN_DEV_VARS.IS_EDITING_MODE
              )
                return;
              e.preventDefault();
              e.stopPropagation();

              const el = e.currentTarget;
              el.disabled = true;
              el.classList?.add("disabled", "cursor-progress");
              g_fn.bro_go_to_page(e, M, INFO, props);
              el.disabled = false;
              el.classList?.remove("disabled", "cursor-progress");
            }}
            extra-attr
          >
            REMOVE
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default comp_bnode_h4zoh;
