import React, { StrictMode, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import "./design.css";
import './index.css';
import prod from './prod';
import App from './App.jsx';
import Utility from "./Utility.jsx";
import { add_sub, remove_subs } from "./utils.jsx";
import g_fn from './g_fn';
const AS = g_fn.AS;
window.g_fn = g_fn;
prod.init(); // prod.init() in main.jsx
// we will use this to edit Login component, utilities and patterns
const SUB_ID = "component-renderer";
const ComponentRenderer = (props) => {
    const [show_utility, set_show_utility] = useState(false);
    useEffect(() => {
        remove_subs(SUB_ID);
        add_sub(SUB_ID, AS.utility_renderer.subscribe((u) => {
            if (!u)
                return set_show_utility(false);
            set_show_utility(true);
        }));
        return () => remove_subs(SUB_ID);
    }, []);
    if (show_utility) {
        return (<Utility />);
    }
    return (<App />);
};
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<ComponentRenderer />);
