import React from "react";
import { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {generateKeyBetween, generateNKeysBetween} from "fractional-indexing"

import { motion, AnimatePresence } from "framer-motion";
import { Reorder } from "framer-motion";

import { fallbackRender, add_sub, remove_subs } from "../utils.jsx";
import { COMP_REG } from "../comp_registry.js";
import g_fn from "../g_fn";

const AS = g_fn.AS;

// @req: keep reference to subs here and remove them on unmount or reload
const SUB_ID = "bnode_36i4u";
const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};

// local code - e.g sub components
const UploadIcon = ()=> (
    <svg
        data_id="bnode_ljhjo"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        data-lucide="upload"
        className="w-20px h-20px"
        id={"bnode_ljhjo"}
        b_type="icon"
        extra-attr
    >
        <path
        data_id=""
        d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"
        extra-attr
        ></path>

        <polyline
        data_id=""
        points="17 8 12 3 7 8"
        extra-attr
        ></polyline>

        <line
        data_id=""
        x1="12"
        x2="12"
        y1="3"
        y2="15"
        extra-attr
        ></line>
    </svg>
)

const comp_bnode_36i4u = (props) => {
  const INFO = {
    model_id: "yvjbe",
    model_name: "test",
    op: "get_selected_one",
    comp_id: "comp_bnode_36i4u",
  };

  // Routes

  // FOR IS_MANY
  let idx = props.idx; // also for protection from error when we might think is many but it's not
  let V = props.V || {}; // for protection from error

  // STATES
  const [rerender, set_rerender] = useState({});
  const [M, set_M] = useState(props.M || {});
  const [PM, set_PM] = useState(props.PM || []);
  const [selected_M, set_selected_M] = useState(null);

  // REFS

  // EFFECTS

  useEffect(() => {
    console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
  }, [rerender]);

  useEffect(() => {
    const deinit = init();

    return () => {
      if (deinit && typeof deinit === "function") deinit();
    };
  }, []);

  useEffect(() => {
    if (EN_BROKEN_COMP_LOGS.MODEL_EFFECT) {
      const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
      console.log(
        "MODEL CHANGED : " + MI + "   => ",
        "model",
        M,
        " props",
        props
      );
    }
  }, [M]);

  useEffect(() => {
    if (!INFO.model_id) return;

    if (query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
    const id = g_fn.get_selected_entity_id(props, INFO);
    if (!id) {
      return console.warn("NO ID FOUND TO UPDATE ENTITY");
    }

    (async () => {
      const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
      console.log("GSTORE GET ONE ENTITY : ", r);
      if (r) set_selected_M(r);
    })();

    const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
      const id = g_fn.get_selected_entity_id(props, INFO);
      if (!id)
        return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
      (async () => {
        const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
        console.log(
          "GSTORE GET ONE ENTITY IN SUBSCRIPTION : ",
          r,
          INFO.model_id,
          INFO.comp_id
        );
        if (r) set_selected_M(r);
      })();
    });

    // On set in GSTORE set state
    const sub2 = AS.GSTORE.subscribe((e)=>{
      const {type, model_id, eid, data} = e;
       if(!data || !data.id) return;
       if(type !== 'set') return;
       if(model_id !== INFO.model_id) return;
       set_M(M=>{
         if(eid !== M.id) return M;
         return data;
       })
   });

    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
    };
  }, []);

  // FUNCTIONS

  const init = () => {
    if (!INFO.model_id) return;

    if (query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
    const id = g_fn.get_selected_entity_id(props, INFO);
    if (!id) {
      console.warn("NO ID FOUND TO GET SELECTED ENTITY");
    }

    if (id) {
      (async () => {
        const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
        console.log("GSTORE GET ONE ENTITY : ", r);
        if (r) set_M(r);
      })();
    }

    const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
      if (!e.eids) return;
      if (e.eids.length !== 1) return;

      const id = e.eids[0];
      if (!id)
        return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
      (async () => {
        const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
        console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r);
        if (r) set_M(r);
      })();
    });


    // On set in GSTORE set state
    const sub2 = AS.GSTORE.subscribe((e)=>{
       const {type, model_id, eid, data} = e;
        if(!data || !data.id) return;
        if(type !== 'set') return;
        if(model_id !== INFO.model_id) return;
        set_M(M=>{
          if(eid !== M.id) return M;
          return data;
        })
    });


    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
    };
  };

  const [show_settings_popup, set_show_settings_popup] = useState(false);
  const test_setting_modal = () => {
    set_show_settings_popup(true);
  };
  const close_test_setting_modal = () => {
    set_show_settings_popup(false);
  };


  const update_test_prop = async (name, newValue, oldValue) => {
    const updates = {};
    updates.id = M.id;
    updates.add = {};
    updates.delete = {};

    updates.add[name] = newValue;
    updates.delete[name] = oldValue;

    const g = await g_fn.AS.GSTORE.update_one(INFO.model_id, M.id, updates);
    if (!g || !g.success) {
      return console.warn(
        "Error in update one of test model in get selected one ",
        g
      );
      console.log("update response of test model in get selected one is: ", g);
    }
  }
  const update_test_unpublish = async () => {
    update_test_prop('status', 'Draft', 'Published');
    AS.navigate("bnode_00000");
  }
  const update_test_publish = async () => {
    console.log("M is : ", M);
    const app = g_fn.GET_GC().APP_JSON;
    if (!app) return console.warn("app json not found");
    const test_model = app.models.find((m) => m.name === "test");
    if (!test_model) return console.warn("test model not found");
    const test_entity_id = M.id;
    console.log("test entity id is: ", test_entity_id);
    const g = await g_fn.AS.GSTORE.update_one(test_model.id, test_entity_id, {
      id: test_entity_id,
      add: { status: "Published" },
      delete: { status: "Draft" },
    });
    if (!g || !g.success) {
      return console.warn(
        "Error in update one of test model in get selected one ",
        g
      );
      console.log("update response of test model in get selected one is: ", g);
    }
    AS.navigate("bnode_00000");
  };
  const add_question_onclick = () => {
    console.log("in add question page");
  };
  const [test_points, set_test_points] = useState(0);
  useEffect(() => {
    let total_points = 0;
    console.log(" questions is : ", M);
    if (!M || !M.questions) return;
    if (!M.questions.length) return;
    for (let ques of M.questions) {
      console.log("ques is: ", ques);
      if(ques?.points){
          total_points = total_points + ques?.points;
      }
    }

    set_test_points(total_points);
  }, [M]);



  const generate_findex_after = (id) => {
    const sortedqs = sort_questions(M.questions);

    console.log('sortedqs', sortedqs);

    const idx = sortedqs.findIndex(q => q.id === id);
    if(idx === -1) return null;

    const cobj = sortedqs[idx];
    const nobj = sortedqs[idx + 1];


    const fidxA = cobj?.findex || null;
    const fidxB = nobj?.findex || null;



    if(fidxA >= fidxB){
        console.warn('findex is not in order', fidxA, fidxB, cobj, nobj);
        return null;
    }

    return generateKeyBetween(fidxA, fidxB);
  }

  const add_question = async (data) => {
    console.log("M in generate test ai fn is:", M);

    



    const app = g_fn.GET_GC().APP_JSON;
    if(!app) {
        console.warn('app json not found');
        return;
    }


    const question_model = app.models.find(m => m.name === 'question');
    if (!question_model) {
        console.warn('question model not found');
        return;
    }

    const ques_create = await g_fn.AS.GSTORE.create_one(question_model.id, data);
    if (!ques_create.success) {
        console.warn('errror in question create  ');
        return;
    }

    console.log('ques create one is: ', ques_create);
    const ques_id = ques_create.data?.id;



    const test_model = app.models.find(m => m.name === 'test');
    if (!test_model) {
        console.warn('test model not found');
        return;
    }

    let id_arr = [];
    for(let ques of M.questions){
        id_arr.push(ques.id);
    }
    const test_entity_id = M.id;

    const test_update = await g_fn.AS.GSTORE.update_one(test_model.id, test_entity_id, { id: test_entity_id, add: { 'questions':[ques_id] }, delete: {} });
    if (!test_update?.success) {
        console.warn('errror in test update  ');
        return;
    }
  };

  const add_default_question = async (id) => {
    const data = {
        question: "Question",
        answers: [
          { id: "a0", v: "Option a" },
          { id: "a1", v: "Option b" },
          { id: "a2", v: "Option c" },
          { id: "a3", v: "Option d" },
        ],
        correct_answers: [{ id: "a2", v: "Option c" }],
        tags: [
          { id: "a0", v: "tag1" },
          { id: "a1", v: "tag2" },
        ],
        type: "MCQs",
        points: 0,
    };

    data.findex = generate_findex_after(id);


    await add_question(data);
  }

  const duplicate_question = async (id) => {
    const mid = g_fn.get_model_by_name('question')?.id;
    if(!mid) return;

    const q = await g_fn.AS.GSTORE.get(mid, id);
    if(!q || !q.id) return;

    const data = JSON.parse(JSON.stringify(q));

    // remove id and findex
    delete data.id;
    delete data.findex;


    data.findex = generate_findex_after(id);

    await add_question(data);
  }

  // this function will be used inside question model,
  // this is declared in test model
  window.duplicate_question = duplicate_question;



  const delete_question = async (id) => {
    const updates = {
      id: M.id,
      add: {},
      delete: {
        questions: [id],
      },
    }
    const g = await g_fn.AS.GSTORE.update_one(INFO.model_id, M.id, updates);
  }
  window.delete_question = delete_question;



    const sort_questions = (qids) => {
        // questions can be array or object or array os ids
        const mid = g_fn.get_model_by_name('question')?.id;
        if(!mid) return qids;

        const question_objs = qids.map(q=>{
            if(typeof(q) === "object" && q.id){
                return q;
            }
            else if(typeof(q) === 'string'){
                const o = g_fn.AS.GSTORE.get(mid, q);
                if(o && o.id) return o;
            }
        }).filter(q=>q);


        const sorted = question_objs.sort((a, b) => {
            if (a.findex > b.findex) return 1;
            if (a.findex < b.findex) return -1;
            return 0;
        });

        return sorted;
    }

  // STATEMENTS

  INFO.set_M = set_M;
  INFO.on_created = props.on_created || props.INFO?.on_created;
  INFO.on_selected = props.on_selected || props.INFO?.on_selected;
  const relation = null;
  const query = null;

  // CONDITIONALS ATTRS
  const COND_ATTRS = {};

  COND_ATTRS["bnode_ziu7h"] = COND_ATTRS["bnode_ziu7h"] || {};

  COND_ATTRS["bnode_jsz9a"] = COND_ATTRS["bnode_jsz9a"] || {};

  COND_ATTRS["bnode_zcdp3"] = COND_ATTRS["bnode_zcdp3"] || {};

  COND_ATTRS["bnode_dxsxm"] = COND_ATTRS["bnode_dxsxm"] || {};

  COND_ATTRS["bnode_gddp5"] = COND_ATTRS["bnode_gddp5"] || {};

  COND_ATTRS["bnode_enijr"] = COND_ATTRS["bnode_enijr"] || {};

  COND_ATTRS["bnode_e30uo"] = COND_ATTRS["bnode_e30uo"] || {};

  COND_ATTRS["bnode_42n4w"] = COND_ATTRS["bnode_42n4w"] || {};

  COND_ATTRS["bnode_5cb3g"] = COND_ATTRS["bnode_5cb3g"] || {};

  COND_ATTRS["bnode_vep2d"] = COND_ATTRS["bnode_vep2d"] || {};

  COND_ATTRS["bnode_b9v7c"] = COND_ATTRS["bnode_b9v7c"] || {};

  COND_ATTRS["bnode_w3ra7"] = COND_ATTRS["bnode_w3ra7"] || {};

  COND_ATTRS["bnode_lrpn4"] = COND_ATTRS["bnode_lrpn4"] || {};

  COND_ATTRS["bnode_q1aie"] = COND_ATTRS["bnode_q1aie"] || {};

  COND_ATTRS["bnode_e9z9i"] = COND_ATTRS["bnode_e9z9i"] || {};

  COND_ATTRS["bnode_fyit0"] = COND_ATTRS["bnode_fyit0"] || {};

  COND_ATTRS["bnode_df8lz"] = COND_ATTRS["bnode_df8lz"] || {};

  COND_ATTRS["bnode_zng7v"] = COND_ATTRS["bnode_zng7v"] || {};

  COND_ATTRS["bnode_4itvd"] = COND_ATTRS["bnode_4itvd"] || {};

  COND_ATTRS["bnode_pxtdt"] = COND_ATTRS["bnode_pxtdt"] || {};

  COND_ATTRS["bnode_4zaze"] = COND_ATTRS["bnode_4zaze"] || {};

  COND_ATTRS["bnode_9d50x"] = COND_ATTRS["bnode_9d50x"] || {};

  COND_ATTRS["bnode_3mru1"] = COND_ATTRS["bnode_3mru1"] || {};

  COND_ATTRS["bnode_llnxy"] = COND_ATTRS["bnode_llnxy"] || {};

  COND_ATTRS["bnode_j0n7j"] = COND_ATTRS["bnode_j0n7j"] || {};

  COND_ATTRS["bnode_cb3kt"] = COND_ATTRS["bnode_cb3kt"] || {};

  COND_ATTRS["bnode_jsyi2"] = COND_ATTRS["bnode_jsyi2"] || {};

  COND_ATTRS["bnode_wctw4"] = COND_ATTRS["bnode_wctw4"] || {};

  COND_ATTRS["bnode_b66rq"] = COND_ATTRS["bnode_b66rq"] || {};

  COND_ATTRS["bnode_8sqcc"] = COND_ATTRS["bnode_8sqcc"] || {};

  COND_ATTRS["bnode_5y0ly"] = COND_ATTRS["bnode_5y0ly"] || {};

  COND_ATTRS["bnode_xvros"] = COND_ATTRS["bnode_xvros"] || {};

  COND_ATTRS["bnode_zo8r8"] = COND_ATTRS["bnode_zo8r8"] || {};

  COND_ATTRS["bnode_52qjn"] = COND_ATTRS["bnode_52qjn"] || {};

  COND_ATTRS["bnode_jo9gf"] = COND_ATTRS["bnode_jo9gf"] || {};

  COND_ATTRS["bnode_rug2y"] = COND_ATTRS["bnode_rug2y"] || {};

  COND_ATTRS["bnode_8jyx8"] = COND_ATTRS["bnode_8jyx8"] || {};

  COND_ATTRS["bnode_1va3l"] = COND_ATTRS["bnode_1va3l"] || {};

  COND_ATTRS["bnode_w5u4t"] = COND_ATTRS["bnode_w5u4t"] || {};

  COND_ATTRS["bnode_12775"] = COND_ATTRS["bnode_12775"] || {};

  COND_ATTRS["bnode_zqwqa"] = COND_ATTRS["bnode_zqwqa"] || {};

  COND_ATTRS["bnode_z9lw6"] = COND_ATTRS["bnode_z9lw6"] || {};

  COND_ATTRS["bnode_k5whv"] = COND_ATTRS["bnode_k5whv"] || {};

  COND_ATTRS["bnode_em6cm"] = COND_ATTRS["bnode_em6cm"] || {};

  COND_ATTRS["bnode_iw2m4"] = COND_ATTRS["bnode_iw2m4"] || {};

  COND_ATTRS["bnode_a787h"] = COND_ATTRS["bnode_a787h"] || {};

  COND_ATTRS["bnode_v6ztx"] = COND_ATTRS["bnode_v6ztx"] || {};

  COND_ATTRS["bnode_rbx06"] = COND_ATTRS["bnode_rbx06"] || {};

  COND_ATTRS["bnode_56qaz"] = COND_ATTRS["bnode_56qaz"] || {};

  COND_ATTRS["bnode_h7cx7"] = COND_ATTRS["bnode_h7cx7"] || {};

  COND_ATTRS["bnode_3502v"] = COND_ATTRS["bnode_3502v"] || {};

  COND_ATTRS["bnode_q5wql"] = COND_ATTRS["bnode_q5wql"] || {};

  COND_ATTRS["bnode_jcmo1"] = COND_ATTRS["bnode_jcmo1"] || {};

  COND_ATTRS["bnode_91bgm"] = COND_ATTRS["bnode_91bgm"] || {};

  COND_ATTRS["bnode_d6m21"] = COND_ATTRS["bnode_d6m21"] || {};

  COND_ATTRS["bnode_ljhjo"] = COND_ATTRS["bnode_ljhjo"] || {};

  COND_ATTRS["bnode_onfna"] = COND_ATTRS["bnode_onfna"] || {};

  COND_ATTRS["bnode_3us0o"] = COND_ATTRS["bnode_3us0o"] || {};

  COND_ATTRS["bnode_vm5kd"] = COND_ATTRS["bnode_vm5kd"] || {};

  COND_ATTRS["bnode_a57ve"] = COND_ATTRS["bnode_a57ve"] || {};

  COND_ATTRS["bnode_vrgdn"] = COND_ATTRS["bnode_vrgdn"] || {};

  COND_ATTRS["bnode_o88br"] = COND_ATTRS["bnode_o88br"] || {};

  COND_ATTRS["bnode_82phb"] = COND_ATTRS["bnode_82phb"] || {};

  COND_ATTRS["bnode_3p249"] = COND_ATTRS["bnode_3p249"] || {};

  COND_ATTRS["bnode_t78iv"] = COND_ATTRS["bnode_t78iv"] || {};

  COND_ATTRS["bnode_5inv1"] = COND_ATTRS["bnode_5inv1"] || {};

  COND_ATTRS["bnode_pazoy"] = COND_ATTRS["bnode_pazoy"] || {};

  COND_ATTRS["bnode_89n52"] = COND_ATTRS["bnode_89n52"] || {};

  COND_ATTRS["bnode_8zt00"] = COND_ATTRS["bnode_8zt00"] || {};

  COND_ATTRS["bnode_4c495"] = COND_ATTRS["bnode_4c495"] || {};

  COND_ATTRS["bnode_olry0"] = COND_ATTRS["bnode_olry0"] || {};

  COND_ATTRS["bnode_fssyk"] = COND_ATTRS["bnode_fssyk"] || {};

  COND_ATTRS["bnode_5virk"] = COND_ATTRS["bnode_5virk"] || {};

  COND_ATTRS["bnode_upmdo"] = COND_ATTRS["bnode_upmdo"] || {};

  COND_ATTRS["bnode_xkza6"] = COND_ATTRS["bnode_xkza6"] || {};

  COND_ATTRS["bnode_4o5lb"] = COND_ATTRS["bnode_4o5lb"] || {};

  COND_ATTRS["bnode_cmniv"] = COND_ATTRS["bnode_cmniv"] || {};

  COND_ATTRS["bnode_13awy"] = COND_ATTRS["bnode_13awy"] || {};

  COND_ATTRS["bnode_0ssy8"] = COND_ATTRS["bnode_0ssy8"] || {};

  COND_ATTRS["bnode_tjxev"] = COND_ATTRS["bnode_tjxev"] || {};

  COND_ATTRS["bnode_6k28b"] = COND_ATTRS["bnode_6k28b"] || {};

  COND_ATTRS["bnode_4zn4b"] = COND_ATTRS["bnode_4zn4b"] || {};

  COND_ATTRS["bnode_49ace"] = COND_ATTRS["bnode_49ace"] || {};

  COND_ATTRS["bnode_h2sed"] = COND_ATTRS["bnode_h2sed"] || {};

  COND_ATTRS["bnode_q39xv"] = COND_ATTRS["bnode_q39xv"] || {};

  COND_ATTRS["bnode_7mznc"] = COND_ATTRS["bnode_7mznc"] || {};

  COND_ATTRS["bnode_uplvy"] = COND_ATTRS["bnode_uplvy"] || {};

  COND_ATTRS["bnode_i22hf"] = COND_ATTRS["bnode_i22hf"] || {};

  COND_ATTRS["bnode_2lsgq"] = COND_ATTRS["bnode_2lsgq"] || {};

  COND_ATTRS["bnode_ipj3w"] = COND_ATTRS["bnode_ipj3w"] || {};

  COND_ATTRS["bnode_3xxbk"] = COND_ATTRS["bnode_3xxbk"] || {};

  COND_ATTRS["bnode_pd4qo"] = COND_ATTRS["bnode_pd4qo"] || {};

  COND_ATTRS["bnode_q4f04"] = COND_ATTRS["bnode_q4f04"] || {};

  COND_ATTRS["bnode_maypy"] = COND_ATTRS["bnode_maypy"] || {};

  COND_ATTRS["bnode_qruhe"] = COND_ATTRS["bnode_qruhe"] || {};

  COND_ATTRS["bnode_xtvsi"] = COND_ATTRS["bnode_xtvsi"] || {};

  COND_ATTRS["bnode_qzc7h"] = COND_ATTRS["bnode_qzc7h"] || {};

  COND_ATTRS["bnode_e57mh"] = COND_ATTRS["bnode_e57mh"] || {};

  COND_ATTRS["bnode_qiird"] = COND_ATTRS["bnode_qiird"] || {};

  COND_ATTRS["bnode_fos6s"] = COND_ATTRS["bnode_fos6s"] || {};

  COND_ATTRS["bnode_ux5kk"] = COND_ATTRS["bnode_ux5kk"] || {};

  COND_ATTRS["bnode_6qig2"] = COND_ATTRS["bnode_6qig2"] || {};

  COND_ATTRS["bnode_qnrzd"] = COND_ATTRS["bnode_qnrzd"] || {};

  COND_ATTRS["bnode_fdnvy"] = COND_ATTRS["bnode_fdnvy"] || {};

  COND_ATTRS["bnode_b761b"] = COND_ATTRS["bnode_b761b"] || {};

  COND_ATTRS["bnode_mebzh"] = COND_ATTRS["bnode_mebzh"] || {};

  COND_ATTRS["bnode_ngqw4"] = COND_ATTRS["bnode_ngqw4"] || {};

  COND_ATTRS["bnode_sr97r"] = COND_ATTRS["bnode_sr97r"] || {};

  COND_ATTRS["bnode_rcia2"] = COND_ATTRS["bnode_rcia2"] || {};

  COND_ATTRS["bnode_suvpj"] = COND_ATTRS["bnode_suvpj"] || {};

  COND_ATTRS["bnode_mdv3c"] = COND_ATTRS["bnode_mdv3c"] || {};

  COND_ATTRS["bnode_bljcp"] = COND_ATTRS["bnode_bljcp"] || {};

  COND_ATTRS["bnode_9zj4j"] = COND_ATTRS["bnode_9zj4j"] || {};

  COND_ATTRS["bnode_puuod"] = COND_ATTRS["bnode_puuod"] || {};

  COND_ATTRS["bnode_et6ym"] = COND_ATTRS["bnode_et6ym"] || {};

  COND_ATTRS["bnode_36i4u"] = COND_ATTRS["bnode_36i4u"] || {};

  // DYNAMIC REACT STATES
  const REACT_STATES = {};
  REACT_STATES["M"] = { state: M, set_state: set_M };

  const AS_COMPONENTS = AS.components["comp_bnode_36i4u"] || {};
  AS_COMPONENTS.REACT_STATES = REACT_STATES;
  AS.components["comp_bnode_36i4u"] = AS_COMPONENTS;

  // MAPPED DATA
  const MAPPED_DATA = {};


    const [hiddens, set_hiddens] = useState([]); // ids
    const localsearch = (text) => {
        const hidden = [];
        const sortedqs = sort_questions(M.questions); // questions can be array of ids or objects, but we want to search in objects
        sortedqs?.forEach((V, idx) => {
            const haystack = JSON.stringify(V).toLowerCase();
            const needle = text?.toLowerCase();
            if (haystack.includes(needle)) return;
            hidden.push(V.id);
        });
        set_hiddens(hidden);
    }



  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={(d) => {
        console.error(d);
      }}
    >
      <div
        data_id="bnode_36i4u"
        name="test"
        op="get_selected_one"
        className="w-full"
        id={"bnode_36i4u" + (idx ? "__" + idx : "")}
        onClick={(e) => {
          g_fn.dev.emit_m(e, M, INFO);
        }}
        extra-attr
      >
        {selected_M && (
          <div
            data_id="bnode_et6ym"
            name="test-data"
            className="flex flex-col gap-4 justify-center"
            id={"bnode_et6ym" + (idx ? "__" + idx : "")}
            extra-attr
          >
            <div
              data_id="bnode_5inv1"
              id={"bnode_5inv1" + (idx ? "__" + idx : "")}
              className="new-div w-full flex items-center justify-center flex-row"
              name="Quiz settings"
              style={{
                padding: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingTop: "0px",
              }}
              extra-attr
            >
              <div
                data_id="bnode_k5whv"
                className="new-div w-full h-full flex items-center justify-center flex-col"
                id={"bnode_k5whv" + (idx ? "__" + idx : "")}
                name="left"
                extra-attr
              >
                <div
                  data_id="bnode_b66rq"
                  name="CONT-subject"
                  className="w-full  pb-5"
                  id={"bnode_b66rq" + (idx ? "__" + idx : "")}
                  extra-attr
                >
                  <div
                    data_id="bnode_wctw4"
                    id={"bnode_wctw4" + (idx ? "__" + idx : "")}
                    className="flex-row gap-2 text-4xl font-bold font-['Open_Sans'] pb-1 flex justify-start items-center"
                    style={{
                      columnGap: "16px",
                      gap: "16px",
                      rowGap: "16px",
                      webkitColumnGap: "16px",
                    }}
                    extra-attr
                  >
                    <div
                      data_id="bnode_zcdp3"
                      name="subject"
                      className="flex flex-col  w-fit gap-6"
                      id={"bnode_zcdp3" + (idx ? "__" + idx : "")}
                      extra-attr
                    >
                      {M?.name && (
                        <div
                          data_id="bnode_ziu7h"
                          className="text-base font-semibold font-['Open_Sans']"
                          id={"bnode_ziu7h" + (idx ? "__" + idx : "")}
                          textContentVb="{M.name}"
                          extra-attr
                        >
                          {M.name}
                        </div>
                      )}
                      {!M.name && (
                        <span
                          data_id="bnode_jsz9a"
                          id={"bnode_jsz9a" + (idx ? "__" + idx : "")}
                          className=" text-base font-semibold font-['Open_Sans']"
                          textContentVb="{M.subject || ' ' + '  Quiz'}"
                          extra-attr
                        >
                          {M.subject || " " + "  Quiz"}
                        </span>
                      )}
                    </div>

                    <svg
                      data_id="bnode_dxsxm"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      data-lucide="settings"
                      className="w-[24px] h-[24px] cursor-pointer text-sm"
                      id={"bnode_dxsxm" + (idx ? "__" + idx : "")}
                      b_type="icon"
                      onClick={test_setting_modal}
                      extra-attr
                    >
                      <path
                        data_id=""
                        d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"
                        extra-attr
                      ></path>

                      <circle
                        data_id=""
                        cx="12"
                        cy="12"
                        r="3"
                        extra-attr
                      ></circle>
                    </svg>

                    <div
                      data_id="bnode_pxtdt"
                      name="CONT-last_modified"
                      className="new-div w-fit flex items-center justify-center text-base font-normal font-['Open_Sans'] text-[#6B7280] whitespace-nowrap"
                      id={"bnode_pxtdt" + (idx ? "__" + idx : "")}
                      style={{ width: "106px" }}
                      extra-attr
                    >
                      <div
                        data_id="bnode_4itvd"
                        id={"bnode_4itvd" + (idx ? "__" + idx : "")}
                        className="w-fit"
                        extra-attr
                      >
                        <div
                          data_id="bnode_zng7v"
                          name="last_modified"
                          className="flex flex-col w-full gap-6 text-sm font-normal font-['Open_Sans']"
                          id={"bnode_zng7v" + (idx ? "__" + idx : "")}
                          style={{
                            padding: "0px",
                            paddingBottom: "0px",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            paddingTop: "0px",
                          }}
                          extra-attr
                        >
                          {parseInt((Date?.now() - M?.last_modified) / 1000) !==
                            0 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) <
                              60 && (
                              <div
                                data_id="bnode_gddp5"
                                className="new-div w-full flex items-center justify-center"
                                id={"bnode_gddp5" + (idx ? "__" + idx : "")}
                                style={{ width: "129px" }}
                                textContentVb="{'Few seconds ago'}"
                                extra-attr
                              >
                                {"Few seconds ago"}
                              </div>
                            )}
                          {parseInt(
                            (Date?.now() - M?.last_modified) / 3600000
                          ) !== 0 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 3600000
                            ) < 24 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 3600000
                            ) !== 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <span
                                data_id="bnode_enijr"
                                className="text-base font-normal font-['Open_Sans'] text-[#6B7280]"
                                name="HOURS"
                                id={"bnode_enijr" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/3600000) + ' hours ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 3600000
                                ) + " hours ago"}
                              </span>
                            )}
                          {parseInt(
                            (Date?.now() - M?.last_modified) / 3600000
                          ) === 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <div
                                data_id="bnode_e30uo"
                                className="new-div w-full flex items-center justify-center"
                                id={"bnode_e30uo" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/3600000) + ' hour ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 3600000
                                ) + " hour ago"}
                              </div>
                            )}
                          {parseInt((Date?.now() - M?.last_modified) / 60000) <
                            60 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 60000
                            ) !== 0 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 60000
                            ) !== 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <div
                                data_id="bnode_42n4w"
                                className="new-div w-full flex justify-start items-start text-sm font-normal font-['Open_Sans']"
                                name="Minutes"
                                id={"bnode_42n4w" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/60000) + ' minutes ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 60000
                                ) + " minutes ago"}
                              </div>
                            )}
                          {parseInt(
                            (Date?.now() - M?.last_modified) / 60000
                          ) === 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <div
                                data_id="bnode_5cb3g"
                                className="new-div w-full flex justify-start items-start"
                                name="Minutes"
                                id={"bnode_5cb3g" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/60000) + ' minute ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 60000
                                ) + " minute ago"}
                              </div>
                            )}
                          {parseInt(
                            (Date?.now() - M?.last_modified) / 86400000
                          ) !== 0 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 86400000
                            ) <= 7 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 86400000
                            ) !== 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <div
                                data_id="bnode_vep2d"
                                className="new-div w-full flex justify-start items-start"
                                id={"bnode_vep2d" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/86400000) + ' days ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 86400000
                                ) + " days ago"}
                              </div>
                            )}
                          {parseInt(
                            (Date?.now() - M?.last_modified) / 86400000
                          ) === 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <div
                                data_id="bnode_b9v7c"
                                className="new-div w-full flex justify-start items-start"
                                id={"bnode_b9v7c" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/86400000) + ' day ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 86400000
                                ) + " day ago"}
                              </div>
                            )}
                          {parseInt(
                            (Date?.now() - M?.last_modified) / 604800000
                          ) !== 0 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 604800000
                            ) < 4 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 604800000
                            ) !== 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <div
                                data_id="bnode_w3ra7"
                                className="new-div w-full flex justify-start items-start"
                                id={"bnode_w3ra7" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/604800000) + ' weeks ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 604800000
                                ) + " weeks ago"}
                              </div>
                            )}
                          {parseInt(
                            (Date?.now() - M?.last_modified) / 604800000
                          ) !== 0 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 604800000
                            ) === 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <div
                                data_id="bnode_lrpn4"
                                className="new-div w-full flex justify-start items-start"
                                id={"bnode_lrpn4" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/604800000) + ' week ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 604800000
                                ) + " week ago"}
                              </div>
                            )}
                          {parseInt(
                            (Date?.now() - M?.last_modified) / 2419200000
                          ) !== 0 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 2419200000
                            ) < 12 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 2419200000
                            ) !== 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <div
                                data_id="bnode_q1aie"
                                className="new-div w-full flex justify-start items-start"
                                id={"bnode_q1aie" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/2419200000) + ' months ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 2419200000
                                ) + " months ago"}
                              </div>
                            )}
                          {parseInt(
                            (Date?.now() - M?.last_modified) / 2419200000
                          ) === 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <div
                                data_id="bnode_e9z9i"
                                className="new-div w-full flex justify-start items-start"
                                id={"bnode_e9z9i" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/2419200000) + ' month ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 2419200000
                                ) + " month ago"}
                              </div>
                            )}
                          {parseInt(
                            (Date?.now() - M?.last_modified) / 29030400000
                          ) !== 0 &&
                            parseInt(
                              (Date?.now() - M?.last_modified) / 29030400000
                            ) !== 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <div
                                data_id="bnode_fyit0"
                                className="new-div w-full flex justify-start items-start"
                                id={"bnode_fyit0" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/29030400000) + ' years ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 29030400000
                                ) + " years ago"}
                              </div>
                            )}
                          {parseInt(
                            (Date?.now() - M?.last_modified) / 29030400000
                          ) === 1 &&
                            parseInt((Date?.now() - M?.last_modified) / 1000) >
                              60 && (
                              <div
                                data_id="bnode_df8lz"
                                className="new-div w-full flex justify-start items-start"
                                id={"bnode_df8lz" + (idx ? "__" + idx : "")}
                                textContentVb="{parseInt((Date.now()-M.last_modified)/29030400000) + ' year ago'}"
                                extra-attr
                              >
                                {parseInt(
                                  (Date.now() - M.last_modified) / 29030400000
                                ) + " year ago"}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    {show_settings_popup && (
                      <div
                        data_id="bnode_jsyi2"
                        className="w-full fixed z-10 left-0 top-0 overflow-auto bg-black/40 h-full flex  rounded-xl"
                        id={"bnode_jsyi2" + (idx ? "__" + idx : "")}
                        name="settings_popup"
                        extra-attr
                      >
                        <div
                          data_id="bnode_cb3kt"
                          className="bg-white w-[70%]   flex-col flex justify-center  items-center relative gap-4 shadow-md rounded-xl mx-auto  mt-10 h-full"
                          id={"bnode_cb3kt" + (idx ? "__" + idx : "")}
                          extra-attr
                        >
                          <div
                            data_id="bnode_4zaze"
                            className="new-div w-full flex items-center justify-center h-full"
                            id={"bnode_4zaze" + (idx ? "__" + idx : "")}
                            extra-attr
                          >
                            <COMP_REG.comp_bnode_rxvft
                              {...{
                                PM: [...PM, M],
                                on_created: props.on_created || INFO.on_created,
                                on_selected:
                                  props.on_selected || INFO.on_selected,
                                INFO: INFO,
                              }}
                              extra-attr
                            />
                          </div>

                          <div
                            data_id="bnode_3mru1"
                            className="w-[50px] h-[50px] absolute top-2 right-4 text-rose-500 bg-rose-100 hover:bg-rose-500 rounded-full hover:text-white p-0.5 flex justify-center items-center"
                            id={"bnode_3mru1" + (idx ? "__" + idx : "")}
                            onClick={close_test_setting_modal}
                            extra-attr
                          >
                            <svg
                              data_id="bnode_9d50x"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              data-lucide="x"
                              className="w-[30px] h-[30px]"
                              b_type="icon"
                              id={"bnode_9d50x" + (idx ? "__" + idx : "")}
                              extra-attr
                            >
                              <path data_id="" d="M18 6 6 18" extra-attr></path>

                              <path data_id="" d="m6 6 12 12" extra-attr></path>
                            </svg>
                          </div>

                          <fn
                            data_id="bnode_llnxy"
                            name="fn:show_test_setting_modal"
                            id={"bnode_llnxy" + (idx ? "__" + idx : "")}
                            extra-attr
                          ></fn>

                          <fn
                            data_id="bnode_j0n7j"
                            name="fn:close_test_setting_modal"
                            id={"bnode_j0n7j" + (idx ? "__" + idx : "")}
                            extra-attr
                          ></fn>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  data_id="bnode_52qjn"
                  name="CONT-curriculum"
                  className="w-full  "
                  id={"bnode_52qjn" + (idx ? "__" + idx : "")}
                  extra-attr
                >
                  <div
                    data_id="bnode_zo8r8"
                    id={"bnode_zo8r8" + (idx ? "__" + idx : "")}
                    className="flex flex-row gap-1.5 text-lg font-normal font-['Open_Sans']"
                    extra-attr
                  >
                    <div
                      data_id="bnode_8sqcc"
                      className="new-div w-fit flex items-center justify-center text-sm font-normal font-['Open_Sans']"
                      id={"bnode_8sqcc" + (idx ? "__" + idx : "")}
                      extra-attr
                    >
                      🏫
                    </div>

                    <div
                      data_id="bnode_xvros"
                      name="curriculum"
                      className="pb-1 text-sm font-normal font-['Open_Sans']"
                      id={"bnode_xvros" + (idx ? "__" + idx : "")}
                      extra-attr
                    >
                      <span
                        data_id="bnode_5y0ly"
                        id={"bnode_5y0ly" + (idx ? "__" + idx : "")}
                        className="pb-1 text-sm font-normal font-['Open_Sans']"
                        textContentVb="{M.curriculum}"
                        extra-attr
                      >
                        {M.curriculum}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  data_id="bnode_w5u4t"
                  name="CONT-grade"
                  className="w-full  "
                  id={"bnode_w5u4t" + (idx ? "__" + idx : "")}
                  extra-attr
                >
                  <div
                    data_id="bnode_1va3l"
                    id={"bnode_1va3l" + (idx ? "__" + idx : "")}
                    className="flex flex-row gap-1.5 text-lg font-normal font-['Open_Sans']"
                    extra-attr
                  >
                    <div
                      data_id="bnode_jo9gf"
                      className="new-div w-fit flex items-center justify-center text-sm font-normal font-['Open_Sans']"
                      id={"bnode_jo9gf" + (idx ? "__" + idx : "")}
                      name="11th Grade"
                      extra-attr
                    >
                      🎓
                    </div>

                    <div
                      data_id="bnode_8jyx8"
                      name="grade"
                      className="flex flex-col  w-full gap-6 "
                      id={"bnode_8jyx8" + (idx ? "__" + idx : "")}
                      extra-attr
                    >
                      <span
                        data_id="bnode_rug2y"
                        id={"bnode_rug2y" + (idx ? "__" + idx : "")}
                        className="pb-1 text-sm font-normal font-['Open_Sans']"
                        textContentVb="{(M.grade || ' ') + 'th Grade'}"
                        extra-attr
                      >
                        {(M.grade || " ") + "th Grade"}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  data_id="bnode_z9lw6"
                  className="new-div w-full flex flex-row gap-1.5 text-lg font-normal font-['Open_Sans'] pb-1"
                  id={"bnode_z9lw6" + (idx ? "__" + idx : "")}
                  name="subject"
                  extra-attr
                >
                  <div
                    data_id="bnode_12775"
                    className="new-div w-fit flex items-center justify-center text-sm font-normal font-['Open_Sans']"
                    id={"bnode_12775" + (idx ? "__" + idx : "")}
                    extra-attr
                  >
                    📚
                  </div>

                  <div
                    data_id="bnode_zqwqa"
                    className="w-fit flex items-center justify-center text-sm font-normal font-['Open_Sans']"
                    id={"bnode_zqwqa" + (idx ? "__" + idx : "")}
                    textContentVb="{M.subject}"
                    extra-attr
                  >
                    {M.subject}
                  </div>
                </div>
              </div>

              <div
                data_id="bnode_3p249"
                className="h-full w-3/5 flex justify-end items-center flex-col"
                id={"bnode_3p249" + (idx ? "__" + idx : "")}
                name="right"
                style={{
                  columnGap: "80px",
                  gap: "80px",
                  rowGap: "80px",
                  webkitColumnGap: "80px",
                }}
                extra-attr
              >
                <div
                  data_id="bnode_d6m21"
                  className="new-div w-full flex justify-end items-center gap-3"
                  id={"bnode_d6m21" + (idx ? "__" + idx : "")}
                  name="top"
                  extra-attr
                >
                  <button
                    data_id="bnode_iw2m4"
                    type="button"
                    className="text-[#6B7280] hover:bg-[#6B7280] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center border-[#6B7280] border-[1px]"
                    id={"bnode_iw2m4" + (idx ? "__" + idx : "")}
                    name="share"
                    extra-attr
                  >
                    <svg
                      data_id="bnode_em6cm"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      data-lucide="share-2"
                      className="w-20px h-20px"
                      id={"bnode_em6cm" + (idx ? "__" + idx : "")}
                      b_type="icon"
                      extra-attr
                    >
                      <circle
                        data_id=""
                        cx="18"
                        cy="5"
                        r="3"
                        extra-attr
                      ></circle>

                      <circle
                        data_id=""
                        cx="6"
                        cy="12"
                        r="3"
                        extra-attr
                      ></circle>

                      <circle
                        data_id=""
                        cx="18"
                        cy="19"
                        r="3"
                        extra-attr
                      ></circle>

                      <line
                        data_id=""
                        x1="8.59"
                        x2="15.42"
                        y1="13.51"
                        y2="17.49"
                        extra-attr
                      ></line>

                      <line
                        data_id=""
                        x1="15.41"
                        x2="8.59"
                        y1="6.51"
                        y2="10.49"
                        extra-attr
                      ></line>
                    </svg>
                  </button>

                  <button
                    data_id="bnode_v6ztx"
                    type="button"
                    op="delete_one"
                    className="text-[#6B7280] hover:bg-[#6B7280] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center border-[#6B7280] border-[1px]"
                    id={"bnode_v6ztx" + (idx ? "__" + idx : "")}
                    name="delete"
                    onClick={(e) => {
                      if (
                        window.BROKEN_DEV_VARS &&
                        window.BROKEN_DEV_VARS.IS_EDITING_MODE
                      )
                        return;
                      e.preventDefault();
                      e.stopPropagation();

                      const el = e.currentTarget;
                      el.disabled = true;
                      el.classList?.add("disabled", "cursor-progress");
                      g_fn.bro_delete_one(e, M, INFO, props);
                      el.disabled = false;
                      el.classList?.remove("disabled", "cursor-progress");
                    }}
                    model_id="yvjbe"
                    extra-attr
                  >
                    <svg
                      data_id="bnode_a787h"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      data-lucide="trash-2"
                      className="w-20px h-20px"
                      id={"bnode_a787h" + (idx ? "__" + idx : "")}
                      b_type="icon"
                      extra-attr
                    >
                      <path data_id="" d="M3 6h18" extra-attr></path>

                      <path
                        data_id=""
                        d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"
                        extra-attr
                      ></path>

                      <path
                        data_id=""
                        d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"
                        extra-attr
                      ></path>

                      <line
                        data_id=""
                        x1="10"
                        x2="10"
                        y1="11"
                        y2="17"
                        extra-attr
                      ></line>

                      <line
                        data_id=""
                        x1="14"
                        x2="14"
                        y1="11"
                        y2="17"
                        extra-attr
                      ></line>
                    </svg>
                  </button>

                  <button
                    data_id="bnode_h7cx7"
                    type="button"
                    className="text-[#6B7280] hover:bg-[#6B7280] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center border-[#6B7280] border-[1px]"
                    id={"bnode_h7cx7" + (idx ? "__" + idx : "")}
                    name="preview"
                    action="['go-to-page']"
                    page_id="bnode_uqf0i"
                    model_id="yvjbe"
                    onClick={(e) => {
                      if (
                        window.BROKEN_DEV_VARS &&
                        window.BROKEN_DEV_VARS.IS_EDITING_MODE
                      )
                        return;
                      e.preventDefault();
                      e.stopPropagation();

                      const el = e.currentTarget;
                      el.disabled = true;
                      el.classList?.add("disabled", "cursor-progress");
                      g_fn.bro_go_to_page(e, M, INFO, props);
                      el.disabled = false;
                      el.classList?.remove("disabled", "cursor-progress");
                    }}
                    extra-attr
                  >
                    <svg
                      data_id="bnode_rbx06"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      data-lucide="play"
                      className="w-20px h-20px"
                      id={"bnode_rbx06" + (idx ? "__" + idx : "")}
                      b_type="icon"
                      extra-attr
                    >
                      <polygon
                        data_id=""
                        points="5 3 19 12 5 21 5 3"
                        extra-attr
                      ></polygon>
                    </svg>

                    <div
                      data_id="bnode_56qaz"
                      className="new-div w-full flex items-center justify-center font-['Open_Sans'] text-sm font-normal"
                      id={"bnode_56qaz" + (idx ? "__" + idx : "")}
                      extra-attr
                    >
                      Preview
                    </div>
                  </button>

                    {M.status === "Draft" && (
                      <button
                        data_id="bnode_91bgm"
                        type="button"
                        className="text-white bg-[#FF5994]  focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center"
                        id={"bnode_91bgm" + (idx ? "__" + idx : "")}
                        name="publish"
                        onClick={update_test_publish}
                        extra-attr
                      >
                        <div
                          data_id="bnode_q5wql"
                          className="w-6 h-6"
                          b_type="icon"
                          id={"bnode_q5wql" + (idx ? "__" + idx : "")}
                          extra-attr
                        >
                          <svg
                            data_id="bnode_3502v"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            data-lucide="mouse-pointer-2"
                            className="w-full h-full"
                            id={"bnode_3502v" + (idx ? "__" + idx : "")}
                            b_type="icon"
                            extra-attr
                          >
                            <path
                              data_id=""
                              d="m4 4 7.07 17 2.51-7.39L21 11.07z"
                              extra-attr
                            ></path>
                          </svg>
                        </div>

                        <div
                          data_id="bnode_jcmo1"
                          className="new-div w-full flex items-center justify-center font-normal font-['Open_Sans'] text-sm"
                          id={"bnode_jcmo1" + (idx ? "__" + idx : "")}
                          extra-attr
                        >
                          Publish
                        </div>
                      </button>
                    )}
                    
                    {M.status === "Published" && (
                      <button
                        data_id="bnode_91bgm"
                        type="button"
                        className="text-white bg-[#10b981] hover:bg-[#1e7559]  focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center"
                        id={"bnode_91bgm" + (idx ? "__" + idx : "")}
                        name="publish"
                        onClick={update_test_unpublish}
                        extra-attr
                      >
                        <div
                          data_id="bnode_q5wql"
                          className="w-6 h-6"
                          b_type="icon"
                          id={"bnode_q5wql" + (idx ? "__" + idx : "")}
                          extra-attr
                        >
                          <svg
                            data_id="bnode_3502v"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            data-lucide="mouse-pointer-2"
                            className="w-full h-full"
                            id={"bnode_3502v" + (idx ? "__" + idx : "")}
                            b_type="icon"
                            extra-attr
                          >
                            <path
                              data_id=""
                              d="m4 4 7.07 17 2.51-7.39L21 11.07z"
                              extra-attr
                            ></path>
                          </svg>
                        </div>

                        <div
                          data_id="bnode_jcmo1"
                          className="new-div w-full flex items-center justify-center font-normal font-['Open_Sans'] text-sm"
                          id={"bnode_jcmo1" + (idx ? "__" + idx : "")}
                          extra-attr
                        >
                          Unpublish
                        </div>
                      </button>
                    )}
                </div>

                <div
                  data_id="bnode_82phb"
                  className="new-div w-full gap-3 flex justify-end items-center"
                  id={"bnode_82phb" + (idx ? "__" + idx : "")}
                  name="bottom"
                  extra-attr
                >
                  <button
                    data_id="bnode_3us0o"
                    type="button"
                    className="text-[#6B7280] bg-[#F9FAFB] hover:text-white hover:bg-[#6B7280] focus:ring-4 focus:ring-blue-300 rounded-[8px] p-2 gap-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center"
                    id={"bnode_3us0o" + (idx ? "__" + idx : "")}
                    name="docupload"
                    onClick={(e)=>{
                        AS.navigate("bnode_e2ttk");
                    }}
                    extra-attr
                  >
                    
                    <UploadIcon />

                    <div
                      data_id="bnode_onfna"
                      className="w-full h-full font-['Open_Sans'] whitespace-nowrap text-sm font-normal"
                      b_type="icon"
                      id={"bnode_onfna" + (idx ? "__" + idx : "")}
                      extra-attr
                    >
                      Generate Another
                    </div>
                  </button>

                  <button
                    data_id="bnode_o88br"
                    type="button"
                    className="bg-gradient-to-r from-[#FF5994] to-[#3488FD] hover:bg-gradient-to-r hover:from-[#E62368] hover:to-[#1E62C2] p-0.5 rounded-[8px] text-xl text-black hover:text-white group flex font-medium"
                    id={"bnode_o88br" + (idx ? "__" + idx : "")}
                    extra-attr
                  >
                    <div
                      data_id="bnode_vrgdn"
                      className="inline-flex items-center justify-center gap-1 p-2 bg-[#eeeeee] group-hover:bg-gradient-to-r from-[#E62368] to-[#1E62C2] rounded-[8px] flex"
                      id={"bnode_vrgdn" + (idx ? "__" + idx : "")}
                      extra-attr
                    >
                      <div
                        data_id="bnode_vm5kd"
                        className="new-div w-fit flex items-center justify-center text-sm font-normal font-['Open_Sans']"
                        id={"bnode_vm5kd" + (idx ? "__" + idx : "")}
                        extra-attr
                      >
                        ✨
                      </div>

                      <div
                        data_id="bnode_a57ve"
                        className="bg-clip-text text-transparent group-hover:text-white bg-gradient-to-r hover:from-[#E62368] hover:to-[#1E62C2] w-full whitespace-nowrap text-sm font-normal font-['Open_Sans']"
                        id={"bnode_a57ve" + (idx ? "__" + idx : "")}
                        extra-attr
                      >
                        AI Enhance
                      </div>
                    </div>
                  </button>
                </div>
              </div>

              <fn
                data_id="bnode_t78iv"
                name="fn:update_test_publish"
                id={"bnode_t78iv" + (idx ? "__" + idx : "")}
                extra-attr
              ></fn>
            </div>

            <div
              data_id="bnode_qiird"
              name="CONT-questions"
              className="w-full flex flex-col justify-center"
              id={"bnode_qiird" + (idx ? "__" + idx : "")}
              extra-attr
            >


            {/* search questions */}
            <div data_id="bnode_oa8jr" className="new-div w-1/3 flex items-center justify-center" name="left" extra-attr >
                <div data_id="bnode_8kaka" className="flex w-full flex-row relative" id={"bnode_8kaka" + (idx ? "__" + idx : "")} extra-attr >
                    <input data_id="bnode_iun1n" type="text" placeholder="Search from questions" className="w-full rounded-md p-2 px-4 outline outline-2 outline-zinc-200 font-['Open_Sans'] font-normal text-base" id={"bnode_iun1n" + (idx ? "__" + idx : "")} action="['oninput-set-text-filters']" model_id="yvjbe" onInput={(e) => { localsearch(e.target.value) }} extra-attr />
                    <div data_id="bnode_x6pdw" className="absolute right-4 top-2" id={"bnode_x6pdw" + (idx ? "__" + idx : "")} extra-attr >
                        <svg data_id="bnode_c3d1h" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id={"bnode_c3d1h" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >
                            <circle data_id="" cx="11" cy="11" r="8" extra-attr >
                            </circle>
                            <path data_id="" d="m21 21-4.3-4.3" extra-attr >
                            </path>
                        </svg>
                    </div>
                </div>
            </div>




             <div
                data_id="bnode_8zt00"
                className="new-div w-full flex items-center justify-center"
                id={"bnode_8zt00" + (idx ? "__" + idx : "")}
                extra-attr
              >

                
                
                <div
                    data_id="bnode_pazoy"
                    className="new-div w-full font-['Open_Sans'] flex justify-start items-center py-3 text-base font-semibold"
                    id={"bnode_pazoy" + (idx ? "__" + idx : "")}
                    textContentVb="{M.questions?.length + ' Questions'}"
                    extra-attr
                    >
                {M.questions?.length + " Questions"}
                </div>

                <div
                  data_id="bnode_89n52"
                  className="new-div w-fit flex justify-end items-center whitespace-nowrap font-normal font-['Open_Sans'] text-xs"
                  id={"bnode_89n52" + (idx ? "__" + idx : "")}
                  textContentVb="{test_points + ' Points'}"
                  extra-attr
                >
                  {test_points + " Points"}
                </div>
              </div>

              {false && (
                <div
                  data_id="bnode_4zn4b"
                  className="new-div w-full flex items-center justify-center relative group flex-col py-8"
                  id={"bnode_4zn4b" + (idx ? "__" + idx : "")}
                  name="Addquestion"
                  extra-attr
                >
                  <div
                    data_id="bnode_upmdo"
                    className="inset-0 w-full flex items-center justify-center gap-[10px] absolute opacity-100 group-hover:opacity-0 bg-opacity-90 bg-opacity-0 duration-300 cursor-none"
                    id={"bnode_upmdo" + (idx ? "__" + idx : "")}
                    name="Addquestion-static"
                    extra-attr
                  >
                    <div
                      data_id="bnode_4c495"
                      className="h-[1px] w-full flex items-center justify-center bg-[#D1D5DB]"
                      id={"bnode_4c495" + (idx ? "__" + idx : "")}
                      name="left"
                      extra-attr
                    ></div>

                    <div
                      data_id="bnode_fssyk"
                      className="new-div w-fit flex items-center justify-center"
                      id={"bnode_fssyk" + (idx ? "__" + idx : "")}
                      extra-attr
                    >
                      <svg
                        data_id="bnode_olry0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-lucide="plus"
                        className="w-[20px] h-[20px] text-[#6B7280]"
                        id={"bnode_olry0" + (idx ? "__" + idx : "")}
                        b_type="icon"
                        extra-attr
                      >
                        <path data_id="" d="M5 12h14" extra-attr></path>

                        <path data_id="" d="M12 5v14" extra-attr></path>
                      </svg>
                    </div>

                    <div
                      data_id="bnode_5virk"
                      className="h-[1px] w-full flex items-center justify-center bg-[#D1D5DB]"
                      id={"bnode_5virk" + (idx ? "__" + idx : "")}
                      name="right"
                      extra-attr
                    ></div>
                  </div>

                  <div
                    data_id="bnode_tjxev"
                    className="inset-0 w-full flex items-center justify-center gap-[10px] absolute opacity-0 group-hover:opacity-100 bg-opacity-0 bg-opacity-90 duration-300 cursor-pointer"
                    id={"bnode_tjxev" + (idx ? "__" + idx : "")}
                    name="Addquestion"
                    onClick={add_question_onclick}
                    extra-attr
                  >
                    <div
                      data_id="bnode_xkza6"
                      className="h-[1px] w-full flex items-center justify-center bg-[#D1D5DB]"
                      id={"bnode_xkza6" + (idx ? "__" + idx : "")}
                      name="left"
                      extra-attr
                    ></div>

                    <div
                      data_id="bnode_13awy"
                      className="new-div w-fit flex items-center justify-center text-3xl text-[#6B7280] bg-white rounded-full px-3 py-1"
                      id={"bnode_13awy" + (idx ? "__" + idx : "")}
                      extra-attr
                    >
                      <svg
                        data_id="bnode_4o5lb"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-lucide="plus"
                        className="w-[20px] h-[20px]"
                        id={"bnode_4o5lb" + (idx ? "__" + idx : "")}
                        b_type="icon"
                        extra-attr
                      >
                        <path data_id="" d="M5 12h14" extra-attr></path>

                        <path data_id="" d="M12 5v14" extra-attr></path>
                      </svg>

                      <div
                        data_id="bnode_cmniv"
                        className="new-div w-fit flex items-center justify-center text-lg font-normal font-['Open_Sans'] whitespace-nowrap"
                        id={"bnode_cmniv" + (idx ? "__" + idx : "")}
                        extra-attr
                      >
                        Add Question
                      </div>
                    </div>

                    <div
                      data_id="bnode_0ssy8"
                      className="h-[1px] w-full flex items-center justify-center bg-[#D1D5DB]"
                      id={"bnode_0ssy8" + (idx ? "__" + idx : "")}
                      name="right"
                      extra-attr
                    ></div>
                  </div>

                  <fn
                    data_id="bnode_6k28b"
                    name="fn:add_question_onclick"
                    id={"bnode_6k28b" + (idx ? "__" + idx : "")}
                    extra-attr
                  ></fn>
                </div>
              )}


              {M && (
                <Reorder.Group axis="y" onReorder={(e)=>{console.log("re-order", e)}} values={g_fn.safe_array(M.questions)}>
                    {sort_questions(g_fn.safe_array(M.questions)).map(o=>o).map((V, idx) =>(
                        <div
                            data_id="bnode_e57mh"
                            name="questions"
                            style={{display: hiddens.includes(V.id) ? "none" : "block"}}
                            className="flex justify-center items-center cursor-pointer flex-col"
                            id={"bnode_e57mh" + (idx ? "__" + idx : "")}
                            key={V.id}
                            extra-attr
                        >
                            <COMP_REG.comp_bnode_wzto2
                            {...{
                                M: V,
                                PM: [...PM, M],
                                on_created: props.on_created || INFO.on_created,
                                on_selected: props.on_selected || INFO.on_selected,
                                INFO: INFO,
                                id: M?.id,
                                prop_name: "questions",
                                IDX: idx,
                            }}
                            extra-attr
                            />

                            
        
                            <div
                            data_id="bnode_xtvsi"
                            className="new-div w-full flex items-center justify-center pt-16 relative group flex-row"
                            id={"bnode_xtvsi" + (idx ? "__" + idx : "")}
                            name="Addquestion"
                            extra-attr
                            >
                            <div
                                data_id="bnode_i22hf"
                                className="inset-0 w-full flex items-center justify-center gap-[10px] absolute opacity-100 group-hover:opacity-0 bg-opacity-90 bg-opacity-0 duration-300"
                                id={"bnode_i22hf" + (idx ? "__" + idx : "")}
                                name="Addquestion-static"
                                extra-attr
                            >
                                <div
                                data_id="bnode_h2sed"
                                className="h-[1px] w-full flex items-center justify-center bg-[#D1D5DB]"
                                id={"bnode_h2sed" + (idx ? "__" + idx : "")}
                                name="left"
                                extra-attr
                                ></div>
        
                                <div
                                data_id="bnode_7mznc"
                                className="new-div w-fit flex items-center justify-center"
                                id={"bnode_7mznc" + (idx ? "__" + idx : "")}
                                extra-attr
                                >
                                <svg
                                    data_id="bnode_q39xv"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    data-lucide="plus"
                                    className="w-[20px] h-[20px] text-[#6B7280]"
                                    id={"bnode_q39xv" + (idx ? "__" + idx : "")}
                                    b_type="icon"
                                    extra-attr
                                >
                                    <path data_id="" d="M5 12h14" extra-attr></path>
        
                                    <path data_id="" d="M12 5v14" extra-attr></path>
                                </svg>
                                </div>
        
                                <div
                                data_id="bnode_uplvy"
                                className="h-[1px] w-full flex items-center justify-center bg-[#D1D5DB]"
                                id={"bnode_uplvy" + (idx ? "__" + idx : "")}
                                name="right"
                                extra-attr
                                ></div>
                            </div>
        
                            <div
                                data_id="bnode_qruhe"
                                className="inset-0 w-full flex items-center justify-center gap-[10px] absolute opacity-0 group-hover:opacity-100 bg-opacity-0 bg-opacity-90 duration-300"
                                id={"bnode_qruhe" + (idx ? "__" + idx : "")}
                                name="Addquestion"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    add_default_question(V.id);
                                }}
                                extra-attr
                            >
                                <div
                                data_id="bnode_2lsgq"
                                className="h-[1px] w-full flex items-center justify-center bg-[#D1D5DB]"
                                id={"bnode_2lsgq" + (idx ? "__" + idx : "")}
                                name="left"
                                extra-attr
                                ></div>
        
                                <div
                                data_id="bnode_q4f04"
                                className="new-div w-fit flex items-center justify-center text-3xl text-[#6B7280] bg-white rounded-full px-3 py-1"
                                id={"bnode_q4f04" + (idx ? "__" + idx : "")}
                                extra-attr
                                >
                                <div
                                    data_id="bnode_3xxbk"
                                    className="new-div w-fit flex items-center justify-center text-lg font-normal font-['Open_Sans'] whitespace-nowrap"
                                    id={"bnode_3xxbk" + (idx ? "__" + idx : "")}
                                    extra-attr
                                >
                                    Add Question
                                </div>
                                </div>
        
                                <div
                                data_id="bnode_maypy"
                                className="h-[1px] w-full flex items-center justify-center bg-[#D1D5DB]"
                                id={"bnode_maypy" + (idx ? "__" + idx : "")}
                                name="right"
                                extra-attr
                                ></div>
                            </div>
                            </div>
                        </div>
                    ))}
                </Reorder.Group>
              )}



            </div>

            <div
              data_id="bnode_qnrzd"
              name="CONT-difficulty"
              className="w-full  "
              id={"bnode_qnrzd" + (idx ? "__" + idx : "")}
              extra-attr
            >
              <div
                data_id="bnode_6qig2"
                id={"bnode_6qig2" + (idx ? "__" + idx : "")}
                extra-attr
              >
                <div
                  data_id="bnode_ux5kk"
                  name="difficulty"
                  className="flex flex-col  w-full gap-6 "
                  id={"bnode_ux5kk" + (idx ? "__" + idx : "")}
                  extra-attr
                >
                  <span
                    data_id="bnode_fos6s"
                    id={"bnode_fos6s" + (idx ? "__" + idx : "")}
                    textContentVb="{M.difficulty}"
                    extra-attr
                  >
                    {M.difficulty}
                  </span>
                </div>
              </div>
            </div>

            <div
              data_id="bnode_mebzh"
              name="CONT-status"
              className="w-full  "
              id={"bnode_mebzh" + (idx ? "__" + idx : "")}
              extra-attr
            >
              <div
                data_id="bnode_b761b"
                id={"bnode_b761b" + (idx ? "__" + idx : "")}
                extra-attr
              >
                <div
                  data_id="bnode_fdnvy"
                  name="status"
                  className="flex flex-col  w-full gap-6 "
                  id={"bnode_fdnvy" + (idx ? "__" + idx : "")}
                  extra-attr
                ></div>
              </div>
            </div>

            <div
              data_id="bnode_suvpj"
              name="CONT-time"
              className="w-full  "
              id={"bnode_suvpj" + (idx ? "__" + idx : "")}
              extra-attr
            >
              <div
                data_id="bnode_rcia2"
                id={"bnode_rcia2" + (idx ? "__" + idx : "")}
                extra-attr
              >
                <div
                  data_id="bnode_sr97r"
                  name="time"
                  className="flex flex-col w-full gap-6 "
                  id={"bnode_sr97r" + (idx ? "__" + idx : "")}
                  extra-attr
                >
                  <span
                    data_id="bnode_ngqw4"
                    id={"bnode_ngqw4" + (idx ? "__" + idx : "")}
                    textContentVb="{M.time || ''}"
                    extra-attr
                  >
                    {M.time || ""}
                  </span>
                </div>
              </div>
            </div>

            <div
              data_id="bnode_puuod"
              name="CONT-name"
              className="w-full  "
              id={"bnode_puuod" + (idx ? "__" + idx : "")}
              extra-attr
            >
              <div
                data_id="bnode_9zj4j"
                id={"bnode_9zj4j" + (idx ? "__" + idx : "")}
                extra-attr
              >
                <div
                  data_id="bnode_bljcp"
                  name="name"
                  className="flex flex-col w-full gap-6 "
                  id={"bnode_bljcp" + (idx ? "__" + idx : "")}
                  extra-attr
                >
                  <span
                    data_id="bnode_mdv3c"
                    id={"bnode_mdv3c" + (idx ? "__" + idx : "")}
                    textContentVb="{M.name || ''}"
                    extra-attr
                  >
                    {M.name || ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default comp_bnode_36i4u;
