import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_bhyih";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_bhyih = (props)=>{
			const INFO  = {"model_id":"bhfmq","model_name":"user","op":"update_one","comp_id":"comp_bnode_bhyih"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					console.log("PROPS : ", props, INFO, relation);
				},[props]);
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = () => {
				if(!INFO.model_id) return;

				if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
				let id = g_fn.get_selected_entity_id(props, INFO);
				if(!id && !relation) {
					return console.warn("NO ID FOUND TO UPDATE ENTITY");
				}

				if(!id && relation) {

					const parent_M = (props.PM && Array.isArray(props.PM) && props.PM.length) ? props.PM[props.PM.length-1] : null;
					console.log("PM AND PARENT_M : ", props.PM, parent_M);
					if(parent_M) {
						const prop = parent_M[relation.prop_name];
						if(!prop) return console.warn("NO PROP FOUND FROM PARENT TO UPDATE ENTITY");
						if(typeof(prop) === "string") {
							id = prop;
						}
						else {
							id = prop.id;
						}
					}
				}

				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY : ", r);
					if(r) {
						const r_copy = JSON.parse(JSON.stringify(r));
						set_M(r_copy);
					}
				})();

				const subs = [];

				subs.push(AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
					const id = g_fn.get_selected_entity_id(props, INFO);
					if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
					(async () => {
						const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
						console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION -> UPDATE_ONE : ", INFO.model_id, r);
						if(r) {
							const r_copy = JSON.parse(JSON.stringify(r));
							set_M(r_copy);
						}
					})();
				}));

				return () => {
					subs.forEach(sub=>sub.unsubscribe());
				}
			}
		
const [show_prof_upd_loading, set_show_prof_upd_loading] = useState(false);
const profile_update_fn = async () => {
    set_show_prof_upd_loading(true);
  	console.log('inside the profile update fn : ', M);
	const entity_id  = M.id;
	const user_model = g_fn.get_model_by_name('user');
	console.log(' entity id is: ', entity_id);
	const pro_upd = await g_fn.AS.GSTORE.update_one(user_model.id, entity_id, M);
	if(!pro_upd?.success) {
        set_show_prof_upd_loading(false);
		console.warn('error in profile update one');
		return;
	}
    set_show_prof_upd_loading(false);
	AS.navigate("bnode_n381t");
	
}


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_h0why"] = COND_ATTRS["bnode_h0why"] || {};
			

				COND_ATTRS["bnode_x841m"] = COND_ATTRS["bnode_x841m"] || {};
			

				COND_ATTRS["bnode_1baom"] = COND_ATTRS["bnode_1baom"] || {};
			

				COND_ATTRS["bnode_hx2yg"] = COND_ATTRS["bnode_hx2yg"] || {};
			

				COND_ATTRS["bnode_lkab6"] = COND_ATTRS["bnode_lkab6"] || {};
			

				COND_ATTRS["bnode_gk3zz"] = COND_ATTRS["bnode_gk3zz"] || {};
			

				COND_ATTRS["bnode_n9gox"] = COND_ATTRS["bnode_n9gox"] || {};
			

				COND_ATTRS["bnode_j1zql"] = COND_ATTRS["bnode_j1zql"] || {};
			

				COND_ATTRS["bnode_xf6f3"] = COND_ATTRS["bnode_xf6f3"] || {};
			

				COND_ATTRS["bnode_0uqo7"] = COND_ATTRS["bnode_0uqo7"] || {};
			

				COND_ATTRS["bnode_1vp58"] = COND_ATTRS["bnode_1vp58"] || {};
			

				COND_ATTRS["bnode_u7r3p"] = COND_ATTRS["bnode_u7r3p"] || {};
			

				COND_ATTRS["bnode_3gvb6"] = COND_ATTRS["bnode_3gvb6"] || {};
			

				COND_ATTRS["bnode_x6uxg"] = COND_ATTRS["bnode_x6uxg"] || {};
			

				COND_ATTRS["bnode_7xwxb"] = COND_ATTRS["bnode_7xwxb"] || {};
			

				COND_ATTRS["bnode_hyh56"] = COND_ATTRS["bnode_hyh56"] || {};
			

				COND_ATTRS["bnode_owzy4"] = COND_ATTRS["bnode_owzy4"] || {};
			

				COND_ATTRS["bnode_rdnaf"] = COND_ATTRS["bnode_rdnaf"] || {};
			

				COND_ATTRS["bnode_43fik"] = COND_ATTRS["bnode_43fik"] || {};
			

				COND_ATTRS["bnode_5gfkc"] = COND_ATTRS["bnode_5gfkc"] || {};
			

				COND_ATTRS["bnode_ch9x3"] = COND_ATTRS["bnode_ch9x3"] || {};
			

				COND_ATTRS["bnode_hnv8b"] = COND_ATTRS["bnode_hnv8b"] || {};
			

				COND_ATTRS["bnode_9mllk"] = COND_ATTRS["bnode_9mllk"] || {};
			

				COND_ATTRS["bnode_3odr0"] = COND_ATTRS["bnode_3odr0"] || {};
			

				COND_ATTRS["bnode_lzvdd"] = COND_ATTRS["bnode_lzvdd"] || {};
			

				COND_ATTRS["bnode_js3mt"] = COND_ATTRS["bnode_js3mt"] || {};
			

				COND_ATTRS["bnode_6antc"] = COND_ATTRS["bnode_6antc"] || {};
			

				COND_ATTRS["bnode_2qngt"] = COND_ATTRS["bnode_2qngt"] || {};
			

				COND_ATTRS["bnode_m83bv"] = COND_ATTRS["bnode_m83bv"] || {};
			

				COND_ATTRS["bnode_je5rm"] = COND_ATTRS["bnode_je5rm"] || {};
			

				COND_ATTRS["bnode_csg8x"] = COND_ATTRS["bnode_csg8x"] || {};
			

				COND_ATTRS["bnode_1xoxx"] = COND_ATTRS["bnode_1xoxx"] || {};
			

				COND_ATTRS["bnode_robyq"] = COND_ATTRS["bnode_robyq"] || {};
			

				COND_ATTRS["bnode_zjt8w"] = COND_ATTRS["bnode_zjt8w"] || {};
			

				COND_ATTRS["bnode_3u4ml"] = COND_ATTRS["bnode_3u4ml"] || {};
			

				COND_ATTRS["bnode_r79g0"] = COND_ATTRS["bnode_r79g0"] || {};
			

				COND_ATTRS["bnode_cxccw"] = COND_ATTRS["bnode_cxccw"] || {};
			

				COND_ATTRS["bnode_8wiwo"] = COND_ATTRS["bnode_8wiwo"] || {};
			

				COND_ATTRS["bnode_fqx4f"] = COND_ATTRS["bnode_fqx4f"] || {};
			

				COND_ATTRS["bnode_wob7g"] = COND_ATTRS["bnode_wob7g"] || {};
			

				COND_ATTRS["bnode_t54iw"] = COND_ATTRS["bnode_t54iw"] || {};
			

				COND_ATTRS["bnode_3k70x"] = COND_ATTRS["bnode_3k70x"] || {};
			

				COND_ATTRS["bnode_qg054"] = COND_ATTRS["bnode_qg054"] || {};
			

				COND_ATTRS["bnode_tp0mf"] = COND_ATTRS["bnode_tp0mf"] || {};
			

				COND_ATTRS["bnode_3kqye"] = COND_ATTRS["bnode_3kqye"] || {};
			

				COND_ATTRS["bnode_feize"] = COND_ATTRS["bnode_feize"] || {};
			

				COND_ATTRS["bnode_2z42l"] = COND_ATTRS["bnode_2z42l"] || {};
			

				COND_ATTRS["bnode_c7uv6"] = COND_ATTRS["bnode_c7uv6"] || {};
			

				COND_ATTRS["bnode_uadfs"] = COND_ATTRS["bnode_uadfs"] || {};
			

				COND_ATTRS["bnode_nwg3e"] = COND_ATTRS["bnode_nwg3e"] || {};
			

				COND_ATTRS["bnode_1al8l"] = COND_ATTRS["bnode_1al8l"] || {};
			

				COND_ATTRS["bnode_086rz"] = COND_ATTRS["bnode_086rz"] || {};
			

				COND_ATTRS["bnode_gl0nt"] = COND_ATTRS["bnode_gl0nt"] || {};
			

				COND_ATTRS["bnode_5a62d"] = COND_ATTRS["bnode_5a62d"] || {};
			

				COND_ATTRS["bnode_kv9ak"] = COND_ATTRS["bnode_kv9ak"] || {};
			

				COND_ATTRS["bnode_qx71z"] = COND_ATTRS["bnode_qx71z"] || {};
			

				COND_ATTRS["bnode_1rq9o"] = COND_ATTRS["bnode_1rq9o"] || {};
			

				COND_ATTRS["bnode_zo80v"] = COND_ATTRS["bnode_zo80v"] || {};
			

				COND_ATTRS["bnode_iuhrv"] = COND_ATTRS["bnode_iuhrv"] || {};
			

				COND_ATTRS["bnode_dqube"] = COND_ATTRS["bnode_dqube"] || {};
			

				COND_ATTRS["bnode_fjz5o"] = COND_ATTRS["bnode_fjz5o"] || {};
			

				COND_ATTRS["bnode_3ywcf"] = COND_ATTRS["bnode_3ywcf"] || {};
			

				COND_ATTRS["bnode_xhfv7"] = COND_ATTRS["bnode_xhfv7"] || {};
			

				COND_ATTRS["bnode_bhyih"] = COND_ATTRS["bnode_bhyih"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_bhyih"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_bhyih"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_bhyih" name="user" op="update_one" className="w-1/3 flex flex-col" id={"bnode_bhyih" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_iuhrv" name="user-data" className="flex flex-col gap-4" id={"bnode_iuhrv" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_0uqo7" name="CONT-image" className="w-full" id={"bnode_0uqo7" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_xf6f3" id={"bnode_xf6f3" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_j1zql" name="image" className="flex flex-col items-center justify-center" id={"bnode_j1zql" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_n9gox" className="new-div w-fit flex items-center justify-center border-[2px] border-white rounded-full" id={"bnode_n9gox" + (idx?"__"+idx:"")} extra-attr >
                
            <label data_id="bnode_gk3zz" className="flex flex-col items-center justify-center w-[116px] h-[117px] border-2 border-[#9CA3AF] border-dashed rounded-full cursor-pointer bg-white" id={"bnode_gk3zz" + (idx?"__"+idx:"")} extra-attr >
                { M?.image?.url && (<div data_id="bnode_x841m" className="w-full h-full flex justify-center items-center" id={"bnode_x841m" + (idx?"__"+idx:"")} extra-attr >
                <img data_id="bnode_h0why" src={M.image?.url} id={"bnode_h0why" + (idx?"__"+idx:"")} className="w-[116px] h-[117px] rounded-full" extra-attr />
            </div>) }
{ !M.image?.url && (<div data_id="bnode_hx2yg" className="flex flex-col items-center justify-center gap-4" id={"bnode_hx2yg" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_1baom" className="one-img-up-svg w-1/2" width="104" height="95" viewBox="0 0 104 95" fill="none" xmlns="http://www.w3.org/2000/svg" b_type="icon" id={"bnode_1baom" + (idx?"__"+idx:"")} extra-attr >
                
            <path data_id="" d="M27.0844 75.5882C20.1463 75.5882 13.9747 71.1665 11.7336 64.5837L11.5821 64.0876C11.0536 62.3444 10.8322 60.8784 10.8322 59.4116V30L0.318182 64.9328C-1.03406 70.0709 2.04737 75.3978 7.21746 76.8172L74.2334 94.6809C75.0698 94.8965 75.9063 95 76.73 95C81.0462 95 84.9902 92.1486 86.0956 87.9465L90 75.5882H27.0844Z" fill="#5F7FFF" fill-opacity="0.25" extra-attr >
                
            </path>
        

            <path data_id="" d="M104 9.70305V59.2969C104 64.6444 99.657 69 94.325 69H27.675C27.2451 69 26.8152 68.9566 26.4278 68.9131C21.9131 68.3533 18.3874 64.6444 18.0433 60.0732C18 59.8142 18 59.5552 18 59.2969V9.70305C18 4.35563 22.343 0 27.675 0H94.325C99.657 0 104 4.35563 104 9.70305Z" fill="#5F7FFF" fill-opacity="0.25" extra-attr >
                
            </path>
        

            <path data_id="" d="M47 21.5C47 26.1939 43.1946 30 38.5004 30C33.8054 30 30 26.1939 30 21.5C30 16.8061 33.8054 13 38.5004 13C43.1946 13 47 16.8061 47 21.5Z" fill="#FFF2EB" extra-attr >
                
            </path>
        

            <path data_id="" fill-rule="evenodd" clip-rule="evenodd" d="M104 59.2355V47.0402L81.3704 24.2135C78.4447 21.2622 73.6268 21.2622 70.7011 24.2135L52.374 42.7006L49.1042 39.4022C46.1785 36.4517 41.3607 36.4517 38.435 39.4022L18 60.0166C18.3442 64.6168 21.8718 68.3492 26.3887 68.9126C26.7763 68.9563 27.2064 69 27.6365 69H78.4447H94.3202C99.6548 69 104 64.6168 104 59.2355Z" fill="#5F7FFF" fill-opacity="0.25" extra-attr >
                
            </path>
        
            </svg>
        
            </div>) }
<input data_id="bnode_lkab6" name="image" type="file" className="hidden" action="oninput-update-m-file" r_for="dropzone-file" id={"bnode_lkab6" + (idx?"__"+idx:"")} model_id="bhfmq" onInput={(e)=>{g_fn.bro_on_input_file(e, INFO)}} extra-attr />
            </label>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_hyh56" name="CONT-name" className="w-full" id={"bnode_hyh56" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_7xwxb" id={"bnode_7xwxb" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_1vp58" className="block text-black mb-2 capitalize font-normal font-['Open_Sans'] text-sm" id={"bnode_1vp58" + (idx?"__"+idx:"")} extra-attr >
                name
            </div>
        

            <div data_id="bnode_x6uxg" name="name" className="flex flex-col  w-full gap-6 " id={"bnode_x6uxg" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_3gvb6" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_3gvb6" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_u7r3p" name="name" type="text" value={M.name} placeholder="name" action="oninput-update-m" className="bg-white p-5 focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 rounded-[12px] font-normal font-['Open_Sans'] text-sm" id={"bnode_u7r3p" + (idx?"__"+idx:"")} style={{padding:"20px", paddingBottom:"20px", paddingLeft:"20px", paddingRight:"20px", paddingTop:"20px"}} model_id="bhfmq" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_hnv8b" name="CONT-bio" className="w-full" id={"bnode_hnv8b" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_ch9x3" id={"bnode_ch9x3" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_owzy4" className="block text-black mb-2 capitalize font-normal font-['Open_Sans'] text-sm" id={"bnode_owzy4" + (idx?"__"+idx:"")} extra-attr >
                bio
            </div>
        

            <div data_id="bnode_5gfkc" name="bio" className="flex flex-col  w-full gap-6 " id={"bnode_5gfkc" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_43fik" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_43fik" + (idx?"__"+idx:"")} extra-attr >
                
            <textarea data_id="bnode_rdnaf" name="bio" placeholder="bio" value={M.bio} action="oninput-update-m" className="bg-white p-5 focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 rounded-[12px] font-normal font-['Open_Sans'] min-h-30 text-sm" id={"bnode_rdnaf" + (idx?"__"+idx:"")} model_id="bhfmq" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            </textarea>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_2qngt" name="CONT-email" className="w-full" id={"bnode_2qngt" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_6antc" id={"bnode_6antc" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_9mllk" className="block text-black mb-2 capitalize font-normal font-['Open_Sans'] text-sm" id={"bnode_9mllk" + (idx?"__"+idx:"")} extra-attr >
                email
            </div>
        

            <div data_id="bnode_js3mt" name="email" className="flex flex-col  w-full gap-6 " id={"bnode_js3mt" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_lzvdd" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_lzvdd" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_3odr0" name="email" type="email" placeholder="email" value={M.email} action="oninput-update-m" className="bg-white p-5 focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 rounded-[12px] font-normal font-['Open_Sans'] text-sm" id={"bnode_3odr0" + (idx?"__"+idx:"")} model_id="bhfmq" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_cxccw" name="CONT-user_role" className="w-full" id={"bnode_cxccw" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_r79g0" id={"bnode_r79g0" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_m83bv" className="block text-black mb-2 capitalize font-normal font-['Open_Sans'] text-sm" id={"bnode_m83bv" + (idx?"__"+idx:"")} extra-attr >
                Role
            </div>
        

            <div data_id="bnode_3u4ml" name="user_role" className="flex flex-col  w-full gap-6 " id={"bnode_3u4ml" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_zjt8w" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_zjt8w" + (idx?"__"+idx:"")} extra-attr >
                
            <select data_id="bnode_robyq" name="user_role" placeholder="user_role" action="oninput-update-m" className="bg-white p-5 focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 rounded-[12px] font-normal font-['Open_Sans'] text-sm" id={"bnode_robyq" + (idx?"__"+idx:"")} model_id="bhfmq" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            <option data_id="bnode_je5rm" selected="true" id={"bnode_je5rm" + (idx?"__"+idx:"")} extra-attr >
                
            </option>
        

            <option data_id="bnode_csg8x" value="Teacher" selected={M.user_role === 'Teacher'} id={"bnode_csg8x" + (idx?"__"+idx:"")} extra-attr >
                Teacher
            </option>
        

            <option data_id="bnode_1xoxx" value="Student" selected={M.user_role === 'Student'} id={"bnode_1xoxx" + (idx?"__"+idx:"")} extra-attr >
                Student
            </option>
        
            </select>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_qg054" name="CONT-school" className="w-full" id={"bnode_qg054" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_3k70x" id={"bnode_3k70x" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_8wiwo" className="block text-black mb-2 capitalize font-normal font-['Open_Sans'] text-sm" id={"bnode_8wiwo" + (idx?"__"+idx:"")} extra-attr >
                school
            </div>
        

            <div data_id="bnode_t54iw" name="school" className="flex flex-col  w-full gap-6 " id={"bnode_t54iw" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_wob7g" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_wob7g" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_fqx4f" name="school" type="text" value={M.school} placeholder="school" action="oninput-update-m" className="bg-white p-5 focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 rounded-[12px] font-normal font-['Open_Sans'] text-sm" id={"bnode_fqx4f" + (idx?"__"+idx:"")} model_id="bhfmq" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_zo80v" name="CONT-subject" className="w-full" id={"bnode_zo80v" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_1rq9o" id={"bnode_1rq9o" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_tp0mf" className="block text-black mb-2 capitalize font-normal font-['Open_Sans'] text-sm" id={"bnode_tp0mf" + (idx?"__"+idx:"")} extra-attr >
                subject
            </div>
        

            <div data_id="bnode_qx71z" name="subject" className="flex flex-col  w-full gap-6 " id={"bnode_qx71z" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_kv9ak" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_kv9ak" + (idx?"__"+idx:"")} extra-attr >
                
            <select data_id="bnode_5a62d" name="subject" placeholder="subject" action="oninput-update-m" className="bg-white p-5 focus:border-[var(--primary-400)] focus:border-1 focus:bg-white focus:outline-none w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 rounded-[12px] font-normal font-['Open_Sans'] text-sm" id={"bnode_5a62d" + (idx?"__"+idx:"")} model_id="bhfmq" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            <option data_id="bnode_3kqye" selected="true" id={"bnode_3kqye" + (idx?"__"+idx:"")} extra-attr >
                
            </option>
        

            <option data_id="bnode_feize" value="Mathematics" selected={M.subject === 'Mathematics'} id={"bnode_feize" + (idx?"__"+idx:"")} extra-attr >
                Mathematics
            </option>
        

            <option data_id="bnode_2z42l" value="Physics" selected={M.subject === 'Physics'} id={"bnode_2z42l" + (idx?"__"+idx:"")} extra-attr >
                Physics
            </option>
        

            <option data_id="bnode_c7uv6" value="Biology" selected={M.subject === 'Biology'} id={"bnode_c7uv6" + (idx?"__"+idx:"")} extra-attr >
                Biology
            </option>
        

            <option data_id="bnode_uadfs" value="Chemistry" selected={M.subject === 'Chemistry'} id={"bnode_uadfs" + (idx?"__"+idx:"")} extra-attr >
                Chemistry
            </option>
        

            <option data_id="bnode_nwg3e" value="Social Science" selected={M.subject === 'Social Science'} id={"bnode_nwg3e" + (idx?"__"+idx:"")} extra-attr >
                Social Science
            </option>
        

            <option data_id="bnode_1al8l" value="English" selected={M.subject === 'English'} id={"bnode_1al8l" + (idx?"__"+idx:"")} extra-attr >
                English
            </option>
        

            <option data_id="bnode_086rz" value="Civics" selected={M.subject === 'Civics'} id={"bnode_086rz" + (idx?"__"+idx:"")} extra-attr >
                Civics
            </option>
        

            <option data_id="bnode_gl0nt" value="History" selected={M.subject === 'History'} id={"bnode_gl0nt" + (idx?"__"+idx:"")} extra-attr >
                History
            </option>
        
            </select>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_xhfv7" className="flex flex-row gap-4  py-4 w-2/3" id={"bnode_xhfv7" + (idx?"__"+idx:"")} extra-attr >
                
            <button data_id="bnode_dqube" className="bg-[#FF5994] text-white px-3 py-2 rounded-lg w-full cursor-pointer text-base font-normal" id={"bnode_dqube" + (idx?"__"+idx:"")} onClick={profile_update_fn} extra-attr >
                SAVE
            </button>
        

            <div data_id="bnode_fjz5o" className="w-full flex items-center justify-center px-3 py-2 rounded-lg border border-[#FF5994] text-[#FF5994] cursor-pointer text-base font-normal" id={"bnode_fjz5o" + (idx?"__"+idx:"")} action="['go-to-page']" page_id="bnode_n381t" model_id="bhfmq" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_go_to_page(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                CANCEL
            </div>
        

            <fn data_id="bnode_3ywcf" name="fn:profile_update_fn" id={"bnode_3ywcf" + (idx?"__"+idx:"")} extra-attr >
                
            </fn>
        
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_bhyih;