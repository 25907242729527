
import React, 
{ useEffect, useRef, useState    } from "react";
import { ErrorBoundary                  } from "react-error-boundary";

import { fallbackRender,
 add_sub,
 remove_subs
}                                         from "../utils.jsx"; 
import {COMP_REG}                         from "../comp_registry.js";
import g_fn                               from '../g_fn';


const AS    = g_fn.AS;



const COMP_BNODE_0RVYP = (props)=>{
const INFO  = {"comp_id":"comp_bnode_0rvyp","model_id":"yvjbe","model_name":"test","op":"create_one"};


const {set_parent_M, prop_name, idx} = props;
const [M, set_M] = useState(props.M || {});
const [PM, set_PM] = useState(props.PM || []);


// const idx = props.idx;   // also for protection from error when we might think is many but it's not
const V = props.V || {};    // for protection from error




useEffect(()=>{  set_M(props.M || {})  }, [props.M]); // when we want to reset the value from parent


const [show_selector, set_show_selector] = useState(props.show_selector || false);
const on_selected = (e, S)=>{
set_M(S || {});
g_fn.on_input_relation(e, S, set_parent_M, prop_name, idx);
set_show_selector(props.show_selector || false); // if you want it to be alway shown pass show_selector={true}
}
const on_created = on_selected;

INFO.on_selected    = on_selected;
INFO.on_created     = on_created;


const toggle_selector = (e)=>{
set_show_selector(s=>!s);
}



return (
 
<div data_id="bnode_0rvyp" name="questions" id={"bnode_0rvyp" + (idx?"__"+idx:"")} extra-attr >
 
<selected data_id="bnode_ufu4b" id={"bnode_ufu4b" + (idx?"__"+idx:"")} extra-attr >
 
<div data_id="bnode_1gd2b" className="text-[var(--primary-500)] " id={"bnode_1gd2b" + (idx?"__"+idx:"")} textContentVb="{M[props.idx].name || ''  }" extra-attr >
 {M[props.idx].name || ''  }
</div>

</selected>


<extra-statements data_id="bnode_3xh00" name="selection-logic" id={"bnode_3xh00" + (idx?"__"+idx:"")} extra-attr >
 
</extra-statements>


<selector data_id="bnode_twvz5" id={"bnode_twvz5" + (idx?"__"+idx:"")} extra-attr >
 
<div data_id="bnode_pkryq" className="flex flex-col p-2" id={"bnode_pkryq" + (idx?"__"+idx:"")} extra-attr >
 < COMP_REG.comp_bnode_ltmqc {...{M: V, PM:[...PM, M], on_created: props.on_created || INFO.on_created, on_selected: props.on_selected || INFO.on_selected, INFO:INFO, id : M?.id, prop_name:"questions", IDX:idx, }} extra-attr />
</div>

</selector>

</div>

)
}

export default COMP_BNODE_0RVYP;
