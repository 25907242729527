import React, { useEffect, useState } from "react";
import g_fn from "./g_fn.js";
// @ts-ignore
import { COMP_REG } from "./comp_registry.js";
COMP_REG;
const MobileOtp = (props) => {
    let idx = props.idx; // for node it's required
    const app_id = g_fn.AS.app.id;
    const app_name = g_fn.AS.app.name;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [attempts, setAttempts] = useState(5);
    const [hash, setHash] = useState('');
    const [resendDisabled, setResendDisabled] = useState(false);
    const [currentStep, setCurrentStep] = useState('phone'); // 'phone' or 'otp'
    const otp_login = g_fn.get_otp_login();
    const handleSendOtp = async () => {
        const errors = [];
        // Validate phone number (assuming 10 digits for simplicity)
        if (phoneNumber.length !== 10) {
            g_fn.feedback('Invalid phone number. Please enter a 10-digit phone number.', "warn");
            return;
        }
        setCurrentStep('otp');
        const res = await g_fn.bro_otp_create(phoneNumber, app_name, app_id, props.role);
        if (!res.success) {
            g_fn.feedback(`Error: ${res.errors}`, "error");
            return;
        }
        // FOR OFFLINE MODULE, ON FIRST HIT, THE TOKEN IS SENT
        if (res.data.token) {
            return g_fn.set_token_after_login(res);
        }
        setHash(res.data.hash);
        g_fn.feedback("OTP Sent!", "log");
        setResendDisabled(true);
        // Simulate a delay before allowing resend
        setTimeout(() => {
            setResendDisabled(false);
        }, 5000); // 5000 milliseconds (5 seconds) delay before allowing resend
    };
    const handleVerifyOtp = async () => {
        const errors = [];
        // Send to API
        const res = await g_fn.bro_otp_verify(phoneNumber, otp, hash, app_id, props.role);
        if (!res.success) {
            g_fn.feedback(`Errors: ${res.errors}`, "warn");
            const curr_attempts = attempts - 1;
            setAttempts(curr_attempts);
            if (curr_attempts === 0) {
                g_fn.feedback('Maximum attempts reached. Please try again later.', "error");
                // Reset values after maximum attempts reached
                setOtp('');
                setAttempts(5);
                setCurrentStep('phone'); // Move back to asking for phone number
            }
            else {
                g_fn.feedback(`Incorrect OTP. ${curr_attempts} attempts left.`, "error");
            }
            // Change hash
            setHash(res.data.hash);
            return;
        }
        // Login to system
        setOtp('');
        setAttempts(5);
        setCurrentStep('phone'); // Move back to asking for phone number
    };
    useEffect(() => {
        // Disable the "Resend OTP" button if attempts are exhausted
        if (attempts === 0) {
            setResendDisabled(true);
        }
    }, [attempts]);
    return (<div className={`w-full mx-auto ${otp_login ? "" : "hidden"}`}>
            {/* <h2 className="text-2xl font-bold mb-4 text-center">Mobile OTP Verification</h2> */}

            {currentStep === 'phone' && (<div className="mb-4">
                    <label htmlFor="phoneNumber" className="block text-gray-700 text-sm font-bold mb-2">
                        Enter Phone Number:
                    </label>
                    <input type="text" id="phoneNumber" name="phoneNumber" placeholder="Enter 10-digit phone number" value={phoneNumber} maxLength={10} onChange={(e) => {
                // Only allow numbers
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value))
                    setPhoneNumber(e.target.value);
            }} className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-md"/>
                </div>)}

            {currentStep === 'otp' && (<div className="mb-4">
                    <label htmlFor="otp" className="block text-gray-700 text-sm font-bold mb-2">
                        Enter OTP:
                    </label>
                    <input type="text" id="otp" name="otp" value={otp} onChange={(e) => setOtp(e.target.value)} className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-md"/>
                </div>)}

            {currentStep === 'otp' && (<div className="mb-4">
                    <p className={`text-red-500 ${attempts === 0 ? 'font-bold' : ''}`}>
                        {attempts === 0 ? 'Maximum attempts reached. Please try again later.' : ''}
                        {attempts > 0 && `Attempts left: ${attempts}`}
                    </p>
                </div>)}

            <div className="flex items-center justify-center mb-4">
                {currentStep === 'phone' && (<div className="flex flex-col items-center justify-center w-80 mx-auto" onClick={handleSendOtp}>
                        <button className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center">
                            Send OTP
                        </button>
                    </div>)}
                {currentStep === 'otp' && (<div className="flex flex-col items-center justify-center w-80 mx-auto" onClick={handleVerifyOtp}>
                        <button className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center">
                            Verify OTP
                        </button>
                    </div>)}
            </div>
        </div>);
};
const Login = (props) => {
    let idx = props.idx; // for node it's required
    const AS = g_fn.AS;
    const [user, set_user] = useState({ email: "" });
    const [roles, set_roles] = useState([]);
    const [selected_role, set_selected_role] = useState("");
    const [mail_sending, set_mail_sending] = useState(false);
    const [mail_sent, set_mail_sent] = useState(false);
    useEffect(() => {
        const roles = g_fn.get_roles();
        if (!roles)
            return;
        set_roles(roles);
        set_selected_role(roles[0]);
    }, []);
    // if already logged in, and path is /login redirect to home
    const check_if_logged_in = () => {
        const path = window.location.pathname;
        if (path === "/login") {
            if (AS.user) {
                AS.navigate("/");
            }
        }
        else if (path.endsWith("/login")) { //  /appid/uiid/login
            if (AS.user) {
                AS.navigate(""); //
            }
        }
    };
    useEffect(() => {
        check_if_logged_in();
        const sub = AS.rx_user.subscribe(user => {
            check_if_logged_in();
        });
        return () => sub.unsubscribe();
    }, []);
    const PreviewAs = () => {
        if (!g_fn.AS.is_dev)
            return null;
        return (<div className="w-full inline-flex items-center">
                <div className="w-32">
                    Preview as:
                </div>
                <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={selected_role} onChange={(e) => set_selected_role(e.target.value)}>
                    {roles.map(r => {
                return (<option key={r} value={r}>{r}</option>);
            })}
                </select>
            </div>);
    };
    const on_email_input = (e) => {
        if (AS.is_dev_edit_mode)
            return;
        g_fn.bro_on_input(e, { set_M: set_user });
    };
    const on_email_input_v2 = (e) => {
        if (AS.is_dev_edit_mode)
            return;
        g_fn.bro_on_input(e, { set_M: set_user });
    };
    const on_email_send = async (e) => {
        if (AS.is_dev_edit_mode)
            return;
        set_mail_sending(true);
        const s = await g_fn.bro_login(e, user, { set_M: set_user }, selected_role);
        set_mail_sending(false);
        set_mail_sent(s || false);
    };
    const on_email_send_v2 = async (e) => {
        if (AS.is_dev_edit_mode)
            return;
        set_mail_sending(true);
        const s = await g_fn.bro_login(e, user, { set_M: set_user }, selected_role);
        set_mail_sending(false);
        set_mail_sent(s || false);
    };
    const EmailSending = () => {
        return (<div>
                {mail_sending ? (<svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.59c0 27.615-22.386 50.001-50 50.001s-50-22.386-50-50 22.386-50 50-50 50 22.386 50 50Zm-90.919 0c0 22.6 18.32 40.92 40.919 40.92 22.599 0 40.919-18.32 40.919-40.92 0-22.598-18.32-40.918-40.919-40.918-22.599 0-40.919 18.32-40.919 40.919Z" fill="currentColor"/><path d="M93.968 39.04c2.425-.636 3.894-3.128 3.04-5.486A50 50 0 0 0 41.735 1.279c-2.474.414-3.922 2.919-3.285 5.344.637 2.426 3.12 3.849 5.6 3.484a40.916 40.916 0 0 1 44.131 25.769c.902 2.34 3.361 3.802 5.787 3.165Z" fill="currentFill"/></svg>) : (<span className="h-6 flex items-center">{mail_sent ? "Login" : "Mail me a magic link"}</span>)}
            </div>);
    };
    const on_google_login = (e) => {
        if (AS.is_dev_edit_mode)
            return;
        g_fn.bro_google_login(selected_role);
    };
    const on_microsoft_login = (e) => {
        if (AS.is_dev_edit_mode)
            return;
        g_fn.bro_microsoft_login(selected_role);
    };
    const on_github_login = (e) => {
        if (AS.is_dev_edit_mode)
            return;
        g_fn.bro_github_login(selected_role);
    };
    const on_linkedin_login = (e) => {
        if (AS.is_dev_edit_mode)
            return;
        g_fn.bro_linkedin_login(selected_role);
    };
    const on_twitter_login = (e) => {
        if (AS.is_dev_edit_mode)
            return;
        g_fn.bro_twitter_login(selected_role);
    };
    // replace this to false if custom login is not available
    const CUSTOM_LOGIN = true;
    if (CUSTOM_LOGIN)
        return (
        // @ts-ignore
        
            <div data_id="bnode_wqxy6" id={"bnode_wqxy6" + (idx?"__"+idx:"")} className=" h-screen" extra-attr >
                
            <div data_id="bnode_t7ak6" className="justify-center items-center bg-white py-5 flex flex-row-reverse w-full bg-[#eeeeee] gap-16 py-16 h-screen" id={"bnode_t7ak6" + (idx?"__"+idx:"")} style={{padding:"64px", paddingBottom:"64px", paddingLeft:"64px", paddingRight:"64px", paddingTop:"64px"}} extra-attr >
                
            <div data_id="bnode_907nz" className="new-div w-1/2 flex items-center justify-center" id={"bnode_907nz" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <div data_id="bnode_lm5ki" className="bg-[#333333] rounded px-8 pt-6 pb-8 w-fit gap-6 flex flex-col rounded-[25px] shadow-2xl" id={"bnode_lm5ki" + (idx?"__"+idx:"")} name="login" style={{columnGap:"32px", gap:"32px", margin:"0px", marginBottom:"0px", marginLeft:"0px", marginRight:"0px", marginTop:"0px", padding:"64px", paddingBottom:"64px", paddingLeft:"64px", paddingRight:"64px", paddingTop:"64px", rowGap:"32px", webkitColumnGap:"32px"}} extra-attr >
                
            <div data_id="bnode_4q60s" className="new-div w-full flex items-center justify-center" id={"bnode_4q60s" + (idx?"__"+idx:"")} name="advizo logo" extra-attr >
                
            <svg data_id="bnode_4mynh" width="223" height="59" viewBox="0 0 223 59" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_4mynh" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M215.919 33.4284C215.919 36.3557 215.47 38.9761 214.573 41.2895C213.676 43.5558 212.402 45.468 210.749 47.026C209.144 48.5841 207.208 49.7644 204.942 50.5671C202.675 51.3697 200.15 51.771 197.364 51.771C194.578 51.771 192.052 51.3461 189.786 50.4962C187.52 49.6464 185.56 48.4424 183.908 46.8844C182.303 45.2791 181.052 43.3433 180.154 41.0771C179.257 38.8108 178.809 36.2613 178.809 33.4284C178.809 30.6428 179.257 28.1169 180.154 25.8506C181.052 23.5843 182.303 21.6722 183.908 20.1141C185.56 18.5088 187.52 17.2813 189.786 16.4314C192.052 15.5816 194.578 15.1567 197.364 15.1567C200.15 15.1567 202.675 15.6052 204.942 16.5023C207.208 17.3521 209.144 18.5797 210.749 20.1849C212.402 21.743 213.676 23.6552 214.573 25.9214C215.47 28.1877 215.919 30.69 215.919 33.4284ZM191.132 33.4284C191.132 36.3085 191.675 38.5275 192.761 40.0856C193.894 41.5964 195.452 42.3519 197.435 42.3519C199.418 42.3519 200.929 41.5728 201.967 40.0148C203.053 38.4567 203.596 36.2613 203.596 33.4284C203.596 30.5956 203.053 28.4238 201.967 26.9129C200.881 25.3549 199.347 24.5758 197.364 24.5758C195.381 24.5758 193.846 25.3549 192.761 26.9129C191.675 28.4238 191.132 30.5956 191.132 33.4284Z" fill="#82B6FF" extra-attr >
                
            </path>
        

            <path data_id="" d="M148.095 50.6379C147.34 49.9769 146.726 49.1979 146.254 48.3008C145.829 47.4037 145.617 46.3886 145.617 45.2555C145.617 44.3112 145.829 43.3906 146.254 42.4935C146.726 41.5492 147.293 40.6758 147.954 39.8731L160.135 25.5673H147.104C146.821 25.048 146.537 24.387 146.254 23.5843C146.018 22.7817 145.9 21.9082 145.9 20.964C145.9 19.3115 146.254 18.1311 146.962 17.4229C147.718 16.6675 148.709 16.2898 149.937 16.2898H172.245C173.001 16.9508 173.591 17.7298 174.016 18.6269C174.488 19.5239 174.724 20.539 174.724 21.6722C174.724 22.6165 174.488 23.5607 174.016 24.505C173.591 25.4021 173.048 26.2519 172.387 27.0546L160.489 41.3604H174.37C174.653 41.8797 174.913 42.5407 175.149 43.3433C175.432 44.146 175.574 45.0194 175.574 45.9637C175.574 47.6162 175.196 48.8202 174.441 49.5756C173.732 50.2838 172.765 50.6379 171.537 50.6379H148.095Z" fill="#FF5994" extra-attr >
                
            </path>
        

            <path data_id="" d="M126.62 6.65817C126.62 4.86405 127.211 3.3532 128.391 2.12564C129.619 0.898083 131.224 0.284302 133.207 0.284302C135.19 0.284302 136.771 0.898083 137.952 2.12564C139.179 3.3532 139.793 4.86405 139.793 6.65817C139.793 8.4523 139.179 9.96315 137.952 11.1907C136.771 12.4183 135.19 13.032 133.207 13.032C131.224 13.032 129.619 12.4183 128.391 11.1907C127.211 9.96315 126.62 8.4523 126.62 6.65817ZM139.227 50.5671C138.707 50.6615 137.928 50.7795 136.889 50.9212C135.898 51.11 134.883 51.2045 133.844 51.2045C132.806 51.2045 131.861 51.1336 131.011 50.992C130.209 50.8504 129.524 50.5671 128.958 50.1421C128.391 49.7172 127.942 49.1507 127.612 48.4424C127.329 47.687 127.187 46.7191 127.187 45.5388V16.7147C127.706 16.6203 128.462 16.5023 129.453 16.3606C130.492 16.1718 131.531 16.0773 132.569 16.0773C133.608 16.0773 134.529 16.1482 135.331 16.2898C136.181 16.4314 136.889 16.7147 137.456 17.1397C138.023 17.5646 138.448 18.1548 138.731 18.9102C139.061 19.6184 139.227 20.5627 139.227 21.743V50.5671Z" fill="#FF9668" extra-attr >
                
            </path>
        

            <path data_id="" d="M210.684 47.9662C209.504 49.1937 208.914 50.7046 208.914 52.4987C208.914 54.2928 209.504 55.8037 210.684 57.0312C211.912 58.2588 213.517 58.8726 215.5 58.8726C217.483 58.8726 219.065 58.2588 220.245 57.0312C221.473 55.8037 222.086 54.2928 222.086 52.4987C222.086 50.7046 221.473 49.1937 220.245 47.9662C219.065 46.7386 217.483 46.1248 215.5 46.1248C213.517 46.1248 211.912 46.7386 210.684 47.9662Z" fill="#82B6FF" extra-attr >
                
            </path>
        

            <path data_id="" d="M111.329 49.7172C110.668 50.2365 109.7 50.6615 108.425 50.992C107.15 51.3225 105.71 51.4877 104.105 51.4877C102.264 51.4877 100.658 51.2516 99.2893 50.7795C97.9673 50.3074 97.0466 49.5519 96.5273 48.5132C96.1023 47.7106 95.583 46.6011 94.9692 45.1847C94.4026 43.721 93.7889 42.1158 93.1279 40.3688C92.4669 38.5747 91.7823 36.6626 91.0741 34.6324C90.3658 32.6022 89.6813 30.5956 89.0203 28.6126C88.4065 26.6296 87.8399 24.7174 87.3206 22.8761C86.8012 21.0348 86.3763 19.4059 86.0458 17.9895C86.7068 17.3285 87.5802 16.7619 88.6662 16.2898C89.7993 15.7704 91.0268 15.5107 92.3488 15.5107C94.0013 15.5107 95.3469 15.8648 96.3856 16.5731C97.4715 17.2341 98.2742 18.5088 98.7935 20.3974L101.556 31.233C102.075 33.3576 102.571 35.2934 103.043 37.0403C103.515 38.74 103.869 40.062 104.105 41.0062H104.459C105.356 37.5124 106.301 33.6409 107.292 29.3916C108.284 25.1424 109.181 20.9639 109.983 16.8563C110.786 16.4314 111.683 16.1009 112.674 15.8648C113.713 15.6288 114.705 15.5107 115.649 15.5107C117.301 15.5107 118.694 15.8648 119.827 16.5731C120.96 17.2813 121.527 18.5088 121.527 20.2557C121.527 21.0112 121.338 22.1207 120.96 23.5843C120.63 25.0007 120.181 26.6296 119.615 28.4709C119.095 30.2651 118.458 32.1772 117.703 34.2074C116.994 36.2376 116.263 38.2206 115.507 40.1564C114.752 42.0921 113.996 43.9099 113.241 45.6096C112.533 47.2621 111.895 48.6313 111.329 49.7172Z" fill="#FF5994" extra-attr >
                
            </path>
        

            <path data_id="" d="M45.3283 33.4284C45.3283 30.3123 45.8004 27.6211 46.7447 25.3548C47.689 23.0413 48.9873 21.1292 50.6398 19.6183C52.3395 18.1075 54.2989 16.998 56.518 16.2897C58.7842 15.5343 61.2158 15.1566 63.8125 15.1566C64.7568 15.1566 65.6775 15.251 66.5745 15.4399C67.4716 15.5815 68.2034 15.7704 68.77 16.0065V4.5335C69.2421 4.39185 69.9975 4.25021 71.0362 4.10857C72.0749 3.91972 73.1372 3.82529 74.2232 3.82529C75.2619 3.82529 76.1825 3.89611 76.9852 4.03775C77.835 4.17939 78.5432 4.46267 79.1098 4.8876C79.6764 5.31252 80.1013 5.9027 80.3846 6.65812C80.6679 7.36633 80.8095 8.31061 80.8095 9.49095V43.06C80.8095 45.2791 79.7708 47.0496 77.6934 48.3716C76.3242 49.2686 74.5537 50.0477 72.3818 50.7087C70.21 51.4169 67.7077 51.771 64.8748 51.771C61.8059 51.771 59.0439 51.346 56.5888 50.4962C54.1809 49.6463 52.1271 48.4188 50.4274 46.8135C48.7749 45.2082 47.5001 43.2961 46.6031 41.077C45.7532 38.8108 45.3283 36.2612 45.3283 33.4284ZM65.4414 24.5758C62.8918 24.5758 60.9325 25.3076 59.5633 26.7712C58.194 28.2349 57.5095 30.4539 57.5095 33.4284C57.5095 36.3556 58.1469 38.5747 59.4216 40.0855C60.6964 41.5492 62.5377 42.281 64.9456 42.281C65.7955 42.281 66.5509 42.1629 67.2119 41.9269C67.9201 41.6436 68.4631 41.3367 68.8408 41.0062V25.4256C67.8965 24.8591 66.7634 24.5758 65.4414 24.5758Z" fill="#FF5994" extra-attr >
                
            </path>
        

            <path data_id="" d="M13.024 8.99529C13.8267 8.52314 15.0306 8.07461 16.6359 7.64969C18.2884 7.22476 19.9409 7.0123 21.5933 7.0123C23.954 7.0123 26.0314 7.3664 27.8256 8.07461C29.6669 8.73561 30.8473 9.79792 31.3666 11.2615C32.2165 13.7167 33.1371 16.5731 34.1286 19.8309C35.1201 23.0886 36.1116 26.4644 37.1031 29.9582C38.0946 33.4049 39.0625 36.8279 40.0068 40.2273C40.951 43.5794 41.7773 46.5539 42.4855 49.1507C41.9189 49.8117 41.0691 50.331 39.9359 50.7087C38.8028 51.1337 37.4572 51.3461 35.8991 51.3461C34.766 51.3461 33.7981 51.2517 32.9955 51.0628C32.2401 50.874 31.6027 50.5907 31.0833 50.213C30.6112 49.8353 30.2099 49.3395 29.8794 48.7258C29.5961 48.112 29.3364 47.4038 29.1003 46.6011L27.8256 41.7145H15.007C14.6293 43.1309 14.2516 44.5945 13.8739 46.1054C13.4962 47.6162 13.1184 49.0799 12.7407 50.4963C12.0797 50.7323 11.3715 50.9212 10.6161 51.0628C9.90791 51.2517 9.03446 51.3461 7.99575 51.3461C5.58784 51.3461 3.79371 50.9448 2.61337 50.1422C1.48023 49.2923 0.913666 48.1356 0.913666 46.672C0.913666 46.011 1.00809 45.35 1.19695 44.689C1.3858 44.028 1.59827 43.2489 1.83434 42.3519C2.16484 41.0771 2.61337 39.4954 3.17993 37.6069C3.79371 35.7183 4.45471 33.6881 5.16292 31.5163C5.87113 29.2972 6.62655 27.031 7.42919 24.7175C8.23182 22.404 8.98723 20.2322 9.69544 18.202C10.4037 16.1246 11.041 14.2832 11.6076 12.678C12.2214 11.0727 12.6935 9.84513 13.024 8.99529ZM21.5225 18.1312C20.8615 20.1141 20.1061 22.3332 19.2563 24.7883C18.4536 27.1962 17.7218 29.6514 17.0608 32.1537H25.8426C25.1816 29.6041 24.497 27.1254 23.7888 24.7175C23.1278 22.2624 22.514 20.0669 21.9474 18.1312H21.5225Z" fill="#FF5994" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        

            <PreviewAs data_id="bnode_ke1l3" name="PreviewAs" id={"bnode_ke1l3" + (idx?"__"+idx:"")} extra-attr >
                
            </PreviewAs>
        

            <div data_id="bnode_vcfue" id={"bnode_vcfue" + (idx?"__"+idx:"")} extra-attr >
                
            <label data_id="bnode_sfo5i" htmlfor="email" className="block text-white font-bold mb-2 font-['Open_Sans'] text-xl" id={"bnode_sfo5i" + (idx?"__"+idx:"")} extra-attr >
                LOGIN
            </label>
        
<input data_id="bnode_x1ts4" type="email" name="email" onInput={on_email_input} placeholder="Email Address" className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-md" id={"bnode_x1ts4" + (idx?"__"+idx:"")} extra-attr />
            </div>
        

            <div data_id="bnode_wx1ut" className="flex flex-col items-center justify-center w-80 mx-auto" onClick={on_email_send} id={"bnode_wx1ut" + (idx?"__"+idx:"")} name="login button" extra-attr >
                
            <button data_id="bnode_moxpb" className="flex items-center bg-[#FF5994] rounded-lg shadow-md px-6 py-2 text-white hover:bg-[#F11B67] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center text-xl font-normal font-['Open_Sans']" id={"bnode_moxpb" + (idx?"__"+idx:"")} extra-attr >
                
            <EmailSending data_id="bnode_hh7u6" name="EmailSending" id={"bnode_hh7u6" + (idx?"__"+idx:"")} extra-attr >
                
            </EmailSending>
        
            </button>
        
            </div>
        

            <div data_id="bnode_xyxti" className="new-div w-full flex items-center justify-center text-base font-['Open_Sans'] font-normal text-[#c3c3c3]" id={"bnode_xyxti" + (idx?"__"+idx:"")} extra-attr >
                OR
            </div>
        

            <MobileOtp data_id="bnode_2xa0u" name="MobileOtp" role={selected_role} id={"bnode_2xa0u" + (idx?"__"+idx:"")} extra-attr >
                
            </MobileOtp>
        

            <div data_id="bnode_nocjx" className="flex items-center justify-center w-80 mx-auto" onClick={on_google_login} id={"bnode_nocjx" + (idx?"__"+idx:"")} name="google" extra-attr >
                
            <button data_id="bnode_hftw3" className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center" id={"bnode_hftw3" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_1sv06" className="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="800" height="800" viewBox="-0.5 0 48 48" id={"bnode_1sv06" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <g data_id="" fill="none" fill-rule="evenodd" extra-attr >
                
            <path data_id="" d="M9.827 24c0-1.524.253-2.986.705-4.356l-7.909-6.04A23.456 23.456 0 0 0 .213 24c0 3.737.868 7.26 2.407 10.388l7.905-6.05A13.885 13.885 0 0 1 9.827 24" fill="#FBBC05" extra-attr >
                
            </path>
        

            <path data_id="" d="M23.714 10.133c3.311 0 6.302 1.174 8.652 3.094L39.202 6.4C35.036 2.773 29.695.533 23.714.533a23.43 23.43 0 0 0-21.09 13.071l7.908 6.04a13.849 13.849 0 0 1 13.182-9.51" fill="#EB4335" extra-attr >
                
            </path>
        

            <path data_id="" d="M23.714 37.867a13.849 13.849 0 0 1-13.182-9.51l-7.909 6.038a23.43 23.43 0 0 0 21.09 13.072c5.732 0 11.205-2.036 15.312-5.849l-7.507-5.804c-2.118 1.335-4.786 2.053-7.804 2.053" fill="#34A853" extra-attr >
                
            </path>
        

            <path data_id="" d="M46.145 24c0-1.387-.213-2.88-.534-4.267H23.714V28.8h12.604c-.63 3.091-2.346 5.468-4.8 7.014l7.507 5.804c4.314-4.004 7.12-9.969 7.12-17.618" fill="#4285F4" extra-attr >
                
            </path>
        
            </g>
        
            </svg>
        

            <span data_id="bnode_vf8yg" id={"bnode_vf8yg" + (idx?"__"+idx:"")} className="font-['Open_Sans'] text-xl font-normal text-[#777777]" extra-attr >
                Sign in with Google
            </span>
        
            </button>
        
            </div>
        

            <div data_id="bnode_jzpvl" className="flex items-center justify-center w-80 mx-auto" onClick={on_microsoft_login} id={"bnode_jzpvl" + (idx?"__"+idx:"")} name="microsoft" extra-attr >
                
            <button data_id="bnode_0yk5p" className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center" id={"bnode_0yk5p" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_wt9d0" className="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" id={"bnode_wt9d0" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" fill="#f3f3f3" d="M0 0h23v23H0z" extra-attr >
                
            </path>
        

            <path data_id="" fill="#f35325" d="M1 1h10v10H1z" extra-attr >
                
            </path>
        

            <path data_id="" fill="#81bc06" d="M12 1h10v10H12z" extra-attr >
                
            </path>
        

            <path data_id="" fill="#05a6f0" d="M1 12h10v10H1z" extra-attr >
                
            </path>
        

            <path data_id="" fill="#ffba08" d="M12 12h10v10H12z" extra-attr >
                
            </path>
        
            </svg>
        

            <span data_id="bnode_vld73" id={"bnode_vld73" + (idx?"__"+idx:"")} className=" font-['Open_Sans'] text-xl font-normal text-[#777777]" extra-attr >
                Sign in with Microsoft
            </span>
        
            </button>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_lzl4t" className="new-div w-1/2 flex-col text-5xl font-medium font-['Open_Sans'] p-16 flex justify-end items-end" id={"bnode_lzl4t" + (idx?"__"+idx:"")} name="left" extra-attr >
                
            <div data_id="bnode_27pta" className="new-div flex-col w-fit flex justify-start items-start gap-4" id={"bnode_27pta" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_uml7d" className="new-div text-[#FF5994] font-bold flex justify-start items-center w-fit" id={"bnode_uml7d" + (idx?"__"+idx:"")} extra-attr >
                Create Test
            </div>
        

            <div data_id="bnode_ko44m" className="new-div text-[#FF5994] font-bold flex justify-start items-center w-fit" id={"bnode_ko44m" + (idx?"__"+idx:"")} extra-attr >
                Instantly
            </div>
        

            <div data_id="bnode_qblk0" className="new-div text-[#FF5994] font-bold flex justify-start items-center w-fit" id={"bnode_qblk0" + (idx?"__"+idx:"")} extra-attr >
                With ✨AI🪄
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
         // EDITABLE LOGIN IMPLEMENTATION
        );
    return (<div className="flex flex-col justify-center items-center bg-white py-5">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 w-full max-w-md space-y-6">
                <div className="text-3xl font-bold text-center text-white">
                    <span className="bg-gradient-to-r text-transparent from-blue-500 to-purple-500 bg-clip-text">
                        Login to {AS.app.name}
                    </span>
                </div>

                <PreviewAs />

                <div>
                    <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Enter Email Address:</label>
                    <input type="email" name="email" onInput={on_email_input_v2} placeholder="Email Address" className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-md"/>
                </div>

                <div className="flex flex-col items-center justify-center w-80 mx-auto" onClick={on_email_send_v2}>
                    <button className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center">
                        <EmailSending />
                    </button>
                </div>



                {/* OTP */}
                {/* For components to work in generated react code, components should be tagged under react */}
                <react name="MobileOtp" role={selected_role}></react>





                {/* OAUTHS */}
                <div className="flex items-center justify-center w-80 mx-auto" onClick={on_google_login}>
                    <button className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center">
                        <svg className="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="800" height="800" viewBox="-0.5 0 48 48"><g fill="none" fill-rule="evenodd"><path d="M9.827 24c0-1.524.253-2.986.705-4.356l-7.909-6.04A23.456 23.456 0 0 0 .213 24c0 3.737.868 7.26 2.407 10.388l7.905-6.05A13.885 13.885 0 0 1 9.827 24" fill="#FBBC05"/><path d="M23.714 10.133c3.311 0 6.302 1.174 8.652 3.094L39.202 6.4C35.036 2.773 29.695.533 23.714.533a23.43 23.43 0 0 0-21.09 13.071l7.908 6.04a13.849 13.849 0 0 1 13.182-9.51" fill="#EB4335"/><path d="M23.714 37.867a13.849 13.849 0 0 1-13.182-9.51l-7.909 6.038a23.43 23.43 0 0 0 21.09 13.072c5.732 0 11.205-2.036 15.312-5.849l-7.507-5.804c-2.118 1.335-4.786 2.053-7.804 2.053" fill="#34A853"/><path d="M46.145 24c0-1.387-.213-2.88-.534-4.267H23.714V28.8h12.604c-.63 3.091-2.346 5.468-4.8 7.014l7.507 5.804c4.314-4.004 7.12-9.969 7.12-17.618" fill="#4285F4"/></g></svg>
                        <span>Continue with Google</span>
                    </button>
                </div>

                <div className="flex items-center justify-center w-80 mx-auto" onClick={on_microsoft_login}>
                    <button className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center">
                        <svg className="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23"><path fill="#f3f3f3" d="M0 0h23v23H0z"/><path fill="#f35325" d="M1 1h10v10H1z"/><path fill="#81bc06" d="M12 1h10v10H12z"/><path fill="#05a6f0" d="M1 12h10v10H1z"/><path fill="#ffba08" d="M12 12h10v10H12z"/></svg>
                        <span>Continue with Microsoft</span>
                    </button>
                </div>

                <div className="flex items-center justify-center w-80 mx-auto" onClick={on_github_login}>
                    <button className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center">
                        <svg className="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.705 0C5.232 0 0 5.271 0 11.791c0 5.212 3.352 9.624 8.003 11.186.581.117.794-.254.794-.566 0-.273-.019-1.21-.019-2.187-3.256.703-3.934-1.406-3.934-1.406-.523-1.367-1.299-1.718-1.299-1.718-1.066-.722.078-.722.078-.722 1.182.078 1.802 1.21 1.802 1.21 1.046 1.796 2.732 1.288 3.41.976.097-.761.407-1.288.736-1.581-2.597-.273-5.329-1.288-5.329-5.818 0-1.288.465-2.343 1.201-3.162-.116-.293-.523-1.503.116-3.124 0 0 .988-.312 3.217 1.21a11.253 11.253 0 0 1 2.926-.391c.988 0 1.996.137 2.926.391 2.229-1.523 3.217-1.21 3.217-1.21.64 1.62.232 2.831.116 3.124.756.82 1.202 1.874 1.202 3.162 0 4.529-2.732 5.525-5.348 5.818.426.371.794 1.074.794 2.186 0 1.581-.019 2.85-.019 3.241 0 .312.213.684.794.566a11.782 11.782 0 0 0 8.003-11.186C23.409 5.271 18.157 0 11.705 0z" fill="#24292f"/></svg>
                        <span>Continue with Github</span>
                    </button>
                </div>

                <div className="flex items-center justify-center w-80 mx-auto" onClick={on_linkedin_login}>
                    <button className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center">
                        <svg className="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -2 44 44"><path d="M44 40h-9.725V25.938c0-3.68-1.52-6.193-4.866-6.193-2.558 0-3.981 1.696-4.643 3.33-.249.586-.21 1.403-.21 2.22V40h-9.634s.124-24.909 0-27.173h9.634v4.265c.57-1.865 3.648-4.526 8.56-4.526C39.211 12.566 44 16.474 44 24.891V40ZM5.18 9.428h-.063C2.013 9.428 0 7.351 0 4.718 0 2.034 2.072 0 5.239 0c3.164 0 5.11 2.029 5.171 4.71 0 2.633-2.007 4.718-5.23 4.718Zm-4.07 3.399h8.576V40H1.11V12.827Z" fill="#007EBB" fill-rule="evenodd"/></svg>
                        <span>Continue with LinkedIn</span>
                    </button>
                </div>

                <div className="flex items-center justify-center w-80 mx-auto" onClick={on_twitter_login}>
                    <button className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center">
                        <svg className="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -4 48 48"><path d="M48 4.735a19.235 19.235 0 0 1-5.655 1.59A10.076 10.076 0 0 0 46.675.74a19.395 19.395 0 0 1-6.257 2.447C38.627 1.225 36.066 0 33.231 0c-5.435 0-9.844 4.521-9.844 10.098 0 .791.085 1.56.254 2.3-8.185-.423-15.44-4.438-20.3-10.555a10.281 10.281 0 0 0-1.332 5.082c0 3.502 1.738 6.593 4.38 8.405a9.668 9.668 0 0 1-4.462-1.26v.124c0 4.894 3.395 8.977 7.903 9.901a9.39 9.39 0 0 1-2.595.356 9.523 9.523 0 0 1-1.854-.18c1.254 4.01 4.888 6.932 9.199 7.01-3.37 2.71-7.618 4.325-12.23 4.325-.795 0-1.58-.047-2.35-.139C4.359 38.327 9.537 40 15.096 40c18.115 0 28.019-15.385 28.019-28.73 0-.439-.009-.878-.026-1.308A20.211 20.211 0 0 0 48 4.735" fill="#00AAEC" fill-rule="evenodd"/></svg>
                        <span>Continue with Twitter</span>
                    </button>
                </div>
            </div>
        </div>);
};
export default Login;
