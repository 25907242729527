import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_uycr2";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_uycr2 = (props)=>{
			const INFO  = {"model_id":"bhfmq","model_name":"user","op":"get_one","comp_id":"comp_bnode_uycr2"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					g_fn.bro_get_one(INFO, set_M, props);
				}, [props])
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = ()=>{

				if(!INFO.model_id) return;


				INFO.query = query; // @todo : make it possible to change in UI editor
				const id = g_fn.get_one_find_id(props, INFO, relation);
				if(!id) {
					// errors => [{code:string, msg:string}]
					// warning => []
					console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
					// return;
				}

				// subscription
				const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e)=>{
					console.log("USER RE-RENDERED");
					const data = e.data;
					set_M(data);
				});


				// first time
				AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);



				return ()=>{
					sub.unsubscribe();
				}

			}
		


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	{"prop_name":"id","prop_value":"{[user].id}","op":"eq"};
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_gw07u"] = COND_ATTRS["bnode_gw07u"] || {};
			

				COND_ATTRS["bnode_4i9qu"] = COND_ATTRS["bnode_4i9qu"] || {};
			

				COND_ATTRS["bnode_cr678"] = COND_ATTRS["bnode_cr678"] || {};
			

				COND_ATTRS["bnode_wn1so"] = COND_ATTRS["bnode_wn1so"] || {};
			

				COND_ATTRS["bnode_4rktn"] = COND_ATTRS["bnode_4rktn"] || {};
			

				COND_ATTRS["bnode_lb96p"] = COND_ATTRS["bnode_lb96p"] || {};
			

				COND_ATTRS["bnode_0w7tn"] = COND_ATTRS["bnode_0w7tn"] || {};
			

				COND_ATTRS["bnode_uckk7"] = COND_ATTRS["bnode_uckk7"] || {};
			

				COND_ATTRS["bnode_tzqtx"] = COND_ATTRS["bnode_tzqtx"] || {};
			

				COND_ATTRS["bnode_drzj3"] = COND_ATTRS["bnode_drzj3"] || {};
			

				COND_ATTRS["bnode_6nesq"] = COND_ATTRS["bnode_6nesq"] || {};
			

				COND_ATTRS["bnode_m19bh"] = COND_ATTRS["bnode_m19bh"] || {};
			

				COND_ATTRS["bnode_7rtls"] = COND_ATTRS["bnode_7rtls"] || {};
			

				COND_ATTRS["bnode_xvt3j"] = COND_ATTRS["bnode_xvt3j"] || {};
			

				COND_ATTRS["bnode_o2pdt"] = COND_ATTRS["bnode_o2pdt"] || {};
			

				COND_ATTRS["bnode_4dns7"] = COND_ATTRS["bnode_4dns7"] || {};
			

				COND_ATTRS["bnode_0l9yb"] = COND_ATTRS["bnode_0l9yb"] || {};
			

				COND_ATTRS["bnode_dmwen"] = COND_ATTRS["bnode_dmwen"] || {};
			

				COND_ATTRS["bnode_942p3"] = COND_ATTRS["bnode_942p3"] || {};
			

				COND_ATTRS["bnode_qeymg"] = COND_ATTRS["bnode_qeymg"] || {};
			

				COND_ATTRS["bnode_og7tn"] = COND_ATTRS["bnode_og7tn"] || {};
			

				COND_ATTRS["bnode_wqr9x"] = COND_ATTRS["bnode_wqr9x"] || {};
			

				COND_ATTRS["bnode_xad7t"] = COND_ATTRS["bnode_xad7t"] || {};
			

				COND_ATTRS["bnode_u5sl6"] = COND_ATTRS["bnode_u5sl6"] || {};
			

				COND_ATTRS["bnode_gqeue"] = COND_ATTRS["bnode_gqeue"] || {};
			

				COND_ATTRS["bnode_7mhd9"] = COND_ATTRS["bnode_7mhd9"] || {};
			

				COND_ATTRS["bnode_vosbi"] = COND_ATTRS["bnode_vosbi"] || {};
			

				COND_ATTRS["bnode_tguuc"] = COND_ATTRS["bnode_tguuc"] || {};
			

				COND_ATTRS["bnode_xfxxs"] = COND_ATTRS["bnode_xfxxs"] || {};
			

				COND_ATTRS["bnode_1a222"] = COND_ATTRS["bnode_1a222"] || {};
			

				COND_ATTRS["bnode_4gyr5"] = COND_ATTRS["bnode_4gyr5"] || {};
			

				COND_ATTRS["bnode_z6ha1"] = COND_ATTRS["bnode_z6ha1"] || {};
			

				COND_ATTRS["bnode_7qu6m"] = COND_ATTRS["bnode_7qu6m"] || {};
			

				COND_ATTRS["bnode_p7x3e"] = COND_ATTRS["bnode_p7x3e"] || {};
			

				COND_ATTRS["bnode_97q06"] = COND_ATTRS["bnode_97q06"] || {};
			

				COND_ATTRS["bnode_hp8x4"] = COND_ATTRS["bnode_hp8x4"] || {};
			

				COND_ATTRS["bnode_uycr2"] = COND_ATTRS["bnode_uycr2"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_uycr2"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_uycr2"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_uycr2" name="user" op="get_one" className="w-full flex flex-col relative gap-5" id={"bnode_uycr2" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_p7x3e" className="w-full flex flex-col relative gap-5 p-8 bg-white rounded-[20px]" id={"bnode_p7x3e" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_7qu6m" name="user-data" action="select-one" className="flex flex-row" id={"bnode_7qu6m" + (idx?"__"+idx:"")} style={{margin:"0px", marginBottom:"0px", marginLeft:"0px", marginRight:"0px", marginTop:"0px"}} model_id="bhfmq" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_select_one(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <div data_id="bnode_wn1so" name="CONT-image" className="w-2/5" id={"bnode_wn1so" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_cr678" id={"bnode_cr678" + (idx?"__"+idx:"")} className="w-fit" extra-attr >
                
            <div data_id="bnode_4i9qu" name="image" className="flex flex-col items-center justify-center w-fit gap-6" id={"bnode_4i9qu" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_gw07u" name="image" style={{backgroundImage:`url("${M.image?.url}")`, height:"209px", width:"201px"}} className="w-32 h-32 bg-gray-200 bg-center bg-cover rounded-[12px]" id={"bnode_gw07u" + (idx?"__"+idx:"")} extra-attr >
                
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_z6ha1" id={"bnode_z6ha1" + (idx?"__"+idx:"")} className="flex items-center justify-center flex-col w-3/5" extra-attr >
                
            <div data_id="bnode_uckk7" name="CONT-name" className="w-full  " id={"bnode_uckk7" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_0w7tn" id={"bnode_0w7tn" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_lb96p" name="name" className="flex flex-col w-full gap-6 " id={"bnode_lb96p" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_4rktn" id={"bnode_4rktn" + (idx?"__"+idx:"")} className="font-['Open_Sans'] pb-2 text-base font-normal" textContentVb="{M.name || ''}" extra-attr >
                {M.name || ''}
            </span>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_m19bh" name="CONT-email" className="w-full  " id={"bnode_m19bh" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_6nesq" id={"bnode_6nesq" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_drzj3" name="email" className="flex flex-col w-full gap-6 " id={"bnode_drzj3" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_tzqtx" id={"bnode_tzqtx" + (idx?"__"+idx:"")} className="font-normal font-['Open_Sans'] text-[#667085] pb-2 text-sm" textContentVb="{M.email || ''}" extra-attr >
                {M.email || ''}
            </span>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_4dns7" name="CONT-bio" className="w-full  " id={"bnode_4dns7" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_o2pdt" id={"bnode_o2pdt" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_xvt3j" name="bio" className="flex flex-col w-full gap-6 " id={"bnode_xvt3j" + (idx?"__"+idx:"")} extra-attr >
                
            <span data_id="bnode_7rtls" id={"bnode_7rtls" + (idx?"__"+idx:"")} className="font-normal font-['Open_Sans'] pb-8 text-sm" textContentVb="{M.bio}" extra-attr >
                {M.bio}
            </span>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_og7tn" name="CONT-role" className="w-full  " id={"bnode_og7tn" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_qeymg" id={"bnode_qeymg" + (idx?"__"+idx:"")} className="pb-3" extra-attr >
                
            <div data_id="bnode_942p3" name="role" className="flex w-full gap-2 flex-row bg-[#EAECF0] rounded-[8px] py-2 px-4" id={"bnode_942p3" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_0l9yb" className="new-div w-fit flex items-center justify-center font-normal font-['Open_Sans'] text-sm" id={"bnode_0l9yb" + (idx?"__"+idx:"")} extra-attr >
                👨‍🏫
            </div>
        

            <span data_id="bnode_dmwen" id={"bnode_dmwen" + (idx?"__"+idx:"")} className=" font-normal font-['Open_Sans'] text-sm" textContentVb="{M.user_role}" extra-attr >
                {M.user_role}
            </span>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_7mhd9" name="CONT-school" className="w-full  " id={"bnode_7mhd9" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_gqeue" id={"bnode_gqeue" + (idx?"__"+idx:"")} className="pb-3" extra-attr >
                
            <div data_id="bnode_u5sl6" name="school" className="flex w-full gap-2 flex-row bg-[#EAECF0] rounded-[8px] py-2 px-4" id={"bnode_u5sl6" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_wqr9x" className="new-div w-fit flex items-center justify-center font-normal font-['Open_Sans'] text-sm" id={"bnode_wqr9x" + (idx?"__"+idx:"")} extra-attr >
                🏫
            </div>
        

            <span data_id="bnode_xad7t" id={"bnode_xad7t" + (idx?"__"+idx:"")} className="font-normal font-['Open_Sans'] text-sm" textContentVb="{M.school || ''}" extra-attr >
                {M.school || ''}
            </span>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_4gyr5" name="CONT-subject" className="w-full  " id={"bnode_4gyr5" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_1a222" id={"bnode_1a222" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_xfxxs" name="subject" className="flex w-full gap-2 flex-row bg-[#EAECF0] rounded-[8px] py-2 px-4" id={"bnode_xfxxs" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_vosbi" className="new-div w-fit flex items-center justify-center font-normal font-['Open_Sans'] text-sm" id={"bnode_vosbi" + (idx?"__"+idx:"")} extra-attr >
                📚
            </div>
        

            <span data_id="bnode_tguuc" id={"bnode_tguuc" + (idx?"__"+idx:"")} className="font-normal font-['Open_Sans'] text-sm" textContentVb="{M.subject}" extra-attr >
                {M.subject}
            </span>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_hp8x4" className="new-div w-full flex justify-end items-end" id={"bnode_hp8x4" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_97q06" className="new-div w-[119px] flex items-center justify-center p-3 bg-[#FF5994] text-white rounded-lg text-base font-normal font-['Open_Sans']" id={"bnode_97q06" + (idx?"__"+idx:"")} action="['select-one','go-to-page']" page_id="bnode_pw9l9" model_id="bhfmq" onClick={async (e)=>{
            
            
            
            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation();

            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                 g_fn.bro_select_one(e, M, INFO, props);  g_fn.bro_go_to_page(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                EDIT
            </div>
        
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_uycr2;