import React from 'react';
import { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
    Routes,
    Route,
    Link,
    useNavigate,
} from "react-router-dom";

import {
    motion,
    AnimatePresence
} from "framer-motion";

import {
    fallbackRender,
    add_sub,
    remove_subs
} from "../utils.jsx";
import { COMP_REG } from "../comp_registry.js";
import g_fn from '../g_fn';






const AS = g_fn.AS;


// @req: keep reference to subs here and remove them on unmount or reload
const SUB_ID = "bnode_k2lnn";
const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


// local code - e.g sub components



const comp_bnode_k2lnn = (props) => {
    const INFO = { "model_id": "", "model_name": "", "op": "get_one", "comp_id": "comp_bnode_k2lnn" };

    // Routes




    // FOR IS_MANY
    let idx = props.idx;   // also for protection from error when we might think is many but it's not
    let V = props.V || {}; // for protection from error


    // STATES
    const [rerender, set_rerender] = useState({});
    const [M, set_M] = useState(props.M || {})
    const [PM, set_PM] = useState(props.PM || [])
    const [selected_M, set_selected_M] = useState(null)

    // REFS


    // EFFECTS

    useEffect(() => {
        console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
    }, [rerender])


    useEffect(() => {
        const deinit = init();

        return () => {
            if (deinit && typeof (deinit) === "function") deinit();
        }
    }, []);


    useEffect(() => {
        g_fn.bro_get_one(INFO, set_M, props);
    }, [props])


    useEffect(() => {
        const sub = AS.GSTORE.subscribe((e) => {
            // console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

            // if model_id and eid are same
            // once data changes we will update M
        });

        return () => {
            sub.unsubscribe();
        }
    }, []);

    useEffect(() => {
        if (EN_BROKEN_COMP_LOGS.MODEL_EFFECT) {
            const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
            console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
        }
    }, [M])

    useEffect(() => {

        if (!INFO.model_id) return;

        if (query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
        const id = g_fn.get_selected_entity_id(props, INFO);
        if (!id) {
            return console.warn("NO ID FOUND TO UPDATE ENTITY");
        }

        (async () => {
            const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
            console.log("GSTORE GET ONE ENTITY : ", r);
            if (r) set_selected_M(r);
        })();

        const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
            const id = g_fn.get_selected_entity_id(props, INFO);
            if (!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
            (async () => {
                const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
                console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
                if (r) set_selected_M(r);
            })();
        });

        // On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
    }, [])


    // FUNCTIONS

    const init = () => {

        if (!INFO.model_id) return;


        INFO.query = query; // @todo : make it possible to change in UI editor
        const id = g_fn.get_one_find_id(props, INFO, relation);
        if (!id) {
            // errors => [{code:string, msg:string}]
            // warning => []
            console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
            // return;
        }

        // subscription
        const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e) => {
            console.log("USER RE-RENDERED");
            const data = e.data;
            set_M(data);
        });


        // first time
        AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);



        return () => {
            sub.unsubscribe();
        }

    }



    // STATEMENTS

    INFO.set_M = set_M;
    INFO.on_created = props.on_created || props.INFO?.on_created;
    INFO.on_selected = props.on_selected || props.INFO?.on_selected;
    const relation = null;
    const query = null;




    // CONDITIONALS ATTRS
    const COND_ATTRS = {};

    COND_ATTRS["bnode_959h0"] = COND_ATTRS["bnode_959h0"] || {};


    COND_ATTRS["bnode_wbuz2"] = COND_ATTRS["bnode_wbuz2"] || {};


    COND_ATTRS["bnode_g8d3x"] = COND_ATTRS["bnode_g8d3x"] || {};


    COND_ATTRS["bnode_k66uh"] = COND_ATTRS["bnode_k66uh"] || {};


    COND_ATTRS["bnode_dq1xe"] = COND_ATTRS["bnode_dq1xe"] || {};


    COND_ATTRS["bnode_dcglp"] = COND_ATTRS["bnode_dcglp"] || {};


    COND_ATTRS["bnode_ni087"] = COND_ATTRS["bnode_ni087"] || {};


    COND_ATTRS["bnode_62oba"] = COND_ATTRS["bnode_62oba"] || {};


    COND_ATTRS["bnode_f8u30"] = COND_ATTRS["bnode_f8u30"] || {};


    COND_ATTRS["bnode_k2caa"] = COND_ATTRS["bnode_k2caa"] || {};


    COND_ATTRS["bnode_qqdjk"] = COND_ATTRS["bnode_qqdjk"] || {};


    COND_ATTRS["bnode_stl1k"] = COND_ATTRS["bnode_stl1k"] || {};


    COND_ATTRS["bnode_81qux"] = COND_ATTRS["bnode_81qux"] || {};


    COND_ATTRS["bnode_fkoef"] = COND_ATTRS["bnode_fkoef"] || {};


    COND_ATTRS["bnode_oj0tt"] = COND_ATTRS["bnode_oj0tt"] || {};


    COND_ATTRS["bnode_oaech"] = COND_ATTRS["bnode_oaech"] || {};


    COND_ATTRS["bnode_dibfm"] = COND_ATTRS["bnode_dibfm"] || {};


    COND_ATTRS["bnode_z6y3h"] = COND_ATTRS["bnode_z6y3h"] || {};


    COND_ATTRS["bnode_1bm50"] = COND_ATTRS["bnode_1bm50"] || {};


    COND_ATTRS["bnode_n245i"] = COND_ATTRS["bnode_n245i"] || {};


    COND_ATTRS["bnode_vu2jg"] = COND_ATTRS["bnode_vu2jg"] || {};


    COND_ATTRS["bnode_hg7gs"] = COND_ATTRS["bnode_hg7gs"] || {};


    COND_ATTRS["bnode_jl9x3"] = COND_ATTRS["bnode_jl9x3"] || {};


    COND_ATTRS["bnode_9dk9a"] = COND_ATTRS["bnode_9dk9a"] || {};


    COND_ATTRS["bnode_if60r"] = COND_ATTRS["bnode_if60r"] || {};


    COND_ATTRS["bnode_iw8za"] = COND_ATTRS["bnode_iw8za"] || {};


    COND_ATTRS["bnode_fn6w0"] = COND_ATTRS["bnode_fn6w0"] || {};


    COND_ATTRS["bnode_z6vmu"] = COND_ATTRS["bnode_z6vmu"] || {};


    COND_ATTRS["bnode_kz2qa"] = COND_ATTRS["bnode_kz2qa"] || {};


    COND_ATTRS["bnode_fn7v2"] = COND_ATTRS["bnode_fn7v2"] || {};


    COND_ATTRS["bnode_etdh7"] = COND_ATTRS["bnode_etdh7"] || {};


    COND_ATTRS["bnode_9mscm"] = COND_ATTRS["bnode_9mscm"] || {};


    COND_ATTRS["bnode_ygr0l"] = COND_ATTRS["bnode_ygr0l"] || {};


    COND_ATTRS["bnode_4tvys"] = COND_ATTRS["bnode_4tvys"] || {};


    COND_ATTRS["bnode_wn6rg"] = COND_ATTRS["bnode_wn6rg"] || {};


    COND_ATTRS["bnode_be1gl"] = COND_ATTRS["bnode_be1gl"] || {};


    COND_ATTRS["bnode_wex0x"] = COND_ATTRS["bnode_wex0x"] || {};


    COND_ATTRS["bnode_2qt76"] = COND_ATTRS["bnode_2qt76"] || {};


    COND_ATTRS["bnode_y4g2l"] = COND_ATTRS["bnode_y4g2l"] || {};


    COND_ATTRS["bnode_wziqa"] = COND_ATTRS["bnode_wziqa"] || {};


    COND_ATTRS["bnode_4y6k3"] = COND_ATTRS["bnode_4y6k3"] || {};


    COND_ATTRS["bnode_l8gq6"] = COND_ATTRS["bnode_l8gq6"] || {};


    COND_ATTRS["bnode_2lnwu"] = COND_ATTRS["bnode_2lnwu"] || {};


    COND_ATTRS["bnode_fttcf"] = COND_ATTRS["bnode_fttcf"] || {};


    COND_ATTRS["bnode_u1kjb"] = COND_ATTRS["bnode_u1kjb"] || {};


    COND_ATTRS["bnode_fxxrs"] = COND_ATTRS["bnode_fxxrs"] || {};


    COND_ATTRS["bnode_q9vsa"] = COND_ATTRS["bnode_q9vsa"] || {};


    COND_ATTRS["bnode_e5cez"] = COND_ATTRS["bnode_e5cez"] || {};


    COND_ATTRS["bnode_qbku9"] = COND_ATTRS["bnode_qbku9"] || {};


    COND_ATTRS["bnode_wlv08"] = COND_ATTRS["bnode_wlv08"] || {};


    COND_ATTRS["bnode_i88dt"] = COND_ATTRS["bnode_i88dt"] || {};


    COND_ATTRS["bnode_aqnvy"] = COND_ATTRS["bnode_aqnvy"] || {};


    COND_ATTRS["bnode_q3oy0"] = COND_ATTRS["bnode_q3oy0"] || {};


    COND_ATTRS["bnode_ayd4v"] = COND_ATTRS["bnode_ayd4v"] || {};


    COND_ATTRS["bnode_8tpz4"] = COND_ATTRS["bnode_8tpz4"] || {};


    COND_ATTRS["bnode_fug74"] = COND_ATTRS["bnode_fug74"] || {};


    COND_ATTRS["bnode_jzfwa"] = COND_ATTRS["bnode_jzfwa"] || {};


    COND_ATTRS["bnode_lk4x8"] = COND_ATTRS["bnode_lk4x8"] || {};


    COND_ATTRS["bnode_wz5kx"] = COND_ATTRS["bnode_wz5kx"] || {};


    COND_ATTRS["bnode_y7ffq"] = COND_ATTRS["bnode_y7ffq"] || {};


    COND_ATTRS["bnode_cu9x1"] = COND_ATTRS["bnode_cu9x1"] || {};


    COND_ATTRS["bnode_lu87b"] = COND_ATTRS["bnode_lu87b"] || {};


    COND_ATTRS["bnode_io8uc"] = COND_ATTRS["bnode_io8uc"] || {};


    COND_ATTRS["bnode_pft8u"] = COND_ATTRS["bnode_pft8u"] || {};


    COND_ATTRS["bnode_uoq99"] = COND_ATTRS["bnode_uoq99"] || {};


    COND_ATTRS["bnode_cx0e9"] = COND_ATTRS["bnode_cx0e9"] || {};


    COND_ATTRS["bnode_fw1p0"] = COND_ATTRS["bnode_fw1p0"] || {};


    COND_ATTRS["bnode_2ef07"] = COND_ATTRS["bnode_2ef07"] || {};


    COND_ATTRS["bnode_4bz3k"] = COND_ATTRS["bnode_4bz3k"] || {};


    COND_ATTRS["bnode_pnp5m"] = COND_ATTRS["bnode_pnp5m"] || {};


    COND_ATTRS["bnode_v1em9"] = COND_ATTRS["bnode_v1em9"] || {};


    COND_ATTRS["bnode_s6uz0"] = COND_ATTRS["bnode_s6uz0"] || {};


    COND_ATTRS["bnode_n32gb"] = COND_ATTRS["bnode_n32gb"] || {};


    COND_ATTRS["bnode_oao8m"] = COND_ATTRS["bnode_oao8m"] || {};


    COND_ATTRS["bnode_vehgb"] = COND_ATTRS["bnode_vehgb"] || {};


    COND_ATTRS["bnode_lad6s"] = COND_ATTRS["bnode_lad6s"] || {};


    COND_ATTRS["bnode_gs0pi"] = COND_ATTRS["bnode_gs0pi"] || {};


    COND_ATTRS["bnode_wwiqa"] = COND_ATTRS["bnode_wwiqa"] || {};


    COND_ATTRS["bnode_t5hx5"] = COND_ATTRS["bnode_t5hx5"] || {};


    COND_ATTRS["bnode_a2q0p"] = COND_ATTRS["bnode_a2q0p"] || {};


    COND_ATTRS["bnode_tdbys"] = COND_ATTRS["bnode_tdbys"] || {};


    COND_ATTRS["bnode_k2lnn"] = COND_ATTRS["bnode_k2lnn"] || {};



    // DYNAMIC REACT STATES
    const REACT_STATES = {};
    REACT_STATES["M"] = { state: M, set_state: set_M };

    const AS_COMPONENTS = AS.components["comp_bnode_k2lnn"] || {};
    AS_COMPONENTS.REACT_STATES = REACT_STATES;
    AS.components["comp_bnode_k2lnn"] = AS_COMPONENTS;




    // MAPPED DATA
    const MAPPED_DATA = {};



    return (
        <ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => { console.error(d) }}>

            <div data_id="bnode_k2lnn" className="min-h-[64px] w-full h-fit overflow-auto flex gap-4 p-4 items-center justify-center bg-[#eeeeee] relative flex-col" name="page:editquestions" findex="a6" id={"bnode_k2lnn" + (idx ? "__" + idx : "")} onClick={(e) => {
                g_fn.dev.emit_m(e, M, INFO)
            }} extra-attr >

                <div data_id="bnode_g8d3x" className="w-3/4 flex justify-start items-center gap-2 text-[#6B7280]" id={"bnode_g8d3x" + (idx ? "__" + idx : "")} name="Back button"   extra-attr >
                    <div className='w-fit flex justify-start items-center gap-2  cursor-pointer text-[#FF5994] border border-[#FF5994] rounded-lg px-2 py-1' action="['go-to-page']" page_id="bnode_jj973" onClick={(e) => {
                        if (window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
                        e.preventDefault(); e.stopPropagation();

                        const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                        g_fn.bro_go_to_page(e, M, INFO, props)
                        el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
                        }}>

                        <svg data_id="bnode_959h0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="move-left" className="w-20px h-20px cursor-pointer" id={"bnode_959h0" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                            <path data_id="" d="M6 8L2 12L6 16" extra-attr >

                            </path>


                            <path data_id="" d="M2 12H22" extra-attr >

                            </path>

                        </svg>


                        <div data_id="bnode_wbuz2" className="new-div w-fit flex items-center justify-center text-xl font-bold font-['Open_Sans'] cursor-pointer" id={"bnode_wbuz2" + (idx ? "__" + idx : "")}  extra-attr >
                            Back
                        </div>
                    </div>

                </div>

                {false && (<div data_id="bnode_tdbys" className="new-div w-5/6 flex items-center justify-center flex-col bg-white rounded-[16px] p-16" id={"bnode_tdbys" + (idx ? "__" + idx : "")} extra-attr >

                    <div data_id="bnode_dq1xe" className="new-div w-full flex justify-end items-center" id={"bnode_dq1xe" + (idx ? "__" + idx : "")} name="close" extra-attr >

                        <svg data_id="bnode_k66uh" width="57" height="58" viewBox="0 0 57 58" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_k66uh" + (idx ? "__" + idx : "")} b_type="icon" className="cursor-pointer fixed top-[155px] right-[130px]" extra-attr >

                            <rect data_id="" x="0.431641" y="0.5" width="56.5685" height="56.5685" rx="28.2842" fill="#FFE4E6" extra-attr >

                            </rect>


                            <path data_id="" fill-rule="evenodd" clip-rule="evenodd" d="M36.8107 20.6896C37.3695 21.2484 37.3695 22.1544 36.8107 22.7133L22.6449 36.8791C22.086 37.4379 21.18 37.4379 20.6212 36.8791C20.0624 36.3202 20.0624 35.4142 20.6212 34.8554L34.787 20.6896C35.3458 20.1308 36.2518 20.1308 36.8107 20.6896Z" fill="#F43F5E" extra-attr >

                            </path>


                            <path data_id="" fill-rule="evenodd" clip-rule="evenodd" d="M20.621 20.6896C21.1798 20.1308 22.0858 20.1308 22.6447 20.6896L36.8105 34.8554C37.3693 35.4142 37.3693 36.3202 36.8105 36.8791C36.2516 37.4379 35.3456 37.4379 34.7868 36.8791L20.621 22.7133C20.0621 22.1544 20.0621 21.2484 20.621 20.6896Z" fill="#F43F5E" extra-attr >

                            </path>

                        </svg>

                    </div>


                    <div data_id="bnode_oj0tt" className="new-div w-full flex justify-start items-center pb-8" id={"bnode_oj0tt" + (idx ? "__" + idx : "")} name="topbar" extra-attr >

                        <div data_id="bnode_qqdjk" className="new-div w-full flex justify-start items-center" id={"bnode_qqdjk" + (idx ? "__" + idx : "")} name="left" extra-attr >

                            <div data_id="bnode_dcglp" className="new-div w-fit flex items-center justify-center text-xl font-semibold font-['Open_Sans'] pr-3" id={"bnode_dcglp" + (idx ? "__" + idx : "")} extra-attr >
                                1.
                            </div>


                            <div data_id="bnode_k2caa" className="new-div w-fit flex items-center justify-center p-2 bg-white rounded-[12px] cursor-pointer border border-[#8a8a8a] gap-[10px]" id={"bnode_k2caa" + (idx ? "__" + idx : "")} name="dropdown" extra-attr >

                                <div data_id="bnode_ni087" className="new-div w-fit flex justify-start items-center text-xl font-['Open_Sans'] font-semibold" id={"bnode_ni087" + (idx ? "__" + idx : "")} extra-attr >
                                    Multiple Choice
                                </div>


                                <div data_id="bnode_f8u30" className="new-div w-fit flex justify-end items-center" id={"bnode_f8u30" + (idx ? "__" + idx : "")} name="right" extra-attr >

                                    <svg data_id="bnode_62oba" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="chevron-down" className="w-[24px] h-[24px] text-[#8a8a8a]" id={"bnode_62oba" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                        <path data_id="" d="m6 9 6 6 6-6" extra-attr >

                                        </path>

                                    </svg>

                                </div>

                            </div>

                        </div>


                        <div data_id="bnode_fkoef" className="new-div w-full flex justify-end items-center" id={"bnode_fkoef" + (idx ? "__" + idx : "")} name="right" extra-attr >

                            <div data_id="bnode_81qux" className="new-div w-fit flex items-center justify-center p-2 bg-white rounded-[12px] cursor-pointer border border-[#8a8a8a] gap-[10px]" id={"bnode_81qux" + (idx ? "__" + idx : "")} name="ansexplbutton" extra-attr >

                                <div data_id="bnode_stl1k" className="new-div w-fit flex justify-start items-center font-['Open_Sans'] font-semibold text-[#8a8a8a] text-lg" id={"bnode_stl1k" + (idx ? "__" + idx : "")} extra-attr >
                                    💡 Add Answer Explaination
                                </div>

                            </div>

                        </div>

                    </div>


                    <div data_id="bnode_oaech" className="new-div w-full text-xl font-bold font-['Open_Sans'] flex justify-start items-center pb-3" id={"bnode_oaech" + (idx ? "__" + idx : "")} extra-attr >
                        QUESTIONS
                    </div>


                    <div data_id="bnode_z6y3h" className="new-div w-full flex items-center justify-center pb-4" id={"bnode_z6y3h" + (idx ? "__" + idx : "")} name="textbox" extra-attr >

                        <textarea data_id="bnode_dibfm" type="text" placeholder="Textarea..." className="w-full h-[165px] p-5 outline outline-2 outline-[#9CA3AF] rounded-[12px] text-xl font-semibold font-['Open_Sans']" id={"bnode_dibfm" + (idx ? "__" + idx : "")} extra-attr >
                            Carl and Passions changed name to what?
                        </textarea>

                    </div>


                    <div data_id="bnode_1bm50" className="new-div w-full text-xl font-bold font-['Open_Sans'] flex justify-start items-center pb-4" id={"bnode_1bm50" + (idx ? "__" + idx : "")} extra-attr >
                        ANSWERS
                    </div>


                    <div data_id="bnode_y7ffq" className="new-div w-full flex items-center justify-center gap-4" id={"bnode_y7ffq" + (idx ? "__" + idx : "")} name="options" extra-attr >

                        <div data_id="bnode_kz2qa" className="new-div w-full flex items-center justify-center flex-col" id={"bnode_kz2qa" + (idx ? "__" + idx : "")} name="1" extra-attr >

                            <div data_id="bnode_iw8za" className="new-div w-full flex items-center justify-center pb-2" id={"bnode_iw8za" + (idx ? "__" + idx : "")} name="top" extra-attr >

                                <div data_id="bnode_n245i" className="new-div w-full flex justify-start items-center" id={"bnode_n245i" + (idx ? "__" + idx : "")} extra-attr >
                                    check
                                </div>


                                <div data_id="bnode_if60r" className="new-div w-full flex justify-end items-center gap-2" id={"bnode_if60r" + (idx ? "__" + idx : "")} name="right" extra-attr >

                                    <button data_id="bnode_hg7gs" type="button" className="text-[#4B5563] bg-[#F3F4F6] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center" id={"bnode_hg7gs" + (idx ? "__" + idx : "")} name="delete" extra-attr >

                                        <svg data_id="bnode_vu2jg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="image" className="lucide lucide-image w-20px h-20px" id={"bnode_vu2jg" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                            <rect data_id="" width="18" height="18" x="3" y="3" rx="2" ry="2" extra-attr >

                                            </rect>


                                            <circle data_id="" cx="9" cy="9" r="2" extra-attr >

                                            </circle>


                                            <path data_id="" d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" extra-attr >

                                            </path>

                                        </svg>

                                    </button>


                                    <button data_id="bnode_9dk9a" type="button" className="text-[#4B5563] bg-[#F3F4F6] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center" id={"bnode_9dk9a" + (idx ? "__" + idx : "")} name="delete" extra-attr >

                                        <svg data_id="bnode_jl9x3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="trash-2" className="w-20px h-20px" id={"bnode_jl9x3" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                            <path data_id="" d="M3 6h18" extra-attr >

                                            </path>


                                            <path data_id="" d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" extra-attr >

                                            </path>


                                            <path data_id="" d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" extra-attr >

                                            </path>


                                            <line data_id="" x1="10" x2="10" y1="11" y2="17" extra-attr >

                                            </line>


                                            <line data_id="" x1="14" x2="14" y1="11" y2="17" extra-attr >

                                            </line>

                                        </svg>

                                    </button>

                                </div>

                            </div>


                            <div data_id="bnode_z6vmu" className="new-div w-full flex items-center justify-center" id={"bnode_z6vmu" + (idx ? "__" + idx : "")} extra-attr >

                                <textarea data_id="bnode_fn6w0" type="text" placeholder="Textarea..." className="w-full h-[150px] rounded-[12px] p-4 outline outline-[1px] outline-[#9CA3AF] text-xl font-normal font-['Open_Sans']" id={"bnode_fn6w0" + (idx ? "__" + idx : "")} extra-attr >
                                    Answer one
                                </textarea>

                            </div>

                        </div>


                        <div data_id="bnode_y4g2l" className="new-div w-full flex items-center justify-center flex-col" id={"bnode_y4g2l" + (idx ? "__" + idx : "")} name="1" extra-attr >

                            <div data_id="bnode_be1gl" className="new-div w-full flex items-center justify-center pb-2" id={"bnode_be1gl" + (idx ? "__" + idx : "")} name="top" extra-attr >

                                <div data_id="bnode_fn7v2" className="new-div w-full flex justify-start items-center" id={"bnode_fn7v2" + (idx ? "__" + idx : "")} extra-attr >
                                    check
                                </div>


                                <div data_id="bnode_wn6rg" className="new-div w-full flex justify-end items-center gap-2" id={"bnode_wn6rg" + (idx ? "__" + idx : "")} name="right" extra-attr >

                                    <button data_id="bnode_9mscm" type="button" className="text-[#4B5563] bg-[#F3F4F6] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center" id={"bnode_9mscm" + (idx ? "__" + idx : "")} name="delete" extra-attr >

                                        <svg data_id="bnode_etdh7" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="image" className="lucide lucide-image w-20px h-20px" id={"bnode_etdh7" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                            <rect data_id="" width="18" height="18" x="3" y="3" rx="2" ry="2" extra-attr >

                                            </rect>


                                            <circle data_id="" cx="9" cy="9" r="2" extra-attr >

                                            </circle>


                                            <path data_id="" d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" extra-attr >

                                            </path>

                                        </svg>

                                    </button>


                                    <button data_id="bnode_4tvys" type="button" className="text-[#4B5563] bg-[#F3F4F6] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center" id={"bnode_4tvys" + (idx ? "__" + idx : "")} name="delete" extra-attr >

                                        <svg data_id="bnode_ygr0l" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="trash-2" className="w-20px h-20px" id={"bnode_ygr0l" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                            <path data_id="" d="M3 6h18" extra-attr >

                                            </path>


                                            <path data_id="" d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" extra-attr >

                                            </path>


                                            <path data_id="" d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" extra-attr >

                                            </path>


                                            <line data_id="" x1="10" x2="10" y1="11" y2="17" extra-attr >

                                            </line>


                                            <line data_id="" x1="14" x2="14" y1="11" y2="17" extra-attr >

                                            </line>

                                        </svg>

                                    </button>

                                </div>

                            </div>


                            <div data_id="bnode_2qt76" className="new-div w-full flex items-center justify-center" id={"bnode_2qt76" + (idx ? "__" + idx : "")} extra-attr >

                                <textarea data_id="bnode_wex0x" type="text" placeholder="Textarea..." className="w-full h-[150px] rounded-[12px] p-4 outline outline-[1px] outline-[#9CA3AF] text-xl font-normal font-['Open_Sans']" id={"bnode_wex0x" + (idx ? "__" + idx : "")} extra-attr >
                                    Answer two
                                </textarea>

                            </div>

                        </div>


                        <div data_id="bnode_qbku9" className="new-div w-full flex items-center justify-center flex-col" id={"bnode_qbku9" + (idx ? "__" + idx : "")} name="1" extra-attr >

                            <div data_id="bnode_fxxrs" className="new-div w-full flex items-center justify-center pb-2" id={"bnode_fxxrs" + (idx ? "__" + idx : "")} name="top" extra-attr >

                                <div data_id="bnode_wziqa" className="new-div w-full flex justify-start items-center" id={"bnode_wziqa" + (idx ? "__" + idx : "")} extra-attr >
                                    check
                                </div>


                                <div data_id="bnode_u1kjb" className="new-div w-full flex justify-end items-center gap-2" id={"bnode_u1kjb" + (idx ? "__" + idx : "")} name="right" extra-attr >

                                    <button data_id="bnode_l8gq6" type="button" className="text-[#4B5563] bg-[#F3F4F6] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center" id={"bnode_l8gq6" + (idx ? "__" + idx : "")} name="delete" extra-attr >

                                        <svg data_id="bnode_4y6k3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="image" className="lucide lucide-image w-20px h-20px" id={"bnode_4y6k3" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                            <rect data_id="" width="18" height="18" x="3" y="3" rx="2" ry="2" extra-attr >

                                            </rect>


                                            <circle data_id="" cx="9" cy="9" r="2" extra-attr >

                                            </circle>


                                            <path data_id="" d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" extra-attr >

                                            </path>

                                        </svg>

                                    </button>


                                    <button data_id="bnode_fttcf" type="button" className="text-[#4B5563] bg-[#F3F4F6] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center" id={"bnode_fttcf" + (idx ? "__" + idx : "")} name="delete" extra-attr >

                                        <svg data_id="bnode_2lnwu" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="trash-2" className="w-20px h-20px" id={"bnode_2lnwu" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                            <path data_id="" d="M3 6h18" extra-attr >

                                            </path>


                                            <path data_id="" d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" extra-attr >

                                            </path>


                                            <path data_id="" d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" extra-attr >

                                            </path>


                                            <line data_id="" x1="10" x2="10" y1="11" y2="17" extra-attr >

                                            </line>


                                            <line data_id="" x1="14" x2="14" y1="11" y2="17" extra-attr >

                                            </line>

                                        </svg>

                                    </button>

                                </div>

                            </div>


                            <div data_id="bnode_e5cez" className="new-div w-full flex items-center justify-center" id={"bnode_e5cez" + (idx ? "__" + idx : "")} extra-attr >

                                <textarea data_id="bnode_q9vsa" type="text" placeholder="Textarea..." className="w-full h-[150px] rounded-[12px] p-4 outline outline-[1px] outline-[#9CA3AF] text-xl font-normal font-['Open_Sans']" id={"bnode_q9vsa" + (idx ? "__" + idx : "")} extra-attr >
                                    Answer three
                                </textarea>

                            </div>

                        </div>


                        <div data_id="bnode_wz5kx" className="new-div w-full flex items-center justify-center flex-col" id={"bnode_wz5kx" + (idx ? "__" + idx : "")} name="1" extra-attr >

                            <div data_id="bnode_fug74" className="new-div w-full flex items-center justify-center pb-2" id={"bnode_fug74" + (idx ? "__" + idx : "")} name="top" extra-attr >

                                <div data_id="bnode_wlv08" className="new-div w-full flex justify-start items-center" id={"bnode_wlv08" + (idx ? "__" + idx : "")} extra-attr >
                                    check
                                </div>


                                <div data_id="bnode_8tpz4" className="new-div w-full flex justify-end items-center gap-2" id={"bnode_8tpz4" + (idx ? "__" + idx : "")} name="right" extra-attr >

                                    <button data_id="bnode_aqnvy" type="button" className="text-[#4B5563] bg-[#F3F4F6] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center" id={"bnode_aqnvy" + (idx ? "__" + idx : "")} name="delete" extra-attr >

                                        <svg data_id="bnode_i88dt" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="image" className="lucide lucide-image w-20px h-20px" id={"bnode_i88dt" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                            <rect data_id="" width="18" height="18" x="3" y="3" rx="2" ry="2" extra-attr >

                                            </rect>


                                            <circle data_id="" cx="9" cy="9" r="2" extra-attr >

                                            </circle>


                                            <path data_id="" d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" extra-attr >

                                            </path>

                                        </svg>

                                    </button>


                                    <button data_id="bnode_ayd4v" type="button" className="text-[#4B5563] bg-[#F3F4F6] hover:text-white focus:ring-4 focus:ring-blue-300 rounded-lg p-2 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center" id={"bnode_ayd4v" + (idx ? "__" + idx : "")} name="delete" extra-attr >

                                        <svg data_id="bnode_q3oy0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="trash-2" className="w-20px h-20px" id={"bnode_q3oy0" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                            <path data_id="" d="M3 6h18" extra-attr >

                                            </path>


                                            <path data_id="" d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" extra-attr >

                                            </path>


                                            <path data_id="" d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" extra-attr >

                                            </path>


                                            <line data_id="" x1="10" x2="10" y1="11" y2="17" extra-attr >

                                            </line>


                                            <line data_id="" x1="14" x2="14" y1="11" y2="17" extra-attr >

                                            </line>

                                        </svg>

                                    </button>

                                </div>

                            </div>


                            <div data_id="bnode_lk4x8" className="new-div w-full flex items-center justify-center" id={"bnode_lk4x8" + (idx ? "__" + idx : "")} extra-attr >

                                <textarea data_id="bnode_jzfwa" type="text" placeholder="Textarea..." className="w-full h-[150px] rounded-[12px] p-4 outline outline-[1px] outline-[#9CA3AF] text-xl font-normal font-['Open_Sans']" id={"bnode_jzfwa" + (idx ? "__" + idx : "")} extra-attr >
                                    Answer four
                                </textarea>

                            </div>

                        </div>

                    </div>


                    <div data_id="bnode_pft8u" className="new-div w-full py-4 flex justify-end items-center" id={"bnode_pft8u" + (idx ? "__" + idx : "")} name="addoptions" extra-attr >

                        <div data_id="bnode_io8uc" className="new-div w-fit flex items-center justify-center p-4 bg-[#F3F4F6] rounded-[12px] cursor-pointer gap-2" id={"bnode_io8uc" + (idx ? "__" + idx : "")} name="button" extra-attr >

                            <svg data_id="bnode_cu9x1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="plus" className="w-[30px] h-[30px] text-[#6B7280]" id={"bnode_cu9x1" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                <path data_id="" d="M5 12h14" extra-attr >

                                </path>


                                <path data_id="" d="M12 5v14" extra-attr >

                                </path>

                            </svg>


                            <div data_id="bnode_lu87b" className="new-div w-fit flex justify-start items-center font-['Open_Sans'] text-black text-xl font-normal" id={"bnode_lu87b" + (idx ? "__" + idx : "")} extra-attr >
                                Add More Options
                            </div>

                        </div>

                    </div>


                    <div data_id="bnode_v1em9" className="new-div w-full flex justify-start items-center gap-2 pb-2" id={"bnode_v1em9" + (idx ? "__" + idx : "")} name="tags" extra-attr >

                        <div data_id="bnode_fw1p0" className="new-div w-fit flex items-center justify-center bg-[#3D3D3D] p-2 rounded-[8px] gap-[10px]" id={"bnode_fw1p0" + (idx ? "__" + idx : "")} name="tag 2" extra-attr >

                            <div data_id="bnode_uoq99" className="new-div w-fit flex items-center justify-center text-base font-semibold font-['Open_Sans'] text-[#D1D5DB]" id={"bnode_uoq99" + (idx ? "__" + idx : "")} extra-attr >
                                Tag Number 2
                            </div>


                            <svg data_id="bnode_cx0e9" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="x" className="w-[20px] h-[20px] text-[#9CA3AF]" id={"bnode_cx0e9" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                <path data_id="" d="M18 6 6 18" extra-attr >

                                </path>


                                <path data_id="" d="m6 6 12 12" extra-attr >

                                </path>

                            </svg>

                        </div>


                        <div data_id="bnode_pnp5m" className="new-div w-fit flex items-center justify-center bg-[#3D3D3D] p-2 rounded-[8px] gap-[10px]" id={"bnode_pnp5m" + (idx ? "__" + idx : "")} name="tag 1" extra-attr >

                            <div data_id="bnode_2ef07" className="new-div w-fit flex items-center justify-center text-base font-semibold font-['Open_Sans'] text-[#D1D5DB]" id={"bnode_2ef07" + (idx ? "__" + idx : "")} extra-attr >
                                Tag 1
                            </div>


                            <svg data_id="bnode_4bz3k" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="x" className="w-[20px] h-[20px] text-[#9CA3AF]" id={"bnode_4bz3k" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                <path data_id="" d="M18 6 6 18" extra-attr >

                                </path>


                                <path data_id="" d="m6 6 12 12" extra-attr >

                                </path>

                            </svg>

                        </div>

                    </div>


                    <div data_id="bnode_lad6s" className="new-div w-full flex justify-start items-center gap-4 pb-4" id={"bnode_lad6s" + (idx ? "__" + idx : "")} name="addtags" extra-attr >
                        <input data_id="bnode_s6uz0" type="text" placeholder="Add tags" className="w-[300px] p-3 outline outline-2 outline-[#9CA3AF] rounded-[12px] text-xl font-normal font-['Open_Sans'] text-[#6B7280]" id={"bnode_s6uz0" + (idx ? "__" + idx : "")} extra-attr />

                        <div data_id="bnode_vehgb" className="new-div w-fit flex items-center justify-center p-4 bg-[#F3F4F6] rounded-[12px] cursor-pointer gap-2" id={"bnode_vehgb" + (idx ? "__" + idx : "")} name="button" extra-attr >

                            <svg data_id="bnode_n32gb" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="plus" className="w-[30px] h-[30px] text-[#6B7280]" id={"bnode_n32gb" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

                                <path data_id="" d="M5 12h14" extra-attr >

                                </path>


                                <path data_id="" d="M12 5v14" extra-attr >

                                </path>

                            </svg>


                            <div data_id="bnode_oao8m" className="new-div w-fit flex justify-start items-center font-['Open_Sans'] text-black text-xl font-normal" id={"bnode_oao8m" + (idx ? "__" + idx : "")} extra-attr >
                                Add Tags
                            </div>

                        </div>

                    </div>


                    <div data_id="bnode_a2q0p" className="new-div w-full flex items-center justify-center" id={"bnode_a2q0p" + (idx ? "__" + idx : "")} extra-attr >

                        <div data_id="bnode_t5hx5" className="new-div w-full flex justify-start items-center gap-4" id={"bnode_t5hx5" + (idx ? "__" + idx : "")} extra-attr >

                            <button data_id="bnode_gs0pi" type="button" className="w-fit text-white bg-[#FF5994] focus:ring-4 focus:ring-blue-300 p-4 px-16 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center rounded-[8px]" id={"bnode_gs0pi" + (idx ? "__" + idx : "")} name="save" extra-attr >
                                SAVE
                            </button>


                            <button data_id="bnode_wwiqa" type="button" className="text-black bg-[#F3F4F6]  focus:ring-4 focus:ring-blue-300 rounded-[8px] p-4 px-16 text-xl font-['Open_Sans'] font-semibold flex justify-end items-center text-[#4B5563]" id={"bnode_wwiqa" + (idx ? "__" + idx : "")} name="cancel" extra-attr >
                                CANCEL
                            </button>

                        </div>

                    </div>

                </div>)}
                < COMP_REG.comp_bnode_986bz {...{ PM: [...PM, M], on_created: props.on_created || INFO.on_created, on_selected: props.on_selected || INFO.on_selected, INFO: INFO }} extra-attr />
            </div>

        </ErrorBoundary>
    )
}

export default comp_bnode_k2lnn;