import React, { useState, useEffect } from 'react';

const Loading = (props) => {

    const [loading, set_loading] = useState(false);

    let idx = 0;

    useEffect(()=>{
        if(!props.INFO?.model_id) return;
        const sub = g_fn.AS.GSTORE.subscribe_message(props.INFO.model_id, (e) => {
            if(['CREATING', 'UPDATING', 'DELETING', 'FETCHING'].includes(e.message)) {
                set_loading(true);
            }
            else {
                set_loading(false);
            }
        });

        return () => {
            sub.unsubscribe();
        }
    },[])

    const loading_html = (
        <div class={`absolute h-full w-full top-0 left-0 inset-0 bg-black/20`}>
                <div className={'flex items-center justify-center h-full w-full'}>
                    <svg className='w-32 h-32' version='1.1' id='L9' x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 0 0'>
                            <path fill='#fff' d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'>
                            <animateTransform 
                                attributeName='transform' 
                                attributeType='XML' 
                                type='rotate'
                                dur='1s' 
                                from='0 50 50'
                                to='360 50 50' 
                                repeatCount='indefinite' />
                        </path>
                    </svg>
                </div>
            </div>
    )


    return (
        <div>
            {[].map(m=>(<div></div>))}
            {loading && loading_html}
        </div>
    )
}

export default Loading;