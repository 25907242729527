import React from 'react';
import { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
	Routes,
	Route,
	Link,
	useNavigate,
} from "react-router-dom";

import {
	motion,
	AnimatePresence
} from "framer-motion";

import {
	fallbackRender,
	add_sub,
	remove_subs
} from "../utils.jsx";
import { COMP_REG } from "../comp_registry.js";
import g_fn from '../g_fn';






const AS = g_fn.AS;


// @req: keep reference to subs here and remove them on unmount or reload
const SUB_ID = "bnode_uqf0i";
const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


// local code - e.g sub components



const comp_bnode_uqf0i = (props) => {
	const INFO = { "model_id": "", "model_name": "", "op": "get_one", "comp_id": "comp_bnode_uqf0i" };

	// Routes




	// FOR IS_MANY
	let idx = props.idx;   // also for protection from error when we might think is many but it's not
	let V = props.V || {}; // for protection from error


	// STATES
	const [rerender, set_rerender] = useState({});
	const [M, set_M] = useState(props.M || {})
	const [PM, set_PM] = useState(props.PM || [])
	const [selected_M, set_selected_M] = useState(null)

	// REFS


	// EFFECTS

	useEffect(() => {
		console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
	}, [rerender])


	useEffect(() => {
		const deinit = init();

		return () => {
			if (deinit && typeof (deinit) === "function") deinit();
		}
	}, []);


	useEffect(() => {
		g_fn.bro_get_one(INFO, set_M, props);
	}, [props])


	useEffect(() => {
		const sub = AS.GSTORE.subscribe((e) => {
			// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

			// if model_id and eid are same
			// once data changes we will update M
		});

		return () => {
			sub.unsubscribe();
		}
	}, []);

	useEffect(() => {
		if (EN_BROKEN_COMP_LOGS.MODEL_EFFECT) {
			const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
			console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
		}
	}, [M])

	useEffect(() => {

		if (!INFO.model_id) return;

		if (query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
		const id = g_fn.get_selected_entity_id(props, INFO);
		if (!id) {
			return console.warn("NO ID FOUND TO UPDATE ENTITY");
		}

		(async () => {
			const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
			console.log("GSTORE GET ONE ENTITY : ", r);
			if (r) set_selected_M(r);
		})();

		const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
			const id = g_fn.get_selected_entity_id(props, INFO);
			if (!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
				if (r) set_selected_M(r);
			})();
		});

		// On set in GSTORE set state
		const sub2 = AS.GSTORE.subscribe((e)=>{
			const {type, model_id, eid, data} = e;
			 if(!data || !data.id) return;
			 if(type !== 'set') return;
			 if(model_id !== INFO.model_id) return;
			 set_M(M=>{
			   if(eid !== M.id) return M;
			   return data;
			 })
		 });

		return () => {
			sub.unsubscribe();
			sub2.unsubscribe();
		}
	}, [])


	// FUNCTIONS

	const init = () => {

		if (!INFO.model_id) return;


		INFO.query = query; // @todo : make it possible to change in UI editor
		const id = g_fn.get_one_find_id(props, INFO, relation);
		if (!id) {
			// errors => [{code:string, msg:string}]
			// warning => []
			console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
			// return;
		}

		// subscription
		const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e) => {
			console.log("USER RE-RENDERED");
			const data = e.data;
			set_M(data);
		});


		// first time
		AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);



		return () => {
			sub.unsubscribe();
		}

	}

	const test_print = () => {
		var contentToPrint = document.getElementById("bnode_l3lhf");
		console.log('content to print is: ', contentToPrint);
		var newWin = window.open('', '_blank');
		newWin.document.write(`<html>
	  <head>
	  	<script src="https://cdn.tailwindcss.com"></script>
		<style type="text/css">body {-webkit-print-color-adjust: exact;}</style>
	  <title>Print</title></head><body>`);
		newWin.document.write(contentToPrint.innerHTML);
		newWin.document.write('</body></html>');
		newWin.document.close();
		setTimeout(() => {
			newWin.print();
		}, 10)
		newWin.onafterprint = function () {
			newWin.close(); // Close the new window after printing
		};
	}


	// STATEMENTS

	INFO.set_M = set_M;
	INFO.on_created = props.on_created || props.INFO?.on_created;
	INFO.on_selected = props.on_selected || props.INFO?.on_selected;
	const relation = null;
	const query = null;




	// CONDITIONALS ATTRS
	const COND_ATTRS = {};

	COND_ATTRS["bnode_3ulgk"] = COND_ATTRS["bnode_3ulgk"] || {};


	COND_ATTRS["bnode_ewe4m"] = COND_ATTRS["bnode_ewe4m"] || {};


	COND_ATTRS["bnode_88qv2"] = COND_ATTRS["bnode_88qv2"] || {};


	COND_ATTRS["bnode_pjkvs"] = COND_ATTRS["bnode_pjkvs"] || {};


	COND_ATTRS["bnode_pu9x0"] = COND_ATTRS["bnode_pu9x0"] || {};


	COND_ATTRS["bnode_3boyn"] = COND_ATTRS["bnode_3boyn"] || {};


	COND_ATTRS["bnode_ggxxl"] = COND_ATTRS["bnode_ggxxl"] || {};


	COND_ATTRS["bnode_rj0aj"] = COND_ATTRS["bnode_rj0aj"] || {};


	COND_ATTRS["bnode_hkch1"] = COND_ATTRS["bnode_hkch1"] || {};


	COND_ATTRS["bnode_c47xi"] = COND_ATTRS["bnode_c47xi"] || {};


	COND_ATTRS["bnode_rkm2f"] = COND_ATTRS["bnode_rkm2f"] || {};


	COND_ATTRS["bnode_l3lhf"] = COND_ATTRS["bnode_l3lhf"] || {};


	COND_ATTRS["bnode_mtiw6"] = COND_ATTRS["bnode_mtiw6"] || {};


	COND_ATTRS["bnode_r9wmk"] = COND_ATTRS["bnode_r9wmk"] || {};


	COND_ATTRS["bnode_uqf0i"] = COND_ATTRS["bnode_uqf0i"] || {};



	// DYNAMIC REACT STATES
	const REACT_STATES = {};
	REACT_STATES["M"] = { state: M, set_state: set_M };

	const AS_COMPONENTS = AS.components["comp_bnode_uqf0i"] || {};
	AS_COMPONENTS.REACT_STATES = REACT_STATES;
	AS.components["comp_bnode_uqf0i"] = AS_COMPONENTS;




	// MAPPED DATA
	const MAPPED_DATA = {};



	return (
		<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => { console.error(d) }}>

			<div data_id="bnode_uqf0i" className="min-h-[64px] w-full h-fit overflow-auto flex gap-4 p-4 items-center justify-center bg-[#eeeeee] flex-col" name="page:print" findex="a8" id={"bnode_uqf0i" + (idx ? "__" + idx : "")} onClick={(e) => {
				g_fn.dev.emit_m(e, M, INFO)
			}} extra-attr >

				{/* GO BACK */}
				<div data_id="bnode_g8d3x" className="w-2/3 flex justify-start items-center gap-2 text-[#6B7280]" id={"bnode_g8d3x" + (idx ? "__" + idx : "")} name="Back button" extra-attr >
					<div className='w-fit flex justify-start items-center gap-2  cursor-pointer text-[#FF5994] border border-[#FF5994] rounded-lg px-2 py-1' action="['go-to-page']" page_id="bnode_jj973" onClick={(e) => {
						if (window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
						e.preventDefault(); e.stopPropagation();

						const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
						g_fn.bro_go_to_page(e, M, INFO, props)
						el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
					}}>

						<svg data_id="bnode_959h0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="move-left" className="w-20px h-20px cursor-pointer" id={"bnode_959h0" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

							<path data_id="" d="M6 8L2 12L6 16" extra-attr >

							</path>


							<path data_id="" d="M2 12H22" extra-attr >

							</path>

						</svg>


						<div data_id="bnode_wbuz2" className="new-div w-fit flex items-center justify-center text-xl font-bold font-['Open_Sans'] cursor-pointer" id={"bnode_wbuz2" + (idx ? "__" + idx : "")} extra-attr >
							Back
						</div>
					</div>

				</div>


				<div data_id="bnode_c47xi" className="new-div w-full gap-4 flex justify-end items-center" id={"bnode_c47xi" + (idx ? "__" + idx : "")} name="exportbuttons" extra-attr >

					<button data_id="bnode_3boyn" type="button" className="text-[#6B7280] focus:ring-4 focus:ring-blue-300 rounded-[8px] p-2 gap-1 font-['Open_Sans'] font-semibold flex justify-end items-center text-lg border-[1px] border-[#6B7280]" id={"bnode_3boyn" + (idx ? "__" + idx : "")} name="publish" onClick={test_print} extra-attr >

						<svg data_id="bnode_pjkvs" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="printer" className="w-[20px] h-[20px]" id={"bnode_pjkvs" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

							<polyline data_id="" points="6 9 6 2 18 2 18 9" extra-attr >

							</polyline>


							<path data_id="" d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" extra-attr >

							</path>


							<rect data_id="" width="12" height="8" x="6" y="14" extra-attr >

							</rect>

						</svg>


						<div data_id="bnode_pu9x0" className="new-div w-full flex items-center justify-center text-base font-semibold font-['Open_Sans']" id={"bnode_pu9x0" + (idx ? "__" + idx : "")} extra-attr >
							Print
						</div>

					</button>


					<button data_id="bnode_hkch1" type="button" className="w-fit text-white bg-[#FF5994] focus:ring-4 focus:ring-blue-300 p-2 gap-1 font-['Open_Sans'] font-semibold flex justify-end items-center rounded-[8px] text-lg" id={"bnode_hkch1" + (idx ? "__" + idx : "")} name="download" extra-attr >

						<svg data_id="bnode_ggxxl" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="download" className="w-[20px] h-[20px]" id={"bnode_ggxxl" + (idx ? "__" + idx : "")} b_type="icon" extra-attr >

							<path data_id="" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" extra-attr >

							</path>


							<polyline data_id="" points="7 10 12 15 17 10" extra-attr >

							</polyline>


							<line data_id="" x1="12" x2="12" y1="15" y2="3" extra-attr >

							</line>

						</svg>


						<div data_id="bnode_rj0aj" className="new-div w-full flex items-center justify-center text-lg font-semibold font-['Open_Sans']" id={"bnode_rj0aj" + (idx ? "__" + idx : "")} extra-attr >
							Download
						</div>

					</button>

				</div>


				<div data_id="bnode_mtiw6" className="new-div w-2/3 flex items-center justify-center bg-white rounded-[20px] p-8 flex-col pb-8 gap-12" id={"bnode_mtiw6" + (idx ? "__" + idx : "")} name="header" style={{ margin: "0px", marginBottom: "0px", marginLeft: "0px", marginRight: "0px", marginTop: "0px" }} extra-attr >

					<div data_id="bnode_rkm2f" className="new-div w-full flex items-center justify-center" id={"bnode_rkm2f" + (idx ? "__" + idx : "")} extra-attr >
						< COMP_REG.comp_bnode_h4zoh {...{ PM: [...PM, M], on_created: props.on_created || INFO.on_created, on_selected: props.on_selected || INFO.on_selected, INFO: INFO }} extra-attr />
					</div>


					<div data_id="bnode_l3lhf" className="new-div w-full flex items-center justify-center" id={"bnode_l3lhf" + (idx ? "__" + idx : "")} extra-attr >
						< COMP_REG.comp_bnode_5nuxp {...{ PM: [...PM, M], on_created: props.on_created || INFO.on_created, on_selected: props.on_selected || INFO.on_selected, INFO: INFO }} extra-attr />
					</div>

				</div>


				<fn data_id="bnode_r9wmk" name="fn:printTest" id={"bnode_r9wmk" + (idx ? "__" + idx : "")} extra-attr >

				</fn>

			</div>

		</ErrorBoundary>
	)
}

export default comp_bnode_uqf0i;