import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_fnv81";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_fnv81 = (props)=>{
			const INFO  = {"model_id":"bhfmq","model_name":"user","op":"get_one","comp_id":"comp_bnode_fnv81"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					g_fn.bro_get_one(INFO, set_M, props);
				}, [props])
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = ()=>{

				if(!INFO.model_id) return;


				INFO.query = query; // @todo : make it possible to change in UI editor
				const id = g_fn.get_one_find_id(props, INFO, relation);
				if(!id) {
					// errors => [{code:string, msg:string}]
					// warning => []
					console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
					// return;
				}

				// subscription
				const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e)=>{
					console.log("USER RE-RENDERED");
					const data = e.data;
					set_M(data);
				});


				// first time
				AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);



				return ()=>{
					sub.unsubscribe();
				}

			}
		


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_dy1hp"] = COND_ATTRS["bnode_dy1hp"] || {};
			

				COND_ATTRS["bnode_azxo6"] = COND_ATTRS["bnode_azxo6"] || {};
			

				COND_ATTRS["bnode_us8c5"] = COND_ATTRS["bnode_us8c5"] || {};
			

				COND_ATTRS["bnode_ne8ja"] = COND_ATTRS["bnode_ne8ja"] || {};
			

				COND_ATTRS["bnode_it7b0"] = COND_ATTRS["bnode_it7b0"] || {};
			

				COND_ATTRS["bnode_ey21e"] = COND_ATTRS["bnode_ey21e"] || {};
			

				COND_ATTRS["bnode_fnv81"] = COND_ATTRS["bnode_fnv81"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_fnv81"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_fnv81"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_fnv81" name="user" op="get_one" className="w-full flex flex-col" id={"bnode_fnv81" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_ey21e" name="user-data" className="flex flex-col w-12 h-12" id={"bnode_ey21e" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_it7b0" name="CONT-image" className="w-full  " id={"bnode_it7b0" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_ne8ja" id={"bnode_ne8ja" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_us8c5" name="image" className="flex items-center justify-center w-full gap-6 flex-row cursor-pointer" id={"bnode_us8c5" + (idx?"__"+idx:"")} action="['go-to-page']" page_id="bnode_n381t" model_id="bhfmq" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_go_to_page(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <div data_id="bnode_dy1hp" name="image" style={{backgroundImage:`url("${M.image?.url}")`, height:"40px", width:"40px"}} className="w-8 h-8 flex flex-shrink-0 bg-no-repeat  bg-gray-200 bg-center bg-cover rounded-full" id={"bnode_dy1hp" + (idx?"__"+idx:"")} extra-attr >
                
            </div>
        
{ !M.image && (<svg data_id="bnode_azxo6" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="circle-user" className="w-10 h-10" id={"bnode_azxo6" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <circle data_id="" cx="12" cy="12" r="10" extra-attr >
                
            </circle>
        

            <circle data_id="" cx="12" cy="10" r="3" extra-attr >
                
            </circle>
        

            <path data_id="" d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662" extra-attr >
                
            </path>
        
            </svg>) }
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_fnv81;