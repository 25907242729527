import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_yezo2";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_yezo2 = (props)=>{
			const INFO  = {"model_id":"","model_name":"","op":"get_one","comp_id":"comp_bnode_yezo2"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					g_fn.bro_get_one(INFO, set_M, props);
				}, [props])
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = ()=>{

				if(!INFO.model_id) return;


				INFO.query = query; // @todo : make it possible to change in UI editor
				const id = g_fn.get_one_find_id(props, INFO, relation);
				if(!id) {
					// errors => [{code:string, msg:string}]
					// warning => []
					console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
					// return;
				}

				// subscription
				const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e)=>{
					console.log("USER RE-RENDERED");
					const data = e.data;
					set_M(data);
				});


				// first time
				AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);



				return ()=>{
					sub.unsubscribe();
				}

			}
		


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_atmtu"] = COND_ATTRS["bnode_atmtu"] || {};
			

				COND_ATTRS["bnode_o1m57"] = COND_ATTRS["bnode_o1m57"] || {};
			

				COND_ATTRS["bnode_6vca2"] = COND_ATTRS["bnode_6vca2"] || {};
			

				COND_ATTRS["bnode_e7g1m"] = COND_ATTRS["bnode_e7g1m"] || {};
			

				COND_ATTRS["bnode_4lxo6"] = COND_ATTRS["bnode_4lxo6"] || {};
			

				COND_ATTRS["bnode_tptdo"] = COND_ATTRS["bnode_tptdo"] || {};
			

				COND_ATTRS["bnode_lfklb"] = COND_ATTRS["bnode_lfklb"] || {};
			

				COND_ATTRS["bnode_lqqbo"] = COND_ATTRS["bnode_lqqbo"] || {};
			

				COND_ATTRS["bnode_wb9ue"] = COND_ATTRS["bnode_wb9ue"] || {};
			

				COND_ATTRS["bnode_itp46"] = COND_ATTRS["bnode_itp46"] || {};
			

				COND_ATTRS["bnode_z56vg"] = COND_ATTRS["bnode_z56vg"] || {};
			

				COND_ATTRS["bnode_9imfd"] = COND_ATTRS["bnode_9imfd"] || {};
			

				COND_ATTRS["bnode_gttkd"] = COND_ATTRS["bnode_gttkd"] || {};
			

				COND_ATTRS["bnode_tsr20"] = COND_ATTRS["bnode_tsr20"] || {};
			

				COND_ATTRS["bnode_yq354"] = COND_ATTRS["bnode_yq354"] || {};
			

				COND_ATTRS["bnode_2ukvg"] = COND_ATTRS["bnode_2ukvg"] || {};
			

				COND_ATTRS["bnode_55k7y"] = COND_ATTRS["bnode_55k7y"] || {};
			

				COND_ATTRS["bnode_se6vl"] = COND_ATTRS["bnode_se6vl"] || {};
			

				COND_ATTRS["bnode_r8jz9"] = COND_ATTRS["bnode_r8jz9"] || {};
			

				COND_ATTRS["bnode_bpkr6"] = COND_ATTRS["bnode_bpkr6"] || {};
			

				COND_ATTRS["bnode_lrsiv"] = COND_ATTRS["bnode_lrsiv"] || {};
			

				COND_ATTRS["bnode_5n8m6"] = COND_ATTRS["bnode_5n8m6"] || {};
			

				COND_ATTRS["bnode_u6460"] = COND_ATTRS["bnode_u6460"] || {};
			

				COND_ATTRS["bnode_2hcop"] = COND_ATTRS["bnode_2hcop"] || {};
			

				COND_ATTRS["bnode_nah38"] = COND_ATTRS["bnode_nah38"] || {};
			

				COND_ATTRS["bnode_dshr3"] = COND_ATTRS["bnode_dshr3"] || {};
			

				COND_ATTRS["bnode_v0t7w"] = COND_ATTRS["bnode_v0t7w"] || {};
			

				COND_ATTRS["bnode_quktu"] = COND_ATTRS["bnode_quktu"] || {};
			

				COND_ATTRS["bnode_sgrm8"] = COND_ATTRS["bnode_sgrm8"] || {};
			

				COND_ATTRS["bnode_v3noq"] = COND_ATTRS["bnode_v3noq"] || {};
			

				COND_ATTRS["bnode_o126j"] = COND_ATTRS["bnode_o126j"] || {};
			

				COND_ATTRS["bnode_km6wv"] = COND_ATTRS["bnode_km6wv"] || {};
			

				COND_ATTRS["bnode_bz8gn"] = COND_ATTRS["bnode_bz8gn"] || {};
			

				COND_ATTRS["bnode_d8spq"] = COND_ATTRS["bnode_d8spq"] || {};
			

				COND_ATTRS["bnode_cdjxe"] = COND_ATTRS["bnode_cdjxe"] || {};
			

				COND_ATTRS["bnode_kkwhp"] = COND_ATTRS["bnode_kkwhp"] || {};
			

				COND_ATTRS["bnode_nykgh"] = COND_ATTRS["bnode_nykgh"] || {};
			

				COND_ATTRS["bnode_0lkbs"] = COND_ATTRS["bnode_0lkbs"] || {};
			

				COND_ATTRS["paint0_linear_299_2567"] = COND_ATTRS["paint0_linear_299_2567"] || {};
			

				COND_ATTRS["bnode_egt2z"] = COND_ATTRS["bnode_egt2z"] || {};
			

				COND_ATTRS["bnode_rzzgc"] = COND_ATTRS["bnode_rzzgc"] || {};
			

				COND_ATTRS["bnode_2mzfb"] = COND_ATTRS["bnode_2mzfb"] || {};
			

				COND_ATTRS["bnode_g7j67"] = COND_ATTRS["bnode_g7j67"] || {};
			

				COND_ATTRS["bnode_r6rb5"] = COND_ATTRS["bnode_r6rb5"] || {};
			

				COND_ATTRS["bnode_1dg5q"] = COND_ATTRS["bnode_1dg5q"] || {};
			

				COND_ATTRS["bnode_x05s7"] = COND_ATTRS["bnode_x05s7"] || {};
			

				COND_ATTRS["bnode_mzoi6"] = COND_ATTRS["bnode_mzoi6"] || {};
			

				COND_ATTRS["bnode_dtfz2"] = COND_ATTRS["bnode_dtfz2"] || {};
			

				COND_ATTRS["bnode_wu9af"] = COND_ATTRS["bnode_wu9af"] || {};
			

				COND_ATTRS["bnode_acyl6"] = COND_ATTRS["bnode_acyl6"] || {};
			

				COND_ATTRS["bnode_tg4x4"] = COND_ATTRS["bnode_tg4x4"] || {};
			

				COND_ATTRS["bnode_a7dea"] = COND_ATTRS["bnode_a7dea"] || {};
			

				COND_ATTRS["bnode_pf214"] = COND_ATTRS["bnode_pf214"] || {};
			

				COND_ATTRS["bnode_owmx1"] = COND_ATTRS["bnode_owmx1"] || {};
			

				COND_ATTRS["bnode_qz04g"] = COND_ATTRS["bnode_qz04g"] || {};
			

				COND_ATTRS["bnode_6ai68"] = COND_ATTRS["bnode_6ai68"] || {};
			

				COND_ATTRS["bnode_l0qbp"] = COND_ATTRS["bnode_l0qbp"] || {};
			

				COND_ATTRS["bnode_6bj74"] = COND_ATTRS["bnode_6bj74"] || {};
			

				COND_ATTRS["bnode_f7mge"] = COND_ATTRS["bnode_f7mge"] || {};
			

				COND_ATTRS["bnode_sgt6h"] = COND_ATTRS["bnode_sgt6h"] || {};
			

				COND_ATTRS["bnode_or110"] = COND_ATTRS["bnode_or110"] || {};
			

				COND_ATTRS["bnode_5ztzo"] = COND_ATTRS["bnode_5ztzo"] || {};
			

				COND_ATTRS["bnode_z9c5f"] = COND_ATTRS["bnode_z9c5f"] || {};
			

				COND_ATTRS["bnode_p0cba"] = COND_ATTRS["bnode_p0cba"] || {};
			

				COND_ATTRS["bnode_vmapd"] = COND_ATTRS["bnode_vmapd"] || {};
			

				COND_ATTRS["bnode_5hx3d"] = COND_ATTRS["bnode_5hx3d"] || {};
			

				COND_ATTRS["bnode_wwc94"] = COND_ATTRS["bnode_wwc94"] || {};
			

				COND_ATTRS["bnode_4o5ra"] = COND_ATTRS["bnode_4o5ra"] || {};
			

				COND_ATTRS["bnode_p2q7j"] = COND_ATTRS["bnode_p2q7j"] || {};
			

				COND_ATTRS["bnode_bzk7a"] = COND_ATTRS["bnode_bzk7a"] || {};
			

				COND_ATTRS["bnode_sloma"] = COND_ATTRS["bnode_sloma"] || {};
			

				COND_ATTRS["bnode_n6w9s"] = COND_ATTRS["bnode_n6w9s"] || {};
			

				COND_ATTRS["bnode_748wg"] = COND_ATTRS["bnode_748wg"] || {};
			

				COND_ATTRS["bnode_4433p"] = COND_ATTRS["bnode_4433p"] || {};
			

				COND_ATTRS["bnode_s69g2"] = COND_ATTRS["bnode_s69g2"] || {};
			

				COND_ATTRS["bnode_po6so"] = COND_ATTRS["bnode_po6so"] || {};
			

				COND_ATTRS["bnode_2r5dl"] = COND_ATTRS["bnode_2r5dl"] || {};
			

				COND_ATTRS["bnode_6vnz8"] = COND_ATTRS["bnode_6vnz8"] || {};
			

				COND_ATTRS["bnode_g7rrc"] = COND_ATTRS["bnode_g7rrc"] || {};
			

				COND_ATTRS["bnode_x2kri"] = COND_ATTRS["bnode_x2kri"] || {};
			

				COND_ATTRS["bnode_sx8lw"] = COND_ATTRS["bnode_sx8lw"] || {};
			

				COND_ATTRS["bnode_zppaa"] = COND_ATTRS["bnode_zppaa"] || {};
			

				COND_ATTRS["bnode_lj3zk"] = COND_ATTRS["bnode_lj3zk"] || {};
			

				COND_ATTRS["bnode_1a2ml"] = COND_ATTRS["bnode_1a2ml"] || {};
			

				COND_ATTRS["bnode_6g0o5"] = COND_ATTRS["bnode_6g0o5"] || {};
			

				COND_ATTRS["bnode_kpx27"] = COND_ATTRS["bnode_kpx27"] || {};
			

				COND_ATTRS["bnode_c3q7t"] = COND_ATTRS["bnode_c3q7t"] || {};
			

				COND_ATTRS["bnode_1ccab"] = COND_ATTRS["bnode_1ccab"] || {};
			

				COND_ATTRS["bnode_yezo2"] = COND_ATTRS["bnode_yezo2"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_yezo2"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_yezo2"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_yezo2" className="min-h-[64px] w-full h-fit overflow-auto flex gap-4 p-4 items-center justify-center bg-[#eeeeee] flex-col" name="page:enhancequiz" findex="a5" id={"bnode_yezo2" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_e7g1m" className="new-div w-full flex justify-start items-center" id={"bnode_e7g1m" + (idx?"__"+idx:"")} name="back bar" extra-attr >
                
            <div data_id="bnode_6vca2" className="new-div w-fit flex justify-start items-center gap-2  cursor-pointer text-[#FF5994] border border-[#FF5994] rounded-lg px-2 py-1" id={"bnode_6vca2" + (idx?"__"+idx:"")} name="Back button" extra-attr >
                
            <svg data_id="bnode_atmtu" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="move-left" className="w-20px h-20px cursor-pointer" id={"bnode_atmtu" + (idx?"__"+idx:"")} b_type="icon" action="['go-to-page']" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_go_to_page(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <path data_id="" d="M6 8L2 12L6 16" extra-attr >
                
            </path>
        

            <path data_id="" d="M2 12H22" extra-attr >
                
            </path>
        
            </svg>
        

            <div data_id="bnode_o1m57" className="new-div w-fit flex items-center justify-center font-['Open_Sans'] cursor-pointer text-base font-semibold" id={"bnode_o1m57" + (idx?"__"+idx:"")} action="['go-to-page']" page_id="bnode_00000" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_go_to_page(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                Back
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_6g0o5" className="new-div w-full flex items-center justify-center flex-col" id={"bnode_6g0o5" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_4lxo6" className="new-div w-full font-['Open_Sans'] flex justify-start items-center text-2xl font-semibold" id={"bnode_4lxo6" + (idx?"__"+idx:"")} extra-attr >
                Enhance Quiz with AI
            </div>
        

            <div data_id="bnode_lqqbo" className="new-div w-full flex items-center justify-center gap-5 py-4" id={"bnode_lqqbo" + (idx?"__"+idx:"")} name="prompt bar" extra-attr >
                <input data_id="bnode_tptdo" type="text" placeholder="Convert this quiz to real-world scenario" className="w-full rounded p-5 outline outline-2 outline-[#FF5994] rounded-[12px] font-['Open_Sans'] text-base font-semibold" id={"bnode_tptdo" + (idx?"__"+idx:"")} style={{boxShadow:"rgb(52, 136, 253) 0px 3px 7.8px 0px, rgb(255, 89, 148) 0px -2px 6.7px 0px", webkitBoxShadow:"rgb(52, 136, 253) 0px 3px 7.8px 0px, rgb(255, 89, 148) 0px -2px 6.7px 0px"}} extra-attr />

            <button data_id="bnode_lfklb" type="button" className="group relative p-5 w-fit flex items-center justify-center flex-col bg-gradient-to-r from-[#FF5994] to-[#3488FD] hover:from-[#1E62C2] hover:to-[#E62368] overflow-hidden cursor-pointer transition-all ease-in-out duration-500 font-['Open_Sans'] text-white rounded-[12px] text-base font-semibold" id={"bnode_lfklb" + (idx?"__"+idx:"")} extra-attr >
                ✨Enhance✨
            </button>
        
            </div>
        

            <div data_id="bnode_55k7y" className="new-div w-full flex justify-start items-center gap-2" id={"bnode_55k7y" + (idx?"__"+idx:"")} name="promtsuggestions" extra-attr >
                
            <div data_id="bnode_itp46" className="new-div w-fit flex items-center justify-center px-3 py-2  bg-[#5A5A5A] rounded-full" id={"bnode_itp46" + (idx?"__"+idx:"")} name="realworld" extra-attr >
                
            <div data_id="bnode_wb9ue" className="new-div w-fit flex items-center justify-center font-normal font-['Open_Sans'] text-white text-sm" id={"bnode_wb9ue" + (idx?"__"+idx:"")} extra-attr >
                ✨ Convert to Real-World Scenario
            </div>
        
            </div>
        

            <div data_id="bnode_9imfd" className="new-div w-fit flex items-center justify-center px-3 py-2  bg-[#5A5A5A] rounded-full" id={"bnode_9imfd" + (idx?"__"+idx:"")} name="fun" extra-attr >
                
            <div data_id="bnode_z56vg" className="new-div w-fit flex items-center justify-center font-normal font-['Open_Sans'] text-white text-sm" id={"bnode_z56vg" + (idx?"__"+idx:"")} extra-attr >
                ✨ Make it Fun
            </div>
        
            </div>
        

            <div data_id="bnode_tsr20" className="new-div w-fit flex items-center justify-center px-3 py-2  bg-[#5A5A5A] rounded-full" id={"bnode_tsr20" + (idx?"__"+idx:"")} name="grammaticalerror" extra-attr >
                
            <div data_id="bnode_gttkd" className="new-div w-fit flex items-center justify-center font-normal font-['Open_Sans'] text-white text-sm" id={"bnode_gttkd" + (idx?"__"+idx:"")} extra-attr >
                ✨ Fix Grammatical and Spelling errors
            </div>
        
            </div>
        

            <div data_id="bnode_2ukvg" className="new-div w-fit flex items-center justify-center px-3 py-2  bg-[#5A5A5A] rounded-full" id={"bnode_2ukvg" + (idx?"__"+idx:"")} name="Simplify" extra-attr >
                
            <div data_id="bnode_yq354" className="new-div w-fit flex items-center justify-center font-normal font-['Open_Sans'] text-white text-sm" id={"bnode_yq354" + (idx?"__"+idx:"")} extra-attr >
                ✨ Simplify Questions
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_bpkr6" className="new-div w-full flex-col flex justify-start items-start py-8" id={"bnode_bpkr6" + (idx?"__"+idx:"")} name="enhanced" extra-attr >
                
            <div data_id="bnode_se6vl" className="new-div w-fit flex items-center justify-center font-semibold font-['Open_Sans'] text-base" id={"bnode_se6vl" + (idx?"__"+idx:"")} extra-attr >
                7/10 Questions were Enhanced
            </div>
        

            <div data_id="bnode_r8jz9" className="new-div w-fit flex items-center justify-center text-sm font-normal font-['Open_Sans'] text-[#6B7280]" id={"bnode_r8jz9" + (idx?"__"+idx:"")} extra-attr >
                Review and save to apply changes to your quiz
            </div>
        
            </div>
        

            <div data_id="bnode_lrsiv" className="new-div w-full font-['Open_Sans'] flex justify-start items-center pb-[10px] text-base font-semibold" id={"bnode_lrsiv" + (idx?"__"+idx:"")} extra-attr >
                Enhanced Questions
            </div>
        

            <div data_id="bnode_5ztzo" className="new-div w-full flex items-center justify-center gap-2 pb-8" id={"bnode_5ztzo" + (idx?"__"+idx:"")} name="questions" extra-attr >
                
            <div data_id="bnode_0lkbs" className="new-div w-full flex items-center justify-center flex-col bg-white p-8 rounded-[12px] opacity-50" id={"bnode_0lkbs" + (idx?"__"+idx:"")} name="left" extra-attr >
                
            <div data_id="bnode_nah38" className="new-div w-full flex items-center justify-center pb-2" id={"bnode_nah38" + (idx?"__"+idx:"")} name="top" extra-attr >
                
            <div data_id="bnode_5n8m6" className="new-div w-full flex justify-start items-center text-sm font-['Open_Sans'] font-normal" id={"bnode_5n8m6" + (idx?"__"+idx:"")} name="left" extra-attr >
                1. Multiple Choice
            </div>
        

            <div data_id="bnode_2hcop" className="new-div w-full flex justify-end items-center" id={"bnode_2hcop" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <svg data_id="bnode_u6460" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_u6460" + (idx?"__"+idx:"")} b_type="icon" className="cursor-pointer" extra-attr >
                
            <rect data_id="" width="32" height="32" rx="4" fill="#D1D5DB" extra-attr >
                
            </rect>
        

            <path data_id="" d="M21.3332 12L13.9998 19.3333L10.6665 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        
            </div>
        

            <div data_id="bnode_dshr3" className="new-div w-full font-['Open_Sans'] flex justify-start items-center text-sm font-normal" id={"bnode_dshr3" + (idx?"__"+idx:"")} extra-attr >
                Carl and the passions changed name to what?
            </div>
        

            <div data_id="bnode_nykgh" className="new-div w-full flex items-center justify-center gap-4" id={"bnode_nykgh" + (idx?"__"+idx:"")} name="options" extra-attr >
                
            <div data_id="bnode_o126j" className="new-div w-full flex items-center justify-center flex-col gap-4" id={"bnode_o126j" + (idx?"__"+idx:"")} name="left" extra-attr >
                
            <div data_id="bnode_quktu" className="new-div w-full flex items-center justify-center bg-[#F3F4F6] p-4 rounded-[8px]" id={"bnode_quktu" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_v0t7w" className="new-div w-full flex justify-start items-center text-sm font-normal font-['Open_Sans']" id={"bnode_v0t7w" + (idx?"__"+idx:"")} extra-attr >
                🔴 A. Answer one
            </div>
        
            </div>
        

            <div data_id="bnode_v3noq" className="new-div w-full flex items-center justify-center bg-[#F3F4F6] p-4 rounded-[8px]" id={"bnode_v3noq" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_sgrm8" className="new-div w-full flex justify-start items-center text-sm font-normal font-['Open_Sans']" id={"bnode_sgrm8" + (idx?"__"+idx:"")} extra-attr >
                🔴 C. Answer three 
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_kkwhp" className="new-div w-full flex items-center justify-center flex-col gap-4" id={"bnode_kkwhp" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <div data_id="bnode_bz8gn" className="new-div w-full flex items-center justify-center bg-[#F3F4F6] p-4 rounded-[8px]" id={"bnode_bz8gn" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_km6wv" className="new-div w-full flex justify-start items-center text-sm font-normal font-['Open_Sans']" id={"bnode_km6wv" + (idx?"__"+idx:"")} extra-attr >
                🟢 B. Answer two
            </div>
        
            </div>
        

            <div data_id="bnode_cdjxe" className="new-div w-full flex items-center justify-center bg-[#F3F4F6] p-4 rounded-[8px]" id={"bnode_cdjxe" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_d8spq" className="new-div w-full flex justify-start items-center text-sm font-normal font-['Open_Sans']" id={"bnode_d8spq" + (idx?"__"+idx:"")} extra-attr >
                🔴 D. Answer four
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <svg data_id="bnode_2mzfb" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="chevrons-right" className="w-[48px] h-[48px] text-gradient-to-r from-[#FF5994] to-[#3488FD]" id={"bnode_2mzfb" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="m6 17 5-5-5-5" extra-attr >
                
            </path>
        

            <path data_id="" d="m13 17 5-5-5-5" extra-attr >
                
            </path>
        

            <svg data_id="bnode_egt2z" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_egt2z" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M13 17.5L18 12.5L13 7.5M6 17.5L11 12.5L6 7.5" stroke="url(#paint0_linear_299_2567)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" extra-attr >
                
            </path>
        

            <defs data_id="" extra-attr >
                
            <lineargradient data_id="paint0_linear_299_2567" id={"paint0_linear_299_2567" + (idx?"__"+idx:"")} x1="6" y1="7.5" x2="19.5204" y2="10.4239" gradientUnits="userSpaceOnUse" extra-attr >
                
            <stop data_id="" stop-color="#FF5994" extra-attr >
                
            </stop>
        

            <stop data_id="" offset="1" stop-color="#3488FD" extra-attr >
                
            </stop>
        
            </lineargradient>
        
            </defs>
        
            </svg>
        

            <svg data_id="bnode_rzzgc" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_rzzgc" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M13 17.5L18 12.5L13 7.5M6 17.5L11 12.5L6 7.5" stroke="url(#paint0_linear_299_2567)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" extra-attr >
                
            </path>
        

            <defs data_id="" extra-attr >
                
            <lineargradient data_id="paint0_linear_299_2567" id={"paint0_linear_299_2567" + (idx?"__"+idx:"")} x1="6" y1="7.5" x2="19.5204" y2="10.4239" gradientUnits="userSpaceOnUse" extra-attr >
                
            <stop data_id="" stop-color="#FF5994" extra-attr >
                
            </stop>
        

            <stop data_id="" offset="1" stop-color="#3488FD" extra-attr >
                
            </stop>
        
            </lineargradient>
        
            </defs>
        
            </svg>
        
            </svg>
        

            <div data_id="bnode_or110" className="new-div w-full flex items-center justify-center flex-col bg-white p-8 rounded-[12px]" id={"bnode_or110" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <div data_id="bnode_dtfz2" className="new-div w-full pb-2 flex justify-center items-center" id={"bnode_dtfz2" + (idx?"__"+idx:"")} name="top" extra-attr >
                
            <div data_id="bnode_1dg5q" className="new-div w-full flex justify-start items-center gap-3" id={"bnode_1dg5q" + (idx?"__"+idx:"")} name="left" extra-attr >
                
            <div data_id="bnode_g7j67" className="new-div w-fit flex justify-start items-center text-sm font-normal font-['Open_Sans']" id={"bnode_g7j67" + (idx?"__"+idx:"")} name="left" extra-attr >
                1. Multiple Choice
            </div>
        

            <button data_id="bnode_r6rb5" type="button" className="group relative py-0.5 px-1 w-fit bg-gradient-to-r from-[#FF5994] to-[#3488FD] rounded-[4px] font-['Open_Sans'] text-white text-xs justify-center items-center font-normal" id={"bnode_r6rb5" + (idx?"__"+idx:"")} extra-attr >
                ✨ Enhanced
            </button>
        
            </div>
        

            <div data_id="bnode_mzoi6" className="new-div w-full flex justify-end items-center" id={"bnode_mzoi6" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <svg data_id="bnode_x05s7" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_x05s7" + (idx?"__"+idx:"")} b_type="icon" className="cursor-pointer" extra-attr >
                
            <rect data_id="" width="32" height="32" rx="4" fill="#F55B99" extra-attr >
                
            </rect>
        

            <path data_id="" d="M21.3332 12L13.9998 19.3333L10.6665 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        
            </div>
        

            <div data_id="bnode_wu9af" className="new-div w-full font-['Open_Sans'] flex justify-start items-center text-sm font-normal" id={"bnode_wu9af" + (idx?"__"+idx:"")} extra-attr >
                Carl and the passions changed name to what?
            </div>
        

            <div data_id="bnode_sgt6h" className="new-div w-full flex items-center justify-center gap-4" id={"bnode_sgt6h" + (idx?"__"+idx:"")} name="options" extra-attr >
                
            <div data_id="bnode_owmx1" className="new-div w-full flex items-center justify-center flex-col gap-4" id={"bnode_owmx1" + (idx?"__"+idx:"")} name="left" extra-attr >
                
            <div data_id="bnode_tg4x4" className="new-div w-full flex items-center justify-center bg-[#F3F4F6] p-4 rounded-[8px]" id={"bnode_tg4x4" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_acyl6" className="new-div w-full flex justify-start items-center text-sm font-normal font-['Open_Sans']" id={"bnode_acyl6" + (idx?"__"+idx:"")} extra-attr >
                🔴 A. Answer one
            </div>
        
            </div>
        

            <div data_id="bnode_pf214" className="new-div w-full flex items-center justify-center bg-[#F3F4F6] p-4 rounded-[8px]" id={"bnode_pf214" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_a7dea" className="new-div w-full flex justify-start items-center text-sm font-normal font-['Open_Sans']" id={"bnode_a7dea" + (idx?"__"+idx:"")} extra-attr >
                🔴 C. Answer three 
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_f7mge" className="new-div w-full flex items-center justify-center flex-col gap-4" id={"bnode_f7mge" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <div data_id="bnode_6ai68" className="new-div w-full flex items-center justify-center bg-[#F3F4F6] p-4 rounded-[8px]" id={"bnode_6ai68" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_qz04g" className="new-div w-full flex justify-start items-center text-sm font-normal font-['Open_Sans']" id={"bnode_qz04g" + (idx?"__"+idx:"")} extra-attr >
                🟢 B. Answer two
            </div>
        
            </div>
        

            <div data_id="bnode_6bj74" className="new-div w-full flex items-center justify-center bg-[#F3F4F6] p-4 rounded-[8px]" id={"bnode_6bj74" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_l0qbp" className="new-div w-full flex justify-start items-center text-sm font-normal font-['Open_Sans']" id={"bnode_l0qbp" + (idx?"__"+idx:"")} extra-attr >
                🔴 D. Answer four
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_1a2ml" className="new-div w-full flex items-center justify-center gap-2" id={"bnode_1a2ml" + (idx?"__"+idx:"")} name="questions" extra-attr >
                
            <div data_id="bnode_sloma" className="new-div w-full flex items-center justify-center flex-col bg-white p-8 rounded-[12px] opacity-50" id={"bnode_sloma" + (idx?"__"+idx:"")} name="left" extra-attr >
                
            <div data_id="bnode_5hx3d" className="new-div w-full flex items-center justify-center pb-2" id={"bnode_5hx3d" + (idx?"__"+idx:"")} name="top" extra-attr >
                
            <div data_id="bnode_z9c5f" className="new-div w-full flex justify-start items-center font-semibold text-base" id={"bnode_z9c5f" + (idx?"__"+idx:"")} name="left" extra-attr >
                2. Fill in the Blanks
            </div>
        

            <div data_id="bnode_vmapd" className="new-div w-full flex justify-end items-center" id={"bnode_vmapd" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <svg data_id="bnode_p0cba" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_p0cba" + (idx?"__"+idx:"")} b_type="icon" className="cursor-pointer" extra-attr >
                
            <rect data_id="" width="32" height="32" rx="4" fill="#D1D5DB" extra-attr >
                
            </rect>
        

            <path data_id="" d="M21.3332 12L13.9998 19.3333L10.6665 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        
            </div>
        

            <div data_id="bnode_wwc94" className="new-div w-full text-lg font-semibold font-['Open_Sans'] flex justify-start items-center" id={"bnode_wwc94" + (idx?"__"+idx:"")} extra-attr >
                ________ is the capital of France.
            </div>
        

            <div data_id="bnode_bzk7a" className="new-div w-full flex items-center justify-center gap-4" id={"bnode_bzk7a" + (idx?"__"+idx:"")} name="options" extra-attr >
                
            <div data_id="bnode_p2q7j" className="new-div w-full flex items-center justify-center bg-[#F3F4F6] p-4 rounded-[8px]" id={"bnode_p2q7j" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_4o5ra" className="new-div w-full flex justify-start items-center" id={"bnode_4o5ra" + (idx?"__"+idx:"")} extra-attr >
                🟢 Paris
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <svg data_id="bnode_n6w9s" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="chevrons-right" className="w-[48px] h-[48px] text-gradient-to-r from-[#FF5994] to-[#3488FD]" id={"bnode_n6w9s" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="m6 17 5-5-5-5" extra-attr >
                
            </path>
        

            <path data_id="" d="m13 17 5-5-5-5" extra-attr >
                
            </path>
        

            <svg data_id="bnode_egt2z" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_egt2z" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M13 17.5L18 12.5L13 7.5M6 17.5L11 12.5L6 7.5" stroke="url(#paint0_linear_299_2567)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" extra-attr >
                
            </path>
        

            <defs data_id="" extra-attr >
                
            <lineargradient data_id="paint0_linear_299_2567" id={"paint0_linear_299_2567" + (idx?"__"+idx:"")} x1="6" y1="7.5" x2="19.5204" y2="10.4239" gradientUnits="userSpaceOnUse" extra-attr >
                
            <stop data_id="" stop-color="#FF5994" extra-attr >
                
            </stop>
        

            <stop data_id="" offset="1" stop-color="#3488FD" extra-attr >
                
            </stop>
        
            </lineargradient>
        
            </defs>
        
            </svg>
        

            <svg data_id="bnode_rzzgc" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_rzzgc" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M13 17.5L18 12.5L13 7.5M6 17.5L11 12.5L6 7.5" stroke="url(#paint0_linear_299_2567)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" extra-attr >
                
            </path>
        

            <defs data_id="" extra-attr >
                
            <lineargradient data_id="paint0_linear_299_2567" id={"paint0_linear_299_2567" + (idx?"__"+idx:"")} x1="6" y1="7.5" x2="19.5204" y2="10.4239" gradientUnits="userSpaceOnUse" extra-attr >
                
            <stop data_id="" stop-color="#FF5994" extra-attr >
                
            </stop>
        

            <stop data_id="" offset="1" stop-color="#3488FD" extra-attr >
                
            </stop>
        
            </lineargradient>
        
            </defs>
        
            </svg>
        
            </svg>
        

            <div data_id="bnode_lj3zk" className="new-div w-full flex items-center justify-center flex-col bg-white p-8 rounded-[12px]" id={"bnode_lj3zk" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <div data_id="bnode_6vnz8" className="new-div w-full flex items-center justify-center pb-2" id={"bnode_6vnz8" + (idx?"__"+idx:"")} name="top" extra-attr >
                
            <div data_id="bnode_s69g2" className="new-div w-full gap-3 flex justify-start items-center" id={"bnode_s69g2" + (idx?"__"+idx:"")} name="left" extra-attr >
                
            <div data_id="bnode_748wg" className="new-div w-fit flex justify-start items-center font-semibold text-base" id={"bnode_748wg" + (idx?"__"+idx:"")} name="left" extra-attr >
                2. Fill in the Blanks
            </div>
        

            <button data_id="bnode_4433p" type="button" className="group relative py-0.5 px-1 w-fit bg-gradient-to-r from-[#FF5994] to-[#3488FD] rounded-[4px] font-['Open_Sans'] text-white text-xs justify-center items-center font-normal" id={"bnode_4433p" + (idx?"__"+idx:"")} extra-attr >
                ✨ Enhanced
            </button>
        
            </div>
        

            <div data_id="bnode_2r5dl" className="new-div w-full flex justify-end items-center" id={"bnode_2r5dl" + (idx?"__"+idx:"")} name="right" extra-attr >
                
            <svg data_id="bnode_po6so" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_po6so" + (idx?"__"+idx:"")} b_type="icon" className="cursor-pointer" extra-attr >
                
            <rect data_id="" width="32" height="32" rx="4" fill="#F55B99" extra-attr >
                
            </rect>
        

            <path data_id="" d="M21.3332 12L13.9998 19.3333L10.6665 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        
            </div>
        

            <div data_id="bnode_g7rrc" className="new-div w-full text-lg font-semibold font-['Open_Sans'] flex justify-start items-center" id={"bnode_g7rrc" + (idx?"__"+idx:"")} extra-attr >
                ________ is the capital of France.
            </div>
        

            <div data_id="bnode_zppaa" className="new-div w-full flex items-center justify-center gap-4" id={"bnode_zppaa" + (idx?"__"+idx:"")} name="options" extra-attr >
                
            <div data_id="bnode_sx8lw" className="new-div w-full flex items-center justify-center bg-[#F3F4F6] p-4 rounded-[8px]" id={"bnode_sx8lw" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_x2kri" className="new-div w-full flex justify-start items-center" id={"bnode_x2kri" + (idx?"__"+idx:"")} extra-attr >
                🟢 Paris
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_1ccab" className="bottom-[5px] w-full fixed bg-white flex justify-end items-center p-2 px-2 gap-[10px]" id={"bnode_1ccab" + (idx?"__"+idx:"")} name="Savebar" extra-attr >
                
            <button data_id="bnode_kpx27" type="button" className="text-[#FF5994] bg-[#F3F4F6] focus:ring-4 focus:ring-blue-300 hover:bg-[#FF5994] hover:text-white rounded-[8px] p-3 font-['Open_Sans'] flex justify-end items-center text-sm font-normal" id={"bnode_kpx27" + (idx?"__"+idx:"")} name="publish" extra-attr >
                Cancel
            </button>
        

            <button data_id="bnode_c3q7t" type="button" className="w-fit text-white bg-[#FF5994] focus:ring-4 focus:ring-blue-300 rounded-[8px] p-3 font-['Open_Sans'] flex justify-end items-center text-sm font-normal" id={"bnode_c3q7t" + (idx?"__"+idx:"")} name="publish" extra-attr >
                Save Changes
            </button>
        
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_yezo2;