import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_6c7ez";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_6c7ez = (props)=>{
			const INFO  = {"model_id":"","model_name":"","op":"get_one","comp_id":"comp_bnode_6c7ez"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					g_fn.bro_get_one(INFO, set_M, props);
				}, [props])
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = ()=>{

				if(!INFO.model_id) return;


				INFO.query = query; // @todo : make it possible to change in UI editor
				const id = g_fn.get_one_find_id(props, INFO, relation);
				if(!id) {
					// errors => [{code:string, msg:string}]
					// warning => []
					console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
					// return;
				}

				// subscription
				const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e)=>{
					console.log("USER RE-RENDERED");
					const data = e.data;
					set_M(data);
				});


				// first time
				AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);



				return ()=>{
					sub.unsubscribe();
				}

			}
		
const input_prompt = (e) => {
const inp_val = e.target?.value;
  console.log(' inp val is : ', inp_val);
  set_inp_prompt(inp_val);
}
const [inp_prompt, set_inp_prompt] = useState("")
const [ques_string, set_ques_string] = useState("")
const [show_loading, set_show_loading] = useState(false);
const generatetestfn = async () => {
  set_show_loading(true);
  console.log(' this fn is called');
    const system_msg = 'You are a quiz generator you will be given context and an extra prompt use that to generate test in json format';
    const ctx = {
        grade: 11,
        subject: 'Biology',
        difficulty: 'Hard',
    }
  
    const prompt = inp_prompt;
    
    const data = {
        system_msg: system_msg,
        ctx:ctx,
        topic:prompt
    }
  
    const resp = await fetch('https://api-manager.brokenatom.workers.dev/input/openai/prompts', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    }).catch(e => {
      console.log('error is : ', e);
      set_show_loading(false);
      return undefined
    });
  
    if(!resp) {
        console.warn('no response');
        set_show_loading(false);
        return;
    }
  
    const res = await resp.json();
    console.log('res in json is : ', res);
    set_show_loading(false);
    const test_obj = res.data[0].chatModelResult;
    console.log('test object is :', test_obj);
    const questions = test_obj.questions;
  questions.map((q) => {
    set_ques_string(s => s+ q.question);
    if(q.options.a.correct){
      set_ques_string(s => s+ ' correct answer: ' + q.options.a.option + ',');
    }
    if(q.options.b.correct){
      set_ques_string(s => s+ ' correct answer: ' + q.options.b.option + ',');
    }
    if(q.options.c.correct){
      set_ques_string(s => s+ ' correct answer: ' + q.options.c.option + ',');
    }
    if(q.options.d.correct){
      set_ques_string(s => s+ ' correct answer: ' + q.options.d.option + ',');
    }
  })
    return questions;
}


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_10xaf"] = COND_ATTRS["bnode_10xaf"] || {};
			

				COND_ATTRS["bnode_0ze80"] = COND_ATTRS["bnode_0ze80"] || {};
			

				COND_ATTRS["bnode_s1gvk"] = COND_ATTRS["bnode_s1gvk"] || {};
			

				COND_ATTRS["bnode_5ivsx"] = COND_ATTRS["bnode_5ivsx"] || {};
			

				COND_ATTRS["bnode_pmxxt"] = COND_ATTRS["bnode_pmxxt"] || {};
			

				COND_ATTRS["bnode_i7ok3"] = COND_ATTRS["bnode_i7ok3"] || {};
			

				COND_ATTRS["bnode_68pl4"] = COND_ATTRS["bnode_68pl4"] || {};
			

				COND_ATTRS["bnode_wj165"] = COND_ATTRS["bnode_wj165"] || {};
			

				COND_ATTRS["bnode_knhen"] = COND_ATTRS["bnode_knhen"] || {};
			

				COND_ATTRS["bnode_53wtz"] = COND_ATTRS["bnode_53wtz"] || {};
			

				COND_ATTRS["bnode_6c7ez"] = COND_ATTRS["bnode_6c7ez"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_6c7ez"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_6c7ez"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_6c7ez" className="min-h-[64px] w-full h-fit overflow-auto flex gap-4 p-4 items-center justify-center " name="page:testing_ai" findex="a9" id={"bnode_6c7ez" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_53wtz" className="new-div w-full flex items-center justify-center flex-col gap-6" id={"bnode_53wtz" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_s1gvk" className="new-div w-1/2 flex items-center justify-center" id={"bnode_s1gvk" + (idx?"__"+idx:"")} extra-attr >
                
            <textarea data_id="bnode_10xaf" type="text" value={inp_prompt} placeholder="Textarea..." className=" w-full rounded p-2 px-4 outline outline-2 outline-zinc-200" id={"bnode_10xaf" + (idx?"__"+idx:"")} onChange={(e) => input_prompt(e)} extra-attr >
                
            </textarea>
        

            <fn data_id="bnode_0ze80" name="input_prompt" id={"bnode_0ze80" + (idx?"__"+idx:"")} extra-attr >
                
            </fn>
        
            </div>
        

            <div data_id="bnode_wj165" className="new-div w-32 flex items-center justify-center bg-pink-400 p-2 rounded  hover:bg-pink-600 hover:text-white" id={"bnode_wj165" + (idx?"__"+idx:"")} onClick={generatetestfn} extra-attr >
                { !show_loading && (<div data_id="bnode_5ivsx" className="new-div w-full flex items-center justify-center" id={"bnode_5ivsx" + (idx?"__"+idx:"")} extra-attr >
                Generate Test
            </div>) }
{ show_loading && (<div data_id="bnode_i7ok3" id={"bnode_i7ok3" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_pmxxt" aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_pmxxt" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <path data_id="" d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" extra-attr >
                
            </path>
        

            <path data_id="" d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" extra-attr >
                
            </path>
        
            </svg>
        
            </div>) }

            <fn data_id="bnode_68pl4" name="generatetestfn" id={"bnode_68pl4" + (idx?"__"+idx:"")} extra-attr >
                
            </fn>
        
            </div>
        

            <div data_id="bnode_knhen" className="new-div w-full flex items-center justify-center" id={"bnode_knhen" + (idx?"__"+idx:"")} textContentVb="{ques_string}" extra-attr >
                {ques_string}
            </div>
        
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_6c7ez;