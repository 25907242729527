import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_00000";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_00000 = (props)=>{
			const INFO  = {"model_id":"","model_name":"","op":"get_one","comp_id":"comp_bnode_00000"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					g_fn.bro_get_one(INFO, set_M, props);
				}, [props])
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});
			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = ()=>{

				if(!INFO.model_id) return;


				INFO.query = query; // @todo : make it possible to change in UI editor
				const id = g_fn.get_one_find_id(props, INFO, relation);
				if(!id) {
					// errors => [{code:string, msg:string}]
					// warning => []
					console.warn("NO ID FOUND FOR GET_ONE : ", INFO);
					// return;
				}

				// subscription
				const sub = AS.GSTORE.subscribe_one(INFO.model_id, id, (e)=>{
					console.log("USER RE-RENDERED");
					const data = e.data;
					set_M(data);
				});


				// first time
				AS.GSTORE.get_one_entity(INFO.model_id, id, INFO.query);



				return ()=>{
					sub.unsubscribe();
				}

			}
		


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_ifi3o"] = COND_ATTRS["bnode_ifi3o"] || {};
			

				COND_ATTRS["bnode_ejoao"] = COND_ATTRS["bnode_ejoao"] || {};
			

				COND_ATTRS["bnode_nr6qs"] = COND_ATTRS["bnode_nr6qs"] || {};
			

				COND_ATTRS["bnode_wqpvi"] = COND_ATTRS["bnode_wqpvi"] || {};
			

				COND_ATTRS["bnode_5addj"] = COND_ATTRS["bnode_5addj"] || {};
			

				COND_ATTRS["bnode_64kyn"] = COND_ATTRS["bnode_64kyn"] || {};
			

				COND_ATTRS["bnode_eb68i"] = COND_ATTRS["bnode_eb68i"] || {};
			

				COND_ATTRS["bnode_xj3mt"] = COND_ATTRS["bnode_xj3mt"] || {};
			

				COND_ATTRS["bnode_lfu7c"] = COND_ATTRS["bnode_lfu7c"] || {};
			

				COND_ATTRS["bnode_x8n0q"] = COND_ATTRS["bnode_x8n0q"] || {};
			

				COND_ATTRS["bnode_12f2w"] = COND_ATTRS["bnode_12f2w"] || {};
			

				COND_ATTRS["bnode_nfkfg"] = COND_ATTRS["bnode_nfkfg"] || {};
			

				COND_ATTRS["bnode_hyubq"] = COND_ATTRS["bnode_hyubq"] || {};
			

				COND_ATTRS["bnode_3bdle"] = COND_ATTRS["bnode_3bdle"] || {};
			

				COND_ATTRS["bnode_00000"] = COND_ATTRS["bnode_00000"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_00000"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_00000"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_00000" id={"bnode_00000" + (idx?"__"+idx:"")} name="start" findex="a0" className="w-full h-screen  px-8 bg-[#eeeeee] flex flex-col" style={{padding:"0px 32px 32px", paddingBottom:"32px", paddingLeft:"32px", paddingRight:"32px", paddingTop:"0px"}} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_nfkfg" className="w-full h-fit  flex gap-4 items-center justify-center flex-col rounded-[20px] pb-16" id={"bnode_nfkfg" + (idx?"__"+idx:"")} name="create bar" style={{margin:"0px", marginBottom:"0px", marginLeft:"0px", marginRight:"0px", marginTop:"0px"}} extra-attr >
                
            <div data_id="bnode_ifi3o" className="new-div w-full font-['Open_Sans'] flex justify-center items-end text-[28px] text-2xl font-semibold" id={"bnode_ifi3o" + (idx?"__"+idx:"")} extra-attr >
                Create Quiz
            </div>
        

            <div data_id="bnode_12f2w" className="new-div w-3/5 flex justify-center items-start" id={"bnode_12f2w" + (idx?"__"+idx:"")} name="icons" style={{columnGap:"16px", gap:"16px", margin:"0px", marginBottom:"0px", marginLeft:"0px", marginRight:"0px", marginTop:"0px", rowGap:"16px", webkitColumnGap:"16px"}} extra-attr >
                
            <div data_id="bnode_64kyn" className="group relative new-div w-3/5 flex items-center justify-center flex-col bg-gradient-to-br from-[#FF5994] to-[#3488FD] hover:from-[#1E62C2]  hover:to-[#E62368] rounded-[12px] overflow-hidden cursor-pointer transition-all ease-in-out duration-500" id={"bnode_64kyn" + (idx?"__"+idx:"")} name="genAI" style={{columnGap:"16px", gap:"16px", padding:"16px", paddingBottom:"16px", paddingLeft:"16px", paddingRight:"16px", paddingTop:"16px", rowGap:"16px", webkitColumnGap:"16px"}} action="['go-to-page']" page_id="bnode_e2ttk" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_go_to_page(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <div data_id="bnode_ejoao" className="new-div flex items-center justify-center text-7xl w-fit h-fit" id={"bnode_ejoao" + (idx?"__"+idx:"")} extra-attr >
                🪄
            </div>
        

            <div data_id="bnode_nr6qs" className="new-div w-full items-center justify-center font-semibold font-['Open_Sans'] flex flex-row-reverse text-white text-base" id={"bnode_nr6qs" + (idx?"__"+idx:"")} extra-attr >
                Generate with AI✨
            </div>
        

            <svg data_id="bnode_wqpvi" width="181" height="181" viewBox="0 0 181 181" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_wqpvi" + (idx?"__"+idx:"")} b_type="icon" className="absolute -left-36 rotate-12 group-hover:rotate-0 group-hover:-left-16 transition-all ease-in-out duration-500 origin-center" extra-attr >
                
            <path data_id="" d="M79.3091 33.2012L79.8553 35.0513L81.6229 35.0513L80.1929 36.1947L80.7391 38.0448L79.3091 36.9014L77.8792 38.0448L78.4254 36.1947L76.9954 35.0513L78.7629 35.0513L79.3091 33.2012Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M135.75 66.4023L136.515 68.9925H138.989L136.987 70.5933L137.752 73.1835L135.75 71.5827L133.748 73.1835L134.513 70.5933L132.511 68.9925H134.985L135.75 66.4023Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M155.212 26.7754L155.977 29.3656H158.452L156.45 30.9664L157.214 33.5565L155.212 31.9557L153.21 33.5565L153.975 30.9664L151.973 29.3656H154.448L155.212 26.7754Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M175.648 56.7632L176.849 60.8334L180.738 60.8334L177.592 63.349L178.794 67.4193L175.648 64.9037L172.502 67.4193L173.704 63.349L170.558 60.8334L174.446 60.8334L175.648 56.7632Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M9.24462 36.4141L10.2278 39.7443L13.4093 39.7443L10.8354 41.8025L11.8186 45.1327L9.24462 43.0745L6.67069 45.1327L7.65385 41.8025L5.07992 39.7443L8.26147 39.7443L9.24462 36.4141Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M59.3602 70.686L60.6711 75.1263L64.9132 75.1263L61.4813 77.8706L62.7921 82.3108L59.3602 79.5666L55.9283 82.3108L57.2392 77.8706L53.8073 75.1263L58.0493 75.1263L59.3602 70.686Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M74.4435 118.882L75.4267 122.212L78.6083 122.212L76.0343 124.27L77.0175 127.6L74.4435 125.542L71.8696 127.6L72.8528 124.27L70.2788 122.212L73.4604 122.212L74.4435 118.882Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M99.2581 84.6094L100.35 88.3096L103.886 88.3096L101.026 90.5965L102.118 94.2967L99.2581 92.0098L96.3981 94.2967L97.4905 90.5965L94.6306 88.3096L98.1657 88.3096L99.2581 84.6094Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M89.5269 41.769L90.1823 43.9892L92.3034 43.9892L90.5874 45.3613L91.2428 47.5814L89.5269 46.2093L87.8109 47.5814L88.4664 45.3613L86.7504 43.9892L88.8714 43.9892L89.5269 41.769Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M109.962 88.8936L110.618 91.1137L112.739 91.1137L111.023 92.4858L111.678 94.706L109.962 93.3338L108.246 94.706L108.902 92.4858L107.186 91.1137L109.307 91.1137L109.962 88.8936Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M123.099 112.456L123.864 115.046H126.339L124.337 116.647L125.101 119.237L123.099 117.636L121.098 119.237L121.862 116.647L119.86 115.046H122.335L123.099 112.456Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M171.755 103.888L172.957 107.958L176.846 107.958L173.7 110.474L174.901 114.544L171.755 112.028L168.609 114.544L169.811 110.474L166.665 107.958L170.554 107.958L171.755 103.888Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M145.481 144.586L146.683 148.656L150.571 148.656L147.425 151.172L148.627 155.242L145.481 152.726L142.335 155.242L143.537 151.172L140.391 148.656L144.28 148.656L145.481 144.586Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M112.395 9.63916L113.378 12.9694L116.56 12.9694L113.986 15.0276L114.969 18.3578L112.395 16.2996L109.821 18.3578L110.804 15.0276L108.23 12.9694L111.412 12.9694L112.395 9.63916Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M79.3091 171.361L80.2923 174.691L83.4738 174.691L80.8999 176.749L81.8831 180.079L79.3091 178.021L76.7352 180.079L77.7184 176.749L75.1444 174.691L78.326 174.691L79.3091 171.361Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M14.1102 137.089L15.0934 140.419L18.2749 140.419L15.701 142.477L16.6841 145.807L14.1102 143.749L11.5363 145.807L12.5194 142.477L9.94551 140.419L13.1271 140.419L14.1102 137.089Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M4.37903 87.8228L5.36219 91.153L8.54374 91.153L5.96981 93.2111L6.95296 96.5414L4.37903 94.4832L1.8051 96.5414L2.78826 93.2111L0.214325 91.153L3.39588 91.153L4.37903 87.8228Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M56.9274 0L57.9106 3.33021L61.0921 3.33021L58.5182 5.38839L59.5013 8.71861L56.9274 6.66042L54.3535 8.71861L55.3366 5.38839L52.7627 3.33021L55.9443 3.33021L56.9274 0Z" fill="#FFCC4D" extra-attr >
                
            </path>
        
            </svg>
        

            <svg data_id="bnode_5addj" width="181" height="181" viewBox="0 0 181 181" fill="none" xmlns="http://www.w3.org/2000/svg" id={"bnode_5addj" + (idx?"__"+idx:"")} b_type="icon" className="absolute -right-36 rotate-12 group-hover:rotate-0 group-hover:-right-16 transition-all ease-in-out duration-500 origin-center" extra-attr >
                
            <path data_id="" d="M79.3091 33.2012L79.8553 35.0513L81.6229 35.0513L80.1929 36.1947L80.7391 38.0448L79.3091 36.9014L77.8792 38.0448L78.4254 36.1947L76.9954 35.0513L78.7629 35.0513L79.3091 33.2012Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M135.75 66.4023L136.515 68.9925H138.989L136.987 70.5933L137.752 73.1835L135.75 71.5827L133.748 73.1835L134.513 70.5933L132.511 68.9925H134.985L135.75 66.4023Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M155.212 26.7754L155.977 29.3656H158.452L156.45 30.9664L157.214 33.5565L155.212 31.9557L153.21 33.5565L153.975 30.9664L151.973 29.3656H154.448L155.212 26.7754Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M175.648 56.7632L176.849 60.8334L180.738 60.8334L177.592 63.349L178.794 67.4193L175.648 64.9037L172.502 67.4193L173.704 63.349L170.558 60.8334L174.446 60.8334L175.648 56.7632Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M9.24462 36.4141L10.2278 39.7443L13.4093 39.7443L10.8354 41.8025L11.8186 45.1327L9.24462 43.0745L6.67069 45.1327L7.65385 41.8025L5.07992 39.7443L8.26147 39.7443L9.24462 36.4141Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M59.3602 70.686L60.6711 75.1263L64.9132 75.1263L61.4813 77.8706L62.7921 82.3108L59.3602 79.5666L55.9283 82.3108L57.2392 77.8706L53.8073 75.1263L58.0493 75.1263L59.3602 70.686Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M74.4435 118.882L75.4267 122.212L78.6083 122.212L76.0343 124.27L77.0175 127.6L74.4435 125.542L71.8696 127.6L72.8528 124.27L70.2788 122.212L73.4604 122.212L74.4435 118.882Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M99.2581 84.6094L100.35 88.3096L103.886 88.3096L101.026 90.5965L102.118 94.2967L99.2581 92.0098L96.3981 94.2967L97.4905 90.5965L94.6306 88.3096L98.1657 88.3096L99.2581 84.6094Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M89.5269 41.769L90.1823 43.9892L92.3034 43.9892L90.5874 45.3613L91.2428 47.5814L89.5269 46.2093L87.8109 47.5814L88.4664 45.3613L86.7504 43.9892L88.8714 43.9892L89.5269 41.769Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M109.962 88.8936L110.618 91.1137L112.739 91.1137L111.023 92.4858L111.678 94.706L109.962 93.3338L108.246 94.706L108.902 92.4858L107.186 91.1137L109.307 91.1137L109.962 88.8936Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M123.099 112.456L123.864 115.046H126.339L124.337 116.647L125.101 119.237L123.099 117.636L121.098 119.237L121.862 116.647L119.86 115.046H122.335L123.099 112.456Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M171.755 103.888L172.957 107.958L176.846 107.958L173.7 110.474L174.901 114.544L171.755 112.028L168.609 114.544L169.811 110.474L166.665 107.958L170.554 107.958L171.755 103.888Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M145.481 144.586L146.683 148.656L150.571 148.656L147.425 151.172L148.627 155.242L145.481 152.726L142.335 155.242L143.537 151.172L140.391 148.656L144.28 148.656L145.481 144.586Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M112.395 9.63916L113.378 12.9694L116.56 12.9694L113.986 15.0276L114.969 18.3578L112.395 16.2996L109.821 18.3578L110.804 15.0276L108.23 12.9694L111.412 12.9694L112.395 9.63916Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M79.3091 171.361L80.2923 174.691L83.4738 174.691L80.8999 176.749L81.8831 180.079L79.3091 178.021L76.7352 180.079L77.7184 176.749L75.1444 174.691L78.326 174.691L79.3091 171.361Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M14.1102 137.089L15.0934 140.419L18.2749 140.419L15.701 142.477L16.6841 145.807L14.1102 143.749L11.5363 145.807L12.5194 142.477L9.94551 140.419L13.1271 140.419L14.1102 137.089Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M4.37903 87.8228L5.36219 91.153L8.54374 91.153L5.96981 93.2111L6.95296 96.5414L4.37903 94.4832L1.8051 96.5414L2.78826 93.2111L0.214325 91.153L3.39588 91.153L4.37903 87.8228Z" fill="#FFCC4D" extra-attr >
                
            </path>
        

            <path data_id="" d="M56.9274 0L57.9106 3.33021L61.0921 3.33021L58.5182 5.38839L59.5013 8.71861L56.9274 6.66042L54.3535 8.71861L55.3366 5.38839L52.7627 3.33021L55.9443 3.33021L56.9274 0Z" fill="#FFCC4D" extra-attr >
                
            </path>
        
            </svg>
        
            </div>
        

            <div data_id="bnode_x8n0q" className="new-div w-2/5 flex items-center justify-center bg-white hover:bg-[#FF5994] hover:text-white rounded-[12px] flex-row h-full cursor-pointer" id={"bnode_x8n0q" + (idx?"__"+idx:"")} name="scratch" style={{columnGap:"16px", gap:"16px", padding:"16px", paddingBottom:"16px", paddingLeft:"16px", paddingRight:"16px", paddingTop:"16px", rowGap:"16px", webkitColumnGap:"16px"}} action="['go-to-page']" page_id="bnode_a8dfp" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_go_to_page(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <div data_id="bnode_lfu7c" className="new-div w-full gap-2 flex justify-center items-center flex-col" id={"bnode_lfu7c" + (idx?"__"+idx:"")} style={{columnGap:"16px", gap:"16px", rowGap:"16px", webkitColumnGap:"16px"}} extra-attr >
                
            <div data_id="bnode_eb68i" className="new-div w-fit flex items-center justify-center text-7xl" id={"bnode_eb68i" + (idx?"__"+idx:"")} extra-attr >
                📝 
            </div>
        

            <div data_id="bnode_xj3mt" className="new-div w-full font-['Open_Sans'] font-semibold flex justify-center items-center text-base" id={"bnode_xj3mt" + (idx?"__"+idx:"")} extra-attr >
                Create From Scratch
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_hyubq" className="new-div w-full flex items-center justify-center text-[28px] font-['Open_Sans'] pb-4 text-2xl font-semibold" id={"bnode_hyubq" + (idx?"__"+idx:"")} extra-attr >
                Your Quizzes
            </div>
        

            <div data_id="bnode_3bdle" className="w-full flex items-center justify-center bg-white p-4 gap-4 rounded-[20px]" id={"bnode_3bdle" + (idx?"__"+idx:"")} name="all tests" extra-attr >
                < COMP_REG.comp_bnode_muwns {...{PM:[...PM, M], on_created: props.on_created || INFO.on_created, on_selected: props.on_selected || INFO.on_selected, INFO:INFO  }} extra-attr />
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_00000;