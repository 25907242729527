import React                              from 'react';
        import { useEffect, useRef, useState    } from "react";
        import { ErrorBoundary                  } from "react-error-boundary";
        import { fallbackRender                 } from "../utils.jsx"; 
        import { COMP_REG }                       from "../comp_registry.js";
        
        
        const COMP_BNODE_ME6W0 = (props)=>{
        
            // INFO
            const I = {"comp_id":"comp_bnode_me6w0"}
        
            return (
                <div id="bnode_me6w0" data_id="bnode_me6w0">
                    <ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
                        <COMP_REG.comp_bnode_me6w0_raw_code {...{...props}} />
                    </ErrorBoundary>
                </div>
            )
        }
        
        export default COMP_BNODE_ME6W0;