import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_mqogx";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_mqogx = (props)=>{
			const INFO  = {"model_id":"fhmae","model_name":"question","op":"update_one","comp_id":"comp_bnode_mqogx"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					console.log("PROPS : ", props, INFO, relation);
				},[props]);
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = () => {
				if(!INFO.model_id) return;

				if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
				let id = g_fn.get_selected_entity_id(props, INFO);
				if(!id && !relation) {
					return console.warn("NO ID FOUND TO UPDATE ENTITY");
				}

				if(!id && relation) {

					const parent_M = (props.PM && Array.isArray(props.PM) && props.PM.length) ? props.PM[props.PM.length-1] : null;
					console.log("PM AND PARENT_M : ", props.PM, parent_M);
					if(parent_M) {
						const prop = parent_M[relation.prop_name];
						if(!prop) return console.warn("NO PROP FOUND FROM PARENT TO UPDATE ENTITY");
						if(typeof(prop) === "string") {
							id = prop;
						}
						else {
							id = prop.id;
						}
					}
				}

				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY : ", r);
					if(r) {
						const r_copy = JSON.parse(JSON.stringify(r));
						set_M(r_copy);
					}
				})();

				const subs = [];

				subs.push(AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
					const id = g_fn.get_selected_entity_id(props, INFO);
					if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
					(async () => {
						const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
						console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION -> UPDATE_ONE : ", INFO.model_id, r);
						if(r) {
							const r_copy = JSON.parse(JSON.stringify(r));
							set_M(r_copy);
						}
					})();
				}));

				return () => {
					subs.forEach(sub=>sub.unsubscribe());
				}
			}
		
const update_answer = async () =>{
    const app = g_fn.GET_GC().APP_JSON;
	if(!app) return console.warn('app json not found');
    
	const question_model = app.models.find(m=>m.name === 'question');
	if(!question_model) return console.warn('question model not found');

    const question_entity_id = M.id
    console.log('entity id is: ', question_entity_id,M.answer_explanation, M.answer_explanation_image);
  const g = await g_fn.AS.GSTORE.update_one( question_model.id,question_entity_id, {id:question_entity_id, add:{'answer_explanation':M.answer_explanation, 'answer_explanation_image':M.answer_explanation_image}, delete:{}});
    if(!g) return;
    console.log(' g is: ', g);
    AS.navigate("bnode_k2lnn");
}


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_5i4fg"] = COND_ATTRS["bnode_5i4fg"] || {};
			

				COND_ATTRS["bnode_8sxy9"] = COND_ATTRS["bnode_8sxy9"] || {};
			

				COND_ATTRS["bnode_hp9y1"] = COND_ATTRS["bnode_hp9y1"] || {};
			

				COND_ATTRS["bnode_s4i1o"] = COND_ATTRS["bnode_s4i1o"] || {};
			

				COND_ATTRS["bnode_4ddw2"] = COND_ATTRS["bnode_4ddw2"] || {};
			

				COND_ATTRS["bnode_ezde9"] = COND_ATTRS["bnode_ezde9"] || {};
			

				COND_ATTRS["bnode_j8ycf"] = COND_ATTRS["bnode_j8ycf"] || {};
			

				COND_ATTRS["bnode_np71h"] = COND_ATTRS["bnode_np71h"] || {};
			

				COND_ATTRS["bnode_mdqxd"] = COND_ATTRS["bnode_mdqxd"] || {};
			

				COND_ATTRS["bnode_rej1u"] = COND_ATTRS["bnode_rej1u"] || {};
			

				COND_ATTRS["bnode_iwjqy"] = COND_ATTRS["bnode_iwjqy"] || {};
			

				COND_ATTRS["bnode_l317m"] = COND_ATTRS["bnode_l317m"] || {};
			

				COND_ATTRS["bnode_2dmzt"] = COND_ATTRS["bnode_2dmzt"] || {};
			

				COND_ATTRS["bnode_mp5pg"] = COND_ATTRS["bnode_mp5pg"] || {};
			

				COND_ATTRS["bnode_9t10y"] = COND_ATTRS["bnode_9t10y"] || {};
			

				COND_ATTRS["bnode_d2yva"] = COND_ATTRS["bnode_d2yva"] || {};
			

				COND_ATTRS["bnode_adood"] = COND_ATTRS["bnode_adood"] || {};
			

				COND_ATTRS["bnode_p55jt"] = COND_ATTRS["bnode_p55jt"] || {};
			

				COND_ATTRS["bnode_jqzfs"] = COND_ATTRS["bnode_jqzfs"] || {};
			

				COND_ATTRS["bnode_saxho"] = COND_ATTRS["bnode_saxho"] || {};
			

				COND_ATTRS["bnode_uuz0y"] = COND_ATTRS["bnode_uuz0y"] || {};
			

				COND_ATTRS["bnode_nq30b"] = COND_ATTRS["bnode_nq30b"] || {};
			

				COND_ATTRS["bnode_kbmxd"] = COND_ATTRS["bnode_kbmxd"] || {};
			

				COND_ATTRS["bnode_t4nop"] = COND_ATTRS["bnode_t4nop"] || {};
			

				COND_ATTRS["bnode_asjxf"] = COND_ATTRS["bnode_asjxf"] || {};
			

				COND_ATTRS["bnode_pq6wj"] = COND_ATTRS["bnode_pq6wj"] || {};
			

				COND_ATTRS["bnode_mqogx"] = COND_ATTRS["bnode_mqogx"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_mqogx"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_mqogx"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_mqogx" name="question" op="update_one" className="w-full flex flex-col p-16 rounded-[16px] border rounded bg-white relative gap-5" id={"bnode_mqogx" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_saxho" name="question-data" className="gap-4 flex flex-row-reverse" id={"bnode_saxho" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_4ddw2" name="CONT-answer_explanation" className="w-full" id={"bnode_4ddw2" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_s4i1o" id={"bnode_s4i1o" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_hp9y1" name="answer_explanation" className="flex flex-col  w-full gap-6 " id={"bnode_hp9y1" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_8sxy9" name="cont" className="flex flex-row items-center justify-center w-full" id={"bnode_8sxy9" + (idx?"__"+idx:"")} extra-attr >
                
            <textarea data_id="bnode_5i4fg" name="answer_explanation" placeholder="Type explanation for the correct answer" value={M.answer_explanation} action="oninput-update-m" className="border border-[#9CA3AF] p-5 placeholder:text-base placeholder:capitalize placeholder:text-[#9CA3AF] w-full h-100" id={"bnode_5i4fg" + (idx?"__"+idx:"")} style={{height:"254px"}} model_id="fhmae" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            </textarea>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_jqzfs" name="CONT-answer_explanation_image" className="w-full" id={"bnode_jqzfs" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_p55jt" id={"bnode_p55jt" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_adood" name="answer_explanation_image" className="flex flex-col  w-full gap-6 " id={"bnode_adood" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_d2yva" name="cont" className="w-full flex flex-col md:flex-row items-center justify-center gap-4" id={"bnode_d2yva" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_mp5pg" className="flex items-center justify-center w-full" id={"bnode_mp5pg" + (idx?"__"+idx:"")} extra-attr >
                
            <label data_id="bnode_2dmzt" className="flex flex-col items-center justify-center w-full h-100 border border-[#9CA3AF] rounded-[12px] cursor-pointer bg-white" id={"bnode_2dmzt" + (idx?"__"+idx:"")} style={{height:"256px"}} extra-attr >
                { M?.answer_explanation_image?.url && (<div data_id="bnode_j8ycf" className="w-full h-full flex justify-center items-center" id={"bnode_j8ycf" + (idx?"__"+idx:"")} extra-attr >
                <img data_id="bnode_ezde9" src={M.answer_explanation_image?.url} id={"bnode_ezde9" + (idx?"__"+idx:"")} extra-attr />
            </div>) }
{ !M.answer_explanation_image?.url && (<div data_id="bnode_iwjqy" className="flex flex-col items-center justify-center gap-4" id={"bnode_iwjqy" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_rej1u" className="flex gap-1 items-center w-full justify-center flex-col text-[#9CA3AF]" id={"bnode_rej1u" + (idx?"__"+idx:"")} extra-attr >
                
            <svg data_id="bnode_np71h" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="image" className="w-[32px] h-[32px]" id={"bnode_np71h" + (idx?"__"+idx:"")} b_type="icon" extra-attr >
                
            <rect data_id="" width="18" height="18" x="3" y="3" rx="2" ry="2" extra-attr >
                
            </rect>
        

            <circle data_id="" cx="9" cy="9" r="2" extra-attr >
                
            </circle>
        

            <path data_id="" d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" extra-attr >
                
            </path>
        
            </svg>
        

            <span data_id="bnode_mdqxd" id={"bnode_mdqxd" + (idx?"__"+idx:"")} className=" text-xl font-semibold font-['Open_Sans']" extra-attr >
                Add Image
            </span>
        
            </div>
        
            </div>) }
<input data_id="bnode_l317m" name="answer_explanation_image" type="file" className="hidden" action="oninput-update-m-file" r_for="dropzone-file" id={"bnode_l317m" + (idx?"__"+idx:"")} model_id="fhmae" onInput={(e)=>{g_fn.bro_on_input_file(e, INFO)}} extra-attr />
            </label>
        
            </div>
        
{ false && (<div data_id="bnode_9t10y" className="new-div w-full flex items-center justify-center" id={"bnode_9t10y" + (idx?"__"+idx:"")} textContentVb="{JSON.stringify(M)}" extra-attr >
                {JSON.stringify(M)}
            </div>) }
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_asjxf" id={"bnode_asjxf" + (idx?"__"+idx:"")} className="flex-row gap-2 flex justify-start items-center" extra-attr >
                
            <div data_id="bnode_nq30b" className="flex flex-col" id={"bnode_nq30b" + (idx?"__"+idx:"")} page_id="bnode_k2lnn" onClick={update_answer} extra-attr >
                
            <button data_id="bnode_uuz0y" className="bg-[#FF5994] text-white p-4 rounded-[8px] focus:outline-none w-full text-xl font-semibold font-['Open_Sans']" id={"bnode_uuz0y" + (idx?"__"+idx:"")} extra-attr >
                SAVE ANSWER EXPLANATION
            </button>
        
            </div>
        

            <div data_id="bnode_t4nop" className="new-div w-fit flex items-center justify-center gap-3 p-4 bg-[#F3F4F6] hover:text-white hover:bg-[#6B7280] rounded-[12px] cursor-pointer text-xl font-semibold font-['Open_Sans']" id={"bnode_t4nop" + (idx?"__"+idx:"")} action="['go-to-page']" page_id="bnode_k2lnn" model_id="fhmae" onClick={(e)=>{

            

            if(window.BROKEN_DEV_VARS && window.BROKEN_DEV_VARS.IS_EDITING_MODE) return;
            e.preventDefault(); e.stopPropagation(); 
            
            const el = e.currentTarget; el.disabled = true; el.classList?.add('disabled', 'cursor-progress');
                g_fn.bro_go_to_page(e, M, INFO, props)
            el.disabled = false; el.classList?.remove('disabled', 'cursor-progress');
        }} extra-attr >
                
            <div data_id="bnode_kbmxd" className="new-div w-full flex items-center justify-center" id={"bnode_kbmxd" + (idx?"__"+idx:"")} extra-attr >
                Cancel
            </div>
        
            </div>
        
            </div>
        

            <fn data_id="bnode_pq6wj" name="fn:update_answer" id={"bnode_pq6wj" + (idx?"__"+idx:"")} extra-attr >
                
            </fn>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_mqogx;