import React                              from 'react';
		import { useEffect, useRef, useState    } from "react";
		import { ErrorBoundary                  } from "react-error-boundary";
		import {
			Routes,
			Route,
			Link,
			useNavigate,
		}                                         from "react-router-dom";

		import {
			motion,
			AnimatePresence
		} 						  				  from "framer-motion";	

		import {
			fallbackRender,
			add_sub,
			remove_subs
		}                                         from "../utils.jsx";
		import {COMP_REG}                         from "../comp_registry.js";
		import g_fn                               from '../g_fn';



		


		const AS    = g_fn.AS;
		

		// @req: keep reference to subs here and remove them on unmount or reload
		const SUB_ID = "bnode_rxvft";
		const EN_BROKEN_COMP_LOGS = window.EN_BROKEN_COMP_LOGS || {};


		// local code - e.g sub components
		


		const comp_bnode_rxvft = (props)=>{
			const INFO  = {"model_id":"yvjbe","model_name":"test","op":"update_one","comp_id":"comp_bnode_rxvft"};

			// Routes
			



			// FOR IS_MANY
			let idx = props.idx;   // also for protection from error when we might think is many but it's not
			let V = props.V || {}; // for protection from error


			// STATES
			const [rerender, set_rerender] = useState({});
const [M, set_M]     = useState(props.M || {})
const [PM, set_PM]     = useState(props.PM || [])
const [selected_M, set_selected_M]                         = useState(null)

			// REFS
			

			// EFFECTS
			
		useEffect(()=>{
			console.log("COMPONENT RERENDERED : ", JSON.stringify(M));
		},[rerender])
	

			useEffect(()=>{
				const deinit =  init();

				return () => {
					if(deinit && typeof(deinit) === "function") deinit();
				}
			}, []);
		

				useEffect(()=>{
					console.log("PROPS : ", props, INFO, relation);
				},[props]);
			

				useEffect(()=>{
					const sub = AS.GSTORE.subscribe((e)=>{
						// console.log("STORE CHANGED: ", INFO.model_name, INFO.op, " data: ",  e);

						// if model_id and eid are same
						// once data changes we will update M
					});

					return ()=>{
						sub.unsubscribe();
					}
				}, []);
			
useEffect(()=>{
			if(EN_BROKEN_COMP_LOGS.MODEL_EFFECT){
				const MI = INFO.model_name.toUpperCase() + " : " + INFO.op.toUpperCase();
				console.log("MODEL CHANGED : " + MI + "   => ", "model", M, " props", props);
			}
		}, [M])

		useEffect(()=>{

			if(!INFO.model_id) return;

			if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
			const id = g_fn.get_selected_entity_id(props, INFO);
			if(!id) {
				return console.warn("NO ID FOUND TO UPDATE ENTITY");
			}

			(async () => {
				const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
				console.log("GSTORE GET ONE ENTITY : ", r);
				if(r) set_selected_M(r);
			})();

			const sub = AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
				const id = g_fn.get_selected_entity_id(props, INFO);
				if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION : ", r, INFO.model_id, INFO.comp_id);
					if(r) set_selected_M(r);
				})();
			});

			// On set in GSTORE set state
			const sub2 = AS.GSTORE.subscribe((e)=>{
				const {type, model_id, eid, data} = e;
				 if(!data || !data.id) return;
				 if(type !== 'set') return;
				 if(model_id !== INFO.model_id) return;
				 set_M(M=>{
				   if(eid !== M.id) return M;
				   return data;
				 })
			 });

			return () => {
				sub.unsubscribe();
				sub2.unsubscribe();
			}
		},[])
	

			// FUNCTIONS
			
			const init = () => {
				if(!INFO.model_id) return;

				if(query) INFO.query = query; // fall back to query if there is no selected entity for the model id;
				let id = g_fn.get_selected_entity_id(props, INFO);
				if(!id && !relation) {
					return console.warn("NO ID FOUND TO UPDATE ENTITY");
				}

				if(!id && relation) {

					const parent_M = (props.PM && Array.isArray(props.PM) && props.PM.length) ? props.PM[props.PM.length-1] : null;
					console.log("PM AND PARENT_M : ", props.PM, parent_M);
					if(parent_M) {
						const prop = parent_M[relation.prop_name];
						if(!prop) return console.warn("NO PROP FOUND FROM PARENT TO UPDATE ENTITY");
						if(typeof(prop) === "string") {
							id = prop;
						}
						else {
							id = prop.id;
						}
					}
				}

				(async () => {
					const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
					console.log("GSTORE GET ONE ENTITY : ", r);
					if(r) {
						const r_copy = JSON.parse(JSON.stringify(r));
						set_M(r_copy);
					}
				})();

				const subs = [];

				subs.push(AS.GSTORE.subscribe_selected_entities(INFO.model_id, (e) => {
					const id = g_fn.get_selected_entity_id(props, INFO);
					if(!id) return console.warn("NO ID FOUND TO UPDATE ENTITY IN SUBSCRIPTION");
					(async () => {
						const r = await AS.GSTORE.get_one_entity(INFO.model_id, id);
						console.log("GSTORE GET ONE ENTITY IN SUBSCRIPTION -> UPDATE_ONE : ", INFO.model_id, r);
						if(r) {
							const r_copy = JSON.parse(JSON.stringify(r));
							set_M(r_copy);
						}
					})();
				}));

				return () => {
					subs.forEach(sub=>sub.unsubscribe());
				}
			}
		
const update_test_model = async () => {
	const test_model = g_fn.get_model_by_name('test');
	if(!test_model) return console.warn('Test model is not found');

	const test_model_id = test_model.id;

	const test_entity_id = M.id;
	if(!test_entity_id) return console.warn('no entity found to update for model ', test_model);
	
	const upd = await g_fn.AS.GSTORE.update_one(test_model_id, test_entity_id, M);
	if(!upd.success) {
		console.warn('test model update is unsuccessfull ');
		return;
	}
	document.getElementById('bnode_3mru1')?.click();
}


			// STATEMENTS
			
		INFO.set_M          = set_M;
		INFO.on_created     = props.on_created  || props.INFO?.on_created;
		INFO.on_selected    = props.on_selected || props.INFO?.on_selected;
		const relation		= null;
		const query			=	null;
	



			// CONDITIONALS ATTRS
			const COND_ATTRS = {};
			
				COND_ATTRS["bnode_8azj4"] = COND_ATTRS["bnode_8azj4"] || {};
			

				COND_ATTRS["bnode_1vscm"] = COND_ATTRS["bnode_1vscm"] || {};
			

				COND_ATTRS["bnode_6hkrn"] = COND_ATTRS["bnode_6hkrn"] || {};
			

				COND_ATTRS["bnode_4395d"] = COND_ATTRS["bnode_4395d"] || {};
			

				COND_ATTRS["bnode_501vj"] = COND_ATTRS["bnode_501vj"] || {};
			

				COND_ATTRS["bnode_b83g7"] = COND_ATTRS["bnode_b83g7"] || {};
			

				COND_ATTRS["bnode_v2zfq"] = COND_ATTRS["bnode_v2zfq"] || {};
			

				COND_ATTRS["bnode_9yrdp"] = COND_ATTRS["bnode_9yrdp"] || {};
			

				COND_ATTRS["bnode_lcquv"] = COND_ATTRS["bnode_lcquv"] || {};
			

				COND_ATTRS["bnode_nxt7q"] = COND_ATTRS["bnode_nxt7q"] || {};
			

				COND_ATTRS["bnode_iktgq"] = COND_ATTRS["bnode_iktgq"] || {};
			

				COND_ATTRS["bnode_na8oc"] = COND_ATTRS["bnode_na8oc"] || {};
			

				COND_ATTRS["bnode_ljubz"] = COND_ATTRS["bnode_ljubz"] || {};
			

				COND_ATTRS["bnode_fj55h"] = COND_ATTRS["bnode_fj55h"] || {};
			

				COND_ATTRS["bnode_0a03r"] = COND_ATTRS["bnode_0a03r"] || {};
			

				COND_ATTRS["bnode_6q6go"] = COND_ATTRS["bnode_6q6go"] || {};
			

				COND_ATTRS["bnode_3dxee"] = COND_ATTRS["bnode_3dxee"] || {};
			

				COND_ATTRS["bnode_28k1l"] = COND_ATTRS["bnode_28k1l"] || {};
			

				COND_ATTRS["bnode_n2e2w"] = COND_ATTRS["bnode_n2e2w"] || {};
			

				COND_ATTRS["bnode_jbhn5"] = COND_ATTRS["bnode_jbhn5"] || {};
			

				COND_ATTRS["bnode_slf9o"] = COND_ATTRS["bnode_slf9o"] || {};
			

				COND_ATTRS["bnode_xq1lt"] = COND_ATTRS["bnode_xq1lt"] || {};
			

				COND_ATTRS["bnode_3xg9l"] = COND_ATTRS["bnode_3xg9l"] || {};
			

				COND_ATTRS["bnode_k8c0d"] = COND_ATTRS["bnode_k8c0d"] || {};
			

				COND_ATTRS["bnode_1rnvd"] = COND_ATTRS["bnode_1rnvd"] || {};
			

				COND_ATTRS["bnode_n6o3n"] = COND_ATTRS["bnode_n6o3n"] || {};
			

				COND_ATTRS["bnode_1y2ho"] = COND_ATTRS["bnode_1y2ho"] || {};
			

				COND_ATTRS["bnode_9wkto"] = COND_ATTRS["bnode_9wkto"] || {};
			

				COND_ATTRS["bnode_uib9h"] = COND_ATTRS["bnode_uib9h"] || {};
			

				COND_ATTRS["bnode_xq3j5"] = COND_ATTRS["bnode_xq3j5"] || {};
			

				COND_ATTRS["bnode_moemt"] = COND_ATTRS["bnode_moemt"] || {};
			

				COND_ATTRS["bnode_fd97b"] = COND_ATTRS["bnode_fd97b"] || {};
			

				COND_ATTRS["bnode_f1487"] = COND_ATTRS["bnode_f1487"] || {};
			

				COND_ATTRS["bnode_g0nzn"] = COND_ATTRS["bnode_g0nzn"] || {};
			

				COND_ATTRS["bnode_wzyuk"] = COND_ATTRS["bnode_wzyuk"] || {};
			

				COND_ATTRS["bnode_z2c96"] = COND_ATTRS["bnode_z2c96"] || {};
			

				COND_ATTRS["bnode_09w9d"] = COND_ATTRS["bnode_09w9d"] || {};
			

				COND_ATTRS["bnode_ff55z"] = COND_ATTRS["bnode_ff55z"] || {};
			

				COND_ATTRS["bnode_d5nss"] = COND_ATTRS["bnode_d5nss"] || {};
			

				COND_ATTRS["bnode_lje31"] = COND_ATTRS["bnode_lje31"] || {};
			

				COND_ATTRS["bnode_qrtlo"] = COND_ATTRS["bnode_qrtlo"] || {};
			

				COND_ATTRS["bnode_y5dap"] = COND_ATTRS["bnode_y5dap"] || {};
			

				COND_ATTRS["bnode_ezppn"] = COND_ATTRS["bnode_ezppn"] || {};
			

				COND_ATTRS["bnode_qpwkk"] = COND_ATTRS["bnode_qpwkk"] || {};
			

				COND_ATTRS["bnode_qpln5"] = COND_ATTRS["bnode_qpln5"] || {};
			

				COND_ATTRS["bnode_i8k3m"] = COND_ATTRS["bnode_i8k3m"] || {};
			

				COND_ATTRS["bnode_mmofk"] = COND_ATTRS["bnode_mmofk"] || {};
			

				COND_ATTRS["bnode_o3x1x"] = COND_ATTRS["bnode_o3x1x"] || {};
			

				COND_ATTRS["bnode_5mxan"] = COND_ATTRS["bnode_5mxan"] || {};
			

				COND_ATTRS["bnode_0pu6y"] = COND_ATTRS["bnode_0pu6y"] || {};
			

				COND_ATTRS["bnode_cnr2u"] = COND_ATTRS["bnode_cnr2u"] || {};
			

				COND_ATTRS["bnode_6kzj3"] = COND_ATTRS["bnode_6kzj3"] || {};
			

				COND_ATTRS["bnode_jr926"] = COND_ATTRS["bnode_jr926"] || {};
			

				COND_ATTRS["bnode_kzmes"] = COND_ATTRS["bnode_kzmes"] || {};
			

				COND_ATTRS["bnode_wmej4"] = COND_ATTRS["bnode_wmej4"] || {};
			

				COND_ATTRS["bnode_df5va"] = COND_ATTRS["bnode_df5va"] || {};
			

				COND_ATTRS["bnode_bcge7"] = COND_ATTRS["bnode_bcge7"] || {};
			

				COND_ATTRS["bnode_cy9yg"] = COND_ATTRS["bnode_cy9yg"] || {};
			

				COND_ATTRS["bnode_ym37n"] = COND_ATTRS["bnode_ym37n"] || {};
			

				COND_ATTRS["bnode_7m2fj"] = COND_ATTRS["bnode_7m2fj"] || {};
			

				COND_ATTRS["bnode_21gax"] = COND_ATTRS["bnode_21gax"] || {};
			

				COND_ATTRS["bnode_x00cb"] = COND_ATTRS["bnode_x00cb"] || {};
			

				COND_ATTRS["bnode_rxvft"] = COND_ATTRS["bnode_rxvft"] || {};
			


			// DYNAMIC REACT STATES
			const REACT_STATES = {};
REACT_STATES["M"] = {state: M, set_state: set_M};

		const AS_COMPONENTS = AS.components["comp_bnode_rxvft"] || {};
		AS_COMPONENTS.REACT_STATES = REACT_STATES;
		AS.components["comp_bnode_rxvft"] = AS_COMPONENTS;
	



			// MAPPED DATA
			const MAPPED_DATA = {};



			return (
				<ErrorBoundary fallbackRender={fallbackRender} onReset={(d) => {console.error(d)}}>
					
            <div data_id="bnode_rxvft" name="test" op="update_one" className="w-full flex flex-col p-16 border rounded-[16px] relative h-full overflow-auto scrollbar-hide" id={"bnode_rxvft" + (idx?"__"+idx:"")} onClick={(e)=>{
            g_fn.dev.emit_m(e, M, INFO)
        }} extra-attr >
                
            <div data_id="bnode_8azj4" className="new-div w-full text-xl font-['Open_Sans'] flex justify-start items-center pb-5 uppercase font-semibold" id={"bnode_8azj4" + (idx?"__"+idx:"")} extra-attr >
                Quiz Settings
            </div>
        

            <div data_id="bnode_cy9yg" name="test-data" className="flex flex-col gap-5" id={"bnode_cy9yg" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_v2zfq" name="CONT-name" className="w-full" id={"bnode_v2zfq" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_b83g7" id={"bnode_b83g7" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_1vscm" className="block text-black mb-2 capitalize font-['Open_Sans'] font-normal text-sm" id={"bnode_1vscm" + (idx?"__"+idx:"")} extra-attr >
                name
            </div>
        

            <div data_id="bnode_501vj" name="name" className="flex flex-col  w-full gap-6 " id={"bnode_501vj" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_4395d" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_4395d" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_6hkrn" name="name" type="text" value={M.name} placeholder="Enter Your Quiz Name" action="oninput-update-m" className="border border-[#9CA3AF] bg-white px-5 py-2  rounded-[12px] w-full placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 text-sm font-normal font-['Open_Sans']" id={"bnode_6hkrn" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_3xg9l" name="CONT-subject" className="w-full" id={"bnode_3xg9l" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_xq1lt" id={"bnode_xq1lt" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_9yrdp" className="block text-black mb-2 capitalize font-['Open_Sans'] font-normal text-sm" id={"bnode_9yrdp" + (idx?"__"+idx:"")} extra-attr >
                subject
            </div>
        

            <div data_id="bnode_slf9o" name="subject" className="flex flex-col  w-full gap-6 " id={"bnode_slf9o" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_jbhn5" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_jbhn5" + (idx?"__"+idx:"")} extra-attr >
                
            <select data_id="bnode_n2e2w" name="subject" placeholder="subject" action="oninput-update-m" className="bg-[#F3F4F6] px-5 py-2 rounded-[12px] w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] text-sm" id={"bnode_n2e2w" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            <option data_id="bnode_lcquv" selected="true" id={"bnode_lcquv" + (idx?"__"+idx:"")} extra-attr >
                
            </option>
        

            <option data_id="bnode_nxt7q" value="Mathematics" selected={M.subject === 'Mathematics'} id={"bnode_nxt7q" + (idx?"__"+idx:"")} extra-attr >
                Mathematics
            </option>
        

            <option data_id="bnode_iktgq" value="Science" selected={M.subject === 'Science'} id={"bnode_iktgq" + (idx?"__"+idx:"")} extra-attr >
                Science
            </option>
        

            <option data_id="bnode_na8oc" value="Social Science" selected={M.subject === 'Social Science'} id={"bnode_na8oc" + (idx?"__"+idx:"")} extra-attr >
                Social Science
            </option>
        

            <option data_id="bnode_ljubz" value="History" selected={M.subject === 'History'} id={"bnode_ljubz" + (idx?"__"+idx:"")} extra-attr >
                History
            </option>
        

            <option data_id="bnode_fj55h" value="Civics" selected={M.subject === 'Civics'} id={"bnode_fj55h" + (idx?"__"+idx:"")} extra-attr >
                Civics
            </option>
        

            <option data_id="bnode_0a03r" value="Biology" selected={M.subject === 'Biology'} id={"bnode_0a03r" + (idx?"__"+idx:"")} extra-attr >
                Biology
            </option>
        

            <option data_id="bnode_6q6go" value="Physics" selected={M.subject === 'Physics'} id={"bnode_6q6go" + (idx?"__"+idx:"")} extra-attr >
                Physics
            </option>
        

            <option data_id="bnode_3dxee" value="Chemistry" selected={M.subject === 'Chemistry'} id={"bnode_3dxee" + (idx?"__"+idx:"")} extra-attr >
                Chemistry
            </option>
        

            <option data_id="bnode_28k1l" value="English" selected={M.subject === 'English'} id={"bnode_28k1l" + (idx?"__"+idx:"")} extra-attr >
                English
            </option>
        
            </select>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_f1487" name="CONT-curriculum" className="w-full" id={"bnode_f1487" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_fd97b" id={"bnode_fd97b" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_k8c0d" className="block text-black mb-2 capitalize font-['Open_Sans'] font-normal text-sm" id={"bnode_k8c0d" + (idx?"__"+idx:"")} extra-attr >
                curriculum
            </div>
        

            <div data_id="bnode_moemt" name="curriculum" className="flex flex-col  w-full gap-6 " id={"bnode_moemt" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_xq3j5" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_xq3j5" + (idx?"__"+idx:"")} extra-attr >
                
            <select data_id="bnode_uib9h" name="curriculum" placeholder="curriculum" action="oninput-update-m" className="bg-[#F3F4F6] px-5 py-2 w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] text-sm rounded-[12px]" id={"bnode_uib9h" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            <option data_id="bnode_1rnvd" selected="true" id={"bnode_1rnvd" + (idx?"__"+idx:"")} extra-attr >
                
            </option>
        

            <option data_id="bnode_n6o3n" value="CBSE" selected={M.curriculum === 'CBSE'} id={"bnode_n6o3n" + (idx?"__"+idx:"")} extra-attr >
                CBSE
            </option>
        

            <option data_id="bnode_1y2ho" value="ICSE" selected={M.curriculum === 'ICSE'} id={"bnode_1y2ho" + (idx?"__"+idx:"")} extra-attr >
                ICSE
            </option>
        

            <option data_id="bnode_9wkto" value="State Board" selected={M.curriculum === 'State Board'} id={"bnode_9wkto" + (idx?"__"+idx:"")} extra-attr >
                State Board
            </option>
        
            </select>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_qpln5" name="CONT-grade" className="w-full" id={"bnode_qpln5" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_qpwkk" id={"bnode_qpwkk" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_g0nzn" className="block text-black mb-2 capitalize font-['Open_Sans'] font-normal text-sm" id={"bnode_g0nzn" + (idx?"__"+idx:"")} extra-attr >
                grade
            </div>
        

            <div data_id="bnode_ezppn" name="grade" className="flex flex-col  w-full gap-6 " id={"bnode_ezppn" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_y5dap" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_y5dap" + (idx?"__"+idx:"")} extra-attr >
                
            <select data_id="bnode_qrtlo" name="grade" placeholder="grade" action="oninput-update-m" className="bg-[#F3F4F6] px-5 py-2 rounded-[12px] w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] text-sm" id={"bnode_qrtlo" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            <option data_id="bnode_wzyuk" selected="true" id={"bnode_wzyuk" + (idx?"__"+idx:"")} extra-attr >
                
            </option>
        

            <option data_id="bnode_z2c96" value="8" selected={M.grade === '8'} id={"bnode_z2c96" + (idx?"__"+idx:"")} extra-attr >
                8
            </option>
        

            <option data_id="bnode_09w9d" value="9" selected={M.grade === '9'} id={"bnode_09w9d" + (idx?"__"+idx:"")} extra-attr >
                9
            </option>
        

            <option data_id="bnode_ff55z" value="10" selected={M.grade === '10'} id={"bnode_ff55z" + (idx?"__"+idx:"")} extra-attr >
                10
            </option>
        

            <option data_id="bnode_d5nss" value="11" selected={M.grade === '11'} id={"bnode_d5nss" + (idx?"__"+idx:"")} extra-attr >
                11
            </option>
        

            <option data_id="bnode_lje31" value="12" selected={M.grade === '12'} id={"bnode_lje31" + (idx?"__"+idx:"")} extra-attr >
                12
            </option>
        
            </select>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_cnr2u" name="CONT-time" className="w-full" id={"bnode_cnr2u" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_0pu6y" id={"bnode_0pu6y" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_i8k3m" className="block text-black mb-2 capitalize font-['Open_Sans'] font-normal text-sm" id={"bnode_i8k3m" + (idx?"__"+idx:"")} extra-attr >
                Quiz Duration
            </div>
        

            <div data_id="bnode_5mxan" name="time" className="flex flex-col  w-full gap-6 " id={"bnode_5mxan" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_o3x1x" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_o3x1x" + (idx?"__"+idx:"")} extra-attr >
                <input data_id="bnode_mmofk" name="time" type="number" value={M.time} placeholder="time" action="oninput-update-m" className="bg-[#F3F4F6] px-5 py-2 rounded-[12px] w-full placeholder:text-base placeholder:capitalize placeholder:text-[#222222]/30 font-normal font-['Open_Sans'] text-sm" id={"bnode_mmofk" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr />
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_bcge7" name="CONT-test_instructions" className="w-full" id={"bnode_bcge7" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_df5va" id={"bnode_df5va" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_6kzj3" className="block text-black mb-2 capitalize font-['Open_Sans'] font-normal text-sm" id={"bnode_6kzj3" + (idx?"__"+idx:"")} extra-attr >
                Quiz instructions
            </div>
        

            <div data_id="bnode_wmej4" name="test_instructions" className="flex flex-col  w-full gap-6 " id={"bnode_wmej4" + (idx?"__"+idx:"")} extra-attr >
                
            <div data_id="bnode_kzmes" name="cont" className="flex flex-row items-center justify-center w-full gap-4" id={"bnode_kzmes" + (idx?"__"+idx:"")} extra-attr >
                
            <textarea data_id="bnode_jr926" name="test_instructions" placeholder="Enter your text instructions" value={M.test_instructions} action="oninput-update-m" className="border border-[#9CA3AF] bg-white px-5 py-2  rounded-[12px] w-full placeholder:text-sm placeholder:capitalize placeholder:text-[#222222]/30 text-sm font-normal font-['Open_Sans']" id={"bnode_jr926" + (idx?"__"+idx:"")} model_id="yvjbe" onInput={(e)=>{g_fn.bro_on_input(e, INFO)}} extra-attr >
                
            </textarea>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        
            </div>
        

            <div data_id="bnode_x00cb" className="flex flex-row gap-4 pt-5" id={"bnode_x00cb" + (idx?"__"+idx:"")} extra-attr >
                
            <button data_id="bnode_ym37n" className="w-fit text-white bg-[#FF5994] focus:ring-4 focus:ring-blue-300 rounded-[8px] px-3 py-1 font-['Open_Sans'] flex justify-end items-center text-sm font-normal" id={"bnode_ym37n" + (idx?"__"+idx:"")} onClick={update_test_model} extra-attr >
                Submit
            </button>
        

            <button data_id="bnode_7m2fj" type="button" className="text-black bg-[#F3F4F6] focus:ring-4 focus:ring-blue-300 p-4 font-['Open_Sans'] font-semibold flex justify-end items-center rounded-[8px] text-sm" id={"bnode_7m2fj" + (idx?"__"+idx:"")} name="publish" onClick={()=> {document.getElementById('bnode_3mru1')?.click()}} extra-attr >
                Cancel
            </button>
        

            <fn data_id="bnode_21gax" name="fn:update_test_model" id={"bnode_21gax" + (idx?"__"+idx:"")} extra-attr >
                
            </fn>
        
            </div>
        
            </div>
        
				</ErrorBoundary>
			)
		}

		export default comp_bnode_rxvft;