
import comp_bnode_fnv81 from "./comp/comp_bnode_fnv81"
import comp_bnode_3mcwk from "./comp/comp_bnode_3mcwk"
import comp_bnode_me6w0_raw_code from "./comp/comp_bnode_me6w0_raw_code"
import comp_bnode_me6w0 from "./comp/comp_bnode_me6w0"
import comp_bnode_muwns from "./comp/comp_bnode_muwns"
import comp_bnode_00000 from "./comp/comp_bnode_00000"
import comp_bnode_ltmqc from "./comp/comp_bnode_ltmqc"
import comp_bnode_0rvyp from "./comp/comp_bnode_0rvyp"
import comp_bnode_l1z1x from "./comp/comp_bnode_l1z1x"
import comp_bnode_a8dfp from "./comp/comp_bnode_a8dfp"
import comp_bnode_s5dvx from "./comp/comp_bnode_s5dvx"
import comp_bnode_e2ttk from "./comp/comp_bnode_e2ttk"
import comp_bnode_rxvft from "./comp/comp_bnode_rxvft"
import comp_bnode_qp1d5 from "./comp/comp_bnode_qp1d5"
import comp_bnode_wzto2 from "./comp/comp_bnode_wzto2"
import comp_bnode_36i4u from "./comp/comp_bnode_36i4u"
import comp_bnode_jj973 from "./comp/comp_bnode_jj973"
import comp_bnode_4ft0a from "./comp/comp_bnode_4ft0a"
import comp_bnode_yezo2 from "./comp/comp_bnode_yezo2"
import comp_bnode_986bz from "./comp/comp_bnode_986bz"
import comp_bnode_k2lnn from "./comp/comp_bnode_k2lnn"
import comp_bnode_mqogx from "./comp/comp_bnode_mqogx"
import comp_bnode_v6ckv from "./comp/comp_bnode_v6ckv"
import comp_bnode_h4zoh from "./comp/comp_bnode_h4zoh"
import comp_bnode_m6ry2 from "./comp/comp_bnode_m6ry2"
import comp_bnode_5nuxp from "./comp/comp_bnode_5nuxp"
import comp_bnode_uqf0i from "./comp/comp_bnode_uqf0i"
import comp_bnode_6c7ez from "./comp/comp_bnode_6c7ez"
import comp_bnode_uycr2 from "./comp/comp_bnode_uycr2"
import comp_bnode_n381t from "./comp/comp_bnode_n381t"
import comp_bnode_bhyih from "./comp/comp_bnode_bhyih"
import comp_bnode_pw9l9 from "./comp/comp_bnode_pw9l9"
import comp_bnode_kuni6 from "./comp/comp_bnode_kuni6"

        export const COMP_REG = {
            comp_bnode_fnv81,
comp_bnode_3mcwk,
comp_bnode_me6w0_raw_code,
comp_bnode_me6w0,
comp_bnode_muwns,
comp_bnode_00000,
comp_bnode_ltmqc,
comp_bnode_0rvyp,
comp_bnode_l1z1x,
comp_bnode_a8dfp,
comp_bnode_s5dvx,
comp_bnode_e2ttk,
comp_bnode_rxvft,
comp_bnode_qp1d5,
comp_bnode_wzto2,
comp_bnode_36i4u,
comp_bnode_jj973,
comp_bnode_4ft0a,
comp_bnode_yezo2,
comp_bnode_986bz,
comp_bnode_k2lnn,
comp_bnode_mqogx,
comp_bnode_v6ckv,
comp_bnode_h4zoh,
comp_bnode_m6ry2,
comp_bnode_5nuxp,
comp_bnode_uqf0i,
comp_bnode_6c7ez,
comp_bnode_uycr2,
comp_bnode_n381t,
comp_bnode_bhyih,
comp_bnode_pw9l9,
comp_bnode_kuni6,
            main: comp_bnode_kuni6
        }
    